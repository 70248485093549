import { Column } from 'primereact/column';
import { useState } from "react";
import { useAppContext } from '../../../../../../context/app';
import DataTableElement from '../../../../../components/atoms/data-table-view';
import PageHeader from '../../../../../components/atoms/page-header';
import HeaderReportFilter from '../../../../../components/organisms/header-report-filter';
import { ModuleEnumReport } from "../../../../../../dto/moduleEnum/module-enum-report";
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";

const ReportPartnerNewUsers: React.FC = () => {
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const appContext = useAppContext()
    const { apiProvider } = appContext

    const findReport = async (param: any) => {
        setIsLoading(true)
        const response: any = await apiProvider.post('/web/partner/report/new-users', param)
        if (response) {
            let dataGrid: any = [];

            // Total visualizations
            response.data.map((item: any) => {
                if (item.contractorId == null) {
                    setTotal(item)
                } else {
                    dataGrid.push(item)
                }
            })

            setResults(dataGrid)
        }
        setIsLoading(false)
    }

    const exportReport = async (param: any) => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.downloadPost('/web/partner/report/new-users/export', param)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Relatórios quantidade de clientes.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }


    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="TOTAL:" footerStyle={{ textAlign: 'center' }} />
                <Column footer={`${(total.totalUsers == null) ? 0 : total.totalUsers}`} footerStyle={{ textAlign: 'center' }} />
                <Column footer={`${(total.newUsers == null) ? 0 : total.newUsers}`} footerStyle={{ textAlign: 'center' }} />
            </Row>
        </ColumnGroup>
    );
    return <>
        <PageHeader text="Quantidade total de clientes e quantidade de novos clientes." subtext="Agrupado por contratante" />

        <HeaderReportFilter labelText="Contratante" exportReport={exportReport} findReport={findReport} typeReport={ModuleEnumReport.CONTRACTOR_B2C} typeModuleName={ModuleEnum.B2C} textPlaceholder="Selecione o contratante" />

        <div style={{ paddingTop: "2em" }}>
            <DataTableElement
                loading={isLoading}
                paginator
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                rows={10}
                value={results}
                responsiveLayout="scroll"
                emptyMessage="Nenhum resultado encontrado"
                itemsList={results}
                footerColumnGroup={footerGroup}
            >
                <Column header="Contrantante" alignHeader="center" align="center" field="contractorName" sortable style={{ width: '34%' }} />
                <Column header="Total de usuários" alignHeader="center" align="center" field="totalUsers" sortable style={{ width: '33%' }} />
                <Column header="Novos usuários" alignHeader="center" align="center" field="newUsers" sortable style={{ width: '33%' }} />
            </DataTableElement>
        </div>
    </>
}

export default ReportPartnerNewUsers