import { Form, Formik } from "formik"
import { Dropdown } from 'primereact/dropdown'
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../context/app"
import { Contractor } from "../../../../../../dto/contractor"
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum"
import { Product } from "../../../../../../dto/product"
import BackButtom from "../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../components/atoms/buttom-request-form"
import PageHeader from "../../../../../components/atoms/page-header"
import SpanSubtitle from "../../../../../components/atoms/span-subtitle"
import ItemForm from "../../../../../components/molecules/item-form"
import { LineFormElement } from "../../../../../components/molecules/item-form/style"
import FormTemplateElement from "../../../../../templates/form-template"
import { DivInput } from '../../../partner/benefit/form/style'
import { ISessionContext, useSessionContext } from "../../../../../../context/session";

const ProductForm: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location as any;
    const isNew = !location.state;
    const appContext = useAppContext();
    const { apiProvider } = appContext;
    const [product, setProduct] = useState({} as Product);
    const [disabled, setDisabled] = useState(false);
    const [selectedContractorsB2BOrB2C, setSelectedContractorsB2BOrB2C] = useState<Contractor>();
    const [groupedContractors, setGroupedContractors] = useState([] as any[]);
    const [admin, setAdmin] = useState(false);
    const context: ISessionContext = useSessionContext();

    const validations = Yup.object({
        name: Yup.string().required().max(250),
        externalCode: Yup.string().required().max(10),
    })

    const save = async (values: any) => {
        appContext.setIsShowLoading(true)
        try {
            let urlAdmin: any = '';
            if (admin) {
                urlAdmin = '/admin';
                values.partnerId = selectedContractorsB2BOrB2C?.id
            }

            if (!isNew) {
                apiProvider.put("/web/product" + urlAdmin, values)
                    .then((response) => {
                        if (response.status == 200) {
                            appContext.toast.show({
                                severity: "success",
                                summary: "Produto",
                                detail: "Editado com sucesso!",
                                life: 3000,
                            })
                            navigate("/product-view")
                        } else {
                            appContext.toast.show({
                                severity: "error",
                                summary: "Produto",
                                detail: "Erro ao salvar produto, tente novamente!",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: "error",
                            summary: "Produto",
                            detail: error.response.data.message || "Erro ao salvar produto, tente novamente!",
                            life: 3000,
                        })
                    });
            } else {
                apiProvider.post("/web/product" + urlAdmin, values)
                    .then((response) => {
                        if (response.status == 200) {
                            appContext.toast.show({
                                severity: "success",
                                summary: "Produto",
                                detail: "Salvo com sucesso!",
                                life: 3000,
                            })
                            navigate("/product-view")
                        } else {
                            appContext.toast.show({
                                severity: "error",
                                summary: "Produto",
                                detail: "Erro ao salvar produto, tente novamente!",
                                life: 3000,
                            })
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: "error",
                            summary: "Produto",
                            detail: error.response.data.message || "Erro ao salvar produto, tente novamente!",
                            life: 3000,
                        })
                    });
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Produto",
                detail: error.response.data.message || "Erro ao salvar produto, tente novamente!",
                life: 3000,
            })
        }

        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!product.id
    }

    const getOneProduct = useCallback(
        async function () {
            const { data: { id, category, name, externalCode } } = await apiProvider.get(`/web/product/${state.item.id}`)

            const data: any = {
                category: { ...category },
                id,
                name,
                externalCode
            }
            setProduct(data)
        }, [apiProvider, state?.item.id])

    const getAllContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/partner')

        if (response?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            response.data.forEach((item: Contractor) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({ label: item.name, value: item });
                    if (state?.item?.partnerId == item.id) {
                        setSelectedContractorsB2BOrB2C(item);
                    }
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({ label: item.name, value: item });
                    if (state?.item?.partnerId == item.id) {
                        setSelectedContractorsB2BOrB2C(item);
                    }
                }
            });

            let objectB2cAndB2B = [];

            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2C', items: aB2C })
            }

            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2B', items: aB2B })
            }

            setGroupedContractors(objectB2cAndB2B)
        }
    }, [])

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOneProduct()
        }
    }, [getOneProduct, apiProvider, isNew, state?.disabled])

    useEffect(() => {
        if (context.session.modules[0].moduleEnum === ModuleEnum.ADMIN) {
            setAdmin(true);
        }
        getAllContractors();
        if (selectedContractorsB2BOrB2C == null && isNew) {
            setDisabled(true);
        }
    }, [])

    return (
        <>
            <PageHeader text="Novo produto" />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate("/product-view")
                    }}
                />

                <SpanSubtitle text="Dados - Produto" />

                {(isLoadApi() || isNew) && (
                    <Formik
                        validationSchema={validations}
                        initialValues={product}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                {admin &&
                                    <LineFormElement>
                                        <DivInput style={{ width: '100%' }}>
                                            CONTRATANTE
                                            <Dropdown
                                                name='contractor'
                                                style={{ width: '100%', height: '50px', borderRadius: '10px' }}
                                                disabled={!isNew}
                                                value={selectedContractorsB2BOrB2C}
                                                onChange={(e) => setSelectedContractorsB2BOrB2C(e.value)}
                                                options={groupedContractors}
                                                optionLabel="label"
                                                optionGroupLabel="label"
                                                optionGroupChildren="items"
                                                emptyMessage="Nenhum resultado encontrado."
                                                emptyFilterMessage="Nenhum resultado encontrado."
                                                placeholder="Selecione o parceiro"
                                                showFilterClear
                                                filter
                                            />
                                        </DivInput>
                                    </LineFormElement>
                                }
                                <LineFormElement style={{ justifyContent: 'space-between' }}>
                                    <ItemForm
                                        propsForm={propsForm}
                                        name="name"
                                        label="Nome"
                                        widthPercent="50"
                                    />
                                    <ItemForm
                                        propsForm={propsForm}
                                        name="externalCode"
                                        label="Código externo"
                                        widthPercent="50"
                                    />
                                </LineFormElement>

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        type="submit"
                                        disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}
                                    >
                                        Salvar dados
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    )
}

export default ProductForm
