import styled from "styled-components";
import talkToUsWave from "../../../assets/talkToUsWave.png";

export const MainDivElement = styled.div`
    display: flex;
    width: 100%; 
`;

export const LeftDivElement = styled.div`
    width: 30%;
    padding: 22px;
`;

export const RightSideDivElement = styled.div`
    background-size: cover;
    background-image: url(${talkToUsWave});
    border-radius: 10px 5px 30px 5px;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-right: -20px;
`;