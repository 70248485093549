import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from "formik";
import { Dropdown } from 'primereact/dropdown';
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { useAppContext } from "../../../../../../../context/app";
import { Category } from "../../../../../../../dto/category";
import BackButtom from "../../../../../../components/atoms/back-buttom";
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form";
import PageHeader from "../../../../../../components/atoms/page-header";
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle";
import ImageUpdate from '../../../../../../components/molecules/image-update';
import ItemForm from "../../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../../components/molecules/item-form/style";
import FormTemplateElement from "../../../../../../templates/form-template";
library.add(fas);

const CategoryForm: React.FC = () => {
    const [category, setCategory] = useState({} as Category)
    const [disabled, setDisabled] = useState(false)
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const isImg = false;
    const { state } = location as any;
    const [selectedIcon, setSelectedIcon] = useState() as any;
    const [iconChanged, seticonChanged] = useState(false)
    const [listIcons, setListIcons] = useState<{ name: string }[]>([]);

    const selectedCountryTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <FontAwesomeIcon icon={option.iconName} /> - {option.iconName}
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <FontAwesomeIcon icon={option.iconName} /> - {option.iconName}
            </div>
        );
    };

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled);
            getOneCategory();
        }

        let responseArray: any[] = [];
        const iconNames = Object.keys(fas);
        const addedIcons = new Set();

        iconNames.forEach((iconName) => {
            const icon = fas[iconName];
            if (!addedIcons.has(icon.iconName)) {
                responseArray.push(icon);
                addedIcons.add(icon.iconName);
            }
        });

        setListIcons(responseArray);
    }, []);

    const getOneCategory = useCallback(async function () {
        const response: any = await apiProvider.get(`/web/category/${state.item.id}`);
        setCategory(response.data);
        if (response.data.nameIcon) {
            const iconNames = Object.keys(fas);
            iconNames.forEach((iconName) => {
                const icon = fas[iconName];
                if (response.data.nameIcon == icon.iconName) {
                    const icon = fas[iconName];
                    setSelectedIcon(icon);
                }
            });
        }
    }, []);

    // Validações do yup
    const validations = Yup.object().shape({
        name: Yup.string().required().max(250)
    })

    // Salvar Category
    async function save(values: any) {
        const formData = new FormData();

        const data = {
            id: values.id ? values.id : null,
            name: values.name,
            isPreferred: values.isPreferred,
            nameIcon: selectedIcon.iconName,
            unicode: selectedIcon.icon[3]
        }

        formData.append("category", JSON.stringify(data));
        formData.append("file", values.file);

        appContext.setIsShowLoading(true);
        try {
            if (!isNew) {
                await apiProvider.put("/web/category", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Categoria',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate("/category-view")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Categoria',
                            detail: error.response.data.message || "Erro ao salvar a categoria, tente novamente!",
                            life: 3000,
                        });
                    });
            } else {
                await apiProvider.post("/web/category", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Categoria',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate("/category-view")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Categoria',
                            detail: error.response.data.message || "Erro ao salvar a categoria, tente novamente!",
                            life: 3000,
                        });
                    });
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Categoria",
                detail: error.response.data.message || "Erro ao salvar a categoria, tente novamente!",
                life: 3000,
            })
        }

        appContext.setIsShowLoading(false);
    }

    function handdleChangeIcon(value: any) {
        setSelectedIcon(value);
        seticonChanged(true);
    }

    function isLoadApi(): boolean {
        return !!category.id;
    }

    return (
        <>
            <PageHeader text="Nova categoria" />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate("/category-view");
                    }}
                />

                <SpanSubtitle text="Dados - Categoria" />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={category}
                        validationSchema={validations}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <ItemForm
                                        disabled={disabled}
                                        propsForm={propsForm}
                                        name="name"
                                        label="Nome"
                                        widthPercent="100"
                                    />
                                </LineFormElement>

                                <LineFormElement>
                                    {isImg && <ImageUpdate
                                        disabled={disabled}
                                        name="file"
                                        nameUrl="imageUrl"
                                        label="Imagem para upload"
                                        propsForm={propsForm}
                                        widthImage={365}
                                        heightImage={177}
                                    />}
                                </LineFormElement>

                                <LineFormElement>
                                    <Dropdown
                                        value={selectedIcon}
                                        onChange={(e) => handdleChangeIcon(e.value)}
                                        options={listIcons}
                                        optionLabel="iconName"
                                        placeholder="Selecione o icone"
                                        filter
                                        valueTemplate={selectedCountryTemplate}
                                        itemTemplate={countryOptionTemplate}
                                        style={{ width: '96.5%' }}
                                    />
                                </LineFormElement>

                                <div style={{ textAlign: 'end' }}>
                                    {!disabled && (
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={isNew ?
                                                !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || selectedIcon == null
                                                :
                                                propsForm.isSubmitting || !propsForm.dirty && !iconChanged
                                            }
                                        >
                                            Salvar dados
                                        </ButtomRequestElement>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik >
                )}
            </FormTemplateElement >
        </>
    )
}

export default CategoryForm
