import {Column} from 'primereact/column';
import React, {useState} from "react";
import {useAppContext} from '../../../../../../context/app';
import {intToStrg} from '../../../../../../utils/formatUtil';
import DataTableElement from '../../../../../components/atoms/data-table-view';
import PageHeader from '../../../../../components/atoms/page-header';
import HeaderReportFilter from '../../../../../components/organisms/header-report-filter';
import {ModuleEnumReport} from "../../../../../../dto/moduleEnum/module-enum-report";
import {ModuleEnum} from "../../../../../../dto/moduleEnum/module-enum";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";

const ReportAdminGroupedByPartner: React.FC = () => {
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const appContext = useAppContext()
    const {apiProvider} = appContext

    const findReport = async (param: any) => {
        setIsLoading(true)
        const response: any = await apiProvider.post('/admin/report/grouped-by-partner', param)

        if (response) {
            let dataGrid: any = [];

            // Total visualizations
            response.data.map((item: any) => {
                if (item.id == null) {
                    setTotal(item)
                } else {
                    dataGrid.push(item)
                }
            })

            setResults(dataGrid)
        }

        setIsLoading(false)
    }

    const exportReport = async (param: any) => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.downloadPost('/admin/report/grouped-by-partner/export', param)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], {type: type})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Relatórios agrupado por parceiro.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    const formatTotal = (item: any, value: string) => {
        if (item.name === 'Total') {
            return (<b>{value}</b>)
        } else {
            return value
        }
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="TOTAL:" footerStyle={{textAlign: 'center'}}/>
                <Column footer={(total.partnerDetail == null)? 0: total.partnerDetail} footerStyle={{textAlign: 'center'}}/>
                <Column footer={(total.viewWebsite == null)? 0: total.viewWebsite} footerStyle={{textAlign: 'center'}}/>
                <Column footer={(total.viewPhone == null)? 0: total.viewPhone} footerStyle={{textAlign: 'center'}}/>
                <Column footer={(total.toCall == null)? 0: total.toCall} footerStyle={{ textAlign: 'center' }}/>
            </Row>
        </ColumnGroup>
    );

    return <>
        <PageHeader
            text="B2B - Visualizações agrupadas por parceiros"
            subtext="Cliques na página, no site, no contato e chamadas"
        />
        <HeaderReportFilter
            labelText="Contratante e Parceiro"
            exportReport={exportReport}
            findReport={findReport}
            typeReport={ModuleEnumReport.PARTNER_B2B}
            typeModuleName={ModuleEnum.B2B}
            textPlaceholder="Selecione o contratante e/ou parceiro"
        />
        <div style={{paddingTop: "2em"}}>
            <DataTableElement
                loading={isLoading}
                paginator
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                rows={10}
                value={results}
                responsiveLayout="scroll"
                emptyMessage="Nenhum resultado encontrado"
                itemsList={results}
                sortField="id"
                sortOrder={-1}
                rowsPerPageOptions={[5, 10, 25]}
                resizableColumns
                footerColumnGroup={footerGroup}
            >
                <Column header="Parceiro" alignHeader="center" align="center" field="name"
                        body={(rowData) => formatTotal(rowData, rowData.name)} sortable style={{width: '20%'}}/>
                <Column header="Visita" alignHeader="center" align="center" field="partnerDetail"
                        body={(rowData) => formatTotal(rowData, intToStrg(rowData.partnerDetail))} sortable
                        style={{width: '20%'}}/>
                <Column header="Clique site" alignHeader="center" align="center" field="viewWebsite"
                        body={(rowData) => formatTotal(rowData, intToStrg(rowData.viewWebsite))} sortable
                        style={{width: '20%'}}/>
                <Column header="Clique contato" alignHeader="center" align="center" field="viewPhone"
                        body={(rowData) => formatTotal(rowData, intToStrg(rowData.viewPhone))} sortable
                        style={{width: '20%'}}/>
                <Column header="Chamadas" alignHeader="center" align="center" field="toCall"
                        body={(rowData) => formatTotal(rowData, intToStrg(rowData.toCall))} sortable
                        style={{width: '20%'}}/>
            </DataTableElement>
        </div>
    </>
}

export default ReportAdminGroupedByPartner