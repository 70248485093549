import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import eyesIcon from "../../../../../../assets/buttons/eyes.svg"
import { useAppContext } from "../../../../../../context/app"
import { ModuleEnum } from '../../../../../../dto/moduleEnum/module-enum'
import { Session } from '../../../../../../dto/session'
import ButtomAddVm from "../../../../../components/atoms/buttom-add-vm"
import ButtonExport from "../../../../../components/atoms/buttom-export"
import DataTableElement from "../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../components/molecules/search-text-label"
import { HeaderElementLeft, HeaderElementRight } from "../../../../../components/organisms/header/style"
import { maskCNPJ } from "../../../../../templates/masks-template"
import HeaderViewElement from "../../../../../templates/view-header-template"
import TableViewElement from "../../../../../templates/view-table-template"
import { ActionButtons } from "../../../partner/benefit/view/style"

const ContractorPartners: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ partnerList: [], isLoading: true });
    const { partnerList } = state;
    const appContext = useAppContext();
    const { apiProvider } = appContext;
    const [filterText, setFilterText] = useState('');
    const session: Session = appContext.sessionProvider.getSession();

    const getAllPartners = useCallback(async () => {
        const response: any = await apiProvider.get('/web/filters/partner/all');
        setState({ partnerList: response.data, isLoading: false });
    }, []);

    const exportReport = async () => {
        appContext.setIsShowLoading(true);

        try {
            const response: any = await apiProvider.download('/web/filters/partner/export?filterText=' + filterText);

            const type = response.headers['content-type'];
            const blob = new Blob([response.data], { type: type });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Exportação de parceiros.xlsx';
            link.click();
        } catch (error) {
            console.error(error);
        }

        appContext.setIsShowLoading(false);
    }

    useEffect(() => {
        getAllPartners();
    }, []);

    const cnpjMask = (rowData: any) => {
        return (maskCNPJ(rowData.cnpj));
    }

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Parceiros" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={() => { navigate("/suggest-partner-form", { state: { goTo: '/contractor-partner-view' } }); }} text="Sugerir Parceiro" />

                    {session?.modules[0]?.moduleEnum === ModuleEnum.ADMIN && (
                        <ButtonExport onClick={exportReport} />
                    )}

                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />

                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rows={10}
                    value={state.partnerList}
                    responsiveLayout="scroll"
                    emptyMessage="Nenhum resultado encontrado"
                    itemsList={partnerList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="name" header="Empresa" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" body={cnpjMask} header="CNPJ" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="site" header="Site" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Detalhes do parceiro" style={{ width: '20%' }} />
                </DataTableElement>
            </TableViewElement>
        </>
    )

    function actionButtons(rowData: any) {
        return (
            <ActionButtons onClick={() => {
                navigate("/partner-contractor-form",
                    { state: { item: rowData, disabled: true } })
            }}
                style={{ width: '100%' }}
            >
                <img alt="" style={{ width: "17px" }} src={eyesIcon} />
            </ActionButtons>
        )
    }
}

export default ContractorPartners
