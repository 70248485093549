import { Form, Formik } from "formik"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useAppContext } from "../../../../../../context/app"
import { Partner } from "../../../../../../dto/partner"
import BackButtom from "../../../../../components/atoms/back-buttom"
import PageHeader from "../../../../../components/atoms/page-header"
import SpanSubtitle from "../../../../../components/atoms/span-subtitle"
import ItemForm from "../../../../../components/molecules/item-form"
import { LineFormElement } from "../../../../../components/molecules/item-form/style"
import FormTemplateElement from "../../../../../templates/form-template"
import { Accordion, AccordionTab } from 'primereact/accordion'

const PartnerFromContractor: React.FC = () => {
  const [partner, setPartner] = useState({} as Partner)
  const [disabled, setDisabled] = useState(false)
  const { apiProvider } = useAppContext()
  const navigate = useNavigate()
  const location = useLocation()
  const isNew = !location.state
  const { state } = location as any

  const save = (values: any) => { }

  const getOnePartner = useCallback(async () => {
    const response: any = await apiProvider.get(`/web/filters/partner/detail/${state.item.id}`)
    setPartner(response.data)
    partnerBenefitData(response.data)
  }, [])

  useEffect(() => {
      setDisabled(state.disabled)
      getOnePartner()
  }, [])

  const partnerBenefitData = (partner: any) => {
    return partner.benefits?.map((partnerValue: any, index:any) => {
        return (
            <AccordionTab style={{marginTop: '10px'}} key={index} header={(partnerValue.benefitValue + '%'  + ' - '  + partnerValue?.title)}>
                <strong>Regras: </strong>
                <span>{partnerValue.rule}</span>
            </AccordionTab>
             )
         })
      }

  return (
    <>
      <PageHeader text="Parceiro" />

      <FormTemplateElement>
        <BackButtom
          onclick={() => {
            navigate("/contractor-partner-view")
          }}
        />

        <SpanSubtitle text="Dados - Parceiro" />

        {(isLoadApi() || isNew) && (
          <Formik
            initialValues={partner && state.item}
            onSubmit={save}
          >
            {(propsForm) => (
              <Form>
                <LineFormElement style={{ justifyContent: "flex-start" }}>
                  <ItemForm
                    disabled={disabled}
                    propsForm={propsForm}
                    name="name"
                    label="Empresa"
                    widthPercent="32"
                  />
                  <ItemForm
                    disabled={disabled}
                    propsForm={propsForm}
                    name="cnpj"
                    label="cnpj"
                    widthPercent="32"
                  />
                </LineFormElement>

                <LineFormElement >
                    <Accordion style={{width: '100%'}}>
                        {partnerBenefitData(partner)}   
                    </Accordion> 
                </LineFormElement>

              </Form>
            )}
          </Formik>
        )}
      </FormTemplateElement>
    </>
  )

  function isLoadApi(): boolean {
    return !!partner.id
  }
}
export default PartnerFromContractor
