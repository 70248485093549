import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { InputSwitch } from "primereact/inputswitch"
import { Tag } from "primereact/tag"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import penIcon from "../../../../../../../assets/buttons/pen.svg"
import trashIcon from "../../../../../../../assets/buttons/trash.svg"
import { useAppContext } from "../../../../../../../context/app"
import { ModuleEnumStatus } from "../../../../../../../dto/moduleEnum/module-enum-status"
import { TruncateData } from "../../../../../../../utils/truncateData"
import { confirm } from "../../../../../../../utils/validation"
import ButtomAddVm from "../../../../../../components/atoms/buttom-add-vm"
import DataTableElement from "../../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../../components/molecules/search-text-label"
import { HeaderElementLeft, HeaderElementRight } from "../../../../../../components/organisms/header/style"
import HeaderViewElement from "../../../../../../templates/view-header-template"
import TableViewElement from "../../../../../../templates/view-table-template"
import { ActionButtons } from "../../../../partner/benefit/view/style"

const BannerView: React.FC = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({ bannerList: [] as any, isLoading: true })
    const { bannerList } = state
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()
    const [filterText, setFilterText] = useState("")

    // LISTAR TODOS OS BANNER
    const getBannerData = useCallback(async () => {
        const response: any = await apiProvider.get("/admin/banner");
        setState({ bannerList: response.data, isLoading: false });
    }, [apiProvider])

    useEffect(() => {
        getBannerData();
    }, [getBannerData])

    // Ações do status
    const handleToggle = async (e: any) => {
        try {
            const data = {
                id: e.id ? e.id : null,
                isActive: (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
            }

            const formData = new FormData()
            formData.append("banner", JSON.stringify(data))

            const response: any = await apiProvider.put(`/admin/banner`, formData)
            if (response == null) {
                throw new Error("Não foi possível realizar essa alteração, tente novamente!");
            }

            appContext.toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Alteração realizada com sucesso!',
                life: 3000,
            })

            setState({
                bannerList: bannerList.map((item: any) => {
                    if (item.id === e.id) {
                        return {
                            ...item,
                            isActive: (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
                        }
                    }
                    return item
                }), isLoading: false
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        }
    };

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Banner" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newBanner} text="Novo banner" />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rows={10}
                    value={state.bannerList}
                    responsiveLayout="scroll"
                    emptyMessage="Nenhum resultado encontrado"
                    itemsList={bannerList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="title" body={(e) => TruncateData(e.title, 50)} header="Título" sortable style={{ width: '45%' }} />
                    <Column alignHeader="center" align="center" field="priorityOrder" header="Prioridade" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="Intervalo" body={(e) => (e.startDay ?? '') + '/' + (e.endDay ?? '')} header="Intervalo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="discloseRequest" body={actionStatus} header="Origem" sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' body={(e) => <InputSwitch checked={(e.isActive == ModuleEnumStatus.ACTIVE)} onChange={() => handleToggle(e)} />} field='isActive' header='Ativo' sortable style={{ width: '15%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: '10%' }} />
                </DataTableElement>
            </TableViewElement>
        </>
    )

    function actionStatus(rowData: any) {
        if (rowData.discloseRequest) {
            return (<Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="Solicitação" />);
        } else {
            return (<Tag className="mr-2" icon="pi pi-user" severity="success" value="ADMIN" />);
        }
    }

    // DELETAR BANNER
    async function deleteBanner(rowData: any) {
        try {
            await apiProvider.delete(`/admin/banner/${rowData.id}`)
            appContext.toast.show({
                severity: "success",
                summary: "Banner",
                detail: "Excluido com sucesso!",
                life: 3000,
            })
            getBannerData()
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Banner",
                detail: "Erro ao excluir banner!",
                life: 3000,
            })
        }
    }

    // NOVO BANNER
    function newBanner() {
        navigate("/banner-form")
    }

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ActionButtons
                    onClick={() => confirm(rowData, "Deseja excluir este Banner?", () => deleteBanner(rowData))}>
                    <img style={{ width: "17px" }} alt="" src={trashIcon} />{" "}
                </ActionButtons>

                <ActionButtons
                    onClick={() => {
                        navigate("/banner-form", { state: { item: rowData, disabled: false }, })
                    }}
                >
                    <img style={{ width: "17px" }} alt="" src={penIcon} />
                </ActionButtons>
            </div>
        )
    }
}

export default BannerView
