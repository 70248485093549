import styled from "styled-components";

export const SubTitleDataElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DataElement = styled.div`
  display: flex;
  height: 80px;
  color: #42425d;
  margin-right: 10px;
  flex-direction: column;
`;

export const Error = styled.span`
  color: red;
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
`;

export const ColorElement = styled.span`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DivColors = styled.span`
  width: 32%;
  margin-right: 10px;
  padding-right: 1%;
`;
