import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { Tag } from 'primereact/tag';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import { useAppContext } from "../../../../../../../context/app";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import { HeaderElementRight } from "../../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import { ActionButtonsElement, ButtomsElement, HeaderElementLeft, TableElement } from "../../contractor/view/style";
import {TruncateData} from "../../../../../../../utils/truncateData";

const MessagesView: React.FC = () => {
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [state, setState] = useState({ messageList: [], isLoading: true });
    const { apiProvider } = useAppContext();
    const { messageList } = state;
    const [mQuery, setMQuery] = useState<any>({matches: window.innerWidth > 1200 ? true : false});

    useEffect(() => {
        getMessages();
    }, []);

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 1400px)");
        setMQuery(mediaQuery);
    }, []);

    const getMessages = useCallback(async function () {
        const response: any = await apiProvider.get("/admin/contact-us");
        setState({ messageList: response.data, isLoading: false });
    }, [])

    return (
        <>
            <PageHeader text="Mensagens"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementRight>
                    <SearchTextLabel onChange={(e: any) => { setFilterText(e.target.value); }} />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginator
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.messageList}
                    responsiveLayout="scroll"
                    itemsList={messageList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="senderName" body={(e) => TruncateData(e.senderName, 20)} header="Usuário" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="email" header="E-mail" sortable style={{ width: '20%', display: (mQuery && !mQuery.matches) ? 'none' : null}} />
                    <Column alignHeader="center" align="center" field="subject" header="Assunto" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="sendDate" header="Data de envio" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="status" body={actionStatus} header="Status" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "10%" }} />
                </DataTableElement>
            </TableElement>
        </>
    )

    function actionStatus(rowData: any) {
        switch (rowData.status) {
            case "ABERTO":
                return (<Tag className="mr-2" icon="pi pi-user" severity="success" value={rowData.status} />);
            case "PENDENTE":
                return (<Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value={rowData.status} />);
            case "FINALIZADO":
                return (<Tag icon="pi pi-times" severity="danger" value={rowData.status} />);
            default:
                return (<Tag icon="pi pi-times" severity="danger" value={rowData.status} />);
        }
    }

    function actionButtons(rowData: any) {
        return (
            <ButtomsElement style={{ justifyContent: "space-evenly" }}>
                <ActionButtonsElement
                    onClick={() => { navigate("/messages-form", { state: { item: rowData, disabled: false }, }) }} >
                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtonsElement>
            </ButtomsElement>
        );
    }

};

export default MessagesView;