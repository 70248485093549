import figura1 from "../../../../../assets/partner/banner1.png"
import figura2 from "../../../../../assets/partner/banner2.png"
import figura3 from "../../../../../assets/partner/banner3.png"
import { Banner } from "../../../../components/molecules/carousel-vm"
import LoginGeneric from "../../../../templates/login"

const Login: React.FC = () => {
  const banners: Banner[] = [
    {
      img: figura1,
      title: "Administre seus ganhos",
      text1: "Aqui é possível acompanhar suas atualizações",
      text2: (
        <>
          {" "}
          <strong>diárias, semanais e mensais.</strong>{" "}
        </>
      ),
    },
    {
      img: figura2,
      title: "Acompanhe as transações",
      text1: "Fique por dentro de tudo que está",
      text2: <>acontecendo dentro do &nbsp;<strong>seu negócio.</strong></>
    },
    {
      img: figura3,
      title: "Melhore seu engajamento",
      text1: "Use nossas ferramentas para melhorar sua",
      text2: <><>classificação no APP e &nbsp;</><strong>saia sempre na frente.</strong></>,
    },
  ]

  return <LoginGeneric banners={banners} />
}

export default Login
