import { Form, Formik } from 'formik'
import { InputMask } from 'primereact/inputmask'
import { InputTextarea } from 'primereact/inputtextarea'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { useAppContext } from '../../../../../../context/app'
import BackButtom from '../../../../../components/atoms/back-buttom'
import Error from '../../../../../components/atoms/error-message'
import DivNumberElement from '../../../../../components/atoms/input-number'
import SpanSubtitle from '../../../../../components/atoms/span-subtitle'
import ItemForm from '../../../../../components/molecules/item-form'
import { LineFormElement } from '../../../../../components/molecules/item-form/style'
import FormTemplateElement from '../../../../../templates/form-template'
import { ButtomSaveElement } from '../../../partner/benefit/form/style'
import { ButtomRequestElement } from '../../../partner/report/grouped-by-contractors/style'
import { InputTextareaDivElement } from '../../informative/form/style'

const SuggestPartner: React.FC = () => {
    const navigate = useNavigate()
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()
    const location = useLocation();
    const params: any = location.state;

    console.log(params.cameFrom);

    const validations = Yup.object().shape({
        companyName: Yup.string().required('Campo obrigatório'),
        message: Yup.string().required('Campo obrigatório'),
    })

    const save = async (values: any) => {
        const data = {
            id: values.id ? values.id : null,
            companyName: values.companyName,
            date: new Date(),
            email: values.email,
            message: values.message,
            phone: values.phone,
            cellPhone: values.cellPhone
        }

        try {
            await apiProvider.post('/web/suggestion', data)
            navigate('/contractor-partner-view')
            appContext.toast.show({
                severity: 'success',
                summary: 'Sugestão',
                detail: 'Sugestão enviada com sucesso!',
                life: 3000,
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Sugestão',
                detail: 'Erro ao enviar sugestão, tente novamente!',
                life: 3000,
            });
        }
    }

    return (
        <FormTemplateElement>

            <BackButtom onclick={() => navigate(params.goTo)} />

            <SpanSubtitle text='Sugestão' />
            <Formik
                initialValues={{} as any}
                validationSchema={validations}
                onSubmit={save}
            >
                {(propsForm) => (
                    <Form>
                        <LineFormElement style={{ justifyContent: 'flex-start' }}>
                            <ItemForm
                                propsForm={propsForm}
                                label='Nome da empresa'
                                widthPercent='100'
                                disabled={false}
                                name='companyName'
                            />

                        </LineFormElement>

                        <LineFormElement style={{ justifyContent: 'flex-start' }}>
                            <ItemForm
                                propsForm={propsForm}
                                label='Email'
                                widthPercent='29'
                                disabled={false}
                                name='email'
                            />
                            <DivNumberElement>
                                Fixo
                                <InputMask
                                    id='phone'
                                    style={{
                                        height: '51px',
                                        width: '100%',
                                        borderRadius: '10px',
                                        marginTop: '7px',
                                    }}
                                    mask={'(99) 9999-9999'}
                                    value={propsForm.values.phone}
                                    onChange={propsForm.handleChange}
                                />
                                <Error name="phone" errors={propsForm.errors} />
                            </DivNumberElement>
                            <DivNumberElement>
                                Celular
                                <InputMask
                                    id='cellPhone'
                                    style={{
                                        marginLeft: '25px',
                                        height: '51px',
                                        width: '100%',
                                        borderRadius: '10px',
                                        marginTop: '7px',
                                    }}
                                    mask={'(99) 9999-9999'}
                                    value={propsForm.values.cellPhone}
                                    onChange={propsForm.handleChange}
                                />
                                <Error name="cellPhone" errors={propsForm.errors} />
                            </DivNumberElement>
                        </LineFormElement>
                        <LineFormElement>
                            <InputTextareaDivElement style={{ width: '100%' }}>
                                Mensagem
                                <InputTextarea
                                    style={{ marginTop: '5px' }}
                                    value={propsForm.values.message}
                                    name='message'
                                    autoResize
                                    onChange={propsForm.handleChange}
                                />
                                <Error name="message" errors={propsForm.errors} />
                            </InputTextareaDivElement>
                        </LineFormElement>
                        <div style={{ textAlign: 'end' }}>
                            <ButtomSaveElement>
                                <ButtomRequestElement
                                    type='submit'
                                    disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}>
                                    Sugerir
                                </ButtomRequestElement>
                            </ButtomSaveElement>
                        </div>
                    </Form>
                )}
            </Formik>
        </FormTemplateElement>
    )
}

export default SuggestPartner