import { useNavigate } from "react-router";
import PageHeader from "../../../../components/atoms/page-header";
import { ItemMenuReportElement } from "./style";
import {Accordion, AccordionTab} from "primereact/accordion";

const RegistrationsView: React.FC = () => {
    const navigate = useNavigate();
    return <>
        <PageHeader text="Cadastros do sistema"/>

        <Accordion activeIndex={0}>
            <AccordionTab header="Cadastros do sistema">
                <ItemMenuReportElement onClick={() => {navigate('/contractor-view') }}>
                    Contratantes
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate('/partner-view') }}>
                    Parceiros
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate('/category-view') }}>
                    Categorias
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate('/userAdm-view') }}>
                    Gestores
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate('/associate-control-view') }}>
                    Usuários do app
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => { navigate('/product-view') }}>
                    Produtos
                </ItemMenuReportElement>
            </AccordionTab>
        </Accordion>

        <Accordion >
            <AccordionTab header="Contratos">
                <ItemMenuReportElement onClick={() => {navigate('/contracts-view') }}>
                    Contratos
                </ItemMenuReportElement>
                <ItemMenuReportElement onClick={() => {navigate('/tributes-view') }}>
                    Tributos
                </ItemMenuReportElement>
            </AccordionTab>
        </Accordion>

    </>
}

export default RegistrationsView