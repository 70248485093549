import styled from "styled-components";
import InputTextVm from "../../components/atoms/input-text";

export const LoginElement = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;


export const LoginTituloElement = styled.h3`
  color: #6285ae;
  font-size: 38px;
  margin-bottom: 20px;
`;

export const FormElement = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;
  padding-left: 35px;
  color: #42425d;
  font-size: 16px;
`;

export const CheckboxElement = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;


export const ButtomElement = styled.button`
  background-color: #6285ae;
  width: 100%;
  cursor: ${props => !props.disabled ? 'pointer' : 'not-allowed'};
  font-weight: bold;
  font-size: 17px;
  height: 50px;
  border-radius: 16px;
  border: none;
  margin-top: 20px;
  color: white;
`;

export const CancelButtonElement = styled.button`
  background-color: #ffffff;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  height: 50px;
  border-radius: 16px;
  border: 1px solid #6285ae;
  margin-top: 20px;
  color: #6285ae;
`;

export const ResetPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResetPasswordButtonElement = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  border: none;
  margin-top: 20px;
  color: #42425d;
`;

type InputTextProps = {
  isEquals: boolean;
}

export const InputTextVmConfirmPassword = styled(InputTextVm)<InputTextProps>`
  border-color: ${props => !props.isEquals && 'red'};
`