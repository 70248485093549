import {FilterMatchMode} from "primereact/api";
import {Column} from "primereact/column";
import {Dialog} from 'primereact/dialog';
import {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import eyes from "../../../../../../../assets/buttons/eyes.svg";
import {useAppContext} from "../../../../../../../context/app";
import {dateToStrg, parseDoubleToStr} from '../../../../../../../utils/formatUtil';
import BackButtom from "../../../../../../components/atoms/back-buttom";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import {ActionButtonsElement, ButtomsElement, HeaderElementLeft, HeaderElementRight, TableElement} from "./style";
import {DataTable} from "primereact/datatable";
import {Tag} from "primereact/tag";

const ContractPaymentView: React.FC = () => {
    const location = useLocation();
    const {state} = location as any
    const navigate = useNavigate();
    const [filterText, setFilterText] = useState("");
    const [payments, setPayments] = useState<any>();
    const [paymentsContractSplit, setPaymentsContractSplit] = useState<any>(null);
    const [dialogVisible, setDialogVisible] = useState(false);
    const appContext = useAppContext();
    const {apiProvider} = appContext

    useEffect(() => {
        setDialogVisible(false);
        getContractorData();
    }, []);

    type PaymentType = {
        dateCreated: string; // Data da cobrança
        dueDate: string; // Data do vencimento
        creditDate: string; // Data do vencimento
        billingType: string; // Boleto
        status: string; // Status
        value: string; // Valor
    };

    const status = (rowData: any) => {
        switch (rowData) {
            case 'PENDING':
                return 'PENDENTE';
                break;
            case 'RECEIVED':
            case 'DONE':
                return 'RECEBIDO';
                break;
        }
    }

    const data = (rowData: any) => {
        if (rowData != null) {
            return (<span>{dateToStrg(rowData)}</span>)
        } else {
            return (<span> -- </span>)
        }
    }

    const formatTotal = (item: any, value: string) => {
        return (<b>{value}</b>);
    }

    const getContractorData = useCallback(async function () {
        if (state.item.customerId != null) {
            const response: any = await apiProvider.get("/integration/asaas/payments/" + state.item.customerId);
            setPayments(response.data);
        }
    }, []);

    const header = (
        <div>
            <p>Splits</p>
        </div>
    );

    function formatValue(value: any) {
        if (typeof value === 'number') {
            if (value % 1 !== 0) {
                return value.toFixed(2);
            } else {
                return value.toFixed(0) + '.00';
            }
        } else {
            return value;
        }
    }

    function actionStatus(rowData: any) {
        switch (rowData.status) {
            case "CONFIRMED":
            case "RECEIVED":
            case "RECEBIDO":
                return (<Tag className="mr-2" icon="pi pi-check" severity="success" value='RECEBIDO'/>);
            case "PENDING":
            case "PENDENTE":
                return (<Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value='PENDENTE'/>);
            case "OVERDUE":
            case "ATRASADO":
                return (<Tag icon="pi pi-times" severity="danger" value='ATRASADO'/>);
        }
    }

    // Função para ocultar o diálogo
    const hideDialog = () => {
        setDialogVisible(false);
    };

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text={"Cobranças/Pagamentos do contrato: " + (state.item.name || "")}> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>
                    <BackButtom onclick={() => {
                        navigate('/contracts-view')
                    }}/>
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: {value: filterText, matchMode: FilterMatchMode.CONTAINS},
                    }}
                    paginator
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={payments}
                    responsiveLayout="scroll"
                    itemsList={payments}
                    sortField={"dateCreated, status"}
                    sortOrder={1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="dateCreated" header="Data/Cobrança"
                            body={(e: any) => data(e.dateCreated)} sortable style={{width: '10%'}}/>
                    <Column alignHeader="center" align="center" field="dueDate" header="Data/Vencimento"
                            body={(e: any) => data(e.dueDate)} sortable style={{width: '10%'}}/>
                    <Column alignHeader="center" align="center" field="creditDate" header="Data/Pagamento"
                            body={(e: any) => data(e.creditDate)} sortable style={{width: '10%'}}/>
                    <Column alignHeader="center" align="center" field="billingType" header="Tipo" sortable
                            style={{width: '20%'}}/>
                    <Column alignHeader="center" align="center" field="value" header="Valor"
                            body={(rowData) => formatTotal(rowData, "R$ " + parseDoubleToStr(rowData.value))} sortable
                            style={{width: '20%'}}/>
                    <Column alignHeader='center' align='center' field='status' header='STATUS' body={actionStatus}
                            sortable style={{width: '10%'}}/>
                    <Column alignHeader="center" align="center" field="actions" header="SPLIT" body={actionButtons}
                            style={{width: "10%"}}/>

                </DataTableElement>
            </TableElement>
        </>
    );

    // RENDERIZAÇÃO DA COLUNA AÇÕES DA TABELA
    function actionButtons(rowData: any) {

        const MaskMoney = (rowData: any) => {
            return (<>{"R$ " + parseDoubleToStr(rowData.value)}</>)
        }

        return (
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <ButtomsElement>
                    <ActionButtonsElement
                        onClick={() => {

                            if(rowData.split == null) { // Parceiro e usuario

                                var totalValue = rowData.netValue + (rowData.value - rowData.netValue);
                                var dataTableValues = [
                                    {
                                        name: 'VM-PARCERIAS',
                                        value: formatValue(rowData.netValue),
                                        status: status(rowData.status)
                                    },
                                    {
                                        name: 'ASAAS',
                                        value: formatValue(rowData.value - rowData.netValue),
                                        status: status(rowData.status)
                                    }
                                ];

                                dataTableValues.push({
                                    name: 'TOTAL',
                                    value: formatValue(totalValue),
                                    status: ' -- '
                                });

                                setPaymentsContractSplit(dataTableValues);
                            }else {

                                let totalValue = (rowData.vmTotalValue + rowData.asaasTotalValue);
                                var dataTableValues = [
                                    {
                                        name: 'VM-PARCERIAS',
                                        value: formatValue(rowData.vmTotalValue),
                                        status: status(rowData.status)
                                    },
                                    {
                                        name: 'ASAAS',
                                        value: formatValue(rowData.asaasTotalValue),
                                        status: status(rowData.status)
                                    }
                                ];

                                rowData.split.forEach((item: any) => {
                                    totalValue += item.fixedValue;
                                    dataTableValues.push({
                                        name: item.partnerName,
                                        value: formatValue(item.fixedValue),
                                        status: status(item.status)
                                    });
                                });

                                dataTableValues.push({
                                    name: 'TOTAL',
                                    value: formatValue(totalValue),
                                    status: ' -- '
                                });

                                setPaymentsContractSplit(dataTableValues);
                            }

                            setDialogVisible(true);
                        }}
                    >
                        <img alt="" style={{width: "17px"}} src={eyes}/>
                    </ActionButtonsElement>
                </ButtomsElement>

                <Dialog
                    visible={dialogVisible}
                    header={header}
                    onHide={hideDialog}
                    breakpoints={{'960px': '75vw', '640px': '100vw'}}
                    style={{width: '75vw', height: '80vw'}}
                >
                    <DataTable value={paymentsContractSplit} tableStyle={{width: '100%'}}>
                        <Column alignHeader="center" align="center" field="name" header="PARTE"></Column>
                        <Column alignHeader="center" align="center" field="value" header="VALOR" body={(e) => MaskMoney(e)}></Column>
                        <Column alignHeader="center" align="center" field="status" header="STATUS" body={(e) => actionStatus(e)}></Column>
                    </DataTable>
                </Dialog>
            </div>
        );
    }
};

export default ContractPaymentView;
