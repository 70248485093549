import { Column } from 'primereact/column';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import React, { useState } from "react";
import { useAppContext } from '../../../../../../context/app';
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum";
import { ModuleEnumReport } from "../../../../../../dto/moduleEnum/module-enum-report";
import { intToStrg, parseDoubleToStr } from '../../../../../../utils/formatUtil';
import { TruncateData } from "../../../../../../utils/truncateData";
import DataTableElement from '../../../../../components/atoms/data-table-view';
import PageHeader from '../../../../../components/atoms/page-header';
import HeaderReportFilter from '../../../../../components/organisms/header-report-filter';

const ReportAdminGroupedByBenefit: React.FC = () => {
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const appContext = useAppContext();
    const { apiProvider } = appContext;

    const findReport = async (param: any) => {
        setIsLoading(true);

        const response: any = await apiProvider.post('/admin/report/grouped-by-benefits', param);
        if (response) {
            let dataGrid: any = [];

            // Total visualizations
            response.data.map((item: any) => {
                if (item.partnerId == null) {
                    setTotal(item);
                } else {
                    dataGrid.push(item);
                }
            });

            setResults(dataGrid);
        }

        setIsLoading(false);
    }


    const exportReport = async (param: any) => {
        appContext.setIsShowLoading(true);

        try {
            const response: any = await apiProvider.downloadPost('/admin/report/grouped-by-benefits/export', param);

            const type = response.headers['content-type'];
            const blob = new Blob([response.data], { type: type });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Relatórios agrupado por benefícios.xlsx';
            link.click();
        } catch (error) {
            console.error(error);
        }

        appContext.setIsShowLoading(false);
    }

    const formatTotal = (item: any, value: string) => {
        if (item.partnerName === 'Total') {
            return (<b>{value}</b>);
        } else {
            return value;
        }
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="TOTAL:" footerStyle={{ textAlign: 'center' }} />
                <Column footer={''} footerStyle={{ textAlign: 'center' }} />
                <Column footer={`${(total.salesAmount == null) ? 0 : total.salesAmount}`} footerStyle={{ textAlign: 'center' }} />
                <Column footer={`R$ ${(total.totalSalesValue == null) ? 0 : total.totalSalesValue}`} footerStyle={{ textAlign: 'center' }} />
            </Row>
        </ColumnGroup>
    );

    return <>
        <PageHeader
            text="B2C - Transações "
            subtext="Agrupado por Benefício"
        />

        <HeaderReportFilter
            labelText="Benefício"
            exportReport={exportReport}
            findReport={findReport}
            typeReport={ModuleEnumReport.BENEFIT}
            typeModuleName={ModuleEnum.B2C}
            textPlaceholder="Selecione o benefício" />

        <div style={{ paddingTop: "2em" }}>
            <DataTableElement
                loading={isLoading}
                paginator
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                rows={10}
                value={results}
                responsiveLayout="scroll"
                emptyMessage="Nenhum resultado encontrado"
                itemsList={results}
                sortField="id"
                sortOrder={-1}
                rowsPerPageOptions={[5, 10, 25]}
                resizableColumns
                footerColumnGroup={footerGroup}
            >
                <Column header="Parceiro" alignHeader="center" align="center" field="partnerName" body={(rowData) => formatTotal(rowData, TruncateData(rowData.partnerName, 50))} sortable style={{ width: '20%' }} />
                <Column header="Benefício" alignHeader="center" align="center" field="benefitTitle" body={(rowData) => formatTotal(rowData, TruncateData(rowData.benefitTitle, 50))} sortable style={{ width: '20%' }} />
                <Column header="Vendas" alignHeader="center" align="center" field="salesAmount" body={(rowData) => formatTotal(rowData, intToStrg(rowData.salesAmount))} sortable style={{ width: '20%' }} />
                <Column header="Valor" alignHeader="center" align="center" field="totalSalesValue" body={(rowData) => formatTotal(rowData, "R$ " + parseDoubleToStr(rowData.totalSalesValue))} sortable style={{ width: '20%' }} />
            </DataTableElement>
        </div>
    </>
}

export default ReportAdminGroupedByBenefit