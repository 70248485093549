import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router"
import asaas from "../../../../../assets/asaas.svg"
import associatesFigure from "../../../../../assets/contractor/dashboard/associates-figure-B2B.png"
import bannerDashboardContractor from "../../../../../assets/contractor/dashboard/banner-dashboard-contractor-B2B.png"
import dolarImg from "../../../../../assets/contractor/dashboard/dolar.png"
import { useAppContext } from "../../../../../context/app"
import { Partner } from "../../../../../dto/partner"
import { Session } from "../../../../../dto/session"
import PageHeader from "../../../../components/atoms/page-header"
import { PanelH } from "../../../../components/atoms/panel/style"
import {
    BannerButton,
    BannerElement,
    BannerTitle,
    ChartBarDiv,
    ChartBarElement,
    Circle,
    CircleText,
    DashboardDivBotton,
    DashboardSize,
    DivBannerText,
    DivImpressions,
    HeaderItemElement,
    LabelChart,
    LabelImpressions,
    NoResultsDiv,
    PanelDashboardValue,
    Partners,
    Square,
    StraightDiv,
    StraightElement,
    TextChart,
    TextChartDiv,
    TextGraph,
    UpperDiv,
    ValueChart,
    ValueElement,
    ValueImpressions
} from "./style"

const timeFilter = [
    { label: 'Diária', id: 'DAY' },
    { label: 'Semanal', id: 'WEEK' },
    { label: 'Mensal', id: 'MONTH' },
]

const DashboardContractor: React.FC = () => {
    const appContext = useAppContext()
    const { apiProvider } = useAppContext()
    const [filterTime, setFilterTime] = useState(timeFilter[2])
    const [partners, setPartners] = useState([])
    const [categories, setCategories] = useState([] as any[])
    const [activeContractoesUsersAmount, setActiveUsersAmount] = useState(0)
    const [commission, setCommission] = useState({} as any)
    const session: Session = appContext.sessionProvider.getSession();
    const navigate = useNavigate()
    const urlAsaas = (process.env.REACT_APP_ENVIRONMENT == 'local') ? 'https://sandbox.asaas.com/login/auth' : 'https://www.asaas.com/login/auth';

    const getAllActiveContractorsUsers = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/contractor/actived-associates')
        setActiveUsersAmount(response.data.activedAssociates || 0)
    }, [])

    const getCommission = useCallback(async () => {
        const response: any = await apiProvider.get("/web/dashboard/contractor/commission")
        setCommission(response.data)
    }, [])

    const getPartners = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/dashboard/contractor/b2b/most-viewed-partners?period=${filterTime.id}`)
        setPartners(response.data)
    }, [])

    const getCategories = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/dashboard/contractor/categories-B2B?period=${filterTime.id}`)
        setCategories(response.data)
    }, [])

    // Renderização de componentes
    const renderCategoryList = useMemo(() => {
        if (categories.length === 0) {
            return <NoResultsDiv>Sem resultados</NoResultsDiv>
        }

        const maior: any = categories[0].amountTransaction

        return categories.map((category: any, index: any) => {
            const widt = ((category.amountTransaction * 100) / maior).toFixed(2)
            return (
                <ChartBarDiv key={index}>
                    <ChartBarElement>
                        <TextChartDiv>{category.categoryName}</TextChartDiv>
                        <StraightDiv>
                            <StraightElement style={{ width: `${widt}%` }} />
                            <ValueElement>{category.amountTransaction}</ValueElement>
                        </StraightDiv>
                    </ChartBarElement>
                </ChartBarDiv>
            )
        })

    }, [categories])

    const renderPartnerList = useMemo(() => {
        if (partners.length === 0) {
            return <NoResultsDiv>Sem resultados</NoResultsDiv>
        }

        return partners.map((partner: Partner, index) => {
            return (
                <Partners key={index}>
                    <CircleText>
                        <Circle>
                            <img style={{ width: "100%", borderRadius: '100%' }} alt="" src={partner.imageUrl} />
                        </Circle>
                        <TextGraph>{partner.name}</TextGraph>
                    </CircleText>

                    <DivImpressions>
                        <LabelImpressions>Impressões</LabelImpressions>
                        <ValueImpressions>{partner.impressions}</ValueImpressions>
                    </DivImpressions>

                </Partners>
            )
        })
    }, [partners])

    useEffect(() => {
        getCommission()
        getPartners()
        getCategories()
        getAllActiveContractorsUsers()
    }, [filterTime])

    return (
        <DashboardSize>
            <UpperDiv>
                <PageHeader text="Dashboard" />
                <HeaderItemElement>
                    Informações dos últimos 30 dias.
                </HeaderItemElement>
            </UpperDiv>

            <BannerElement>
                <DivBannerText>
                    Com os destaques você pode mais!
                    <br />
                    <BannerTitle>
                        Divulgue
                        <span style={{ color: "#01FF0E" }}> informativos na <br />tela inicial </span>
                        do APP
                    </BannerTitle>
                </DivBannerText>

                <img style={{ height: "calc(100% + 3.6em)", marginTop: "-1.8em" }} alt=""
                    src={bannerDashboardContractor} />

                <DivBannerText style={{ width: "18em" }}>
                    Seus usuários estão ansiosos para <br />
                    saber mais sobre as suas novidades. <br />
                    <strong>Divulgue agora!</strong> <br />

                    <BannerButton onClick={() => {
                        navigate("/want-to-disclose")
                    }}>Quero Divulgar</BannerButton>
                </DivBannerText>
            </BannerElement>

            <DashboardDivBotton>
                <div style={{ width: "60%", marginRight: "10px" }}>
                    <div style={{ display: "flex" }}>
                        {(session.masterManager && session?.modules[0]?.contractor?.idAsaasCustomer) &&
                            <>
                                <PanelDashboardValue style={{ width: "50%", marginRight: "10px" }}>
                                    <div>
                                        <LabelChart>Usuário ativos</LabelChart>
                                        <ValueChart style={{ fontSize: "30px" }}>
                                            {activeContractoesUsersAmount ? activeContractoesUsersAmount : "-"}
                                        </ValueChart>
                                    </div>

                                    <Square>
                                        <img style={{ height: "2em" }} alt="" src={associatesFigure} />
                                    </Square>
                                </PanelDashboardValue>

                                <PanelDashboardValue style={{ width: "50%" }}>
                                    <div>
                                        <LabelChart>Veja suas comissões:</LabelChart>
                                        <ValueChart style={{ fontSize: "30px" }}>
                                            {<a href={urlAsaas} target={"is_black"}><img src={asaas} width={'100px'}></img></a>}
                                        </ValueChart>
                                    </div>

                                    <Square>
                                        <img style={{ height: "2em" }} alt="" src={dolarImg} />
                                    </Square>
                                </PanelDashboardValue>
                            </>
                        }

                    </div>

                    <PanelH>
                        <TextChart>Categoria mais acessada</TextChart>
                        <div>{renderCategoryList}</div>
                    </PanelH>
                </div>

                <PanelH style={{ width: "40%" }}>
                    <TextChart>Parceiros mais acessados</TextChart>
                    <div>{renderPartnerList}</div>
                </PanelH>

            </DashboardDivBotton>

        </DashboardSize>
    )

}

export default DashboardContractor
