import {Form, Formik} from "formik";
import {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router";
import * as Yup from "yup";
import {useAppContext} from "../../../../../../../context/app";
import BackButtom from "../../../../../../components/atoms/back-buttom";
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form";
import Error from "../../../../../../components/atoms/error-message";
import InputTextVm from "../../../../../../components/atoms/input-text";
import PageHeader from "../../../../../../components/atoms/page-header";
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle";
import {LineFormElement} from "../../../../../../components/molecules/item-form/style";
import FormTemplateElement from "../../../../../../templates/form-template";
import {DivIn} from "./style";
import {InputNumber} from 'primereact/inputnumber';

const CommissionForm: React.FC = () => {
    const [commission, setCommission] = useState({} as any);
    const [disabled, setDisabled] = useState(false);
    const appContext = useAppContext();
    const { apiProvider } = appContext;
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location as any;
    const isNew = !state || !state.item;

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled);
            getOneCommission();
        }
    }, []);

    const getOneCommission = useCallback(async () => {
        const response: any = await apiProvider.get(`/admin/commission/${state.item.id}`);
        setCommission(response.data);
    }, [])

    const mensagensDeErro = {
        min: 'O valor mínimo deve ser 0%',
        max: 'O valor máximo deve ser 100%'
    };

    // Validações do yup
    const validations = Yup.object().shape({
        activeUsersAmout: Yup.number().positive().integer().required(),
        percentCommission: Yup.number().required("Campo Obrigatório").positive().min(0, mensagensDeErro.min).max(100, mensagensDeErro.max).nullable(true).notOneOf([0, NaN]),
        totalReceived: Yup.number().required("Campo Obrigatório").positive().nullable(true).notOneOf([0, NaN])
    });

    return (
        <>
            <PageHeader text="Comissão" />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate("/commission-view");
                    }}
                />

                <SpanSubtitle text={state.contractor.name} />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={commission}
                        validationSchema={validations}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <h4> Usuários ativos</h4>
                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <DivIn>
                                        Quantidade
                                        <InputTextVm
                                            style={{ borderRadius: "10px", width: "8em", marginTop: "10px" }}
                                            name="activeUsersAmout"
                                            value={propsForm.values.activeUsersAmout}
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                            type="number"
                                        />
                                        <Error name="activeUsersAmout" errors={propsForm.errors} />
                                    </DivIn>

                                </LineFormElement>

                                <h4>Total de comissão a receber</h4>

                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <DivIn>
                                        Valor recebido
                                        <InputNumber
                                            name="totalReceived"
                                            value={propsForm.values.totalReceived}
                                            onValueChange={propsForm.handleChange}
                                            mode="currency"
                                            currency="BRL"
                                            locale="pt-BR"
                                            placeholder="R$ 0,00"
                                            disabled={disabled}
                                        />
                                        <Error name="totalReceived" errors={propsForm.errors} />
                                    </DivIn>

                                    <DivIn>
                                        Percentual da comissão
                                        <InputNumber
                                            name="percentCommission"
                                            value={propsForm.values.percentCommission}
                                            onValueChange={propsForm.handleChange}
                                            suffix="%"
                                            placeholder="%"
                                            maxFractionDigits={2}
                                            minFractionDigits={2}
                                            disabled={disabled}
                                        />
                                        <Error name="percentCommission" errors={propsForm.errors} />
                                    </DivIn>
                                </LineFormElement>
                                <div style={{ textAlign: 'end' }}>
                                    {!disabled && (
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}>
                                            Cadastrar valores
                                        </ButtomRequestElement>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    );

    async function save(values: any) {
        const data = {
            id: values.id ? values.id : null,
            activeUsersAmout: values.activeUsersAmout,
            percentCommission: values.percentCommission,
            totalReceived: values.totalReceived,
            contractor: state.contractor
        }

        appContext.setIsShowLoading(true)
        try {
            if (isNew) {
                await apiProvider.post("/admin/commission", data);
            } else {
                await apiProvider.put("/admin/commission/" + values.id, data);
            }
            navigate("/commission-view");
            appContext.toast.show({
                severity: "success",
                summary: "Comissão",
                detail: "Salva com sucesso!",
                life: 3000,
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Comissão",
                detail: "Erro ao salvar comissão!",
                life: 3000,
            });
        }
        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!commission.id;
    }
};
export default CommissionForm;
