import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import eyesIcon from '../../../../../../assets/buttons/eyes.svg'
import { useAppContext } from '../../../../../../context/app'
import { dateTimeToStrg, parseDoubleToStr } from '../../../../../../utils/formatUtil'
import ButtomAddVm from '../../../../../components/atoms/buttom-add-vm'
import DataTableElement from '../../../../../components/atoms/data-table-view'
import PageHeader from '../../../../../components/atoms/page-header'
import SearchTextLabel from '../../../../../components/molecules/search-text-label'
import { HeaderElementLeft, HeaderElementRight } from '../../../../../components/organisms/header/style'
import HeaderViewElement from '../../../../../templates/view-header-template'
import TableViewElement from '../../../../../templates/view-table-template'
import { StatusButtomElement } from '../../../admin/forms/category/view/style'
import { ActionButtons } from '../../benefit/view/style'

const TransactionView: React.FC = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({ transactionList: [], isLoading: true })
    const { transactionList } = state
    const { apiProvider } = useAppContext()

    const [filterText, setFilterText] = useState('')

    useEffect(() => {
        getTransactionData()
    }, [])

    const data = (rowData: any) => {
        return (
            <span>
                {dateTimeToStrg(rowData.requestDate)}
            </span>
        )
    }

    const formatedTotalPayment = (rowData: any) => {
        return <span>{"R$ " + parseDoubleToStr(rowData.totalPayment)}</span>
    }

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text='Transações' />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newTransaction} text='Nova transação' />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    value={state.transactionList}
                    responsiveLayout='scroll'
                    emptyMessage='Nenhum resultado encontrado'
                    itemsList={transactionList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader='center' align='center' field='requestDate' body={data} header='Data e hora' sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' field='associateEmail' header='Usuário' sortable style={{ width: '30%' }} />
                    <Column alignHeader='center' align='center' field='productName' header='Produto' sortable style={{ width: '20%' }} />
                    <Column alignHeader='center' align='center' field='totalAmount' header='Valor total' sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' field='benefitValue' header='Benefício (%)' sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' field='totalPayment' body={formatedTotalPayment} header='Valor pago' sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' field='actions' body={actionButtons} header='Ação' sortable style={{ width: '10%' }} />
                </DataTableElement>
            </TableViewElement>
        </>
    )

    // FUNÇÕES DO SISTEMA

    // LISTAR TODOS AS TRANSAÇÕES
    async function getTransactionData() {
        const response: any = await apiProvider.get('/web/transaction')

        setState({ transactionList: response.data, isLoading: false })
    }

    // NOVA TRANSAÇÃO
    function newTransaction() {
        navigate('/transaction-form')
    }

    // RENDERIZAÇÃO DAS COLUNAS DA TABELA

    function statusButton(rowData: any) {
        if (rowData.status !== 'APROVADO') {
            return <StatusButtomElement buttonColor={false}>Negado</StatusButtomElement>
        }
        return <StatusButtomElement buttonColor>Aprovado</StatusButtomElement>
    }

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ActionButtons
                    onClick={() => {
                        navigate('/transaction-form', {
                            state: { item: rowData, disabled: true },
                        })
                    }}
                >
                    {' '}
                    <img style={{ width: '20px' }} alt='' src={eyesIcon} />
                </ActionButtons>
            </div>
        );
    }
}

export default TransactionView
