import { FaAngleLeft } from "react-icons/fa";
import { BackButtomElement } from "./style";


interface ButtonEvent {
  onclick: any;
}

const BackButtom: React.FC<ButtonEvent> = ({ onclick }) => {
  return (
    <BackButtomElement onClick={onclick}>
      <FaAngleLeft style={{ marginRight: "10px" }} />
      Voltar
    </BackButtomElement>
  );
};

export default BackButtom;
