// errorUtils.js (ou errorUtils.ts)
const sanitizeError = (json_data:any, ) => {
  return new Promise((resolve, reject) => {
      let jsonObject:any;
      let errorMessagesArray = [] as any;
      const errorMessage = json_data.message;

      const startIndex = errorMessage.indexOf('{');
      const endIndex = errorMessage.lastIndexOf('}]}');

      const jsonPart = errorMessage.substring(startIndex, endIndex + 3);

      try {
          jsonObject = JSON.parse(jsonPart);
      } catch (error) {
          console.error("Erro ao analisar JSON:", error);
          reject(new Error("Erro ao analisar JSON"));
          return;
      }

      if (Array.isArray(jsonObject.errors)) {
          Object.keys(jsonObject.errors).forEach((key) => {
              errorMessagesArray.push(jsonObject.errors[key].description);
          });
          resolve(errorMessagesArray);
      } else {
          reject(new Error("jsonObject is not an array"));
      }
  });
};

export { sanitizeError };
