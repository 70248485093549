import styled from "styled-components";

export const SpanHelp = styled.div`
    font-size: 0.9em;
    margin-bottom: 1em;
`;

export const ButtomDownalod = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#d3d3d3 " : "#B7E4FF")};
  color: ${({ disabled }) => (disabled ? "white" : "#195C8C")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
 
  /* background-color: #B7E4FF; */
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  /* color: #195C8C;
  cursor: pointer; */
  border-radius: 11px;
  width: 13em;
  height: 45px;
  border: none;
  margin-left: auto;
`;
