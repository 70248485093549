import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import trashIcon from "../../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../../context/app";
import { confirm } from "../../../../../../../utils/validation";
import ButtomAddVm from "../../../../../../components/atoms/buttom-add-vm";
import ButtonExport from "../../../../../../components/atoms/buttom-export";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import { HeaderElementLeft, HeaderElementRight } from "../../../../../../components/organisms/header/style";
import { maskCNPJ } from "../../../../../../templates/masks-template";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import TableViewElement from "../../../../../../templates/view-table-template";
import { ActionButtons } from "../../../../partner/benefit/view/style";

const PartnerView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ partnerList: [], isLoading: true });
    const { apiProvider } = useAppContext();
    const { partnerList } = state;
    const appContext = useAppContext();
    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        getPartnerData();
    }, []);

    const cnpjMask = (rowData: any) => {
        return (
            <>{maskCNPJ(rowData.cnpj)}</>
        )
    }

    const phoneAndCellPhone = (rowData: any) => {
        return (<>{(rowData.phone != null && rowData.phone != '') ? rowData.phone : rowData.cellPhone}</>)
    }

    const exportReport = async () => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.download('/admin/partner/export?filterText=' + filterText)
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Relatórios de parceiros.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    // FUNÇÕES DO SISTEMA

    // LISTAR TODOS OS PARTNERS
    async function getPartnerData() {
        const response: any = await apiProvider.get("/admin/partner");
        setState({ partnerList: response.data, isLoading: false });
    }

    // DELETAR PARTNER
    async function deletePartner(rowData: any) {
        try {
            await apiProvider.delete(`/admin/partner/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Parceiro",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getPartnerData();
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Parceiro",
                detail: error.response.data.message || "Erro ao excluir parceiro!",
                life: 3000,
            });
        }
    }

    // NOVO PARTNER
    function newPartner() {
        navigate("/partner-form");
    }

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Parceiros" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newPartner} text="Novo parceiro" />
                    <ButtonExport onClick={exportReport} />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel onChange={(e: any) => { setFilterText(e.target.value); }} />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS }
                    }}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rows={10}
                    value={state.partnerList}
                    responsiveLayout="scroll"
                    emptyMessage="Nenhum resultado encontrado"
                    itemsList={partnerList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="name" header="Nome" sortable style={{ width: '40%' }} />
                    <Column alignHeader="center" align="center" field="cnpj" header="Cnpj" body={cnpjMask} sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="phone" header="Telefone" body={phoneAndCellPhone} sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "10%" }} />
                </DataTableElement>
            </TableViewElement>
        </>
    );


    // RENDERIZAÇÃO DAS COLUNAS DA TABELA

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ActionButtons
                    onClick={() =>
                        confirm(rowData, "Deseja excluir este parceiro?", () =>
                            deletePartner(rowData)
                        )
                    }
                >
                    <img alt="" style={{ width: "17px" }} src={trashIcon} />{" "}
                </ActionButtons>

                <ActionButtons
                    onClick={() => {
                        navigate("/partner-form", {
                            state: { item: rowData, disabled: false },
                        });
                    }}
                >

                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtons>
            </div>
        );
    }
};

export default PartnerView;
