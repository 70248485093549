import { FileUpload } from "primereact/fileupload";
import { useEffect, useRef, useState } from "react";
import { ErrorEl } from "../../atoms/error-message/style";
import { ContainerUpload, InstructionsDiv, SelectDiv } from "./style";

interface FileImageProps {
	propsForm: any;
	name: string;
	nameUrl: string;
	label: string;
	disabled: boolean;
	heightImage: number;
	widthImage: number;
	sizeRef?: number;
}

const ImageUpdate: React.FC<FileImageProps> = ({
	propsForm,
	disabled,
	name,
	nameUrl,
	label,
	heightImage,
	widthImage,
	sizeRef
}) => {
	const fileUploadRef = useRef(null);
	const [validateImg, setValidateImg] = useState('');

	useEffect(() => {
		propsForm.values.validateImg = validateImg;
	}, [validateImg]);

	const uploadHandler = async (event: any) => {
		const file = event.files[0];
		setValidateImg('');

        const [img, type] = file.type.split("/");
        if (type != "jpeg") {
            setValidateImg("Este arquivo está no formato ." + type + " e deve estar em .jpeg")
        }

        // verificar se o tamanho do arquivo é maior que 1 MB
		if (file.size > 1000000) { 
			setValidateImg('O arquivo é muito grande. O tamanho máximo permitido é de 1 MB!');
		}

		const image = new Image();
		image.src = URL.createObjectURL(file);
		image.onload = () => {
            // verificar se a imagem tem pelo menos 360x170 pixels
			if (image.width > 365 && image.height > 177 || image.width < 360 && image.height < 170) {
				setValidateImg('As dimensões da imagem estão incorretas, ajuste-a e tente novamente!');
			}
		};

		propsForm.setFieldValue(name, event.files[0])
		event.options.clear()

		const reader = new FileReader();
		let blob = await fetch(file.objectURL).then(r => r.blob());
		reader.readAsDataURL(blob);
		reader.onloadend = function () {
			propsForm.setFieldValue(nameUrl, reader.result)
		}
	}

	if (!sizeRef) sizeRef = 20

	let height
	let width

	if (heightImage >= widthImage) {
		height = sizeRef
		width = ((sizeRef * widthImage) / heightImage)
	} else {
		width = sizeRef
		height = ((sizeRef * heightImage) / widthImage)
	}

	return (<>
		<ContainerUpload>
			{label}
			<div style={{ color: 'red' }}>
				<h2><b>{validateImg}</b></h2>
			</div>
			<SelectDiv>

				<div style={{ width: width + "em", height: height + "em" }}>
					{propsForm.values[nameUrl] && <img style={{ width: width + "em", height: height + "em" }} alt="" src={propsForm.values[nameUrl]}></img>}
				</div>

				<InstructionsDiv>
					<p>
						Para melhor qualidade, sua imagem deverá ser nos seguintes formatos:
						<br /> * .jpeg
						<br /><b>Tamanho máximo:</b> 1 mb
						<br /><b>Dimensão:</b> {widthImage}px X {heightImage}px (largura x altura)
					</p>
					<FileUpload
						ref={fileUploadRef}
						chooseLabel="Selecionar"
						customUpload
						auto
						mode="basic"
						disabled={disabled}
						accept="image/*"
						name={name}
						uploadHandler={uploadHandler}
						onRemove={() => { propsForm.setFieldValue(name, undefined) }}
					/>
					<ErrorEl>
						{propsForm.values[name] || propsForm.values[nameUrl] ? <></> : 'Campo obrigatório'}
					</ErrorEl>
				</InstructionsDiv>

			</SelectDiv>
		</ContainerUpload>

	</>);
};

export default ImageUpdate;
