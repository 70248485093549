import {Column} from 'primereact/column';
import {MultiSelect} from 'primereact/multiselect';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import penIcon from '../../../../../../../assets/buttons/pen.svg';
import trashIcon from '../../../../../../../assets/buttons/trash.svg';
import {useAppContext} from '../../../../../../../context/app';
import {confirm} from '../../../../../../../utils/validation';
import ButtomAddVm from '../../../../../../components/atoms/buttom-add-vm';
import ButtonExport from '../../../../../../components/atoms/buttom-export';
import ButtonImport from '../../../../../../components/atoms/buttom-import';
import DataTableElement from '../../../../../../components/atoms/data-table-view';
import PageHeader from '../../../../../../components/atoms/page-header';
import SearchTextLabel from '../../../../../../components/molecules/search-text-label';
import {HeaderElementRight} from '../../../../../../components/organisms/header/style';
import HeaderViewElement from '../../../../../../templates/view-header-template';
import TableViewElement from '../../../../../../templates/view-table-template';
import {ActionButtons} from '../../../../partner/benefit/view/style';
import {HeaderElementLeft} from '../../contractor/view/style';
import {ContractorsDivElement} from './style';
import {InputSwitch} from "primereact/inputswitch";
import {TruncateData} from "../../../../../../../utils/truncateData";

const AssociateControlView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ useAppList: [] as any[], isLoading: true });
    const { useAppList: userAppList } = state;
    const appContext = useAppContext();
    const { apiProvider, toast } = appContext;
    const [contractorList, setContractorList] = useState([]);
    const [listUserApp, setListUserApp] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedContractor, setSelectedContractor] = useState([] as any);
    const [mQuery, setMQuery] = useState<any>({matches: window.innerWidth > 1400 ? true : false});

    useEffect(() => {
        let mediaQuery = window.matchMedia("(min-width: 1400px)");
        setMQuery(mediaQuery);
        getContractors();
        getAppUsers();
    }, []);

    useEffect(() => {
        setState({ useAppList: [], isLoading: true });
        let list: any[] = [];

        if (filterText.length < 1 && selectedContractor.length < 1) {
            setState({ useAppList: listUserApp, isLoading: false });
            return;
        }

        if (selectedContractor.length > 0 && filterText.length < 1) {
            listUserApp.forEach((item: any) => {
                let contractorName = item.contractorName.toUpperCase()
                selectedContractor.forEach((itemTwo: any) => {
                    if (contractorName.indexOf(itemTwo.name.toUpperCase()) >= 0) {
                        if (!list.includes(item)) list.unshift(item)
                    }
                })
            })
        } else {
            listUserApp.forEach((item: any) => {
                let email = item.email.toUpperCase()
                let userName = item.userName.toUpperCase()
                let contractorName = item.contractorName.toUpperCase()
                if (email.indexOf(filterText.toUpperCase()) >= 0 || userName.indexOf(filterText.toUpperCase()) >= 0 || contractorName.indexOf(filterText.toUpperCase()) >= 0) {
                    if (selectedContractor.length > 0) {
                        selectedContractor.forEach((itemTwo: any) => {
                            if (contractorName.indexOf(itemTwo.name.toUpperCase()) >= 0) {
                                if (!list.includes(item)) list.unshift(item)
                            }
                        })
                    } else {
                        if (!list.includes(item)) list.unshift(item)
                    }
                }
            })
        }

        setState({ useAppList: list, isLoading: false });

    }, [selectedContractor, filterText]);

    const newUserApp = () => {
        navigate('/associate-control-form');
    };

    const navImport = () => {
        navigate('/register-user-form');
    };

    const handleToggle = async (e: any) => {
        try {
            const data = {
                id: e.userId ? e.userId : null,
                isActive: (e.isActive != null) ? !e.isActive : false
            }

            const response: any = await apiProvider.put(`/admin/user/${data.id}`, data)
            if (response == null) {
                throw new Error("Não foi possível realizar essa alteração, tente novamente!");
            }

            appContext.toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Alteração realizada com sucesso!',
                life: 3000,
            })

            setState({
                useAppList: userAppList.map(item => {
                    if (item.userId === e.userId) {
                        return { ...item, isActive: !e.isActive }
                    }
                    return item
                }), isLoading: false
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        }
    };

    const getAppUsers = useCallback(
        async function () {
            const contractorsIds: any[] = [];
            selectedContractor.map((value: any) => {
                contractorsIds.push(value.id);
            });

            const response: any = await apiProvider.get('/admin/user/findAdminUsersByFilter?filterText=' + filterText + '&contractosIds=' + contractorsIds);
            setListUserApp(response.data);
            setState({ useAppList: response.data, isLoading: false });
        },
        []
    );

    const getContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/contractor');
        setContractorList(response.data);
    }, []);

    const deleteUser = useCallback(async ({ userId }: any) => {
        try {
            await apiProvider.delete(`/admin/user/${userId}`);

            toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Usuário excluido com sucesso!',
                life: 3000,
            });

            await getAppUsers();
        } catch (error: any) {
            toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            });
        }
    }, []);

    const exportReport = async () => {
        try {
            appContext.setIsShowLoading(true);

            const exportIds: any[] = [];
            userAppList.map((value: any) => {
                exportIds.push(value.id);
            });

            if (exportIds.length > 0) {
                const response: any = await apiProvider.download('/admin/user/export?exportIds=' + exportIds);
                const type = response.headers['content-type'];
                const blob = new Blob([response.data], { type: type });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Exportação de usuários do app.xlsx';
                link.click();
            } else {
                throw new Error("Sem dados para exportar, tente novamente!")
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        } finally {
            appContext.setIsShowLoading(false);
        }
    };

    return (
        <>
            <PageHeader text='Controle de usuários com acesso ao aplicativo.' />
            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newUserApp} text='Novo usuário' />
                    <ButtonExport onClick={exportReport} />
                    <ButtonImport onClick={navImport} />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel onChange={(e: any) => { setFilterText(e.target.value) }} />
                </HeaderElementRight>
            </HeaderViewElement>
            <TableViewElement>
                <ContractorsDivElement>
                    Contratante
                    <MultiSelect
                        name='contractor' style={{ width: '100%' }}
                        value={selectedContractor}
                        options={contractorList} onChange={(e) => setSelectedContractor(e.value)}
                        optionLabel='name' display='chip'
                    />
                </ContractorsDivElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    rows={10}
                    value={state.useAppList}
                    responsiveLayout='scroll'
                    emptyMessage='Nenhum resultado encontrado'
                    itemsList={userAppList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader='center' align='center' field='moduleName' header='Módulo' sortable style={{ width: '5%' }} />
                    <Column alignHeader='center' align='center' field='contractorName' body={(e) => TruncateData(e.contractorName, 20)} header='Contratante' sortable style={{ width: '30%' }} />
                    <Column alignHeader='center' align='center' field='userName' body={(e) => TruncateData(e.userName, 30)} header='Usuário' sortable style={{ width: '25%' }} />
                    <Column alignHeader='center' align='center' field='email' header='Email' sortable style={{ width: '20%', display: (mQuery && !mQuery.matches) ? 'none' : null}} />
                    <Column alignHeader='center' align='center' body={(e) => <InputSwitch checked={e.isActive} onChange={() => handleToggle(e)} />} field='isActive' header='Ativo' sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' body={actionButtons} header='Ações' style={{ width: '10%' }} />
                </DataTableElement>
            </TableViewElement>
        </>
    );

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <ActionButtons
                    onClick={() => confirm(rowData, 'Deseja excluir este usuário?', () => deleteUser(rowData))}>
                    <img alt='' style={{ width: '17px' }} src={trashIcon} />
                </ActionButtons>
                <ActionButtons
                    style={{ marginLeft: '1em' }}
                    onClick={() => {
                        navigate('/associate-control-form', {
                            state: { contractor: selectedContractor, item: rowData, disabled: false },
                        });
                    }}
                >
                    <img alt='' style={{ width: '17px' }} src={penIcon} />
                </ActionButtons>
            </div>
        );
    };
};

export default AssociateControlView;
