import { Form, Formik } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { useAppContext } from '../../../../../../context/app'
import { Informative } from '../../../../../../dto/informative'
import BackButtom from '../../../../../components/atoms/back-buttom'
import ButtomRequestElement from '../../../../../components/atoms/buttom-request-form'
import SelectDropDownVm from '../../../../../components/atoms/select-drop-down'
import SpanSubtitle from '../../../../../components/atoms/span-subtitle'
import ImageUpdate from '../../../../../components/molecules/image-update'
import ItemForm from '../../../../../components/molecules/item-form'
import { LineFormElement } from '../../../../../components/molecules/item-form/style'
import { Error } from '../../../../../components/organisms/form-address/style'
import FormTemplateElement from '../../../../../templates/form-template'
import { ButtomSaveElement } from '../../../partner/benefit/form/style'
import { CategoryDivElement, InputTextareaDivElement } from './style'

const InformativeForm: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any
    const [informative, setInformative] = useState({} as Informative)
    const [disabled, setDisabled] = useState(false)
    const [categories, setCategory] = useState([])
    const appContext = useAppContext()
    const { apiProvider } = appContext

    const getOneInformative = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/informative/${state.item.id}`)
        setInformative(response.data)
    }, [])

    const getCategory = useCallback(async () => {
        const response: any = await apiProvider.get('/web/category')
        setCategory(response.data)
    }, [])

    const validations = Yup.object().shape({
        title: Yup.string().max(90).required(),
        text: Yup.string().max(2000).required(),
        category: Yup.object().required(),
        imageUrl: Yup.string().required(),
    })

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOneInformative()
        }
        getCategory()
    }, [])

    async function save(values: any) {
        const data = {
            ...values,
            categoryId: values.category.id,
            category: undefined,
            imageUrl: undefined,
            date: undefined,
        }

        const formData = new FormData()

        formData.append('informative', JSON.stringify(data))
        formData.append('file', values.file)

        appContext.setIsShowLoading(true)
        try {
            if (!isNew) {
                await apiProvider.put('/web/informative', formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Informativo',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate('/informative-view')
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Informativo',
                            detail: error.response.data || 'Erro ao salvar informativo!',
                            life: 3000,
                        });
                    });
            } else {
                await apiProvider.post('/web/informative', formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Informativo',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate('/informative-view')
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Informativo',
                            detail: error.response.data || 'Erro ao salvar informativo!',
                            life: 3000,
                        });
                    });
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Informativo',
                detail: error.response.data || 'Erro ao salvar informativo!',
                life: 3000,
            })
        }

        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!informative.id
    }

    return (
        <FormTemplateElement>
            <BackButtom
                onclick={() => {
                    navigate('/informative-view')
                }}
            />

            <SpanSubtitle text='Dados - informativo' />

            {(isLoadApi() || isNew) && (
                <Formik
                    initialValues={informative}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <Form>
                            <LineFormElement style={{ justifyContent: 'flex-start' }}>
                                <ItemForm
                                    propsForm={propsForm}
                                    label='Título'
                                    widthPercent='50'
                                    disabled={disabled}
                                    name='title'
                                />

                                <CategoryDivElement>
                                    Categoria
                                    <SelectDropDownVm
                                        style={{ width: '100%', height: '100%' }}
                                        disabled={disabled}
                                        name='category'
                                        onChange={propsForm.handleChange}
                                        value={propsForm.values.category}
                                        options={categories}
                                        optionLabel='name'
                                        showClear
                                    />
                                    <Error style={{ width: '100%' }}>{propsForm.errors.category ? (propsForm.errors.category) : (<>&nbsp;</>)}</Error>
                                </CategoryDivElement>
                            </LineFormElement>

                            <InputTextareaDivElement style={{ width: '100%' }}>
                                Texto
                                <InputTextarea
                                    style={{ marginTop: '5px' }}
                                    disabled={disabled}
                                    value={propsForm.values.text}
                                    name='text'
                                    autoResize
                                    onChange={propsForm.handleChange}
                                />
                                <Error>{propsForm.errors.text ? (propsForm.errors.text) : (<>&nbsp;</>)}</Error>
                            </InputTextareaDivElement>
                            <ImageUpdate
                                disabled={disabled}
                                name="file"
                                nameUrl="imageUrl"
                                label="Image"
                                propsForm={propsForm}
                                widthImage={365}
                                heightImage={177}
                            />
                            <div style={{ textAlign: 'end' }}>
                                {!disabled && (
                                    <ButtomSaveElement>
                                        <ButtomRequestElement
                                            type='submit'
                                            disabled={isNew ?
                                                !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                                :
                                                propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                            }
                                        >
                                            Salvar dados
                                        </ButtomRequestElement>
                                    </ButtomSaveElement>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </FormTemplateElement>
    )
}

export default InformativeForm
