import styled from "styled-components";

export const CategoryDivElement = styled.div`
  margin-left: 15px;
  width: 40%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

export const InputTextareaDivElement = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
