import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router"
import bannerImg from "../../../../../assets/partner/dashboard/bannerImg.png"
import carrinhoImg from "../../../../../assets/partner/dashboard/carrinho.png"
import clienteImg from "../../../../../assets/partner/dashboard/clientes.png"
import dolarImg from "../../../../../assets/partner/dashboard/dolar.png"
import porcentoImg from "../../../../../assets/partner/dashboard/porcento.png"
import { useAppContext } from "../../../../../context/app"
import { parseDoubleToStr } from "../../../../../utils/formatUtil"
import PageHeader from "../../../../components/atoms/page-header"
import SelectDropDownVm from "../../../../components/atoms/select-drop-down"
import {
  AssociateComission,
  BannerButton,
  BannerElement,
  BannerTitle,
  Chart1,
  Chart2,
  ChartBarDiv,
  ChartBarElement,
  Circle,
  CircleText,
  Contractors,
  DashboardSize,
  DivBannerGraph,
  DivGraphs,
  DivImg,
  DivPartners,
  DivsBanner,
  DivsChartPartner,
  DivsCharts,
  DivText,
  DivTitleDrop,
  Graph,
  HeaderItemElement,
  PercentageOfTransactions,
  ScrollPanelElement,
  Square,
  StraightDiv,
  StraightElement,
  TextChartDiv,
  TextGraph,
  TextGraph2,
  UpperDiv,
  ValueElement
} from "./style"

const periods = [
  { label: "Diária", key: "DAY"},
  { label: "Semanal", key: "WEEK"},
  { label: "Mensal", key: "MONTH"},
]

const DashboardPartner: React.FC = () => {
  const [sales, setSales] = useState(0 as any)
  const [earnings, setEarnings] = useState(0 as any)
  const [discounted, setDiscounted] = useState(0 as any)
  const [associates, setAssociates] = useState(0 as any)
  const [activityProducts, setActivityProducts] = useState([
    { productName: "sem resultados", amount: 0 },
  ] as any)
  const [contractors, setContractors] = useState([])
  const [period, setPeriod] = useState(periods[2])

  const { apiProvider } = useAppContext()
  const navigate = useNavigate()

  useEffect(() => {
    getSales()
    getEarnings()
    getDiscounted()
    getAssociates()
    getActivityProducts()
    getContractors()
  }, [period])

  const renderContractors = useMemo(() => {
    if (contractors.length <= 0) {
      return <></>
    }

    return contractors.map((contractor: any, index) => {
      return (
        <Contractors key={index}>
          <CircleText>
            <Circle>
              <img style={{ width: "100%", borderRadius: '100%' }} alt="" src={contractor.imageUrl} />
            </Circle>
            <DivText>
              <TextGraph>{contractor.contractorName}</TextGraph>
            </DivText>
          </CircleText>

          <PercentageOfTransactions>
             <span style={{color: 'white'}}>{contractor.percentageOfTransactions}%</span>
          </PercentageOfTransactions>
        </Contractors>
      )
    })
  }, [contractors])

  const renderActiveList = useMemo(() => {

    if (!activityProducts || activityProducts.length <= 0) {
      return (
        <ChartBarDiv>
          <ChartBarElement>
            <TextChartDiv style={{ justifyContent: "flex-start", width: "100%" }}>
              Sem resultados
            </TextChartDiv>
          </ChartBarElement>
        </ChartBarDiv>
      )
    }

    const maior = activityProducts[0].amount

    return activityProducts.map((category: any, index:any) => {
      const widt = ((category.amount * 100) / maior).toFixed(2)
      return (
        <ChartBarDiv key={index}>
          <ChartBarElement>
            <TextChartDiv>{category.productName}</TextChartDiv>
            <StraightDiv>
              <StraightElement style={{ width: `${widt}%` }} />
              <ValueElement>{category.amount}</ValueElement>
            </StraightDiv>
          </ChartBarElement>
        </ChartBarDiv>
      )
    })

  }, [activityProducts])

  const getSales = useCallback(async () => {
    const response: any = await apiProvider.get(
      `/web/dashboard/partner/sales?period=${period.key}`
    )
    setSales(response.data)
  }, [period])

  const getEarnings = useCallback(async () => {
    const response: any = await apiProvider.get(
      `/web/dashboard/partner/earnings?period=${period.key}`
    )
    setEarnings(response.data)
  }, [period])

  const getDiscounted = useCallback(async () => {
    const response: any = await apiProvider.get(
      `/web/dashboard/partner/discounted?period=${period.key}`
    )
    setDiscounted(response.data)
  }, [period])

  const getAssociates = useCallback(async () => {
    const response: any = await apiProvider.get(
      `/web/dashboard/partner/associates?period=${period.key}`
    )
    setAssociates(response.data)
  }, [period])

  const getActivityProducts = useCallback(async () => {
    const response: any = await apiProvider.get(
      `/web/dashboard/partner/activity-products?period=${period.key}`
    )
    setActivityProducts(response.data)
  }, [period])

  const getContractors = useCallback(async () => {
    const response: any = await apiProvider.get(
      `/web/dashboard/partner/contractors?period=${period.key}`
    )
    setContractors(response.data)
  }, [period])

  return (
    <DashboardSize>
      <UpperDiv>
        <PageHeader text="Dashboard" />
          Informações dos últimos 30 dias.
        {/*<HeaderItemElement>*/}
        {/*  Ver atualização*/}
        {/*  <SelectDropDownVm*/}
        {/*    style={{ marginLeft: "8px", fontWeight: "bold" }}*/}
        {/*    panelStyle={{ fontWeight: "bold" }}*/}
        {/*    name="timeFilter"*/}
        {/*    onChange={(e) => setPeriod(e.value)}*/}
        {/*    value={period}*/}
        {/*    options={periods}*/}
        {/*    optionLabel="label"*/}
        {/*  />*/}
        {/*</HeaderItemElement>*/}
      </UpperDiv>

      <DivBannerGraph>
        
        <BannerElement>
          <DivsBanner>
            Com os destaques você pode mais!
            <BannerTitle>
              <span style={{ color: "white" }}> Divulgue</span> seus benefícios
                na tela inicial
              <span style={{ color: "white" }}>do nosso APP</span>
            </BannerTitle>
            <BannerButton onClick={  () => {navigate("/want-to-disclose")}}>Quero Divulgar</BannerButton>
          </DivsBanner>
          <DivImg>
            <img
              style={{ height: "140%", marginBottom: "25px" }}
              alt=""
              src={bannerImg}
            />
          </DivImg>
        </BannerElement>

        <DivGraphs>
          <Graph>
            <Square>
              <img style={{ width: "42%" }} alt="" src={clienteImg} />
            </Square>
            <DivText>
              <TextGraph>Novos Clientes</TextGraph>
              <TextGraph>
                <strong>{associates.amount}</strong>
              </TextGraph>
            </DivText>
          </Graph>
          <Graph>
            <Square style={{ backgroundColor: "#C9DEED" }}>
              <img style={{ width: "42%" }} alt="" src={porcentoImg} />
            </Square>
            <DivText>
              <TextGraph>Descontos oferecidos</TextGraph>
              <TextGraph>
                <strong>R$ {discounted.discountedAmount ? parseDoubleToStr(discounted.discountedAmount) : 0} </strong>
              </TextGraph>
            </DivText>
          </Graph>
        </DivGraphs>
      </DivBannerGraph>
        <DivsCharts>
            <Chart1>
                {/* Vendas */}
                <AssociateComission style={{ marginRight: "10px" }}>
                    <DivText>
                        <TextGraph style={{ marginBottom: "10px" }}>Vendas</TextGraph>
                        <TextGraph style={{ fontSize: "22px" }}>
                            <strong>{sales.salesAmount}</strong>
                        </TextGraph>
                    </DivText>
                    <Square style={{ backgroundColor: "#5EDB93", width: "25%" }}>
                        <img style={{ width: "49%" }} alt="" src={carrinhoImg} />
                    </Square>
                </AssociateComission>
                {/* Ganhos */}
                <AssociateComission>
                    <DivText>
                        <TextGraph style={{ marginBottom: "10px" }}>Ganhos</TextGraph>
                        <TextGraph style={{ fontSize: "22px" }}>
                            <strong>R$ {earnings.totalEarnings ? parseDoubleToStr(earnings.totalEarnings) : 0}</strong>
                        </TextGraph>
                    </DivText>
                    <Square style={{ backgroundColor: "#5EDB93", width: "25%" }}>
                        <img style={{ width: "49%" }} alt="" src={dolarImg} />
                    </Square>
                </AssociateComission>
            </Chart1>
            <Chart2>
                <DivTitleDrop>Atividade</DivTitleDrop>
                <ScrollPanelElement>{renderActiveList}</ScrollPanelElement>
            </Chart2>
        </DivsCharts>
    </DashboardSize>
  )

}

export default DashboardPartner
