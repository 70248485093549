import { Form, Formik } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { useAppContext } from "../../../../../../context/app";
import { ContractorUser } from "../../../../../../dto/contractorUser";
import { Session } from '../../../../../../dto/session';
import BackButtom from "../../../../../components/atoms/back-buttom";
import ButtomRequestElement from "../../../../../components/atoms/buttom-request-form";
import SpanSubtitle from "../../../../../components/atoms/span-subtitle";
import ItemForm from "../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../components/molecules/item-form/style";
import FormTemplateElement from "../../../../../templates/form-template";
import { ButtomSaveElement, DivInput } from "../../../partner/benefit/form/style";

const UserContractorForm: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isNew = !location.state;
    const { state } = location as any;
    const [user, setUser] = useState({} as ContractorUser);
    const [disabled, setDisabled] = useState(false);
    const [change, setChange] = useState(false);
    const [masterManager, setMasterManager] = useState(false);
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const session: Session = appContext.sessionProvider.getSession();

    const getOneContractorUser = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/user/contractor/${state.item.id}`);
        setUser(response.data);
        setMasterManager(response.data.masterManager);
    }, []);

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled);
            getOneContractorUser();
        }
    }, []);

    const validations = Yup.object().shape({
        name: Yup.string().required().max(250),
        email: Yup.string().required().email().max(200),
    });

    async function save(values: any) {
        appContext.setIsShowLoading(true)
        values.masterManager = masterManager

        try {
            if (!isNew) {
                await apiProvider.put("/web/user/contractor", values);
            } else {
                await apiProvider.post("/web/user/contractor", values);
            }

            navigate("/userContractor-view");
            appContext.toast.show({
                severity: "success",
                summary: "Contratante",
                detail: "Salvo com sucesso!",
                life: 3000,
            });
        } catch (error: any) {
            const message = error.response.data.message;
            if (message === "This E-mail is already registered") {
                appContext.toast.show({
                    severity: "error",
                    summary: "E-mail",
                    detail: "Este e-mail já existe, tente outro!",
                    life: 3000,
                });
            } else {
                appContext.toast.show({
                    severity: "error",
                    summary: "Usuário",
                    detail: `Erro ao salvar usuário: ${message}`,
                    life: 3000,
                });
            }
        }
        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!user.id;
    }

    function _setMasterManager(value: boolean) {
        setMasterManager(value);
        setChange(true);
    }

    function showContent(rowData: any) {
        if (rowData.values.principal) {
            return false;
        } else {
            if (session.masterManager) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <FormTemplateElement>
            <BackButtom
                onclick={() => {
                    navigate("/userContractor-view");
                }}
            />

            <SpanSubtitle text="Dados - usuário" />

            {(isLoadApi() || isNew) && (
                <Formik initialValues={user} validationSchema={validations} onSubmit={save}>
                    {(propsForm) => (
                        <Form>
                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                <ItemForm propsForm={propsForm} label="Nome" widthPercent="50" disabled={disabled} name="name" />
                                <ItemForm propsForm={propsForm} widthPercent="50" label="Email" disabled={disabled} name="email" />
                                {showContent(propsForm) && (
                                    <DivInput style={{ width: '10%' }}>
                                        Gestor?
                                        <InputSwitch
                                            style={{ marginTop: "15px", marginLeft: "15px" }}
                                            checked={masterManager}
                                            onChange={(e: any) => _setMasterManager(e.value)}
                                            disabled={disabled}
                                        />
                                    </DivInput>
                                )}
                            </LineFormElement>

                            <div style={{ textAlign: 'end' }}>
                                {!disabled && (
                                    <ButtomSaveElement>
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={(!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty) && !change}>
                                            Salvar dados
                                        </ButtomRequestElement>
                                    </ButtomSaveElement>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </FormTemplateElement>
    );
};

export default UserContractorForm;
