import React, { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router";
import InputTextVm from "../../components/atoms/input-text";
import { ButtomElement, CancelButtonElement, FormElement, LoginElement as ResetPasswordElement, LoginTituloElement as ResetPasswordTituloElement } from "./style";
import { useAppContext } from "../../../context/app";

type StateVerifyAccountProps = {
  backPage: string;
  email: string;
};

const VerifyAccount: React.FC = () => {
  const context = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");

  useEffect(() => {
    const state = location.state as StateVerifyAccountProps;
    setEmail(state.email);
  });

  useEffect(() => {
    sendCodeToEmail()
      .then()
      .catch((err) => {
        context.toast.show({
          severity: "error",
          summary: "Código de confirmação",
          detail: "Falha ao enviar código de confirmação, tente novamente!",
          life: 3000,
        });

        goBack();
      });
  });

  const sendCodeToEmail = useCallback(async () => {
    await Auth.resendSignUp(email);
  }, [email]);

  const insertCode = useCallback(async () => {
    await Auth.confirmSignUp(email, code);
    context.toast.show({
      severity: "success",
      summary: "Conta ativada com sucesso",
      detail: "Sua conta foi ativada. Faça login e comece a usar o sistema.",
      life: 3000,
    });

    goBack();
  }, [code]);

  const goBack = useCallback(() => {
    setCode("");
    setEmail("");

    const state = location.state as StateVerifyAccountProps;
    navigate(state?.backPage || "/");
  }, []);

  return (
    <ResetPasswordElement>
      <FormElement>
        <ResetPasswordTituloElement>Confirme sua conta</ResetPasswordTituloElement>
        Informe o código de segurança enviado para <strong>{email}</strong>
        <InputTextVm style={{ marginBottom: "15px" }} value={code} onChange={(e) => setCode(e.target.value)} />
        <ButtomElement disabled={!code} onClick={insertCode}>
          Verificar
        </ButtomElement>
        <CancelButtonElement onClick={goBack}>Cancelar</CancelButtonElement>
      </FormElement>
    </ResetPasswordElement>
  );
};

export default VerifyAccount;
