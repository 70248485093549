import { CSSProperties } from "react";
import { ErrorEl } from "./style";

interface ErrorProps {
    name:string;
    errors: any;
    style?: CSSProperties | any
  }
  
  const Error: React.FC<ErrorProps> = ({name, errors, style}) => {
  
    return (
        <ErrorEl style={style}>  
          { errors[name] ? (errors[name]) : <></> }
        </ErrorEl>
    )
  };
  
  export default Error;
  