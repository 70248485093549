import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppContext } from "../../../context/app";
import { StateResetPasswordEnum } from "../../../dto/stateVerifyAccount";
import InputTextVm from "../../components/atoms/input-text";
import RolePassword from "../../components/atoms/role-password";
import { ButtomElement, CancelButtonElement, FormElement, InputTextVmConfirmPassword, LoginElement as ResetPasswordElement, LoginTituloElement as ResetPasswordTituloElement } from "./style";

type StateProps = {
    backPage: string;
    step?: StateResetPasswordEnum;
};

const ResetPassword: React.FC = () => {
    const context = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [steps, setSteps] = useState(StateResetPasswordEnum.CHECK_EMAIL);

    useEffect(() => {
        const state = getStateInLocation();
        if (state.step) {
            setSteps(state.step);
        }
    }, []);

    const sendCodeToMail = useCallback(async () => {
        Auth.forgotPassword(email)
            .then((data: any) => {
                setSteps(StateResetPasswordEnum.CODE_VERIFICATION);
            })
            .catch((err: Error) => {
                context.toast.show({
                    severity: "error",
                    summary: "E-mail inválido",
                    detail: "E-mail não encontrado na lista de acessos. Por favor verifique e tente novamente!",
                    life: 3000,
                });
            });
    }, [email, steps]);

    const insertCode = useCallback(() => {
        setSteps(StateResetPasswordEnum.RESET);
    }, [code, steps]);

    const updatePasswordUsingCode = useCallback(() => {
        if (password !== passwordConfirm) {
            context.toast.show({
                severity: "error",
                summary: "Senha inválida",
                detail: "As senhas não conferem, tente novamente!",
                life: 3000,
            });
            return;
        }

        Auth.forgotPasswordSubmit(email, code, password)
            .then((data: any) => {
                context.toast.show({
                    severity: "success",
                    summary: "Concluído",
                    detail: "Senha alterada com sucesso!",
                    life: 3000,
                });

                goBack();
            })
            .catch((err: Error) => {
                if (err.message.includes("Password does not conform to policy")) {
                    context.toast.show({
                        severity: "error",
                        summary: "Atenção",
                        detail: "Verifique as regras de alteração de senha e tente novamente!",
                        life: 3000,
                    });
                    return;
                }

                context.toast.show({
                    severity: "error",
                    summary: "Senha inválida",
                    detail: "Não foi possível alterar a senha, tente novamente!",
                    life: 3000,
                });
            });
    }, [email, code, password, passwordConfirm]);

    const goBack = useCallback(() => {
        setCode("");
        setEmail("");
        setPassword("");
        setPasswordConfirm("");

        const state = getStateInLocation();
        navigate(state?.backPage || "/");
    }, []);

    const getStateInLocation = useCallback(() => {
        return location.state as StateProps;
    }, [location]);

    return (
        <ResetPasswordElement>

            <FormElement style={{ maxWidth: 500 }}>
                {steps === StateResetPasswordEnum.CHECK_EMAIL ? (
                    <>
                        <ResetPasswordTituloElement>Alterar senha</ResetPasswordTituloElement>
                        E-mail
                        <InputTextVm style={{ marginBottom: "15px" }} value={email} onChange={(e) => setEmail(e.target.value)} />
                        <ButtomElement disabled={!email} onClick={sendCodeToMail}>
                            Continuar
                        </ButtomElement>
                    </>
                ) : steps === StateResetPasswordEnum.CODE_VERIFICATION ? (
                    <>
                        Informe o código de segurança enviado para <strong>{email}</strong>
                        <InputTextVm style={{ marginBottom: "15px" }} value={code} onChange={(e) => setCode(e.target.value)} />
                        <ButtomElement disabled={!code} onClick={insertCode}>
                            Verificar
                        </ButtomElement>
                    </>
                ) : (
                    <>
                        Nova senha
                        <InputTextVm autoComplete="off" type="password" style={{ marginBottom: "15px" }} value={password} onChange={(e) => setPassword(e.target.value)} />
                        Confirme a nova senha
                        <InputTextVmConfirmPassword autoComplete="off" type="password" isEquals={password === passwordConfirm} style={{ marginBottom: "15px" }} value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                        <ButtomElement disabled={password !== passwordConfirm || !password || !passwordConfirm} onClick={updatePasswordUsingCode}>
                            Alterar
                        </ButtomElement>
                        <RolePassword />
                    </>
                )}

                <CancelButtonElement onClick={goBack}>Cancelar</CancelButtonElement>
            </FormElement>
        </ResetPasswordElement>
    );
};

export default ResetPassword;
