import styled from "styled-components";

export const CarouselVmElement = styled.div`
  width: 70%;
  text-align: center;

  .p-carousel .p-carousel-indicators .p-carousel-indicator button {
    width: 1rem !important;
    height: 1rem !important;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 50% !important;
  }
`;

export const CarouselTextElement = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
`;

export const CarouselTitleElement = styled.span`
  width: 100%;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
`;

export const CarouselImageElement = styled.img`
  width: 100%;
  height: 100%;
`;
