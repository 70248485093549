export enum ModuleEnumReport {
    ADMIN,
    BANNER,
    BANNER_B2C,
    BANNER_B2B,
    CONTRACTOR,
    BENEFIT,
    BENEFIT_B2C,
    BENEFIT_B2B,
    CATEGORIES,
    CONTRACTOR_B2C,
    CONTRACTOR_B2B,
    CONTRACTOR_AND_PARTNER,
    CONTRACTOR_AND_PARTNER_B2C,
    CONTRACTOR_AND_PARTNER_B2B,
    INFO,
    INFO_B2C,
    INFO_B2B,
    PARTNER,
    PARTNER_B2C,
    PARTNER_B2B,
    APP,
    PRODUCTS,
    POPUP,
    POPUP_B2C,
    POPUP_B2B,
    NEWS,
    NEWS_B2C,
    NEWS_B2B

}
