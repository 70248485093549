import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";

export const DashboardSize = styled.div`
  width: 100%;
`;

export const BannerElement = styled.div`
  display: flex;
  width: 65%;
  height: 100%;
  background-color: #6285ae;
  border-radius: 18px;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const DivsChartPartner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 60%; */
`;

export const DivsCharts = styled.div`
  width: 100%;
`;

export const Chart1 = styled.div`
  width: 100%;
  height: 7em;
  display: flex;
  margin-bottom: 1em;
`;

export const Chart2 = styled.div`
  width: 100%;
  height: 20em;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
`;

export const AssociateComission = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
`;

export const DivPartners = styled.div`
  padding: 15px;
  width: 34%;
  height: 28em;
  border-radius: 15px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.15);
  background-color: white;
`;

export const PercentageOfTransactions = styled.div`
  height: 1.8em;
  margin-top: 0.5em;
  width: 15%;
  border-radius: 5px;
  align-items: center;
  display: flex;
  background-color: #27AE60;
  justify-content: center;
`;

export const DivsBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-size: 17px;
  color: #d9ebf3;
  text-align: center;
`;

export const BannerTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

export const BannerButton = styled.button`
  border: none;
  margin-top: 12px;
  border-radius: 10px;
  background-color: #00205c;
  color: white;
  width: 180px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const UpperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 4em;
`;

export const HeaderItemElement = styled.div`
  color: #42425d;
`;

export const DivBannerGraph = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 15em;
  margin-bottom: 1em;
`;

export const DivGraphs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 34%;
`;

export const Graph = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 7em;
  border-radius: 15px;
  padding: 20px;
  background-color: white;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);

`;

export const DivImg = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
`;

export const Square = styled.div`
  display: flex;
  border-radius: 13px;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 75%;
  background-color: #fee094;
  margin-right: 10px;
`;

export const TextGraph = styled.span`
  font-size: 17px;
  font-weight: 500;
  color: #42425d;
  margin-bottom: 5px;
`;

export const TextGraph2 = styled.span`
  display: flex;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  color: #42425d;
  margin-bottom: 20px;
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const DivTitleDrop = styled.div`
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 600;
  color: #42425d;
`;

export const Contractors = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

export const Circle = styled.div`
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
  background-color: white;
`;

export const CircleText = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
`;

export const ScrollPanelElement = styled(ScrollPanel)`
  width: 100%;
  height: 100%;

  .p-scrollpanel-bar {
    border: 0 none;
    background: #cdd0d4 !important;
  }
`;

export const ChartBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChartBarElement = styled.div`
  display: flex;
  width: 100%;
`;

export const TextChartDiv = styled.div`
  display: flex;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #42425d;
`;

export const StraightDiv = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  width: 80%;
  height: 50px;
`;

export const StraightElement = styled.div`
  border-radius: 10px;
  background-color: #6285ae;
  width: 100%;
  height: 22px;
  margin-right: 10px;
`;

export const ValueElement = styled.div`
  width: 60px;
  color: #42425d;
  font-size: 16px;
  font-weight: 400;
`;
