import { ButtonProcessElement } from "./style";

interface ButtonEvent {
    onClick: any;
    disabled? : boolean;
}

const ButtonProcess: React.FC<ButtonEvent> = ({onClick, disabled}) => { 
    return <ButtonProcessElement disabled={disabled} label="Processar"  onClick={onClick}/>
}

export default ButtonProcess;
