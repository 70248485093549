import React, { useState} from "react"
import {useAppContext} from "../../../context/app"
import SessionContext, {ISessionContext} from "../../../context/session"
import {ModuleEnum} from "../../../dto/moduleEnum/module-enum"
import {Session} from "../../../dto/session"
import RoutesTemplate from "../../templates/routes"
import AdminMenu from "../modules/admin/menu"
import MenuContractorB2C from "../modules/contractor/menu-B2C"
import MenuContractorB2B from "../modules/contractor/menu-B2B"
import MenuPartnerB2C from "../modules/partner/menu-B2C"
import MenuPartnerB2B from "../modules/partner/menu-B2B"

const Home: React.FC = () => {

  const [isMenuOpen, setIsOpen] = useState(false)
  const toggleMenu = () => setIsOpen((state) => !state)
  const session: Session = useAppContext().sessionProvider.getSession()

  let menu: any

//Monta o menu de acordo com o módulo do usuário logado.
    if(session.modules[0].moduleEnum === ModuleEnum.ADMIN){
        menu = <AdminMenu/>
    } else if(session.modules[0].moduleEnum === ModuleEnum.PARTNER && session.modules[0]?.partner?.moduleName === ModuleEnum.B2C){
        menu = <MenuPartnerB2C/>
    } else if(session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR && session.modules[0]?.contractor?.moduleName === ModuleEnum.B2C){
        menu = <MenuContractorB2C/>
    } else if(session.modules[0].moduleEnum === ModuleEnum.PARTNER && session.modules[0]?.partner?.moduleName === ModuleEnum.B2B){
        menu = <MenuPartnerB2B/>
    } else if(session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR && session.modules[0]?.contractor?.moduleName === ModuleEnum.B2B){
        menu = <MenuContractorB2B/>
    }

  //Monta o menu de acordo com o módulo do usuário logado.
  const sessionContext: ISessionContext = {
    session,
    toggleMenu,
    isMenuOpen,
  }

  return (
      <SessionContext.Provider value={sessionContext}>
        <RoutesTemplate menu={menu}/>
      </SessionContext.Provider>
  )
}

export default Home
