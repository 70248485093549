import styled from "styled-components";

export const Error = styled.span`
  color: red;
  text-align: right;
  margin-top: 5px;
  font-size: 12px;
`;

export const AddressDivElement = styled.div`
display: flex;
width: 30%;
flex-direction: column;
height: 100px;
`;
