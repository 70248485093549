import { Carousel } from "primereact/carousel";
import { useState } from "react";

import {
  CarouselVmElement,
  CarouselTitleElement,
  CarouselTextElement,
  CarouselImageElement,
} from "./style";

interface CarouselVmProps {
  banners?: Banner[];
}

const CarouselVm: React.FC<CarouselVmProps> = (props) => {
  const [page, setPage] = useState(0);

  function productTemplate(banner: Banner) {
    return <CarouselImageElement src={banner.img} />;
  }

  function changeBanner(e: any) {
    if (e.page !== page) {
      setPage(e.page);
    }
  }

  return (
    <CarouselVmElement>
      <Carousel
        page={page}
        onPageChange={changeBanner}
        value={props.banners}
        numVisible={1}
        numScroll={1}
        itemTemplate={productTemplate}
        style={{
          width: "70%",
          margin: "auto",
        }}
      />

      <CarouselTitleElement>
        {props && props.banners && props.banners[page].title}
      </CarouselTitleElement>
      <CarouselTextElement>
        {props && props.banners && props.banners[page].text1}
      </CarouselTextElement>
      <CarouselTextElement>
        {props && props.banners && props.banners[page].text2}
      </CarouselTextElement>
    </CarouselVmElement>
  );
};

export default CarouselVm;

export interface Banner {
  img: any;
  title: string;
  text1: string;
  text2: any;
}
