import { MultiSelect } from "primereact/multiselect";
import React, { useCallback, useEffect, useState } from "react";
import trashIcon from "../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../context/app";
import { ModuleEnum } from "../../../../dto/moduleEnum/module-enum";
import { ModuleEnumReport } from "../../../../dto/moduleEnum/module-enum-report";
import { ActionButtons } from "../../../pages/modules/partner/benefit/view/style";
import ButtonExport from "../../atoms/buttom-export";
import ButtonProcess from "../../atoms/button-process";
import Error from "../../atoms/error-message";
import InputDate from "../../molecules/input-date";
import { HeaderViewReportElement } from "./style";

interface HeaderReportFilterProps {
    findReport: any;
    exportReport: any;
    labelText?: string;
    typeReport?: any;
    textPlaceholder?: string;
    typeModuleName?: ModuleEnum;
    isHidden?: boolean;
}

const HeaderReportFilter: React.FC<HeaderReportFilterProps> = (
    {
        findReport,
        exportReport,
        labelText,
        typeReport,
        textPlaceholder,
        typeModuleName,
        isHidden
    }) => {

    const now = new Date(); // data atual
    const [dateStart, setDateStart] = useState(new Date(now.getFullYear(), now.getMonth(), 1) as Date) // Inicial date
    const [dateEnd, setDateEnd] = useState(new Date() as Date) // Final date
    const [listReport, setListReport] = useState([] as any[]); // List used on "Options" from MultiSelect
    const [filterSelected, setFilterSelected] = useState([] as any[]); // Used to receive the name of module (Partner, contractor or benefit)
    const appContext = useAppContext();
    const { apiProvider } = appContext;

    // Requisiting data to backend
    const getAllDataReport = useCallback(async () => {
        let url = "";

        switch (typeReport) {
            case ModuleEnumReport.BANNER:
                url = "/admin/banner";
                break

            case ModuleEnumReport.BANNER_B2C:
                url = "/admin/banner?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.BANNER_B2B:
                url = "/admin/banner?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.BENEFIT:
                url = "/web/benefit/findBenefitForReport";
                break

            case ModuleEnumReport.BENEFIT_B2C:
                url = "/web/benefit/findBenefitForReport?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.BENEFIT_B2B:
                url = "/web/benefit/findBenefitForReport?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.CATEGORIES:
                url = "/web/category";
                break

            case ModuleEnumReport.CONTRACTOR:
                url = "/admin/contractor";
                break

            case ModuleEnumReport.CONTRACTOR_B2B:
                url = "/admin/contractor?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.CONTRACTOR_B2C:
                url = "/admin/contractor?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.CONTRACTOR_AND_PARTNER:
                url = "/admin/contractor/contractorPartner";
                break

            case ModuleEnumReport.CONTRACTOR_AND_PARTNER_B2C:
                url = "/admin/contractor/contractorPartner?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.CONTRACTOR_AND_PARTNER_B2B:
                url = "/admin/contractor/contractorPartner?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.NEWS:
                url = "/admin/news";
                break

            case ModuleEnumReport.NEWS_B2C:
                url = "/admin/news?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.NEWS_B2B:
                url = "/admin/news?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.INFO:
                url = "/web/informative";
                break

            case ModuleEnumReport.INFO_B2C:
                url = "/web/informative?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.INFO_B2B:
                url = "/web/informative?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.PARTNER:
                url = "/admin/partner";
                break

            case ModuleEnumReport.PARTNER_B2C:
                url = "/admin/partner?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.PARTNER_B2B:
                url = "/admin/partner?moduleName=" + ModuleEnum.B2B;
                break

            case ModuleEnumReport.PRODUCTS:
                url = "/web/product";
                break

            case ModuleEnumReport.POPUP:
                url = "/admin/popup";
                break

            case ModuleEnumReport.POPUP_B2C:
                url = "/admin/popup?moduleName=" + ModuleEnum.B2C;
                break

            case ModuleEnumReport.POPUP_B2B:
                url = "/admin/popup?moduleName=" + ModuleEnum.B2B;
                break

            default:
                break
        }

        // Receiving data according to the requested module.enum
        const response: any = await apiProvider.get(url);
        if (response.data[0].name != null) {
            setListReport(response.data.sort((a: { name: any; }, b: { name: any; }) => a.name.localeCompare(b.name)));
        } else if (response.data[0].title != null) {
            setListReport(response.data.sort((a: { title: any; }, b: {
                title: any;
            }) => a.title.localeCompare(b.title)));
        }

    }, []);

    // To load the filter when the page opens
    useEffect(() => {
        getAllDataReport();
    }, []);

    useEffect(() => {
        findReport(buildParam());
    }, [listReport]);

    // Inserting data on multiselect
    const handleChangeMulti = useCallback(async (e) => {
        setFilterSelected(e.value);
    }, []);

    // Receiving, in nameList from filterSelected, all names from objects and transforming the data.
    const buildParam = () => {
        const nameList: any[] = [];
        const idList: any[] = [];

        if (filterSelected.length > 0) {
            filterSelected.map((value: any) => {
                if (value.name != null) {
                    nameList.push(value.name.toUpperCase());
                } else if (value.title != null) {
                    nameList.push(value.title.toUpperCase());
                }

                idList.push(value.id);
            });
        } else if (listReport.length > 0) {
            listReport.map((value: any) => {
                if (value.name != null) {
                    nameList.push(value.name.toUpperCase());
                } else if (value.title != null) {
                    nameList.push(value.title.toUpperCase());
                }

                idList.push(value.id);
            });
        }

        return { dateStart: dateStart, dateEnd: dateEnd, filtersText: nameList, filterIds: idList, moduleName: typeModuleName }
    }

    // When the user click on the trash side the filter bar, call this function
    const removeFilter = () => {
        setFilterSelected([] as any[]);
    }

    return (
        <HeaderViewReportElement style={{ maxWidth: "100%", display: isHidden ? 'none' : 'block' }}>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <InputDate label='Data inicial' minDate={null} value={dateStart} setValue={setDateStart} />

                <InputDate
                    label='Data final'
                    minDate={dateStart}
                    value={(dateStart <= dateEnd) ? dateEnd : dateStart}
                    setValue={setDateEnd}
                />

                <div style={{
                    display: "flex",
                    width: '100%',
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "25px"
                }}>
                    <MultiSelect
                        style={{ width: '200%', maxWidth: '600px', alignItens: 'left', marginRight: "20px" }}
                        disabled={false}
                        name='categorys'
                        value={(filterSelected == null) ? "" : filterSelected}
                        options={listReport}
                        optionLabel={(listReport != null && listReport.length > 0 && listReport[0].name != null) ? 'name' : 'title'}
                        onChange={(e) => handleChangeMulti(e)}
                        display="chip"
                        filter
                        placeholder={textPlaceholder}
                    />
                    <Error name='categorys' errors={""} />

                    <ActionButtons><img src={trashIcon} onClick={() => removeFilter()} /></ActionButtons>
                </div>

            </div>

            <div style={{ display: "flex", justifyContent: "end", marginTop: "1em" }}>
                <ButtonProcess onClick={() => {
                    findReport(buildParam())
                }} disabled={!dateStart || !dateEnd} />
                <ButtonExport onClick={() => {
                    exportReport(buildParam())
                }} />
            </div>
        </HeaderViewReportElement>
    )
}

export default HeaderReportFilter
