import styled from "styled-components"

export const WelcomeLoginDivElement = styled.div`
    display: flex;
    background-color:#00205C;
    width: 100%; 
    height: 100vh;
    align-items: center;
    justify-content: center; 
    flex-direction: column;
    -webkit-animation: slidein-from-right2 500ms;
    animation: slidein-from-right2 500ms;
    overflow: hidden;

    @keyframes slidein-from-right2 {
    from {
        opacity: 0;
        -webkit-transform:translateX(100%);
    }
    to {
        opacity: 1;
        -webkit-transform:translateX(0%);
    }
}
`;

