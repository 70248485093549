import { CSSProperties } from "styled-components";
import InputTextVm from "../../atoms/input-text";
import { SearchTextLabelDiv } from "./style";

interface ValueChange {
  onChange?: any;
  style?: CSSProperties
}

const SearchTextLabel: React.FC<ValueChange> = ({ onChange, style }) => {
  return (
    <SearchTextLabelDiv style={style}>
      <span style={{width: "100%"}} className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputTextVm onChange={onChange} placeholder="Digite o que deseja buscar..."
          style={{
            borderRadius: "10px",
            width: "100%",
            height: "43px",
            marginTop: "0px",
          }}
        />
      </span>
    </SearchTextLabelDiv> 
  );
};

export default SearchTextLabel;
