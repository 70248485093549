import axios, { AxiosInstance } from "axios";

const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

let token = localStorage.getItem("@vmparcerias:app_token");
httpClient.interceptors.request.use(async (config: any) => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default class ApiProvider {

  post(url: string, rest: any) {
    return httpClient.post(url, rest);
  }

  put(url: string, rest: any) {
    return httpClient.put(url, rest);
  }

  delete(url: string) {
    return httpClient.delete(url);
  }

  async get<T>(url: string, ...rest: any): Promise<T> {
    return httpClient.get(url, { ...rest });
  }

  async download<T>(url: string): Promise<T> {
    return httpClient.get(url, {
      responseType: 'arraybuffer',
    });
  }

  async downloadPost<T>(url: string, data?:any): Promise<T> {
    return httpClient.post(url, data, {
      responseType: 'arraybuffer',
    });
  }

  setToken(tokens: string) {
    localStorage.setItem("@vmparcerias:app_token", tokens);
    token = tokens;
  }
}
