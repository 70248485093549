import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useAppContext } from "../../../../context/app";
import FormTemplateElement from "../../../templates/form-template";
import ButtomRequestElement from "../../atoms/buttom-request-form";
import InputTextVm from "../../atoms/input-text";
import RolePassword from "../../atoms/role-password";
import SpanSubtitle from "../../atoms/span-subtitle";
import { FormElement, InputDiv } from "./styles";

const UpdatePassword: React.FC = () => {
    const navigate = useNavigate();
    const context = useAppContext();
    const user = context.sessionProvider.getSession().user;

    const [oldPass, setOldPass] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/;

    const save = () => {
        if (pass !== confirmPass) {
            context.toast.show({
                severity: "error",
                summary: "Atenção",
                detail: "As senhas não conferem, tente novamente!",
                life: 3000,
            });

            return;
        }

        if (!regex.test(pass)) {
            context.toast.show({
                severity: "error",
                summary: "Atenção",
                detail: "As senha não atende aos critérios!",
                life: 3000,
            });

            return;
        }


        Auth.currentAuthenticatedUser()
            .then((user: any) => {
                return Auth.changePassword(user, oldPass, pass);
            })
            .then((data: any) => {

                context.toast.show({
                    severity: "success",
                    summary: "Concluído",
                    detail: "Senha alterada com sucesso!",
                    life: 3000,
                });

                navigate("/user-profile");
            })
            .catch((err: any) => {
                if (err?.message?.includes("Password did not conform with policy")) {
                    context.toast.show({
                        severity: "error",
                        summary: "Atenção",
                        detail: "Verifique as regras de alteração de senha e tente novamente!",
                        life: 3000,
                    });

                    return;
                }

                if (err?.message?.includes("Attempt limit exceeded, please try after some time")) {
                    context.toast.show({
                        severity: "error",
                        summary: "Atenção",
                        detail: "Limite de tentativas excedido, tente novamente depois de alguns minutos!",
                        life: 3000,
                    });

                    return;
                }

                context.toast.show({
                    severity: "error",
                    summary: "Atenção",
                    detail: "Não foi possível alterar a senha, tente novamente!",
                    life: 3000,
                });
            });
    };

    return (
        <FormTemplateElement>
            <SpanSubtitle text="Alteração de senha" />

            <FormElement>
                <InputDiv>
                    Senha atual
                    <InputTextVm style={{ maxWidth: 285 }} autoComplete="off" type="password" value={oldPass} onChange={(e) => setOldPass(e.target.value)} />
                </InputDiv>

                <InputDiv>
                    Nova senha
                    <InputTextVm style={{ maxWidth: 285 }} autoComplete="off" type="password" value={pass} onChange={(e) => setPass(e.target.value)} />
                </InputDiv>

                <InputDiv>
                    Confirme a nova senha
                    <InputTextVm style={{ maxWidth: 285 }} autoComplete="off" type="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} />
                </InputDiv>
            </FormElement>

            <RolePassword />

            <ButtomRequestElement disabled={!oldPass || !pass || !confirmPass || pass !== confirmPass || (!regex.test(pass) && !regex.test(confirmPass))} onClick={save}>
                Salvar
            </ButtomRequestElement>
        </FormTemplateElement>
    );
};
export default UpdatePassword;
