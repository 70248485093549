import React from "react";
import { RoleContainer } from "./styles";

const RolePassword: React.FC = () => {
  return (
    <RoleContainer>
      <strong>Para sua segurança, a senha deverá conter no mínimo 8 caracteres, dos quais:</strong>
      <ul>
        <li>Ao menos 1 letra maiúscula (A...Z)</li>
        <li>Ao menos 1 letra minúscula (a...z)</li>
        <li>Ao menos 1 número (0...9)</li>
        <li>Ao menos 1 caracter especial (!@#$%...)</li>
      </ul>
    </RoleContainer>
  );
};

export default RolePassword;
