import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import eyesIcon from "../../../../../../assets/buttons/eyes.svg";
import penIcon from "../../../../../../assets/buttons/pen.svg";
import trashIcon from "../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../context/app";
import { confirm } from "../../../../../../utils/validation";
import ButtomAddVm from "../../../../../components/atoms/buttom-add-vm";
import DataTableElement from "../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../components/molecules/search-text-label";
import { HeaderElementLeft, HeaderElementRight } from "../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../templates/view-header-template";
import TableViewElement from "../../../../../templates/view-table-template";
import { ActionButtons } from "../../benefit/view/style";

const StoreView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ storeList: [], isLoading: true });
    const { storeList } = state;
    const { apiProvider } = useAppContext();
    const appContext = useAppContext();

    const [filterText, setFilterText] = useState("");

    useEffect(() => {
        getStoreData();
    }, []);

    const getStoreData = useCallback(async () => {
        const response: any = await apiProvider.get("/web/store");
        setState({ storeList: response.data, isLoading: false });
    }, [])

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Lojas" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newStore} text="Nova Loja" />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    value={state.storeList}
                    responsiveLayout="scroll"
                    emptyMessage="Nenhum resultado encontrado"
                    itemsList={storeList}
                    sortField="userId"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="description" header="Descrição" sortable style={{ width: '45%' }} />
                    <Column alignHeader="center" align="center" field="phone" header="Telefone" sortable style={{ width: '45%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "10%" }} />
                </DataTableElement>
            </TableViewElement>
        </>
    );

    // DELETAR LOJA
    async function deleteStore(rowData: any) {
        try {
            await apiProvider.delete(`/web/store/${rowData.id}`);
            getStoreData();
            appContext.toast.show({
                severity: "success",
                summary: "Loja",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Loja",
                detail: "Erro ao exlcuir a loja!",
                life: 3000,
            });
        }
    }

    // NOVA LOJA
    function newStore() {
        navigate("/store-form");
    }

    // RENDERIZAÇÃO DAS COLUNAS DA TABELA
    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <ActionButtons onClick={() => confirm(rowData, "Deseja excluir esta loja ?", () => deleteStore(rowData))}>
                    <img style={{ width: "17px" }} alt="" src={trashIcon} />{" "}
                </ActionButtons>
                <ActionButtons
                    onClick={() => {
                        navigate("/store-form", {
                            state: { item: rowData, disabled: false },
                        });
                    }}
                >
                    <img style={{ width: "17px" }} alt="" src={penIcon} />
                </ActionButtons>
            </div>
        );
    }
};

export default StoreView;
