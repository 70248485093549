import { Column } from 'primereact/column';
import React, { useState } from "react";
import { useAppContext } from '../../../../../../context/app';
import { intToStrg } from '../../../../../../utils/formatUtil';
import DataTableElement from '../../../../../components/atoms/data-table-view';
import PageHeader from '../../../../../components/atoms/page-header';
import HeaderReportFilter from '../../../../../components/organisms/header-report-filter';
import { ModuleEnumReport } from "../../../../../../dto/moduleEnum/module-enum-report";
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import {TruncateData} from "../../../../../../utils/truncateData";

const ReportAdminGroupedByPopup: React.FC = () => {
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const appContext = useAppContext()
    const { apiProvider } = appContext

    const findReport = async (param: any) => {
        setIsLoading(true)
        const response: any = await apiProvider.post('/admin/report/grouped-by-popup', param)
        if (response) {
            let dataGrid: any = [];

            // Total visualizations
            response.data.map((item: any) => {
                if (item.id == null) {
                    setTotal(item)
                } else {
                    dataGrid.push(item)
                }
            })

            setResults(dataGrid)
        }

        setIsLoading(false)
    }

    const exportReport = async (param: any) => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.downloadPost('/admin/report/grouped-by-popup/export', param)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Relatórios agrupado por benefícios.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    const formatTotal = (item: any, value: string) => {
        if (item.name === 'Total') {
            return (<b>{value}</b>)
        } else {
            return value
        }
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="TOTAL:" footerStyle={{ textAlign: 'center' }} />
                <Column footer={(total.views == null) ? 0 : total.views} footerStyle={{ textAlign: 'center' }} />
                <Column footer={(total.clicks == null) ? 0 : total.clicks} footerStyle={{ textAlign: 'center' }} />
            </Row>
        </ColumnGroup>
    );

    return <>
        <PageHeader
            text="B2B - Interações agrupadas por Popup."
            subtext="Quantidade de visualizações e cliques recebidos em cada popup"
        />

        <HeaderReportFilter
            labelText="Popup"
            exportReport={exportReport}
            findReport={findReport}
            typeReport={ModuleEnumReport.POPUP_B2B}
            typeModuleName={ModuleEnum.B2C}
            textPlaceholder="Selecione o PopUp" />

        <div style={{ paddingTop: "2em" }}>
            <DataTableElement
                loading={isLoading}
                paginator
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                rows={10}
                value={results}
                responsiveLayout="scroll"
                emptyMessage="Nenhum resultado encontrado"
                itemsList={results}
                sortField="id"
                sortOrder={-1}
                rowsPerPageOptions={[5, 10, 25]}
                resizableColumns
                footerColumnGroup={footerGroup}
            >
                <Column header="Popup" alignHeader="center" align="center" field="name" body={(rowData) => formatTotal(rowData, TruncateData(rowData.name,80))} sortable style={{ width: '34%' }} />
                <Column header="Visualizações" alignHeader="center" align="center" field="views" body={(rowData) => formatTotal(rowData, rowData.views)} sortable style={{ width: '33%' }} />
                <Column header="Clicks" alignHeader="center" align="center" field="clicks" body={(rowData) => formatTotal(rowData, intToStrg(rowData.clicks))} sortable style={{ width: '33%' }} />
            </DataTableElement>
        </div>
    </>
}

export default ReportAdminGroupedByPopup