import moment from "moment"

export const parseDoubleToStr = (value: any) => {
    if (value) {
        return parseFloat(value).toFixed(2).replace('.', ',')
    }
    return '0'
}

export const dateTimeToStrg = (date: Date, format: string = "") => {
    if (date) {
        return moment(date).format(format ? format : "DD/MM/YY  hh:mm").replace(' ', ' às ')
    }
    return ''
}

export const dateToStrg = (date: Date) => {
    if (date) {
        return moment(date).format('DD/MM/YY')
    }
    return ''
}

export const intToStrg = (value: any): string => {
    if (value) {
        return parseInt(value).toString()
    }
    return '0'
}