import { Checkbox } from "primereact/checkbox"
import { useEffect, useState } from "react"
import welcomeLoginGuy from '../../../assets/welcomeLoginGuy.svg'
import { useSessionContext } from "../../../context/session"
import ButtomRequestElement from "../../components/atoms/buttom-request-form"
import { WelcomeLoginDivElement } from "./style"

interface Properties {
    setIsFirstAccess: any
}

const WelcomeLoginTemplate: React.FC<Properties> = ({ setIsFirstAccess }) => {
    const [politic, setPolitic] = useState(false);
    const [term, setTerm] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (term && politic) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [term, politic])

    return (
        <>
            <WelcomeLoginDivElement>
                <img style={{ width: '370px', height: '370px' }} alt='' src={welcomeLoginGuy}></img>
                <h1 style={{ color: 'white', textAlign: 'center' }}>Seja bem vindo(a), <br /> {useSessionContext().session.user.name} </h1>
                <div>
                    <div style={{ color: 'white', marginTop: 20, display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            style={{ marginRight: '10px', marginBottom: '10px' }}
                            checked={politic}
                            onChange={() => setPolitic(!politic)}
                            name='term'
                        />
                        <span>Li e concordo com a <a style={{ color: 'white' }} href="https://vmparcerias.com/politica-de-privacidade" target="_blank"><strong>Política de Privacidade</strong></a>.</span>
                    </div>
                    <Checkbox
                        style={{ marginRight: '10px' }}
                        checked={term}
                        onChange={() => setTerm(!term)}
                        name='politic' />
                    <span style={{ color: 'white' }}>Li e concordo com os<a style={{ color: 'white' }} href="https://vmparcerias.com/termos-de-uso" target="_blank"><strong> Termos de Uso</strong></a>.</span>
                </div>
                <ButtomRequestElement
                    style={{ backgroundColor: !disabled ? '#6285AE' : '', color: 'white', marginTop: '50px' }}
                    onClick={() => setIsFirstAccess(false)}
                    disabled={disabled}
                >
                    Vamos começar
                </ButtomRequestElement>
            </WelcomeLoginDivElement>
        </>
    )

}

export default WelcomeLoginTemplate