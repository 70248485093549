import { useNavigate } from "react-router";
import PageHeader from "../../../../../components/atoms/page-header";
import { ItemMenuReportElement } from "./style";

const ReportPartnerView: React.FC = () => {
    const navigate = useNavigate();
    return <>
        <PageHeader text="Relatórios"/> 

        <ItemMenuReportElement onClick={() => {navigate("/grouped-by-contractors") }}> 
            Visão com transações agrupados por contrante 
        </ItemMenuReportElement>
        
        <ItemMenuReportElement onClick={() => {navigate("/grouped-by-products") }}> 
            Visão com transações agrupados por produtos 
        </ItemMenuReportElement>

        <ItemMenuReportElement onClick={() => {navigate("/report-partner-grouped-by-benefit") }}> 
            Visão com transações agrupados por beneficios  
        </ItemMenuReportElement>

        <ItemMenuReportElement onClick={() => {navigate("/report-partner-new-user") }}> 
            Quantidade total de clientes e quantidade de novos clientes 
        </ItemMenuReportElement>

        
    </>
}

export default ReportPartnerView