import styled from "styled-components";

export const StoreList = styled.div`
  height: 145px;
  width: 320px;
  margin-right: 15px;
  padding: 15px;
  font-size: 14px;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.06);
  background-color: rgba(25, 92, 140, 0.05);
  border-radius: 25px;
`;

export const StreetAndNumberInfoElement = styled.div`
  color: #42425d;
  font-weight: normal;
  padding-top: 20px;
`;

export const CityNumberInfoElement = styled.div`
  color: #6c727a;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0px;
`;

export const UpperHeaderDivElement = styled.div`
  display: flex;
  align-items: center;
`;

export const StoresElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const SubTitleDataElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const DataElement = styled.div`
  display: flex;
  height: 80px;
  color: #42425d;
  margin-right: 10px;
  flex-direction: column;
`;

export const CheckboxItemsElement = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  height: 120px;
  justify-content: space-between;
`;

export const CheckboxElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
`;

export const Error = styled.span`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`;

export const SelectItemsDiv = styled.div`
  display: flex;
  width: 35%;
  align-items: center;
  justify-content: space-between;
`;

export const ButtomSaveElement = styled.div`
  margin-top: 15px;
`;

export const FormElementItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivInput = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin-right: 2em;
`;

export const DivSelect = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
`;
