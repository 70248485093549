import styled from "styled-components";

interface buttonColor {
  buttonColor: boolean;
}

export const StatusButtomElement = styled.button<buttonColor>`
  width: 80px;
  padding: 7px;
  border-radius: 25px;
  background-color: ${({ buttonColor }) =>
    buttonColor ? "#27AE60" : "#D21C1C"};
  color: white;
  border: none;
`;
