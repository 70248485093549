import { FilterMatchMode } from "primereact/api";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import trashIcon from "../../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../../context/app";
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { Session } from '../../../../../../../dto/session';
import { TruncateData } from "../../../../../../../utils/truncateData";
import { confirm } from "../../../../../../../utils/validation";
import ButtomAddVm from "../../../../../../components/atoms/buttom-add-vm";
import ButtonExport from "../../../../../../components/atoms/buttom-export";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import { HeaderElementLeft, HeaderElementRight } from "../../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import { ActionButtonsElement, ButtomsElement, TableElement } from "../../contractor/view/style";

const UserAdmView: React.FC = () => {
    const navigate = useNavigate();
    const appContext = useAppContext();
    const { apiProvider } = appContext;
    const [isAdmSelected, setIsAdmSelected] = useState(true);
    const [isContractorSelected, setIsContractorSelected] = useState(true);
    const [isPartnerSelected, setIsPartnerSelected] = useState(true);
    const [userList, setUserList] = useState([] as any[]);
    const [listFull, setListFull] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterText, setFilterText] = useState('');
    const moduleListFilterReport: string[] = [ModuleEnum.ADMIN, ModuleEnum.CONTRACTOR, ModuleEnum.PARTNER];
    const session: Session = appContext.sessionProvider.getSession();

    useEffect(() => {
        getAllUsers();
    }, []);

    useEffect(() => {
        if (!filterText) {
            setUserList(listFull)
        } else {
            const list: any[] = [];
            listFull.forEach((item: any) => {
                let email = item.email.toUpperCase()
                let company = item.company.toUpperCase()
                if (email.indexOf(filterText.toUpperCase()) >= 0 || company.indexOf(filterText.toUpperCase()) >= 0) {
                    list.unshift(item)
                }
            })
            setUserList(list)
        }
    }, [filterText]);

    const getAllUsers = useCallback(async () => {
        const response: any = await apiProvider.get("/admin/manager/findByFilters?modules=" + moduleListFilterReport);
        setListFull(response.data)
        setUserList(response.data);
        setIsLoading(false)
    }, [])

    const bodyModuleColumn = (rowData: any) => {
        if (rowData.module === ModuleEnum.CONTRACTOR) {
            return 'CONTRATANTE'
        } else if (rowData.module === ModuleEnum.PARTNER) {
            return 'PARCEIRO'
        } else if (rowData.module === ModuleEnum.ADMIN) {
            return 'ADMINISTRADOR'
        }
    }

    const downloadExport = async () => {
        appContext.setIsShowLoading(true)
        try {
            let url = '/admin/manager/export?modules=' + createFilterModule() + '&filterText=' + filterText
            appContext.setIsShowLoading(true)
            const response: any = await apiProvider.download(url)
            appContext.setIsShowLoading(false)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Exportação de gestores.xlsx'
            link.click()
        } catch (e: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Usuário",
                detail: e.response.data.message || "Erro ao exportar os gestores, tente novamente!",
                life: 3000,
            })
        }
        appContext.setIsShowLoading(false)
    }

    function showContent(rowData: any) {
        if (rowData.principal) {
            return false;
        } else {
            if (session.masterManager) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <>
            <PageHeader text="Gestores"> </PageHeader>

            <div style={{ width: "100%", display: "flex" }}>
                <ButtomAddVm style={{}} onclick={newUser} text="Novo gestor" />
                <ButtonExport onClick={downloadExport} />
            </div>

            <HeaderViewElement style={{ marginTop: "5px", justifyContent: 'space-between' }}>
                <HeaderElementLeft>
                    <div
                        style={{
                            textAlign: "center",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "5px"
                        }}
                    >
                        <Checkbox
                            style={{ marginRight: "10px" }}
                            checked={isAdmSelected}
                            onChange={(e) => {
                                setIsAdmSelected(e.checked);
                            }}
                        />
                        Admin
                        <Checkbox
                            style={{ marginRight: "10px", marginLeft: "15px" }}
                            checked={isContractorSelected}
                            onChange={(e) => {
                                setIsContractorSelected(e.checked);
                            }}
                        />
                        Contratante
                        <Checkbox
                            style={{ marginRight: "10px", marginLeft: "15px" }}
                            checked={isPartnerSelected}
                            onChange={(e) => setIsPartnerSelected(e.checked)}
                        />
                        Parceiro
                    </div>
                </HeaderElementLeft>
                <HeaderElementRight >
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement >
                <DataTableElement
                    loading={isLoading}
                    paginator
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    itemsList={userList}
                    filters={{
                        module: {
                            value: createFilterModule(),
                            matchMode: FilterMatchMode.IN,
                        },
                    }}
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={userList}
                    responsiveLayout="scroll"
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="module" header="Módulo" body={bodyModuleColumn} sortable style={{ width: '15%' }} />
                    <Column alignHeader="center" align="center" field="company" header="Empresa" body={(e) => TruncateData(e.company, 20)} sortable style={{ width: '25%' }} />
                    <Column alignHeader="center" align="center" field="email" body={(e) => TruncateData(e.email, 30)} header="Email" sortable style={{ width: '25%' }} />
                    <Column alignHeader="center" align="center" field="principal" body={(e) => actionStatusPricipal(e)} header="Principal?" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="masterManager" body={(e) => actionStatusManager(e)} header="Gestor?" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "20%" }} />
                </DataTableElement>
            </TableElement>
        </>
    );


    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <ButtomsElement>
                    {showContent(rowData) && (
                        <ActionButtonsElement
                            onClick={() =>
                                confirm(rowData, "Deseja excluir este usuário?", () =>
                                    deleteUser(rowData)
                                )
                            }
                        >
                            <img alt="" style={{ width: "17px" }} src={trashIcon} />
                        </ActionButtonsElement>
                    )}
                    <ActionButtonsElement
                        onClick={() => {
                            navigate("/userAdm-form", {
                                state: { item: rowData, disabled: false },
                            });
                        }}
                    >
                        <img alt="" style={{ width: "17px" }} src={penIcon} />
                    </ActionButtonsElement>
                </ButtomsElement>
            </div>
        );
    }

    function createFilterModule() {
        const moduleList: string[] = [];

        if (!isContractorSelected && !isAdmSelected && !isPartnerSelected) {
            return moduleList.push(ModuleEnum.EMPTY);
        }

        if (isContractorSelected) {
            moduleList.push(ModuleEnum.CONTRACTOR);
        }

        if (isAdmSelected) {
            moduleList.push(ModuleEnum.ADMIN);
        }

        if (isPartnerSelected) {
            moduleList.push(ModuleEnum.PARTNER);
        }

        return moduleList;
    }

    async function deleteUser(rowData: any) {
        try {
            await apiProvider.delete(`/admin/manager/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Usuário",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getAllUsers();
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Usuário",
                detail: "Erro ao excluir usuário!",
                life: 3000,
            })
        }
    }

    function newUser() {
        navigate("/userAdm-form");
    }

    function actionStatusManager(rowData: any) {
        if (rowData.masterManager) {
            return (<i className="pi pi-check" style={{ color: 'green' }} />);
        } else {
            return (<i className="pi pi-times" style={{ color: 'red' }}></i>);
        }
    }

    function actionStatusPricipal(rowData: any) {
        if (rowData.principal) {
            return (<i className="pi pi-check" style={{ color: 'green' }} />);
        } else {
            return (<i className="pi pi-times" style={{ color: 'red' }}></i>);
        }
    }

};

export default UserAdmView;
