import { Calendar } from "primereact/calendar";
import { ErrorEl } from "../../atoms/error-message/style";
import { InputDateDiv } from "./style";

interface InputDateProps {
    label: string;
    name?: string;
    value?: Date;
    setValue: any;
    minDate: any;
    maxDate?: any;
    dateFormat?: string;
    showButtonBar?: any;
    monthNavigator?: any;
    yearNavigator?: any;
}

const InputDate: React.FC<InputDateProps> = ({ value, name, setValue, label, minDate, maxDate, dateFormat, showButtonBar, monthNavigator, yearNavigator }) => {
    return (
        <InputDateDiv>
            <label>{label}</label>
            <Calendar
                style={{ height: '3em', width: '12em' }}
                dateFormat={dateFormat ?? 'dd/mm/yy'}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                name={name}
                onChange={(e) => setValue(e.value as any)}
                showIcon={true}
                showButtonBar={showButtonBar ?? false}
                monthNavigator={monthNavigator ?? false}
                yearNavigator={yearNavigator ?? false}
            />
            <ErrorEl>{!value ? "Campo obrigatório" : <></>}</ErrorEl>
        </InputDateDiv>
    );
};

export default InputDate;
