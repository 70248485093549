import styled from "styled-components";
import { ProgressBar } from 'primereact/progressbar';

interface isProgressBarShow {
  isShow : boolean;
}

export const ProgressBarElement = styled(ProgressBar)<isProgressBarShow>`
  display: ${({ isShow }) => (isShow ? "block" : "none")};
`;

export const HideProgressBarElement = styled.div<isProgressBarShow>`
  display: ${({ isShow }) => (isShow ? "none" : "block")};
`;

export const HeaderElement = styled.header`
  height: 75px;
  align-items: center;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
`;

export const HeaderDiv = styled.header`
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

export const ImgLogoElementLeft = styled.img`
  margin-left: 20px;
  margin-right: 10px;
  height: 60px;
  width: 60px;
  @media(max-width: 1320px){
    margin-left: 0px;
  }
`;

export const SideBarElement = styled.div`
  width: 40px;
  cursor: pointer;
  height: 100%;
  padding: 15px;
  margin-right: 10px;
`;

export const SideBarMainDivElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MenuItensElement = styled.div`
  margin-top: 1em;
  display: flex;
  width: 90%;
  border-radius: 15px;
  background-color: #FFFFFF;
  min-height: 3.7em;
  box-shadow: 0px 0px 25px rgba(163, 163, 163, 0.2);
  border: 1px solid #F0F0F0;
`;

export const ItensInsideDivItensElement = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.3em;
  margin-left: 0.7em;
`;

export const UserImageElement = styled.div`
  width: 4em; 
  height: 4em;
  border-radius: 50%;
  background-color: #00205C;
  display: flex;
  justify-content: center;
  align-items: center; 
`;

export const SessionUserNameDivElement = styled.div`
  color: #195c8c;
  font-size: 25px;
  margin-top: 10px;
  font-weight: 700;
`;

export const MenuButtonsDivElement = styled.div`
  display: flex;
  cursor: pointer;
  background-color: #f0f0f0;
  height: 50px;
  border-radius: 50%;
  width: 50px;
  margin-top: 15px;
`;

export const HeaderElementLeft = styled.div`
  width: 100%;
  align-items: center;
  padding-left: 15px;
  display: flex;
`;

export const HeaderElementRight = styled.div`
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export const ButtonMenuElement = styled.button`
  background-color: #f0f0f0;
  width: 50px;
  height: 50px;
  border-radius: 13px;
  justify-content: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: none;
  @media(max-width: 1320px){
    display:none;
  }
`;

export const MenuProfileElement = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  justify-content: center;
  color: #00205c;
`;
