import styled from "styled-components";

interface buttonColor {
  buttonColor: boolean;
}

export const BenefitButtomElement = styled.button`
  height: 45px;
  border: none;
  text-align: center;
  width: 12em;
  color: white;
  border-radius: 17px;
  background-color: #27ae60;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
`;

export const ActionButtons = styled.button`
  background-color: #f7f9fc;
  width: 50px;
  height: 50px;
  border-radius: 13px;
  justify-content: center;
  display: flex;
  cursor: pointer;
  align-items: center;
  border: none;
`;

export const StatusButtomElement = styled.button<buttonColor>`
  width: 70px;
  padding: 7px;
  border-radius: 25px;
  background-color: ${({ buttonColor }) =>
    buttonColor ? "#27AE60" : "#D21C1C"};
  color: white;
  border: none;
`;
