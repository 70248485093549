import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import asaas from "../../../../../assets/asaas.svg"
import associatesFigure from "../../../../../assets/contractor/dashboard/associatesFigure.svg"
import bannerImg from "../../../../../assets/contractor/dashboard/bannerImg.png"
import dolarImg from "../../../../../assets/contractor/dashboard/dolar.png"
import { useAppContext } from "../../../../../context/app"
import { Partner } from "../../../../../dto/partner"
import { Session } from "../../../../../dto/session"
import { parseDoubleToStr } from "../../../../../utils/formatUtil"
import PageHeader from "../../../../components/atoms/page-header"
import { TextGraph2 } from "../../partner/dashboard/style"
import { LabelChart, ValueChart } from "../dashboard-B2B/style"
import {
    AssociatesDivElement,
    BannerButton,
    BannerElement,
    BannerImage,
    BannerTitle,
    Chart1,
    Chart2,
    ChartBarDiv,
    ChartBarElement,
    Circle,
    CircleText,
    DashboardDivs,
    DashboardSize,
    DivRight,
    DivText,
    DivText2,
    DivsBanner,
    HeaderItemElement,
    ImgDivElement,
    Partners,
    ScrollPanelElement,
    Square,
    StraightDiv,
    StraightElement,
    TextChart,
    TextChartDiv,
    TextGraph, UpperDiv,
    ValueElement
} from "./style"

const DashboardContractor: React.FC = () => {
    const navigate = useNavigate();
    const [economy, setEconomy] = useState({ economyAmount: 0, totalPercent: 0 } as any);
    const [partners, setPartners] = useState([]);
    const [categories, setCategories] = useState([{ categoryName: "sem resultados", amountTransaction: 0 }] as any);
    const { apiProvider } = useAppContext();
    const [commission, setCommission] = useState({} as any);
    const [activeContractoesUsersAmount, setActiveUsersAmount] = useState(0);
    const appContext = useAppContext()
    const session: Session = appContext.sessionProvider.getSession();
    const urlAsaas = (process.env.REACT_APP_ENVIRONMENT == 'local') ? 'https://sandbox.asaas.com/login/auth' : 'https://www.asaas.com/login/auth';

    const getCommission = useCallback(async () => {
        const response: any = await apiProvider.get("/web/dashboard/contractor/commission")
        setCommission(response.data)
    }, []);

    const getAllActiveContractorsUsers = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/contractor/actived-associates')
        setActiveUsersAmount(response.data.activedAssociates || 0)
    }, []);

    useEffect(() => {
        getAllActiveContractorsUsers();
        getCommission();
        getPartners();
        getCategories();
        getEconomy();
    }, []);

    const timeFilter = [
        { label: "Diária" },
        { label: "Semanal" },
        { label: "Mensal" },
    ];

    function renderCategoryList() {
        if (categories && categories.length > 0) {
            const maior: any = categories[0].amountTransaction;

            return categories.map((category: any, index: any) => {
                const widt = ((category.amountTransaction * 100) / maior).toFixed(2);
                return (
                    <ChartBarDiv key={index}>
                        <ChartBarElement>
                            <TextChartDiv>{category.categoryName}</TextChartDiv>
                            <StraightDiv>
                                <StraightElement style={{ width: `${widt}%` }} />
                                <ValueElement>{category.amountTransaction}</ValueElement>
                            </StraightDiv>
                        </ChartBarElement>
                    </ChartBarDiv>
                )
            });
        }

        return (
            <ChartBarDiv>
                <ChartBarElement>
                    <TextChartDiv style={{ justifyContent: "flex-start", width: "100%" }}>
                        Sem resultados
                    </TextChartDiv>
                </ChartBarElement>
            </ChartBarDiv>
        )
    }

    function renderPartnerList() {
        return partners.map((partner: Partner, index) => {
            return (
                <Partners key={index}>
                    <CircleText>
                        <Circle>
                            <img style={{ width: "100%", borderRadius: '100%' }} alt="" src={partner.imageUrl} />
                        </Circle>
                        <DivText2>
                            <TextGraph>{partner.name}</TextGraph>
                        </DivText2>
                    </CircleText>
                </Partners>
            )
        })
    }

    return (
        <DashboardSize>
            <UpperDiv>
                <PageHeader text="Dashboard" />
                <HeaderItemElement>
                    Informações dos últimos 30 dias.
                </HeaderItemElement>
            </UpperDiv>

            <BannerElement>
                <DivsBanner>
                    Com os destaques você pode mais!
                    <br />
                    <BannerTitle>
                        <span style={{ color: "white" }}> Divulgue</span> suas novidades na
                        tela inicial <span style={{ color: "white" }}>do APP</span>
                    </BannerTitle>
                </DivsBanner>
                <BannerImage>
                    <img
                        style={{ width: "90%", marginBottom: "30px" }}
                        alt=""
                        src={bannerImg}
                    />
                </BannerImage>
                <DivsBanner style={{ width: "28%" }}>
                    Seus colaboradores estão ansiosos para saber mais sobre as suas
                    novidades. <strong>Divulgue agora!</strong> <br />
                    <BannerButton onClick={() => {
                        navigate("/want-to-disclose")
                    }}>Quero Divulgar</BannerButton>
                </DivsBanner>
            </BannerElement>

            <DashboardDivs style={{ flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Chart1>
                        <AssociatesDivElement>
                            <TextChart>Usuário ativos</TextChart>
                            <TextChart style={{ fontSize: "28px" }}>
                                {activeContractoesUsersAmount &&
                                    <>
                                        <strong>{activeContractoesUsersAmount}</strong>
                                    </>
                                }
                            </TextChart>
                        </AssociatesDivElement>
                        <ImgDivElement>
                            <Square>
                                <img style={{ width: "52%" }} alt="" src={associatesFigure} />
                            </Square>
                        </ImgDivElement>
                    </Chart1>
                    <Chart1>
                        <AssociatesDivElement>
                            <TextChart>Economia para os usuários</TextChart>
                            <TextChart>
                                <strong
                                    style={{ fontSize: "24px" }}>R$ {economy.economyAmount ? parseDoubleToStr(economy.economyAmount) : 0} </strong>
                                <span style={{ fontSize: "20px" }}>({parseDoubleToStr(economy.totalPercent)}%)</span>
                            </TextChart>
                        </AssociatesDivElement>
                        <ImgDivElement>
                            <Square>
                                <img style={{ width: "52%" }} alt="" src={dolarImg} />
                            </Square>
                        </ImgDivElement>
                    </Chart1>
                    {(session.masterManager && session?.modules[0]?.contractor?.idAsaasCustomer) &&
                        <Chart1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <AssociatesDivElement style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <LabelChart>Veja suas comissões:</LabelChart>
                                    <ValueChart style={{ fontSize: "30px" }}>
                                        <a href={urlAsaas} target={"is_black"}><img src={asaas} width={'100px'} alt="Asaas Logo" /></a>
                                    </ValueChart>
                                </div>
                            </AssociatesDivElement>
                        </Chart1>
                    }

                </div>
                <div style={{ display: "flex", height: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "65%", height: "100%" }}>
                        <Chart2>
                            <TextChart style={{ fontSize: "19px", fontWeight: "bold" }}>
                                Atividade dos usuários
                            </TextChart>
                            <ScrollPanelElement>{renderCategoryList()}</ScrollPanelElement>
                        </Chart2>
                    </div>
                    <DivRight>
                        <DivText style={{ marginBottom: "15px" }}>
                            <TextGraph2 style={{ marginBottom: "10px" }}>
                                Redes mais utilizadas
                            </TextGraph2>
                        </DivText>
                        <ScrollPanelElement>{renderPartnerList()}</ScrollPanelElement>
                    </DivRight>
                </div>
            </DashboardDivs>
        </DashboardSize>
    )

    async function getPartners() {
        const response: any = await apiProvider.get(`/web/dashboard/contractor/b2c/most-viewed-partners`)
        setPartners(response.data)
    }

    async function getCategories() {
        const response: any = await apiProvider.get(`/web/dashboard/contractor/categories?period=MONTH`)
        setCategories(response.data)
    }

    async function getEconomy() {
        try {
            const response: any = await apiProvider.get(`/web/dashboard/contractor/economy?period=MONTH`)
            setEconomy(response.data)
        } catch (error: any) {
            setEconomy({ economyAmount: 0, totalPercent: 0 })
        }
    }
}

export default DashboardContractor
