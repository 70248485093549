import styled from "styled-components";

interface isButtomDisabled {
  disabled: boolean;
}

export const ButtomRequestElement = styled.button<isButtomDisabled>`
  background-color: ${({ disabled }) => (disabled ? "#d3d3d3 " : "#B7E4FF")};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ disabled }) => (disabled ? "white" : "#195C8C")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  border-radius: 11px;
  width: 11em;
  height: 3em;
  border: none;
  margin-top: 1.3em;
`;

export const HeaderViewReportElement = styled.div`
  width: 100%;
  border-radius: 15px;
  padding-bottom: 0.5em;
  padding-top: 1em;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  align-items: start;
  justify-content: space-evenly;
`;