import { Form, Formik } from 'formik'
import { Dropdown } from "primereact/dropdown"
import { InputTextarea } from 'primereact/inputtextarea'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { useAppContext } from '../../../../../../../context/app'
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum"
import BackButtom from '../../../../../../components/atoms/back-buttom'
import ButtomRequestElement from '../../../../../../components/atoms/buttom-request-form'
import DivInput from '../../../../../../components/atoms/div-input'
import Error from '../../../../../../components/atoms/error-message'
import PageHeader from '../../../../../../components/atoms/page-header'
import SpanSubtitle from '../../../../../../components/atoms/span-subtitle'
import ImageUpdate from '../../../../../../components/molecules/image-update'
import ItemForm from '../../../../../../components/molecules/item-form'
import { LineFormElement } from '../../../../../../components/molecules/item-form/style'
import FormTemplateElement from '../../../../../../templates/form-template'
import { InputTextareaDivElement } from '../../../../contractor/informative/form/style'

const NewsForm: React.FC = () => {
    const [news, setNews] = useState({} as any)
    const [disabled, setDisabled] = useState(false)
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any
    const [moduleSelected, setModuleSelected] = useState(null) as any;
    const [typeListSelected, setTypeListSelected] = useState(null) as any;
    const moduleList = [
        {
            name: ModuleEnum.B2C
        },
        {
            name: ModuleEnum.B2B
        }
    ];
    const typeListOptions = [
        {
            name: 'NOVIDADES',
            code: 0
        },
        {
            name: 'INFORMES CULTURAIS',
            code: 1
        }
    ];

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOneNew()
        } else {
            setDisabled(true);
        }
    }, [])

    const isLoadApi = () => {
        return !!news.id
    }

    const getOneNew = useCallback(async () => {
        const response: any = await apiProvider.get(`/admin/news/${state.item.id}`)
        typeListOptions.forEach(option => {
            if (option.code == response.data.type) {
                setTypeListSelected(option);
            }
        });
        setNews(response.data)
        setModuleSelected({ name: response?.data?.moduleName })
    }, [])

    const validations = Yup.object().shape({
        title: Yup.string().required().max(90),
        image: Yup.string().required(),
        text: Yup.string().required().max(2000),
        introText: Yup.string().required().max(90)
    })

    const save = async (values: any) => {
        const formData = new FormData()

        const data = {
            id: values.id ? values.id : null,
            title: values.title,
            text: values.text,
            date: new Date(),
            introText: values.introText,
            moduleName: moduleSelected.name,
            type: typeListSelected?.code
        }

        formData.append('new', JSON.stringify(data))
        formData.append('file', values.file)

        appContext.setIsShowLoading(true)
        try {
            if (!isNew) {
                await apiProvider.put('/admin/news', formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Novidade',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate('/news-view')
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Novidade',
                            detail: error.response.data.message || 'Erro ao cadastrar novidade, tente novamente!',
                            life: 3000,
                        });
                    });
            } else {
                await apiProvider.post('/admin/news', formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Novidade',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate('/news-view')
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Novidade',
                            detail: error.response.data.message || 'Erro ao cadastrar novidade, tente novamente!',
                            life: 3000,
                        });
                    });
            }
        } catch (e: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Novidade',
                detail: e.response.data.message || 'Erro ao cadastrar novidade, tente novamente!',
                life: 3000,
            })
        }
        appContext.setIsShowLoading(false)
    }

    function handleChangeSetModule(e: any) {
        if (e?.value) {
            setDisabled(false);
            setModuleSelected(e.value);
        }
    };

    function handleChangeSetTypeList(e: any) {
        if (e?.value) {
            setTypeListSelected(e.value);
        }
    }

    return (
        <>
            <PageHeader text='Novidade' />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate('/news-view')
                    }}
                />

                <SpanSubtitle text='Dados - novidade' />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={news}
                        validationSchema={validations}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <DivInput style={{ width: '100%' }}>
                                    MÓDULO
                                    <Dropdown
                                        style={{ marginTop: '10px' }}
                                        value={moduleSelected}
                                        onChange={(e) => handleChangeSetModule(e)}
                                        options={moduleList}
                                        optionLabel="name"
                                        placeholder="Selecione o módulo desejado."
                                        disabled={(isNew) ? false : true}
                                    />
                                </DivInput>
                                <DivInput style={{ width: '100%' }}>
                                    Tipo
                                    <Dropdown
                                        style={{ marginTop: '10px' }}
                                        value={typeListSelected}
                                        onChange={(e: any) => setTypeListSelected(e.value)}
                                        options={typeListOptions}
                                        optionLabel="name"
                                        placeholder="Selecione o tipo desejado."
                                        disabled={(isNew) ? false : true}
                                    />
                                </DivInput>
                                <LineFormElement style={{ justifyContent: 'flex-start' }}>
                                    <ItemForm
                                        disabled={disabled}
                                        propsForm={propsForm}
                                        name='title'
                                        label='Título'
                                        widthPercent='100'
                                    />

                                </LineFormElement>
                                <ItemForm
                                    disabled={disabled}
                                    propsForm={propsForm}
                                    name='introText'
                                    label='Texto prévio'
                                    widthPercent='100'
                                />
                                <LineFormElement>

                                </LineFormElement>

                                <LineFormElement>
                                    <InputTextareaDivElement style={{ width: '100%' }}>
                                        Texto
                                        <InputTextarea
                                            style={{ marginTop: '5px' }}
                                            disabled={disabled}
                                            value={propsForm.values.text}
                                            name='text'
                                            autoResize
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name="text" errors={propsForm.errors} />
                                    </InputTextareaDivElement>
                                </LineFormElement>

                                <LineFormElement>
                                    <ImageUpdate
                                        disabled={disabled}
                                        name="file"
                                        nameUrl="image"
                                        label="Upload da logomarca da contratante."
                                        propsForm={propsForm}
                                        widthImage={365}
                                        heightImage={177}
                                    />
                                </LineFormElement>

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        type='submit'
                                        disabled={isNew ?
                                            !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != '' || typeListSelected == null
                                            :
                                            propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                        }
                                    >
                                        Salvar dados
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    )
}

export default NewsForm
