import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useSessionContext } from '../../../context/session';


export const MenuElement = styled.ul`
  height: '100%';
  background-color: #00205c;
  width: ${() => (useSessionContext().isMenuOpen ? '4.5em' : '14em')};
  padding: 15px;
  border-radius: 24px;
  margin-top: 0px;
  @media(max-width: 1320px){
    width: 4.5em;
  }
`;

export const MenuSpanElement = styled.span`
  display: ${() => (useSessionContext().isMenuOpen ? 'none' : 'block')};
  margin-left: 10px;
  @media(max-width: 1320px){
    display: none;
  }
`;

interface MenuItemsElementProps {
  selected: boolean;
}

export const MenuItemsElement = styled(Link)<MenuItemsElementProps>`
  width: 100%;
  color: ${({ selected }) => (selected ? '#00205c' : 'white')};
  display: flex;
  font-family: Poppins;
  font-size: 1.1em;
  align-items: center;
  cursor: pointer;
  height: 45px;
  margin-bottom: 5px;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  background-color: ${({ selected }) => (selected ? 'white' : '#00205c')};
  padding-left: 10px;
  padding-right: 10px;
`;

export const FooterMainDivElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  margin-top: 35%;
  height: 30%;
  background-color:#6285AE;
  border-radius: 10%;
`;

export const UpperImageDivElement = styled.div`
  height: 10%;
  /* width: 100%; */
  margin-bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TextDivElement = styled.div`
  width: 100%;
  height: 45%;
`;

export const ButtonElement = styled.div`
  width: 90%;
  height: 60%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ButtonElementDiv = styled.div`
  cursor: pointer;
  height: 45px; width: 100%;
  background-color: #00205C;
  border-radius: 17px;
`;

export const ButtonElementClosedMenu = styled.div`
 cursor: pointer; 
 margin-top: 170px;
 height: 8%;
 width: 100%;
 background-color: #6285AE;
 border-radius: 30%;
`;