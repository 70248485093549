import {
  faBox, faChartPie,
  faExchangeAlt, faHandHoldingUsd, faStore, faUsers
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import Menu from "../../../../templates/menu";


interface MenuContractorProps {}

const MenuPartner: React.FC<MenuContractorProps> = () => {
  const itens: any = [
    {
      key: 1,
      icon: faChartPie,
      title: "Dashboard",
      route: ["/dashboard-partner", "/"]
    },
    {
      key: 2,
      icon: faHandHoldingUsd,
      title: "Benefícios",
      route: ["/benefit-view", "/benefit-form"]
    },
    {
      key: 3,
      icon: faStore,
      title: "Lojas",
      route: ["/store-view", "/store-form"]
    },
    {
      key: 4,
      icon: faBox,
      title: "Produtos",
      route: ["/product-view", "/product-form"]
    },
    {
      key: 5,
      icon: faExchangeAlt,
      title: "Transações",
      route: ["/transaction-view", "/transaction-form"]
    },
    {
      key: 6,
      icon: faUsers,
      title: "Gestores",
      route: ["/userPartner-view", "/userPartner-form"]
    },
    {
      key: 7,
      icon: faChartPie,
      title: "Relatórios",
      route: ["/report-partner-view", "/grouped-by-contractors", "/grouped-by-products", "/report-partner-new-user", "report-partner-grouped-by-benefit", "report-admin-grouped-by-benefit"]
    },
  ];

  return <Menu itens={itens} />;
};
export default MenuPartner;
