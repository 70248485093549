import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import eyesIcon from '../../../../../../../assets/buttons/eyes.svg'
import { useAppContext } from '../../../../../../../context/app'
import { dateTimeToStrg } from '../../../../../../../utils/formatUtil'
import DataTableElement from '../../../../../../components/atoms/data-table-view'
import PageHeader from '../../../../../../components/atoms/page-header'
import SearchTextLabel from '../../../../../../components/molecules/search-text-label'
import { HeaderElementLeft, HeaderElementRight } from '../../../../../../components/organisms/header/style'
import HeaderViewElement from '../../../../../../templates/view-header-template'
import { ActionButtonsElement } from '../../../../partner/product/view/style'
import { ButtomsElement, TableElement } from '../../contractor/view/style'

const SuggestPartnerView: React.FC = () => {
    const navigate = useNavigate()
    const [filterText, setFilterText] = useState('')
    const [state, setState] = useState({ suggestionsList: [], isLoading: true })
    const { suggestionsList } = state
    const { apiProvider } = useAppContext()

    useEffect(() => {
        getAllSuggestions()
    }, [])

    const getAllSuggestions = async () => {
        const response: any = await apiProvider.get('/admin/suggestion')
        setState({ suggestionsList: response.data, isLoading: false })
    }

    const actionButtons = (rowData: any) => {
        return (
            <ButtomsElement style={{ justifyContent: 'center' }}>
                <ActionButtonsElement
                    onClick={() => {
                        navigate('/suggest-partner-form-admin', { state: { item: rowData, disabled: true, goTo: '/partner-view' } })
                    }}
                >
                    <img alt='' style={{ width: '17px' }} src={eyesIcon} />
                </ActionButtonsElement>
            </ButtomsElement>
        )
    }

    const formatDate = (value: any) => {
        return (
            <span>
                {dateTimeToStrg(value.date)}
            </span>
        )
    }

    return (
        <>
            <PageHeader text='Sugestões de empresa'> </PageHeader>
            <HeaderViewElement>
                <HeaderElementLeft>
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    value={state.suggestionsList}
                    responsiveLayout='scroll'
                    emptyMessage='Nenhum resultado encontrado'
                    itemsList={suggestionsList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader='center' align='center' field='moduleName' header='Módulo' sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' field='companyName' header='Empresa' sortable style={{ width: '30%' }} />
                    <Column alignHeader='center' align='center' field='suggestedEmail' header='Email' sortable style={{ width: '30%' }} />
                    <Column alignHeader='center' align='center' field='dateToString' header='Data/Lançamento' sortable style={{ width: '20%' }} />
                    <Column alignHeader='center' align='center' field='actions' body={actionButtons} header='Ações' style={{ width: '10%' }} />
                </DataTableElement>
            </TableElement>
        </>
    )
}
export default SuggestPartnerView
