import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { Tag } from "primereact/tag";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import eyes from "../../../../../../../assets/buttons/eyes.svg";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import plusSign from "../../../../../../../assets/buttons/plus-sign.svg";
import trashIcon from "../../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../../context/app";
import { ModuleEnumStatus } from "../../../../../../../dto/moduleEnum/module-enum-status";
import { TruncateData } from "../../../../../../../utils/truncateData";
import { confirm } from "../../../../../../../utils/validation";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import { maskCNPJ } from "../../../../../../templates/masks-template";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import {
    ActionButtonsElement,
    ButtomsElement,
    ContractorButtomElement,
    HeaderElementLeft,
    HeaderElementRight,
    TableElement
} from "./style";

const ContractorView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ contractsList: [] as any[], isLoading: true })
    const [filterText, setFilterText] = useState("")
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const { contractsList } = state;

    useEffect(() => {
        getContractorData();
    }, []);

    const cnpjMask = (rowData: any) => {
        switch (rowData.typeOfPayer) {
            case 0:
                return (<>{maskCNPJ(rowData.contractor.cnpj)}</>)
                break
            case 1:
                return (<>{maskCNPJ(rowData.partner.cnpj)}</>)
                break
            case 2:
                return (<>{maskCNPJ(rowData.user.cpf)}</>)
                break
            default:
                return (<>Sem dados</>)
                break
        }
    }

    const verifyModuleName = (rowData: any) => {
        switch (rowData.typeOfPayer) {
            case 0:
                return (<>{rowData.contractor.moduleName}</>)
                break
            case 1:
                return (<>{rowData.partner.moduleName}</>)
                break
            case 2:
                return (<>{rowData.user.moduleName}</>)
                break
            default:
                return (<>Sem dados</>)
                break
        }
    }

    const verifyContractName = (rowData: any) => {
        switch (rowData.typeOfPayer) {
            case 0:
                return (<>{TruncateData(rowData.contractor.corporateName, 18)}</>)
                break
            case 1:
                return (<>{TruncateData(rowData.partner.corporateName, 18)}</>)
                break
            case 2:
                return (<>{TruncateData(rowData.user.corporateName, 18)}</>)
                break
            default:
                return (<>Sem dados</>)
                break
        }
    }

    const linked = (rowData: any) => {
        if (rowData.customerId != null) {
            return (<Tag className="mr-2" icon="pi pi-user" severity="success" value={'Vinculado'} />);
        } else {
            return (<Tag icon="pi pi-times" severity="danger" value={'Não vinculado'} />);
        }
    }

    // DELETE CONTRACT
    async function deleteContracts(rowData: any) {
        try {
            await apiProvider.delete(`/admin/contract/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Contrato",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getContractorData();
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Contrato",
                detail: "Erro ao excluir o contrato!",
                life: 3000,
            });
        }
    }

    const getContractorData = useCallback(
        async function () {
            const response: any = await apiProvider.get("/admin/contract");
            setState({ contractsList: response.data, isLoading: false });
        }, [])


    const handleToggle = async (e: any) => {

        try {
            const data = {
                id: e.id ? e.id : null,
                isActive: (e.isActive == null) ? ModuleEnumStatus.INACTIVE : (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
            }

            const response: any = await apiProvider.put(`/admin/contract/${data.id}`, data)
            if (response == null) {
                throw new Error("Não foi possível realizar essa alteração, tente novamente!");
            }

            appContext.toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Alteração realizada com sucesso!',
                life: 3000,
            })

            setState({
                contractsList: contractsList.map(item => {
                    if (item.id === e.id) {
                        return { ...item, isActive: data.isActive }
                    }
                    return item
                }), isLoading: false
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        }
    };

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Contratos"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>

                    <ContractorButtomElement onClick={() => {
                        navigate("/contracts-form");
                    }}>
                        <img alt="" style={{ marginRight: "10px" }} src={plusSign} /> Novo contrato
                    </ContractorButtomElement>

                </HeaderElementLeft>

                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginator
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.contractsList}
                    responsiveLayout="scroll"
                    itemsList={contractsList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" body={(e) => verifyModuleName(e)} header="Módulo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="name" body={(e) => TruncateData(e.name, 15)} header="Contrato" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="nameContractorPartner" body={(e) => verifyContractName(e)} header="Contratante/Parceiro" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="cnpjCpf" header="CNPJ" body={cnpjMask} sortable style={{ width: '20%' }} />
                    <Column alignHeader='center' align='center' field='isActive' header='ASAAS' body={linked} sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' field='isActive' header='Ativo' body={(e) => <InputSwitch checked={(e.isActive == ModuleEnumStatus.ACTIVE) ? true : false} onChange={() => handleToggle(e)} />} sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="actions" header="Ações" body={actionButtons} style={{ width: "10%" }} />
                </DataTableElement>
            </TableElement>
        </>
    );

    // RENDERIZAÇÃO DA COLUNA AÇÕES DA TABELA
    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <ButtomsElement>
                    <ActionButtonsElement
                        onClick={() => {
                            navigate("/contract-payment", {
                                state: { item: rowData },
                            });
                        }}
                    >
                        <img alt="" style={{ width: "17px" }} src={eyes} />{" "}
                    </ActionButtonsElement>
                    <ActionButtonsElement
                        onClick={() =>
                            confirm(rowData, "Deseja excluir este contrato?", () =>
                                deleteContracts(rowData)
                            )
                        }
                    >
                        <img alt="" style={{ width: "17px" }} src={trashIcon} />{" "}
                    </ActionButtonsElement>

                    <ActionButtonsElement
                        onClick={() => {
                            navigate("/contracts-form", {
                                state: { item: rowData, disabled: false },
                            });
                        }}
                    >
                        <img alt="" style={{ width: "17px" }} src={penIcon} />
                    </ActionButtonsElement>
                </ButtomsElement>
            </div>
        );
    }
};

export default ContractorView;
