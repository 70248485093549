import React, {useCallback, useEffect, useState} from "react";
import * as Yup from "yup";
import {useAppContext} from "../../../../context/app";
import {ActionEnum} from "../../../../dto/actionEnum";
import {FormElementItems} from "../../../pages/modules/partner/benefit/form/style";
import SelectDropDownVm from "../../atoms/select-drop-down";
import ItemForm from "../../molecules/item-form";
import {LineFormElement} from "../../molecules/item-form/style";
import {Error} from "../form-address/style";
import {BannerITemsElement} from "./style";
import {ModuleEnum} from "../../../../dto/moduleEnum/module-enum";
import {ISessionContext, useSessionContext} from "../../../../context/session";

interface ActionFowardingProps {
    propsForm: any;
    disabled: boolean;
}

const ActionFowarding: React.FC<ActionFowardingProps> = ({propsForm, disabled}) => {
    const {apiProvider} = useAppContext();
    const context: ISessionContext = useSessionContext();
    const [partnerList, setPartnerList] = useState([]);
    const [categoryList, setcategoryList] = useState([]);
    const [benefitList, setbenefitList] = useState([]);

    const actionsAdmin = [
        {
            value: ActionEnum.EXTERNAL_LINK,
            label: "Link externo",
        },
        {
            value: ActionEnum.ACTION_BENEFIT_ID,
            label: "Benefício",
        },
        {
            value: ActionEnum.ACTION_CATEGORY_ID,
            label: "Categoria",
        },
        {
            value: ActionEnum.ACTION_PARTNER_ID,
            label: "Parceiro",
        },
    ];

    const actionsContractor = [
        {
            value: ActionEnum.EXTERNAL_LINK,
            label: "Link externo",
        },
        {
            value: ActionEnum.ACTION_CATEGORY_ID,
            label: "Categoria",
        },
    ];

    const actionsPartner = [
        {
            value: ActionEnum.EXTERNAL_LINK,
            label: "Link externo",
        },
        {
            value: ActionEnum.ACTION_BENEFIT_ID,
            label: "Benefício",
        },
        {
            value: ActionEnum.ACTION_CATEGORY_ID,
            label: "Categoria",
        },
        {
            value: ActionEnum.ACTION_PARTNER_ID,
            label: "Promover página",
        },
    ];

    function getUserType(): any {
        let defineAction = null;

        if (context.session.modules[0].moduleEnum === ModuleEnum.ADMIN) {
            defineAction = actionsAdmin
        } else if (context.session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR) {
            defineAction = actionsContractor
        } else if (context.session.modules[0].moduleEnum === ModuleEnum.PARTNER) {
            defineAction = actionsPartner
        }

        return defineAction
    }

    function render() {
        if (propsForm.values.actionForwarding.actionEnum === 0) {/*External link*/
            return (
                <BannerITemsElement>
                    <ItemForm
                        propsForm={propsForm}
                        disabled={disabled}
                        label="Link externo"
                        name="actionForwarding.externalLink"
                        widthPercent="100"/>
                </BannerITemsElement>
            );
        }
        if (propsForm.values.actionForwarding.actionEnum === 1) { /*Benefit*/
            return (
                <BannerITemsElement>
                    <FormElementItems style={{width: " 100%"}}>
                        Parceiro
                        <SelectDropDownVm
                            disabled={disabled}
                            name="actionForwarding.partner"
                            onChange={propsForm.handleChange}
                            value={propsForm.values.actionForwarding.partner}
                            options={partnerList}
                            optionLabel="name"
                        />
                        <Error>{propsForm.errors.actionForwarding ? propsForm.errors.actionForwarding.partner : <>&nbsp;</>}</Error>
                    </FormElementItems>

                    <FormElementItems style={{width: " 100%"}}>
                        Benefício
                        <SelectDropDownVm
                            disabled={disabled}
                            name="actionForwarding.benefit"
                            onChange={propsForm.handleChange}
                            value={propsForm.values.actionForwarding.benefit}
                            options={benefitList}
                            optionLabel="name"
                        />
                        <Error>{propsForm.errors.actionForwarding ? propsForm.errors.actionForwarding.benefit : <>&nbsp;</>}</Error>
                    </FormElementItems>
                </BannerITemsElement>
            );
        }

        if (propsForm.values.actionForwarding.actionEnum === 2) {/*Categories*/
            return (
                <BannerITemsElement>
                    <FormElementItems style={{width: " 100%"}}>
                        Categoria
                        <SelectDropDownVm
                            disabled={disabled} name="actionForwarding.category"
                            onChange={propsForm.handleChange}
                            value={propsForm.values.actionForwarding.category}
                            options={categoryList}
                            optionLabel="name"
                        />
                        <Error>{propsForm.errors.actionForwarding ? propsForm.errors.actionForwarding.category : <>&nbsp;</>}</Error>
                    </FormElementItems>
                </BannerITemsElement>
            );
        }

        if (propsForm.values.actionForwarding.actionEnum === 3) {/*Partner*/
            return (
                <BannerITemsElement>
                    <FormElementItems style={{width: " 100%"}}>
                        Parceiro
                        <SelectDropDownVm
                            disabled={disabled}
                            name="actionForwarding.partner"
                            onChange={propsForm.handleChange}
                            value={propsForm.values.actionForwarding.partner}
                            options={partnerList}
                            optionLabel="name"
                        />
                        <Error>{propsForm.errors.actionForwarding ? propsForm.errors.actionForwarding.partner : <>&nbsp;</>}</Error>
                    </FormElementItems>
                </BannerITemsElement>
            );
        }
    }

    function cleanFields() {
        if (propsForm.values.actionForwarding.actionEnum === 0) {
            propsForm.values.actionForwarding.partner = "";
            propsForm.values.actionForwarding.benefit = "";
            propsForm.values.actionForwarding.category = "";
        }
        if (propsForm.values.actionForwarding.actionEnum === 1) {
            propsForm.values.actionForwarding.externalLink = "";
            propsForm.values.actionForwarding.category = "";
        }
        if (propsForm.values.actionForwarding.actionEnum === 2) {
            propsForm.values.actionForwarding.externalLink = "";
            propsForm.values.actionForwarding.partner = "";
            propsForm.values.actionForwarding.benefit = "";
        }
        if (propsForm.values.actionForwarding.actionEnum === 3) {
            propsForm.values.actionForwarding.externalLink = "";
            propsForm.values.actionForwarding.benefit = "";
            propsForm.values.actionForwarding.category = "";
        }
    }

    function validateActionEnum() {
        if (propsForm.values.actionForwarding.actionEnum === 1 || propsForm.values.actionForwarding.actionEnum === 3) {
            getAllPartners();
        }

        if (propsForm.values.actionForwarding.actionEnum === 2) {
            getAllCategory();
        }
    }

    const getOnePartnerBenefit = useCallback(
        async function () {
            if (propsForm.values.actionForwarding.partner) {
                const response: any = await apiProvider.get(`/admin/benefit/partner/${propsForm.values.actionForwarding.partner.id}`);
                const benefits = response.data.map((item: any) => {
                    return {
                        benefitValue: item.benefitValue, //Just to make sure it looks identical to the backend.
                        id: item.id,
                        name: item.name,
                        title: item.title, //Just to make sure it looks identical to the backend.
                    }
                })
                setbenefitList(benefits);
            }

            if (!propsForm.values.actionForwarding.partner) {
                setbenefitList([]);
            }
        },
        [propsForm, apiProvider]
    );

    const onePartner = useCallback(
        function () {
            if (propsForm.values.actionForwarding.actionEnum === 1 || propsForm.values.actionForwarding.actionEnum === 2) {
                getOnePartnerBenefit();
            }
        },
        [propsForm, getOnePartnerBenefit]
    );

    useEffect(() => {
        cleanFields();
        validateActionEnum();
        render();
    }, [propsForm.values.actionForwarding.actionEnum]);

    useEffect(() => {
        onePartner();
    }, [propsForm.values.actionForwarding.partner]);

    return (
        <LineFormElement>
            <FormElementItems style={{height: "100px", width: "20%"}}>
                Ações
                <SelectDropDownVm
                    disabled={disabled}
                    name="actionForwarding.actionEnum"
                    onChange={propsForm.handleChange}
                    value={propsForm.values.actionForwarding.actionEnum}
                    options={getUserType()}
                    optionLabel="label"
                />
            </FormElementItems>
            <>{render()}</>
        </LineFormElement>
    );

    async function getAllPartners() {
        let response: any = null;
        let responseArray: any[] = [];
        if (context.session.modules[0].moduleEnum === ModuleEnum.ADMIN) {
            response = await apiProvider.get("/admin/partner");
            setPartnerList(response.data);
        } else if (context.session.modules[0].moduleEnum === ModuleEnum.PARTNER) {
            response = await apiProvider.get(`/admin/partner/${context.session?.modules[0]?.partner?.id}`);
            responseArray.push(response.data);
            response = responseArray;
            setPartnerList(response);
        }
    }

    async function getAllCategory() {
        const response: any = await apiProvider.get("/web/category");
        setcategoryList(response.data);
    }
};

export function buildValidationActionFowarding(validation: any) {
    validation.actionForwarding = Yup.object().shape({
        externalLink: Yup.string().when(["actionEnum"], (actionEnum, schema): any => {
            if (actionEnum === 0) {
                return schema.required().max(1000);
            }
        }),
        partner: Yup.object().when(["actionEnum"], (actionEnum, schema): any => {
            if (actionEnum === 1 || actionEnum === 3) {
                return schema.required();
            }
        }),
        benefit: Yup.object().when(["actionEnum"], (actionEnum, schema): any => {
            if (actionEnum === 1) {
                return schema.required();
            }
        }),
        category: Yup.object().when(["actionEnum"], (actionEnum, schema): any => {
            if (actionEnum === 2) {
                return schema.required();
            }
        }),
    });

    return Yup.object().shape(validation);
}

export default ActionFowarding;
