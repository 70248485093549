import { Form, Formik } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import AppContext, { useAppContext } from "../../../../context/app";
import { ISessionContext, useSessionContext } from "../../../../context/session";
import { ModuleEnum } from "../../../../dto/moduleEnum/module-enum";
import FormTemplateElement from "../../../templates/form-template";
import ButtomRequestElement from "../../atoms/buttom-request-form";
import SpanSubtitle from "../../atoms/span-subtitle";
import ItemForm from "../../molecules/item-form";
import { LineFormElement } from "../../molecules/item-form/style";

const UserProfile: React.FC = () => {
  const navigate = useNavigate()
  const user = useAppContext().sessionProvider.getSession().user;
  const context: ISessionContext = useSessionContext()
  const validations = Yup.object().shape({
    name: Yup.string().required().max(250),
    email: Yup.string().required().email(),
  });

  const save = (values: any) => {
  }

  const handleUpdatePassword = () => {
    navigate("/update-password")
  }

  return (
    <FormTemplateElement>

      <SpanSubtitle text="Meus dados" />

        <Formik
          initialValues={context && user}
          validationSchema={validations}
          onSubmit={save}
        >
          {(propsForm) => (
            <Form>
              <LineFormElement style={{ justifyContent: "flex-start", marginBottom: "0em" }}>
                <ItemForm
                  propsForm={propsForm}
                  label="Nome"
                  widthPercent="28"
                  disabled={true}
                  name="name"
                />

                <ItemForm
                  propsForm={propsForm}
                  widthPercent="28"
                  label="Email"
                  disabled={true}
                  name="email"
                />
              </LineFormElement>

              <ButtomRequestElement disabled={false} type="button" onClick={handleUpdatePassword}>Alterar minha senha</ButtomRequestElement>

            </Form>
          )}
        </Formik>
    </FormTemplateElement>
  );

};
export default UserProfile;
