import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import penIcon from "../../../../../../assets/buttons/pen.svg"
import trashIcon from "../../../../../../assets/buttons/trash.svg"
import { useAppContext } from "../../../../../../context/app"
import { dateTimeToStrg } from "../../../../../../utils/formatUtil"
import { confirm } from "../../../../../../utils/validation"
import ButtomAddVm from "../../../../../components/atoms/buttom-add-vm"
import DataTableElement from "../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../components/molecules/search-text-label"
import { HeaderElementLeft, HeaderElementRight } from "../../../../../components/organisms/header/style"
import HeaderViewElement from "../../../../../templates/view-header-template"
import { ActionButtonsElement, ButtomsElement, TableElement } from "../../../admin/forms/contractor/view/style"

const InformativeView: React.FC = () => {
    const navigate = useNavigate()
    const appContext = useAppContext()

    const [state, setState] = useState({ informativeList: [], isLoading: true })
    const { informativeList } = state
    const [filterText, setFilterText] = useState("")
    const { apiProvider } = useAppContext()

    useEffect(() => {
        getAllinformatives()
    }, [])

    const getAllinformatives = useCallback(async () => {
        const response: any = await apiProvider.get("/web/informative")
        setState({ informativeList: response.data, isLoading: false })
    }, [])

    const data = (rowData: any) => {
        return (
            <span>
                {dateTimeToStrg(rowData.date)}
            </span>
        )
    }

    async function deleteInformative(rowData: any) {
        try {
            await apiProvider.delete(`/web/informative/${rowData.id}`)
            appContext.toast.show({
                severity: "success",
                summary: "Informativo",
                detail: "Excluido com sucesso!",
                life: 3000,
            })
            getAllinformatives()
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Informativo",
                detail: "Erro ao excluir informativo!",
                life: 3000,
            })
        }
    }

    function newInformative() {
        navigate("/informative-form")
    }

    return (
        <>
            <PageHeader text="Informativos"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newInformative} text="Novo informativo" />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.informativeList}
                    responsiveLayout="scroll"
                    itemsList={informativeList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                >
                    <Column alignHeader="center" align="center" field="title" header="Título" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="date" body={data} header="Data" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="categoryName" header="Categoria" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: '10%' }} />
                </DataTableElement>
            </TableElement>
        </>
    )

    function actionButtons(rowData: any) {
        return (
            <ButtomsElement>
                <ActionButtonsElement
                    onClick={() =>
                        confirm(rowData, "Deseja excluir este informativo?", () =>
                            deleteInformative(rowData)
                        )
                    }
                >
                    <img alt="" style={{ width: "17px" }} src={trashIcon} />
                </ActionButtonsElement>
                <ActionButtonsElement
                    onClick={() => {
                        navigate("/informative-form", {
                            state: { item: rowData, disabled: false },
                        })
                    }}
                >
                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtonsElement>
            </ButtomsElement>
        )
    }
}

export default InformativeView
