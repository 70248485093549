import styled from "styled-components";

export const SelectElement = styled.div`
  width: 100%;
  height: 100px;
  flex-direction: column;
  display: flex;
`;

export const CheckboxIsPreferedElement = styled.div`
  display: flex;
  // margin-top: 20px;
  align-items: center;
`;
