import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ResetPassword from './ui/templates/reset-password';
import LoginContractor from './ui/pages/modules/contractor/login';
import LoginPartner from './ui/pages/modules/partner/login';
import LoginAdmin from './ui/pages/modules/admin/login';
import { useAppContext } from './context/app';
import { Toast } from 'primereact/toast';
import VerifyAccount from './ui/templates/verify-account';
import NewPassword from './ui/templates/new-password';
import Term from './ui/templates/term';
import { Session } from './dto/session';
import { ModuleEnum } from './dto/moduleEnum/module-enum';

const PublicRoutes: React.FC = () => {
  const context = useAppContext();
  const session: Session = context.sessionProvider.getSession();
  return (
    <>
      <Toast ref={(el) => (context.toast = el)} />
      <Routes>
        <Route path='/' element={<LoginAdmin />} />
        <Route path='/parceiro' element={<LoginPartner />} />
        <Route path='/contratante' element={<LoginContractor />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/verify-account' element={<VerifyAccount />} />
        <Route path='/new-password' element={<NewPassword />} />
        <Route path='/term' element={<Term />} />

        <Route path='*' element={<LoginAdmin />} />

        {session?.modules[0]?.moduleEnum === ModuleEnum.CONTRACTOR && <Route path='*' element={<LoginContractor />} />}
        {session?.modules[0]?.moduleEnum === ModuleEnum.PARTNER && <Route path='*' element={<LoginPartner />} />}
        {session?.modules[0]?.moduleEnum === ModuleEnum.ADMIN && <Route path='*' element={<LoginAdmin />} />}
      </Routes>
    </>
  );
};

export default PublicRoutes;
