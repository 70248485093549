import styled from "styled-components";

export const DashboardSize = styled.div`
  width: 100%;
  min-width: 820px;
`;

export const UpperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 4em;
`;

export const BannerElement = styled.div`
  display: flex;
  width: 100%;
  height: 12em;
  background-color: #6285ae;
  border-radius: 18px;
  padding-left: 1em;
  padding-right: 1em;
`;

export const DashboardDivBotton = styled.div`
  display: flex;
  width: 100%;
`;

export const DivBannerText = styled.div`
  margin: auto;
  font-size: 17px;
  text-align: center;
`;

export const BannerTitle = styled.span`
  font-size: 1.6em;
  font-weight: 600;
`;

export const BannerButton = styled.button`
  border: none;
  margin-top: 12px;
  border-radius: 10px;
  background-color: #454DDE;
  color: white;
  width: 180px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const HeaderItemElement = styled.div`
`;

export const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5em;
  height: 3.5em;
  border-radius: 16px;
  background-color: #01FF0E;
  padding: 5px;
  float: right;
`;

export const TextChart = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
`;

export const LabelChart = styled.div`
  font-size: 0.8em;
  font-weight: 500;
`;

export const ValueChart = styled.div`
  font-weight: 700;
  font-size: 1.1em;
`;

export const PanelDashboardValue = styled.div`
  border-radius: 15px;
  width: 100%;
  padding: 1.5em 2em;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background: white;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const Partners = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5em;
`;

export const CircleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
`;

export const Circle = styled.div`
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
  background-color: white;
  margin-right: 10px;
`;

export const DivImpressions = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const LabelImpressions = styled.div`
  font-size: 0.8em;
  font-weight: 400;
  color: #9898B2;
`;

export const ValueImpressions = styled.div`
  font-size: 0.8em;
  font-weight: 400;
`;

export const TextGraph = styled.span`
  font-size: 17px;
  font-weight: 500;
`;

export const ChartBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NoResultsDiv = styled.div`
  width: 100%;
  font-size: 0.7em; 
  text-align: center;
  color: #9898B2;
`;


export const ChartBarElement = styled.div`
  display: flex;
  width: 100%;
`;

export const StraightDiv = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  width: 80%;
  height: 1.8em;
`;

export const StraightElement = styled.div`
  border-radius: 10px;
  background-color: #454DDE;
  width: 100%;
  height: 1em;
  margin-right: 10px;
`;

export const TextChartDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  font-size: 0.8em;
  font-weight: 400;
`;

export const ValueElement = styled.div`
  width: 60px;
  font-size: 1.1em;
  font-weight: 400;
`;
