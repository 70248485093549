
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import eyesIcon from "../../../../../../assets/buttons/eyes.svg";
import { useAppContext } from "../../../../../../context/app";
import DataTableElement from "../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../components/molecules/search-text-label";
import {
  HeaderElementLeft,
  HeaderElementRight
} from "../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../templates/view-header-template";
import TableViewElement from "../../../../../templates/view-table-template";
import { ActionButtons } from "../../../partner/benefit/view/style";


const TransactionContractorView: React.FC = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ transactionList: [], isLoading: true });
  const { transactionList } = state;
  const { apiProvider } = useAppContext();

  const [filterText, setFilterText] = useState("");

  const getAllTrasanction = useCallback(async () => {
    const response: any = await apiProvider.get("/web/filters/transaction/all");
    setState({ transactionList: response.data, isLoading: false });
  }, [])

  useEffect(() => {
    getAllTrasanction();
  }, []);

  // RENDERIZAÇÃO
  return (
    <>
      <PageHeader text="Transações" />

      <HeaderViewElement>
        <HeaderElementLeft />
        <HeaderElementRight>
          <SearchTextLabel
            onChange={(e: any) => {
              setFilterText(e.target.value);
            }}
          />
        </HeaderElementRight>
      </HeaderViewElement>

      <TableViewElement>
        <DataTableElement
          loading={state.isLoading}
          paginator
          filters={{
            global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
          }}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          value={state.transactionList}
          responsiveLayout="scroll"
          emptyMessage="Nenhum resultado encontrado"
          itemsList={transactionList}
        >
          <Column
            alignHeader="center"
            align="center"
            field="associateName"
            header="Usuário"
          />
          <Column
            alignHeader="center"
            align="center"
            field="partnerName"
            header="Parceiro"
          />
          <Column
            alignHeader="center"
            align="center"
            field="benefitValue"
            header="Benefício Utilizado"
          />
          <Column
            alignHeader="center"
            align="center"
            field="requestDate"
            header="Data e hora"
          />
          <Column
            alignHeader="center"
            align="center"
            field="totalAmount"
            header="Valor total"
          />
          <Column
            alignHeader="center"
            align="center"
            field="economyAmount"
            header="Economia"
          />
          <Column
            alignHeader="center"
            align="center"
            field="status"
            header="Status"
          />
          <Column
            alignHeader="center"
            align="center"
            field="actions"
            body={actionButtons}
            header="Ação"
          />
        </DataTableElement>
      </TableViewElement>
    </>
  );

  function actionButtons(rowData: any) {
    return (
      <ActionButtons
        style={{ width: "100%" }}
        onClick={() => {
          navigate("", { state: { item: rowData, disabled: true } });
        }}
      >
        {" "}
        <img alt="" style={{ width: "20px" }} src={eyesIcon} />
      </ActionButtons>
    );
  }
};
export default TransactionContractorView;