import { faArrowAltCircleUp, faChartPie, faClipboardCheck, faComment, faFlag, faMoneyBillWave, faNewspaper, faUpload, faUserTie } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Menu from '../../../../templates/menu'


interface MenuContractorProps {}

const AdminMenu: React.FC<MenuContractorProps> = () => {
  const itens: any = [
    {
      key: 1,
      icon: faUserTie,
      title: 'Cadastros',
      route: ['/registrations-view', '/contractor-view', '/contractor-form', '/partner-view', '/partner-form', '/userAdm-view', '/userAdm-form','/associate-control-view', '/register-user-form', '/category-view', '/category-form', '/associate-control-form', '/contracts-view', '/contracts-form'],
    },
    // {
    //   key: 1,
    //   icon: faUserTie,
    //   title: 'Contratante',
    //   route: ['/contractor-view', '/contractor-form'],
    // },
    // {
    //   key: 2,
    //   icon: faHandsHelping,
    //   title: 'Parceiros',
    //   route: ['/partner-view', '/partner-form']
    // },
    // {
    //   key: 3,
    //   icon: faUsers,
    //   title: 'Gestores',
    //   route: ['/userAdm-view', '/userAdm-form', '/']
    // },
    // {
    //   key: 4,
    //   icon: faMobileAlt,
    //   title: 'Usuários',
    //   route: ['/associate-control-view', '/register-user-form']
    // },
    // {
    //   key: 5,
    //   icon: faLayerGroup,
    //   title: 'Categoria',
    //   route: ['/category-view', '/category-form']
    // },
    {
      key: 6,
      icon: faFlag,
      title: 'Banner',
      route: ['/banner-view', '/banner-form']
    },
    {
      key: 7,
      icon: faArrowAltCircleUp,
      title: 'Pop up',
      route: ['/popup-view', '/popup-form']
    },
    {
      key: 8,
      icon: faUpload,
      title: 'Importações',
      route: ['/imports-view', '/register-transaction-form', '/register-user-form', '/register-productor-form', '/register-payment-form']
    },
    // {
    //   key: 8,
    //   icon: faArrowUp,
    //   title: 'Transações',
    //   route: ['/register-transaction-form']
    // },
    {
      key: 9,
      icon: faMoneyBillWave,
      title: 'Comissões',
      route: ['/commission-view', '/commission-form'],
    },
    {
      key: 10,
      icon: faComment,
      title: 'Mensagens',
      route: ['/messages', 'messages-form']
    },
    {
      key: 11,
      icon: faNewspaper,
      title: 'Novidades',
      route: ['/news-view', '/news-form']
    },
    {
      key: 12,
      icon: faClipboardCheck,
      title: 'Sugestões',
      route: ['/suggest-partner-view', '/suggest-partner-form-admin']
    },
    {
      key: 13,
      icon: faChartPie,
      title: "Relatórios",
      route: ["/report-admin-view", "/admin-grouped-by-partner", "/grouped-by-contractor-partner-productor", "/report-admin-new-user", "/report-admin-grouped-by-benefit"]
    },
  ]

  return <Menu itens={itens} />
}

export default AdminMenu
