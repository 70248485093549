import { DataTable } from "primereact/datatable";
import styled from "styled-components";

interface ItemsList {
  itemsList: any[];
}

export const DataTableElement = styled(DataTable)<ItemsList>`
  width: 100%;
  max-width: 100%;

  .p-datatable-thead > tr > th {
    background-color: #195c8c;
    color: white;
  }

  .p-datatable-thead > tr > th:first-child {
    border-radius: 15px 0px 0px 15px;
  }

  .p-datatable-thead > tr > th:last-child {
    border-radius: 0px 15px 15px 0px;
  }

  .p-datatable-tbody > tr > td {
    padding: 10px !important;
    word-break: break-all;
  }
`;
