import styled from "styled-components";
import waveBackground from "../../../assets/contractor/login/wave-background.svg";
import waveBackgroundPartner from "../../../assets/partner/wave-background-partner.svg"

export const LoginElement = styled.div`
  width: 99%;
  height: 100vh;
  display: flex;
  @media (max-width: 649px){
    display: none;
  }
`;

export const LeftSideElement = styled.div`
  width: 70%;
  height: 100vh;
  background-size: cover;
  background-image: url(${window.location.pathname === '/parceiro' ? waveBackgroundPartner : waveBackground});
  padding-top: 5em;
`;

export const RightSideElement = styled.div`
  width: 30%;
  min-width: 22em;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightPanelElement = styled.div`
  height: 80%;
  font-weight: bold;
  padding-top: 50px;
`;

export const LoginTituloElement = styled.h3`
  color: #6285ae;
  font-size: 38px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormElement = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-right: 35px;
  padding-left: 35px;
  color: #42425d;
  font-size: 16px;
`;

export const CheckboxElement = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
`;

export const ButtomElement = styled.button`
  background-color: #6285ae;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 17px;
  height: 50px;
  border-radius: 16px;
  border: none;
  margin-top: 20px;
  color: white;

  &:disabled {
    opacity: 30%;
    cursor: not-allowed;
  }
`;

export const ResetPassword = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResetPasswordButtonElement = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  border: none;
  margin-top: 20px;
  color: #42425d;
`;
