import styled from "styled-components";

export const FormElement = styled.div`
  margin: auto;
  display: flex;
  /* flex-direction: column; */
  color: #42425d;
  font-size: 16px;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`
