import { useNavigate } from "react-router";
import PageHeader from "../../../../../components/atoms/page-header";
import { ItemMenuReportElement } from "./style";
import {Accordion, AccordionTab} from "primereact/accordion";
import React from "react";

const ReportAdminView: React.FC = () => {
    const navigate = useNavigate();
    return <>
        <PageHeader text="Relatórios"/>

        <Accordion >
            <AccordionTab header="Módulo B2C">
                <ItemMenuReportElement onClick={() => {navigate("/grouped-by-contractor-partner-productor") }}>
                    Número de vendas e das receitas dos produtos mais consumidos agrupados por contratantes
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/admin-grouped-by-partner") }}>
                    Número de vendas e valor das receitas agrupadas por parceiros
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/report-admin-new-user") }}>
                    Quantidade total de clientes e quantidade de novos clientes agrupado por parceiros
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/report-admin-grouped-by-benefit") }}>
                    Visão com transações agrupados por beneficios
                </ItemMenuReportElement>
            </AccordionTab>
            <AccordionTab header="Módulo B2B">
                <ItemMenuReportElement onClick={() => {navigate("/report-admin-grouped-by-partner") }}>
                    Visualizações agrupadas por parceiros
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/report-admin-grouped-by-banner") }}>
                    Resultado dos Banners
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/report-admin-grouped-by-popup") }}>
                    Resultado dos popups
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/report-admin-grouped-by-news") }}>
                    Resultado das novidades
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate("/report-admin-grouped-by-informative") }}>
                    Resultado dos informativos
                </ItemMenuReportElement>
            </AccordionTab>

        </Accordion>


    </>
}

export default ReportAdminView