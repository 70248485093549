import { Form, Formik } from "formik"
import { Dropdown } from "primereact/dropdown"
import { InputMask } from "primereact/inputmask"
import { InputNumber } from "primereact/inputnumber"
import { InputTextarea } from "primereact/inputtextarea"
import { RadioButton } from "primereact/radiobutton"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../../context/app"
import { ModuleCompanyTypeEnum } from "../../../../../../../dto/moduleEnum/company-type-enum"
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum"
import { Partner } from "../../../../../../../dto/partner"
import { validateCnpj } from "../../../../../../../utils/validation"
import BackButtom from "../../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form"
import DivInput from "../../../../../../components/atoms/div-input"
import DivNumberElement from "../../../../../../components/atoms/input-number"
import InputTextVm from "../../../../../../components/atoms/input-text"
import PageHeader from "../../../../../../components/atoms/page-header"
import RadioButtonElement from "../../../../../../components/atoms/radio-button-phone"
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle"
import ImageUpdate from "../../../../../../components/molecules/image-update"
import { LineFormElement } from "../../../../../../components/molecules/item-form/style"
import FormAddress, { buildValidationAddress } from "../../../../../../components/organisms/form-address"
import { Error } from "../../../../../../components/organisms/form-address/style"
import FormTemplateElement from "../../../../../../templates/form-template"

const PartnerForm: React.FC = () => {
    const [partner, setPartner] = useState({ webSite: 'https://' } as Partner)
    const [disabled, setDisabled] = useState(true)
    const moduleList = [{ name: ModuleEnum.B2C }, { name: ModuleEnum.B2B }]
    const [moduleSelected, setModuleSelected] = useState(null) as any
    const [companyType, setCompanyType] = useState(null) as any;
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any
    const optionsCompanyType = [
        {
            type: 'MEI',
            typeValue: ModuleCompanyTypeEnum.MEI
        },
        {
            type: 'LIMITADA',
            typeValue: ModuleCompanyTypeEnum.LIMITED
        },
        {
            type: 'INDIVIDUAL',
            typeValue: ModuleCompanyTypeEnum.INDIVIDUAL
        },
        {
            type: 'ASSOCIAÇÃO',
            typeValue: ModuleCompanyTypeEnum.ASSOCIATION
        },
    ];

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOnePartner()
        }
    }, [])

    // Validações do yup
    const validations = buildValidationAddress({
        moduleName: Yup.object()
            .nullable()
            .when((userModule, schema): any => {
                if (moduleSelected?.name != ModuleEnum.B2C && moduleSelected?.name != ModuleEnum.B2B) {
                    return schema.required();
                }
            }),
        email: Yup.string().required("Favor digitar o e-mail!").email(),
        name: Yup.string().required("Campo obrigatório"),
        corporateName: Yup.string().required("Campo obrigatório").nullable(),
        cnpj: Yup.string()
            .required("Campo obrigatório")
            .test("isCnpjValid", "cnpj inválido", (cnpj) => {
                try {
                    return validateCnpj(cnpj)
                } catch (error) {
                    return false
                }
            }),
        about: Yup.string().required("Campo obrigatório"),
        cellPhone: Yup.string().required("Campo obrigatório")
    })

    function handleChangeSetModule(e: any) {
        if (e?.value?.name) {
            setDisabled(false)
            setModuleSelected(e.value)
        }
    }

    return (
        <>
            <PageHeader text="Novo parceiro" />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate("/partner-view")
                    }}
                />

                <SpanSubtitle text="Dados - Parceiro" />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={partner}
                        validationSchema={validations}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <DivInput style={{ width: '100%' }}>
                                    MÓDULO
                                    <Dropdown
                                        style={{ marginTop: '10px' }}
                                        value={moduleSelected}
                                        onChange={(e) => handleChangeSetModule(e)}
                                        options={moduleList}
                                        optionLabel="name"
                                        placeholder="Selecione o módulo desejado."
                                        disabled={(isNew) ? false : true}
                                    />
                                </DivInput>

                                <LineFormElement style={{ marginBottom: "0" }}>
                                    <DivInput style={{ width: "32%" }}>
                                        Nome
                                        <InputTextVm
                                            style={{
                                                borderRadius: "10px",
                                                width: "97%",
                                                marginTop: "10px",
                                            }}
                                            name="name"
                                            value={propsForm.values.name}
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                        />
                                        <Error>
                                            {propsForm.errors.name ? (propsForm.errors.name) : (<>&nbsp;</>)}
                                        </Error>
                                    </DivInput>

                                    <DivInput style={{ width: "32%" }}>
                                        Cnpj
                                        <InputMask
                                            name="cnpj"
                                            disabled={disabled}
                                            style={{
                                                width: "94%",
                                                borderRadius: "10px",
                                                marginTop: "10px",
                                            }}
                                            mask="99.999.999/9999-99"
                                            value={propsForm.values.cnpj}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error>
                                            {propsForm.errors.cnpj ? (propsForm.errors.cnpj) : (<>&nbsp;</>)}
                                        </Error>
                                    </DivInput>
                                    <DivNumberElement style={{ width: "15%" }}>
                                        Fixo
                                        <InputMask
                                            id="phone"
                                            disabled={disabled}
                                            style={{
                                                height: "51px",
                                                width: "94%",
                                                borderRadius: "10px",
                                                marginTop: "7px",
                                            }}
                                            mask={'(99) 9999-9999'}
                                            value={propsForm.values.phone}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error>
                                            {propsForm.errors.phone ? (propsForm.errors.phone) : (<>&nbsp;</>)}
                                        </Error>
                                    </DivNumberElement>
                                    <DivNumberElement style={{ width: "15%" }}>
                                        Celular
                                        <InputMask
                                            id="cellPhone"
                                            disabled={disabled}
                                            style={{
                                                height: "51px",
                                                width: "94%",
                                                borderRadius: "10px",
                                                marginTop: "7px",
                                            }}
                                            mask={'(99) 99999-9999'}
                                            value={propsForm.values.cellPhone}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error>
                                            {propsForm.errors.cellPhone ? (propsForm.errors.cellPhone) : (<>&nbsp;</>)}
                                        </Error>
                                    </DivNumberElement>
                                </LineFormElement>
                                <LineFormElement style={{ marginBottom: '0px' }}>
                                    <DivInput style={{ width: '50%' }}>
                                        Razão Social
                                        <InputTextVm
                                            style={{
                                                borderRadius: '10px',
                                                width: '97%',
                                                marginTop: '10px',
                                            }}
                                            value={propsForm.values.corporateName}
                                            name="corporateName"
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                        />
                                        <Error>{propsForm.errors.corporateName ? propsForm.errors.corporateName : <>&nbsp;</>}</Error>
                                    </DivInput>
                                    <DivInput style={{ width: '15%', marginTop: '2px', marginRight: '20px' }}>
                                        Tipo da empresa
                                        <Dropdown
                                            name="companyType"
                                            disabled={disabled}
                                            style={{ marginTop: '10px' }}
                                            value={companyType}
                                            onChange={(e) => setCompanyType(e.value)}
                                            options={optionsCompanyType}
                                            optionLabel="type"
                                            placeholder="Selecione o tipo da empresa"
                                        />
                                    </DivInput>
                                    <DivInput style={{ width: '50%' }}>
                                        Email
                                        <InputTextVm
                                            name='email'
                                            style={{ height: '54px', borderRadius: '10px', marginTop: '9px' }}
                                            value={propsForm.values.email}
                                            onChange={propsForm.handleChange}
                                            disabled={(isNew && !disabled) ? false : true}
                                        />
                                        <Error>{propsForm.errors.email ? propsForm.errors.email : <>&nbsp;</>}</Error>
                                    </DivInput>
                                </LineFormElement>
                                <LineFormElement style={{ marginBottom: '0px' }}>
                                    <DivInput style={{ width: '50%' }}>
                                        Inscrição municipal
                                        <InputNumber
                                            style={{ borderRadius: '10px', width: '97%', marginTop: '10px' }}
                                            name="countyRegistry"
                                            value={propsForm.values.countyRegistry}
                                            onValueChange={propsForm.handleChange}
                                            useGrouping={false}
                                            disabled={disabled}
                                        />
                                        <Error>{propsForm.errors.countyRegistry ? propsForm.errors.countyRegistry : <>&nbsp;</>}</Error>
                                    </DivInput>
                                    <DivInput style={{ width: '50%' }}>
                                        Inscrição estadual
                                        <InputNumber
                                            style={{ height: '54px', borderRadius: '10px', marginTop: '9px' }}
                                            name="stateRegistry"
                                            value={propsForm.values.stateRegistry}
                                            onValueChange={propsForm.handleChange}
                                            disabled={disabled}
                                            useGrouping={false}
                                        />
                                        <Error>{propsForm.errors.stateRegistry ? propsForm.errors.stateRegistry : <>&nbsp;</>}</Error>
                                    </DivInput>
                                </LineFormElement>
                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <DivInput style={{ width: '100%', marginTop: '9px' }}>
                                        Site
                                        <InputTextVm
                                            style={{ borderRadius: '10px', width: '98.5%' }}
                                            name='webSite'
                                            value={propsForm.values.webSite}
                                            onChange={(e) => { if (!e.target.value.startsWith('https://')) { e.target.value = 'https://'; } propsForm.handleChange(e); }}
                                            disabled={disabled}
                                        />
                                        <Error>{propsForm.errors.webSite ? propsForm.errors.webSite : <>&nbsp;</>}</Error>
                                    </DivInput>
                                </LineFormElement>
                                Sobre
                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <InputTextarea
                                        style={{ marginTop: "5px", width: "98.5%" }}
                                        disabled={disabled}
                                        value={propsForm.values.about}
                                        name="about"
                                        autoResize
                                        onChange={propsForm.handleChange}
                                    />
                                </LineFormElement>
                                <Error>
                                    {propsForm.errors.about ? (propsForm.errors.about) : (<>&nbsp;</>)}
                                </Error>
                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <LineFormElement>
                                        <ImageUpdate
                                            disabled={disabled}
                                            name="file"
                                            nameUrl="imageUrl"
                                            label="Logomarca"
                                            propsForm={propsForm}
                                            widthImage={1}
                                            heightImage={1}
                                            sizeRef={8} />
                                    </LineFormElement>
                                </LineFormElement>

                                <SpanSubtitle text="Endereço" />

                                <FormAddress
                                    disabled={disabled}
                                    propsForm={propsForm}
                                    appContext={appContext}
                                />

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        type="submit"
                                        disabled={isNew ?
                                            !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                            :
                                            propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                        }
                                    // disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}
                                    >
                                        Salvar dados
                                    </ButtomRequestElement>
                                </div>

                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    )

    // Funções do sistema
    async function getOnePartner() {
        const response: any = await apiProvider.get(`/admin/partner/${state.item.id}`)

        optionsCompanyType.forEach(option => {
            if (response.data.companyType == option.typeValue) {
                setCompanyType(option);
            }
        });

        response.data.validateImg = ''
        response.data.file = response.data.imageUrl
        setPartner(response.data)

        setModuleSelected({ name: response?.data?.moduleName })
    }

    // Salvar partner
    async function save(values: any) {

        if (companyType == null) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Dados incompletos',
                detail: 'Favor selecionar o tipo da empresa!',
                life: 3000,
            });

            return;
        }

        const data = {
            moduleName: moduleSelected.name,
            id: values.id ? values.id : null,
            name: values.name.toUpperCase(),
            corporateName: values.corporateName.toUpperCase(),
            about: values.about,
            phone: values.phone,
            cellPhone: values.cellPhone,
            address: values.address,
            cnpj: values.cnpj,
            webSite: values.webSite,
            email: values.email,
            countyRegistry: values.countyRegistry,
            stateRegistry: values.stateRegistry,
            companyType: companyType?.typeValue
        }

        const formData = new FormData()
        formData.append("file", values.file)
        formData.append("partner", JSON.stringify(data))

        appContext.setIsShowLoading(true)
        try {
            if (!isNew) {
                await apiProvider.put("/admin/partner", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Parceiro',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate("/partner-view")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Parceiro',
                            detail: error.response.data.message || "Erro ao salvar parceiro, tente novamente!",
                            life: 3000,
                        });
                    });
            } else {
                await apiProvider.post("/admin/partner", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Parceiro',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate("/partner-view")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Parceiro',
                            detail: error.response.data.message || "Erro ao salvar parceiro, tente novamente!",
                            life: 3000,
                        });
                    });
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Parceiro",
                detail: error.response.data.message || "Erro ao salvar parceiro, tente novamente!",
                life: 3000,
            })
        }

        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!partner.id
    }
}

export default PartnerForm
