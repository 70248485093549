import { Form, Formik } from "formik"
import { InputMask } from "primereact/inputmask"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../context/app"
import { Store } from "../../../../../../dto/store"
import BackButtom from "../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../components/atoms/buttom-request-form"
import DivInput from "../../../../../components/atoms/div-input"
import Error from "../../../../../components/atoms/error-message"
import DivNumberElement from "../../../../../components/atoms/input-number"
import InputTextVm from "../../../../../components/atoms/input-text"
import PageHeader from "../../../../../components/atoms/page-header"
import SpanSubtitle from "../../../../../components/atoms/span-subtitle"
import { LineFormElement } from "../../../../../components/molecules/item-form/style"
import FormAddress, { buildValidationAddress } from "../../../../../components/organisms/form-address"
import FormTemplateElement from "../../../../../templates/form-template"
import { DivIn } from "../../../admin/forms/comission/form/style"

const StoreForm: React.FC = () => {
    const [store, setStore] = useState({} as Store)
    const [disabled, setDisabled] = useState(false)
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any

    const getOneStore = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/store/${state.item.id}`)
        setStore(response.data)
    }, [])

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOneStore()
        }
    }, [])

    // Validações do yup
    const validations = buildValidationAddress({
        description: Yup.string().required("Campo obrigatório"),
        phone: Yup.string().required("Campo obrigatório"),
        externalCode: Yup.string().required("Campo obrigatório")
    })

    return (
        <>
            <PageHeader text="Nova Loja" />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate("/store-view")
                    }}
                />

                <SpanSubtitle text="Dados - Loja" />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={store}
                        validationSchema={validations}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <LineFormElement>
                                    <DivInput>
                                        Descrição
                                        <InputTextVm
                                            style={{
                                                borderRadius: "10px",
                                                width: "95%",
                                                marginTop: "10px",
                                            }}
                                            name="description"
                                            value={propsForm.values.description}
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                        />
                                        <Error name="description" errors={propsForm.errors} />
                                    </DivInput>

                                    <DivIn>
                                        Código externo
                                        <InputTextVm
                                            style={{ borderRadius: '10px', width: '8em', marginTop: '10px' }}
                                            name="externalCode"
                                            value={propsForm.values.externalCode}
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                        />
                                        <Error name="externalCode" errors={propsForm.errors} />
                                    </DivIn>

                                    <DivNumberElement>
                                        Fixo
                                        <InputMask
                                            id='phone'
                                            style={{
                                                height: '51px',
                                                width: '100%',
                                                borderRadius: '10px',
                                                marginTop: '7px',
                                            }}
                                            mask={'(99) 9999-9999'}
                                            value={propsForm.values.phone}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name="phone" errors={propsForm.errors} />
                                    </DivNumberElement>
                                    <DivNumberElement>
                                        Celular
                                        <InputMask
                                            id='cellPhone'
                                            style={{
                                                height: '51px',
                                                width: '100%',
                                                borderRadius: '10px',
                                                marginTop: '7px',
                                            }}
                                            mask={'(99) 9999-9999'}
                                            value={propsForm.values.cellPhone}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name="cellPhone" errors={propsForm.errors} />
                                    </DivNumberElement>
                                </LineFormElement>

                                <SpanSubtitle text="Endereço" />

                                <FormAddress
                                    disabled={disabled}
                                    propsForm={propsForm}
                                    appContext={appContext}
                                />

                                <div style={{ textAlign: 'end' }}>
                                    {!disabled && (
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}
                                        >
                                            Salvar dados
                                        </ButtomRequestElement>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    )

    // Funções do sistema

    // Salvar partner
    async function save(values: any) {

        const data = {
            id: values.id ? values.id : "",
            address: {
                complement: values.address.complement,
                district: values.address.district,
                state: values.address.state,
                street: values.address.street,
                zipCode: values.address.zipCode,
                city: values.address.city,
                number: values.address.number,
            },
            description: values.description,
            externalCode: values.externalCode,
            isActive: true,
            phone: values.phone,
            cellPhone: values.cellPhone
        }

        appContext.setIsShowLoading(true)
        try {
            if (!isNew) {
                await apiProvider.put("/web/store/", data)
            } else {
                await apiProvider.post("/web/store", data)
            }
            navigate("/store-view")
            appContext.toast.show({
                severity: "success",
                summary: "Loja",
                detail: "Salvo com sucesso!",
                life: 3000,
            })
        } catch (error: any) {
            let mensagem = 'Erro ao salvar loja, tente novamente!'
            if (error.response.status === 400 && error.response.data.message) {
                mensagem = error.response.data.message
            }

            appContext.toast.show({
                severity: "error",
                summary: "Loja",
                detail: mensagem,
                life: 3000,
            })
        }
        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!store.id
    }
}

export default StoreForm
