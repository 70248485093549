import { Form, Formik } from "formik"
import { FileUpload } from "primereact/fileupload"
import { useEffect, useRef, useState } from "react"
import { FaFileDownload } from "react-icons/fa"
import { useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../../context/app"
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum"
import BackButtom from "../../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form"
import PageHeader from "../../../../../../components/atoms/page-header"
import SelectDropDownVm from "../../../../../../components/atoms/select-drop-down"
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle"
import { Error } from "../../../../../../components/organisms/form-address/style"
import FormTemplateElement from "../../../../../../templates/form-template"
import { ButtomDownalod, SpanHelp } from "./style"

const RegisterTransactionForm: React.FC = () => {
    const navigate = useNavigate()
    const [partnerList, setPartnerList] = useState([])
    const fileUpload = useRef(null as any);
    const appContext = useAppContext()
    const { apiProvider } = appContext

    useEffect(() => {
        getPartners()
    }, [])

    async function getPartners() {
        const response: any = await apiProvider.get(`/admin/partner?moduleName=` + ModuleEnum.B2C)
        setPartnerList(response.data)
    }

    // Validações do yup
    const validations = Yup.object().shape({
        partner: Yup.object().required(),
        imageUrl: Yup.string().required(),
    })

    const downalodTemplate = async (partner: any) => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.download(`/admin/bulk-transaction-registration/download/template/${partner.id}`)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Importação em massa de transações (' + partner.name + ').xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    // Salvar Cadastros
    const save = async (values: any, obj: any) => {
        appContext.setIsShowLoading(true)
        const formData = new FormData()
        formData.append("partnerId", values.partner.id)
        formData.append("file", values.file)
        try {
            await apiProvider.post("/admin/bulk-transaction-registration", formData)
            appContext.toast.show({ severity: 'success', summary: 'Parceiro', detail: 'Salvo com sucesso!', life: 3000 })

            obj.resetForm({})
            fileUpload.current.clear();
        } catch (error: any) {
            let title = 'Importação de transações.'
            let mensagem = 'Erro ao importar novas transações, entre em contato com o administrador!'

            if (error.response.status === 400 && error.response.data.message) {
                mensagem = error.response.data.message
            }

            appContext.toast.show({ severity: "error", summary: title, detail: mensagem, life: 5000 })
            console.error(error)

        }
        appContext.setIsShowLoading(false)
    }

    return (
        <>
            <PageHeader text="Importação de transações através de planílha." subtext="Agrupada por benefício" />

            <FormTemplateElement>

                <Formik
                    initialValues={{} as any}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <>
                            <div style={{ width: "100%", display: "flex" }}>
                                <BackButtom
                                    onclick={() => {
                                        navigate("/imports-view")
                                    }}
                                />

                                <ButtomDownalod
                                    onClick={() => downalodTemplate(propsForm.values.partner)}
                                    disabled={!propsForm.values.partner}
                                >
                                    <FaFileDownload style={{ marginRight: "0.5em" }} />
                                    <span> Download exemplo</span>
                                </ButtomDownalod>
                            </div>

                            <SpanSubtitle text="Como fazer a importação de transações?" />
                            <SpanHelp>
                                Selecione o parceiro, baixe a planilha de exemplo clicando em "Download exemplo", preencha as linhas
                                com as novas transações, envie a planilha de volta para nosso sistema, e clique no botão "Importar
                                transações".
                            </SpanHelp>

                            <Form>
                                <div>
                                    Parceiro
                                    <SelectDropDownVm
                                        style={{ width: "100%" }}
                                        name="partner"
                                        onChange={propsForm.handleChange}
                                        value={propsForm.values.partner}
                                        options={partnerList}
                                        optionLabel="name"
                                        filter
                                    />
                                    <Error>{propsForm.errors.partner ? (propsForm.errors.partner) : (<>&nbsp;</>)}</Error>
                                </div>

                                <div>
                                    Planilha para importação de transações em massa.
                                    <FileUpload
                                        ref={fileUpload}
                                        style={{ marginTop: "5px" }}
                                        name="invoice"
                                        customUpload
                                        uploadHandler={(event: any) => {
                                            propsForm.setFieldValue("file", event.files[0])
                                            propsForm.setFieldValue('imageUrl', event.files[0])
                                        }}
                                        onRemove={(event) => {
                                            propsForm.setFieldValue("file", undefined)
                                            propsForm.setFieldValue('imageUrl', undefined)
                                        }}
                                        auto
                                        chooseLabel="Anexar arquivo"
                                    />
                                    <Error>
                                        {!propsForm.values.imageUrl ? 'Campo obrigatório' : (<>&nbsp;</>)}
                                    </Error>
                                </div>

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        style={{ marginTop: "2em" }}
                                        type="submit"
                                        disabled={propsForm.isSubmitting || !propsForm.values.partner || !propsForm.values.file}>
                                        Importar transações
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </FormTemplateElement>
        </>
    )
}

export default RegisterTransactionForm
