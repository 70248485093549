import {
  faChartPie, faHandsHelping, faNewspaper,
  faUserCheck,
  faUsers
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import Menu from '../../../../templates/menu'


interface MenuContractorProps {}

const MenuContractorB2C: React.FC<MenuContractorProps> = () => {
  const itens: any = [
    {
      key: 1,
      icon: faChartPie,
      title: 'Dashboard',
      route: ['/dashboard-contractor', '/']
    },
    {
      key: 2,
      icon: faUsers,
      title: 'Usuários',
      route: ['/associate-view', '/associate-form'],
    },
    {
      key: 3,
      icon: faNewspaper,
      title: 'Informativos',
      route: ['/informative-view', '/informative-form']
    },
    {
      key: 4,
      icon: faUserCheck,
      title: 'Gestores',
      route: ['/userContractor-view', '/userContractor-form']
    },
    // {
    //   key: 5,
    //   icon: faExchangeAlt,
    //   title: 'Transações',
    //   route: ['/contractor-transaction-view', '/contractor-transaction-form']
    // },
    {
      key: 6,
      icon: faHandsHelping,
      title: 'Parceiros',
      route: ['/contractor-partner-view', '/partner-contractor-form'],
    }, 
    {
      key: 7,
      icon: faChartPie,
      title: "Relatórios",
      route: ["/report-contractor-view", "/grouped-by-category", "/grouped-by-partner"]
    },
  ]

  return <Menu itens={itens} />
}
export default MenuContractorB2C
