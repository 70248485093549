import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router"
import asaas from "../../../../../assets/asaas.svg"
import bannerDashboardContractor from "../../../../../assets/contractor/dashboard/banner-dashboard-contractor-B2B.png"
import { useAppContext } from "../../../../../context/app"
import { Partner } from "../../../../../dto/partner"
import { Session } from "../../../../../dto/session"
import PageHeader from "../../../../components/atoms/page-header"
import ReportPartnerGroupedByPartner from "../report/report-partner-grouped-by-contractor-B2B"
import {
    BannerButton,
    BannerElement,
    BannerTitle,
    ChartBarDiv,
    ChartBarElement,
    Circle,
    CircleText,
    DashboardDivBotton,
    DashboardSize,
    DivBannerText,
    DivImpressions,
    HeaderItemElement,
    LabelChart,
    LabelImpressions,
    NoResultsDiv,
    PanelDashboardValue,
    Partners,
    StraightDiv,
    StraightElement,
    TextChartDiv,
    TextGraph,
    UpperDiv,
    ValueChart,
    ValueElement,
    ValueImpressions
} from "./style"

const timeFilter = [
    { label: 'Diária', id: 'DAY' },
    { label: 'Semanal', id: 'WEEK' },
    { label: 'Mensal', id: 'MONTH' },
]

const DashboardContractor: React.FC = () => {
    const [filterTime, setFilterTime] = useState(timeFilter[2]);
    const [partners, setPartners] = useState([]);
    const [categories, setCategories] = useState([] as any[]);
    const navigate = useNavigate();
    const { apiProvider } = useAppContext();
    const appContext = useAppContext()
    const session: Session = appContext.sessionProvider.getSession();
    const urlAsaas = (process.env.REACT_APP_ENVIRONMENT == 'local') ? 'https://sandbox.asaas.com/login/auth' : 'https://www.asaas.com/login/auth';

    const getPartners = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/dashboard/contractor/partners?period=${filterTime.id}`)
        setPartners(response.data)
    }, [])

    const getCategories = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/dashboard/contractor/categories?period=${filterTime.id}`)
        setCategories(response.data)
    }, [])

    // Renderização de componentes
    const renderCategoryList = useMemo(() => {
        if (categories.length === 0) {
            return <NoResultsDiv>Sem resultados</NoResultsDiv>
        }

        const maior: any = categories[0].impressions

        return categories.map((category: any, index: any) => {
            const widt = ((category.impressions * 100) / maior).toFixed(2)
            return (
                <ChartBarDiv key={index}>
                    <ChartBarElement>
                        <TextChartDiv>{category.categoryName}</TextChartDiv>
                        <StraightDiv>
                            <StraightElement style={{ width: `${widt}%` }} />
                            <ValueElement>{category.impressions}</ValueElement>
                        </StraightDiv>
                    </ChartBarElement>
                </ChartBarDiv>
            )
        })

    }, [categories])

    const renderPartnerList = useMemo(() => {
        if (partners.length === 0) {
            return <NoResultsDiv>Sem resultados</NoResultsDiv>
        }

        return partners.map((partner: Partner, index) => {
            return (
                <Partners key={index}>
                    <CircleText>
                        <Circle>
                            <img style={{ width: "100%", borderRadius: '100%' }} alt="" src={partner.imageUrl} />
                        </Circle>
                        <TextGraph>{partner.name}</TextGraph>
                    </CircleText>

                    <DivImpressions>
                        <LabelImpressions>Impressões</LabelImpressions>
                        <ValueImpressions>{partner.impressions}</ValueImpressions>
                    </DivImpressions>

                </Partners>
            )
        })
    }, [partners])

    useEffect(() => {
        getPartners()
        getCategories()
    }, [filterTime])

    return (
        <DashboardSize>

            <UpperDiv>
                <PageHeader text="Dashboard" />
                <HeaderItemElement>
                    Informações deste mês.
                </HeaderItemElement>

            </UpperDiv>

            <BannerElement style={{ marginBottom: '2rem' }}>
                <DivBannerText>
                    Com os destaques você pode mais!
                    <br />
                    <BannerTitle>
                        Divulgue
                        <span style={{ color: "#01FF0E" }}> informativos na <br />tela inicial </span>
                        do APP
                    </BannerTitle>
                </DivBannerText>

                <img style={{ height: "calc(100% + 3.6em)", marginTop: "-1.8em" }} alt="" src={bannerDashboardContractor} />

                <DivBannerText style={{ width: "18em" }}>
                    Seus usuários estão ansiosos para <br />
                    saber mais sobre as suas novidades. <br />
                    <strong>Divulgue agora!</strong> <br />

                    <BannerButton onClick={() => { navigate("/want-to-disclose") }}>Quero Divulgar</BannerButton>
                </DivBannerText>
            </BannerElement>

            {(session.masterManager && session?.modules[0]?.partner?.walletId) &&
                <DashboardDivBotton>
                    <PanelDashboardValue style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ textAlign: "center" }}>
                            <LabelChart>Veja suas comissões:</LabelChart>
                            <ValueChart style={{ fontSize: "30px" }}>
                                <a href={urlAsaas} target="_blank" rel="noopener noreferrer">
                                    <img src={asaas} width="100px" alt="Asaas" />
                                </a>
                            </ValueChart>
                        </div>
                    </PanelDashboardValue>
                </DashboardDivBotton>
            }

            <ReportPartnerGroupedByPartner isDashboard={true} />

        </DashboardSize>
    )

}
export default DashboardContractor
