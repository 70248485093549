import { Auth } from "aws-amplify";
import { Password } from "primereact/password";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppContext } from "../../../context/app";
import RolePassword from "../../components/atoms/role-password";
import { ButtomElement, CancelButtonElement, FormElement, LoginElement as ResetPasswordElement, LoginTituloElement as ResetPasswordTituloElement } from "./style";

type StateProps = {
    backPage: string;
    email: string;
    oldPass: string;
};

const NewPassword: React.FC = () => {
    const context = useAppContext();
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState("");
    const [oldPass, setOldPass] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%])[A-Za-z\d!@#$%]{8,}$/;

    useEffect(() => {
        setOldPass(getStateInLocation().oldPass);
        setEmail(getStateInLocation().email);
    }, []);

    const save = () => {
        if (pass !== confirmPass) {
            context.toast.show({
                severity: "error",
                summary: "Atenção",
                detail: "As senhas não conferem, tente novamente!",
                life: 3000,
            });

            return;
        }

        if (!regex.test(pass)) {
            context.toast.show({
                severity: "error",
                summary: "Atenção",
                detail: "As senha não atende aos critérios!",
                life: 3000,
            });

            return;
        }

        Auth.signIn(email, oldPass)
            .then((user: any) => {
                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                    Auth.completeNewPassword(user, confirmPass)
                        .then((data: any) => {
                            context.toast.show({
                                severity: "success",
                                summary: "Concluído",
                                detail: "Senha salva com sucesso!",
                                life: 3000,
                            });

                            goBack();
                        })
                        .catch((err: any) => {
                            if (err?.message?.includes("Password does not conform to policy")) {
                                context.toast.show({
                                    severity: "error",
                                    summary: "Atenção",
                                    detail: "Verifique as regras de alteração de senha e tente novamente!",
                                    life: 3000,
                                });
                                return;
                            }

                            context.toast.show({
                                severity: "error",
                                summary: "Atenção",
                                detail: "Não foi possível alterar a senha, tente novamente!",
                                life: 3000,
                            });
                        });
                }
            })
            .catch((err: any) => {
                context.toast.show({
                    severity: "error",
                    summary: "Atenção",
                    detail: "Não foi possível alterar a senha, tente novamente!",
                    life: 3000,
                });
            });
    };

    const goBack = useCallback(() => {
        setOldPass("");
        setPass("");
        setConfirmPass("");

        const state = getStateInLocation();
        navigate(state?.backPage || "/");
    }, []);

    const getStateInLocation = useCallback(() => {
        return location.state as StateProps;
    }, [location]);

    return (
        <ResetPasswordElement>
            <ResetPasswordTituloElement>Cadastrar nova senha</ResetPasswordTituloElement>
            <FormElement style={{ maxWidth: 500 }}>
                Nova senha
                <Password
                    style={{ display: 'flex', borderRadius: '10px', marginBottom: "15px" }}
                    size={38}
                    value={pass}
                    onChange={(e) => setPass(e.target.value)}
                    feedback={false}
                    placeholder="Digite a nova senha"
                    toggleMask
                />

                Confirme a nova senha
                <Password
                    style={{ display: 'flex', borderRadius: '10px', marginBottom: "15px" }}
                    size={38}
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                    feedback={false}
                    placeholder="Confirme a nova senha"
                    toggleMask
                />

                <ButtomElement
                    disabled={!pass || !confirmPass || pass !== confirmPass || (!regex.test(pass) && !regex.test(confirmPass))}
                    onClick={save}>
                    Alterar
                </ButtomElement>

                <CancelButtonElement onClick={goBack}>Cancelar</CancelButtonElement>

                <RolePassword />
            </FormElement>
        </ResetPasswordElement>
    );
};

export default NewPassword;
