import { Form, Formik } from "formik"
import { Accordion, AccordionTab } from "primereact/accordion"
import { Dropdown } from "primereact/dropdown"
import { InputNumber } from "primereact/inputnumber"
import { InputSwitch } from "primereact/inputswitch"
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../../context/app"
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum"
import { ModuleEnumStatus } from "../../../../../../../dto/moduleEnum/module-enum-status"
import { Popup } from "../../../../../../../dto/popup"
import BackButtom from "../../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form"
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle"
import ImageUpdate from "../../../../../../components/molecules/image-update"
import ItemForm from "../../../../../../components/molecules/item-form"
import { LineFormElement } from "../../../../../../components/molecules/item-form/style"
import ActionFowarding, { buildValidationActionFowarding } from "../../../../../../components/organisms/action-fowarding"
import FormTemplateElement from "../../../../../../templates/form-template"
import { ButtomSaveElement, DivInput, FormElementItems } from "../../../../partner/benefit/form/style"

const PopupForm: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any
    const [disabled, setDisabled] = useState(true)
    const [popup, setPopup] = useState({ actionForwarding: { actionEnum: 0 } } as Popup)
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const [moduleSelected, setModuleSelected] = useState(null) as any;
    const [disabledIsUser, setDisabledIsUser] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [alterIsActive, setAlterIsActive] = useState(false);
    const moduleList = [{ name: ModuleEnum.B2C }, { name: ModuleEnum.B2B }];
    const [startDay, setStartDay] = useState<number>(32);
    const [endDay, setEndDay] = useState<number>(1);
    const [minEndDay, setMinEndDay] = useState<number | null>(null);
    const [maxEndDay, setMaxEndDay] = useState<number | null>(null);
    const [isModifyDates, setIsModifyDates] = useState(false);

    const mensagensDeErro = {
        min: 'O valor mínimo deve ser 1',
        max: 'O valor máximo deve ser 5'
    };

    const validations = buildValidationActionFowarding({
        title: Yup.string().required("Campo obrigatório"),
        priorityOrder: Yup.number().min(1, mensagensDeErro.min).max(5, mensagensDeErro.max).required("Campo obrigatório")
    })

    const getOnePopup = useCallback(async () => {
        const response: any = await apiProvider.get(`/admin/popup/${state.item.id}`)
        if (response.data.isActive == ModuleEnumStatus.INACTIVE) {
            setIsActive(false)
        }
        if (response.data.emailUser) {
            setDisabledIsUser(false)
        }
        setPopup(response.data)
        setModuleSelected({ name: response?.data?.moduleName })
        setStartDay(response.data.startDay ?? 1);
        setEndDay(response.data.endDay ?? 1);
    }, [])

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOnePopup()
        }
    }, []);

    function handleChangeSetModule(e: any) {
        if (e?.value?.name) {
            setDisabled(false);
            setModuleSelected(e.value);
        }
    }

    function handleChangeSetActive(e: any) {
        setIsActive(e);
        setAlterIsActive(true);
    }

    function handleChangeSetStartDay(newValue: any) {
        setIsModifyDates(true);

        setStartDay(newValue);
        setStartDay(newValue);
        setEndDay(newValue);
    }

    function handleChangeSetEndDay(newValue: any) {
        setIsModifyDates(true);

        if ((startDay + 6) > 31) {
            if (parseInt(newValue) > 31) {
                setEndDay(1);
                setMinEndDay(1);
            } else {
                setEndDay(newValue);
            }

            setMaxEndDay((startDay + 6) - 31);
        } else {
            setEndDay(newValue);
            setMinEndDay(newValue);
            setMaxEndDay(startDay + 6);
        }
    }

    function isLoadApi(): boolean {
        return !!popup.id
    }

    return (
        <FormTemplateElement>
            <BackButtom onclick={() => { navigate("/popup-view") }} />

            <SpanSubtitle text="Pop Up" />

            <Accordion multiple activeIndex={[1]}>
                <AccordionTab header="Dados do Solicitante" disabled={disabledIsUser}>
                    {(isLoadApi()) && (
                        <Formik
                            initialValues={popup}
                            validationSchema={validations}
                            onSubmit={save}
                        >
                            {(propsForm) => (
                                <Form>
                                    <LineFormElement style={{ justifyContent: "flex-start" }}>
                                        <ItemForm
                                            propsForm={propsForm}
                                            label="Enviado por:"
                                            disabled={true}
                                            name="sendByUser"
                                        />
                                        <ItemForm
                                            propsForm={propsForm}
                                            label="E-mail:"
                                            disabled={true}
                                            name="emailUser"
                                        />
                                        <ItemForm
                                            propsForm={propsForm}
                                            label="Vinculada á:"
                                            disabled={true}
                                            name="companyUser"
                                        />
                                    </LineFormElement>
                                </Form>
                            )}
                        </Formik>
                    )}
                </AccordionTab>

                <AccordionTab header="Dados do popup">
                    {(isLoadApi() || isNew) && (
                        <Formik
                            initialValues={popup}
                            validationSchema={validations}
                            onSubmit={save}
                        >
                            {(propsForm) => (
                                <Form>
                                    <DivInput style={{ width: '100%', height: '50px' }}>
                                        Módulo
                                        <Dropdown
                                            style={{ marginTop: '10px' }}
                                            value={moduleSelected}
                                            onChange={(e) => handleChangeSetModule(e)}
                                            options={moduleList}
                                            optionLabel="name"
                                            placeholder="Selecione o módulo desejado."
                                            disabled={(isNew) ? false : true}
                                        />
                                    </DivInput>
                                    <LineFormElement style={{ justifyContent: "space-between", marginTop: "60px" }}>
                                        <ItemForm
                                            propsForm={propsForm}
                                            widthPercent="50"
                                            label="Título"
                                            disabled={disabled}
                                            name="title"
                                        />
                                        <ItemForm
                                            propsForm={propsForm}
                                            widthPercent="50"
                                            label="Prioridade"
                                            disabled={disabled}
                                            name="priorityOrder"
                                            type="number"
                                            tip="Escala: 1 a 5, sendo 1 a prioridade mais alta"
                                        />
                                        <FormElementItems style={{ width: "10%", height: "100px", marginLeft: "5px", alignItems: 'center', justifyContent: 'center' }}>
                                            Ativo
                                            <InputSwitch
                                                checked={isActive}
                                                name='isActive'
                                                onChange={(e: any) => {
                                                    handleChangeSetActive(e.target.value)
                                                }}
                                                disabled={disabled}
                                            />
                                        </FormElementItems>
                                    </LineFormElement>

                                    <LineFormElement style={{ marginTop: 30 }}>
                                        <ImageUpdate
                                            disabled={disabled}
                                            name="file"
                                            nameUrl="imageUrl"
                                            label="Imagem para upload"
                                            propsForm={propsForm}
                                            widthImage={365}
                                            heightImage={177}
                                        />

                                        <LineFormElement style={{ justifyContent: "space-around", width: "35%", height: "20%", marginTop: "100px" }}>
                                            <div>
                                                <label htmlFor="startDay">Dia inicial:</label><br />
                                                <InputNumber
                                                    inputId="startDay"
                                                    value={startDay ?? 1}
                                                    onValueChange={(e: any) => handleChangeSetStartDay(e.value)}
                                                    mode="decimal"
                                                    showButtons
                                                    min={1}
                                                    max={31}
                                                />
                                            </div>
                                            <div>
                                                <label htmlFor="endDay">Dia final:</label><br />
                                                <InputNumber
                                                    inputId="endDay"
                                                    value={endDay ?? 1}
                                                    onValueChange={(e: any) => handleChangeSetEndDay(e.value)}
                                                    mode="decimal"
                                                    showButtons
                                                    min={minEndDay ?? 1}
                                                    max={maxEndDay ?? 32}
                                                />
                                            </div>
                                        </LineFormElement>

                                    </LineFormElement>

                                    <SpanSubtitle text="Ação ao clicar em um popup" />

                                    <LineFormElement>
                                        <ActionFowarding disabled={disabled} propsForm={propsForm} />
                                    </LineFormElement>

                                    <div style={{ textAlign: 'end' }}>
                                        {!disabled && (
                                            <ButtomSaveElement>
                                                <ButtomRequestElement
                                                    type="submit"
                                                    disabled={isNew ?
                                                        !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                                        :
                                                        (!isModifyDates || propsForm.isSubmitting || propsForm.values.validateImg != '') && !propsForm.dirty
                                                    }
                                                >
                                                    Salvar dados
                                                </ButtomRequestElement>
                                            </ButtomSaveElement>
                                        )}
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    )}
                </AccordionTab>
            </Accordion>
        </FormTemplateElement>
    )

    async function save(values: any) {
        try {
            let actionEnum: string = "";

            if (values.actionForwarding.actionEnum === 0) {
                actionEnum = "EXTERNAL_LINK"
            } else if (values.actionForwarding.actionEnum === 1) {
                actionEnum = "BENEFIT"
            } else if (values.actionForwarding.actionEnum === 2) {
                actionEnum = "CATEGORY"
            } else if (values.actionForwarding.actionEnum === 3) {
                actionEnum = "PARTNER"
            }

            const formatExternalLink = (link: string) => {
                if (link) {
                    if (link.startsWith("https://")) {
                        return link;
                    } else {
                        return `https://${link}`;
                    }
                }
                return "";
            }

            const data = {
                actionForwarding: {
                    actionEnum,
                    categoryId: values.actionForwarding.category ? values.actionForwarding.category.id : "",
                    externalLink: formatExternalLink(values.actionForwarding.externalLink),
                    id: values.actionForwarding.id ? values.actionForwarding.id : "",
                    partnerId: values.actionForwarding.partner ? values.actionForwarding.partner.id : "",
                },
                id: values.id ? values.id : "",
                priorityOrder: values.priorityOrder,
                isActive: (isActive) ? ModuleEnumStatus.ACTIVE : ModuleEnumStatus.INACTIVE,
                title: values.title,
                moduleName: moduleSelected.name,
                startDay: startDay,
                endDay: endDay
            }

            const formData = new FormData()
            formData.append("popup", JSON.stringify(data))
            formData.append("file", values.file)

            appContext.setIsShowLoading(true)

            if (isNew) {
                await apiProvider.post("/admin/popup", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Popup',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate("/popup-view")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Popup',
                            detail: error.response.data.message || "Erro ao salvar Popup!",
                            life: 3000,
                        });
                    });
            } else {
                await apiProvider.put("/admin/popup", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Popup',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            navigate("/popup-view")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Popup',
                            detail: error.response.data.message || "Erro ao salvar Popup!",
                            life: 3000,
                        });
                    });
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Popup",
                detail: error.response.data.message || "Erro ao salvar Popup!",
                life: 3000,
            })
        }

        appContext.setIsShowLoading(false)
    }
}

export default PopupForm
