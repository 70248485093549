import styled from "styled-components";


export const ContainerUpload = styled.div`
`;

export const SelectDiv = styled.div`
  margin-top: 1em;
  display: flex;
`;

export const InstructionsDiv = styled.div`
  width: 100%;
  margin-left: 1em;
`;

export const ImageDiv = styled.img`
`;


