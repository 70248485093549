import { FilterMatchMode } from 'primereact/api'
import { Column } from 'primereact/column'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import penIcon from "../../../../../../../assets/buttons/pen.svg"
import trashIcon from "../../../../../../../assets/buttons/trash.svg"
import { useAppContext } from '../../../../../../../context/app'
import { dateTimeToStrg } from '../../../../../../../utils/formatUtil'
import { confirm } from '../../../../../../../utils/validation'
import ButtomAddVm from '../../../../../../components/atoms/buttom-add-vm'
import DataTableElement from '../../../../../../components/atoms/data-table-view'
import PageHeader from '../../../../../../components/atoms/page-header'
import SearchTextLabel from '../../../../../../components/molecules/search-text-label'
import { HeaderElementLeft, HeaderElementRight } from '../../../../../../components/organisms/header/style'
import HeaderViewElement from '../../../../../../templates/view-header-template'
import TableViewElement from '../../../../../../templates/view-table-template'
import { ActionButtonsElement } from '../../../../partner/product/view/style'

const NewsView: React.FC = () => {
    const [filterText, setFilterText] = useState('')
    const navigate = useNavigate()
    const [state, setState] = useState({ newsList: [], isLoading: true })
    const { newsList } = state
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()

    useEffect(() => {
        getNews()
    }, [])

    const newNews = () => {
        navigate("/news-form")
    }

    const deleteNew = async (rowData: any) => {
        try {
            await apiProvider.delete(`/admin/news/${rowData.id}`)
            getNews()
            appContext.toast.show({
                severity: "success",
                summary: "Novidade",
                detail: "Excluida com sucesso!",
                life: 3000,
            })
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Novidade",
                detail: "Erro ao exlcuir novidade, tente novamente!",
                life: 3000,
            })
        }
    }

    const getNews = useCallback((
        async function () {
            const response: any = await apiProvider.get('/admin/news')
            setState({ newsList: response.data, isLoading: false })
        }
    ), [])

    const data = (rowData: any) => {
        return (<span>{dateTimeToStrg(rowData.date)}</span>)
    }

    const dataType = (rowData: any) => {
        let text = (rowData.type == 0) ? 'NOVIDADES' : 'INFORMES CULTURAIS';
        return (<span>{text}</span>)
    }

    return (
        <>
            <PageHeader text='Novidades' />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newNews} text='Nova novidade' />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    rows={10}
                    value={state.newsList}
                    responsiveLayout='scroll'
                    emptyMessage='Nenhum resultado encontrado'
                    itemsList={newsList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="moduleName" header="Tipo" body={dataType} sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="title" header="Título" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="date" body={data} header="Data/Lançamento" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: '10%' }} />
                </DataTableElement>
            </TableViewElement>
        </>
    )

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <ActionButtonsElement
                    onClick={() =>
                        confirm(rowData, 'Deseja excluir esta novidade ?', () =>
                            deleteNew(rowData)
                        )
                    }
                >
                    <img alt='' style={{ width: '17px' }} src={trashIcon} />
                </ActionButtonsElement>

                <ActionButtonsElement
                    onClick={() => {
                        navigate('/news-form', {
                            state: { item: rowData, disabled: false },
                        })
                    }}
                >
                    <img alt='' style={{ width: '17px' }} src={penIcon} />
                </ActionButtonsElement>
            </div>
        )
    }
}

export default NewsView
