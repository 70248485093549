import styled from "styled-components";

export const RadioButtonDivElement = styled.div`
  height: 55px;
  display: flex;
  margin-right: 20px;
`;

export const RadioButtonElement = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
`;

export const RadioButtonTwoElement = styled.div`
  display: flex;
  height: 55px;
  margin-right: 15px;
`;

export const DropBoxDivPartnerElement = styled.div`
  display: flex;
  height: 75px;
  flex-direction: column;
  margin-right: 20px;
`;

export const DropBoxDivContractorElement = styled.div`
  display: flex;
  flex-direction: column;
  height: 75px;
  margin-left: 15px;
`;
