import styled from "styled-components";

export const HeaderElementLeft = styled.div`
  width: 54em;
  align-items: center;
  padding-left: 15px;
  display: flex;
`;

export const ButtomsElement = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContractorButtomElement = styled.button`
  height: 45px;
  border: none;
  text-align: center;
  width: 210px;
  color: white;
  border-radius: 17px;
  background-color: #27ae60;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
`;

export const HeaderElementRight = styled.div`
  width: 60%;
  padding-right: 15px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export const TableElement = styled.div`
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  @media(max-width: 1320px){
    width: 97%;
  }
  @media(max-width: 825px){
    width: 90%;
  }
`;

export const ActionButtonsElement = styled.button`
  background-color: #f7f9fc;
  width: 3em;
  height: 3em;
  border-radius: 13px;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  align-items: center;
  border: none;
`;
