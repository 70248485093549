export function maskCPF(value: any) {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d)/, "$1.$2")
		.replace(/(\d{3})(\d{1,2})/, "$1-$2")
		.replace(/(-\d{2})\d+?$/, "$1");
}

export function maskCNPJ(value: any) {
	return value
		.replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
		.replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1')
}

export function maskPhone(value: any) {
	value = value.replace(/\D/g, "") // Remove tudo o que não é dígito

	value = value.replace(/^(\d\d)(\d)/, "($1) $2") // Coloca parênteses em volta dos dois primeiros dígitos

	if (value.length < 14) {
		value = value.replace(/(\d{4})(\d)/, "$1-$2") // Número com 8 dígitos. Formato: (99) 9999-9999
	} else {
		value = value.replace(/(\d{5})(\d)/, "$1-$2") // Número com 9 dígitos. Formato: (99) 99999-9999
	}
	return value
}

export function maskCEP(value: any) {
	return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
}
