import { Form, Formik } from "formik"
import { FileUpload } from "primereact/fileupload"
import { useCallback, useEffect, useRef, useState } from "react"
import { FaFileDownload } from "react-icons/fa"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../../context/app"
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form"
import Error from "../../../../../../components/atoms/error-message"
import PageHeader from "../../../../../../components/atoms/page-header"
import SelectDropDownVm from "../../../../../../components/atoms/select-drop-down"
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle"
import FormTemplateElement from "../../../../../../templates/form-template"
import { ButtomDownalod, SpanHelp } from "./style"
import { useNavigate } from "react-router";
import BackButtom from "../../../../../../components/atoms/back-buttom";
import { Contractor } from "../../../../../../../dto/contractor";
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { Dropdown } from "primereact/dropdown";

const RegisterProdutorsForm: React.FC = () => {
    const navigate = useNavigate()
    const [partnerList, setPartnerList] = useState([])
    const fileUpload = useRef(null as any);
    const appContext = useAppContext()
    const { apiProvider } = appContext

    const { state } = location as any
    const [selectedPartnersB2BOrB2C, setSelectedPartnersB2BOrB2C] = useState<Contractor>();
    const [groupedPartners, setGroupedPartners] = useState([] as any[]);

    useEffect(() => {
        getPartners()
    }, [])

    // Validações do yup
    const validations = Yup.object().shape({
        partner: Yup.object().when((actionEnum, schema): any => {
            if (setSelectedPartnersB2BOrB2C === null) {
                return schema.required();
            }
        }),
        imageUrl: Yup.string().required(),
    })

    const downalodTemplate = async () => {
        try {
            appContext.setIsShowLoading(true)
            const response: any = await apiProvider.download(`/admin/bulk-productor-registration/download/template`)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Importação em massa de produtos.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        } finally{
            appContext.setIsShowLoading(false)
        }
    }

    // Salvar Cadastros
    const save = async (values: any, obj: any) => {
        const formData = new FormData()
        const partnerId:any = selectedPartnersB2BOrB2C?.id;
        formData.append("partnerId", partnerId)
        formData.append("file", values.file)
        try {
            await apiProvider.post("/admin/bulk-productor-registration", formData)
            appContext.toast.show({
                severity: 'success',
                summary: 'Produtos',
                detail: 'Planilha importada com sucesso !',
                life: 3000
            })

            obj.resetForm({})
            fileUpload.current.clear();
        } catch (error: any) {
            let title = 'Importação de produtos.'
            let mensagem = 'Erro ao importar novas produtos, entre em contato com o administrador!'
            if (error.response.status === 400 && error.response.data.message) {
                mensagem = error.response.data.message
            }

            appContext.toast.show({ severity: "error", summary: title, detail: mensagem, life: 5000 })
            console.error(error)
        }
    }

    // Funções do sistema
    const getPartners = async () => {
        const response: any = await apiProvider.get(`/admin/partner`)
        setPartnerList(response.data)
    }

    // Importação com B2B e B2C com delimitação por módulo
    const getAllContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/partner')

        if (response?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            response.data.forEach((item: Contractor) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({ label: item.name, value: item });
                    if (state?.item?.contractorCnpj == item.cnpj) {
                        setSelectedPartnersB2BOrB2C(item);
                    }
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({ label: item.name, value: item });
                    if (state?.item?.contractorCnpj == item.cnpj) {
                        setSelectedPartnersB2BOrB2C(item);
                    }
                }
            });

            let objectB2cAndB2B = [];

            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2C', items: aB2C })
            }

            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2B', items: aB2B })
            }

            setGroupedPartners(objectB2cAndB2B)
            setPartnerList(response.data)
        }
    }, [])

    useEffect(() => {
        getAllContractors()
    }, [])

    return (
        <>
            <PageHeader text="Importação de produtos através de planílha." />

            <FormTemplateElement>

                <div style={{ width: "100%", display: "flex" }}>
                    <BackButtom
                        onclick={() => {
                            navigate("/imports-view")
                        }}
                    />

                    <ButtomDownalod onClick={() => downalodTemplate()} disabled={selectedPartnersB2BOrB2C == null}><FaFileDownload style={{ marginRight: "0.5em" }} /><span> Download exemplo</span></ButtomDownalod>
                </div>

                <Formik
                    initialValues={{} as any}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <>
                            <SpanSubtitle text="Como fazer a importação de produtos?" />
                            <SpanHelp>
                                Selecione o parceiro, baixe a planilha de exemplo clicando em "Download exemplo", preencha as linhas
                                com os novos produtos, envie a planilha de volta para nosso sistema, e clique no botão "Importar
                                produtos".
                            </SpanHelp>

                            <Form>
                                <div>
                                    Parceiro
                                    <Dropdown
                                        name='contractor'
                                        style={{ width: '100%', height: '50px', borderRadius: '10px' }}
                                        value={selectedPartnersB2BOrB2C}
                                        onChange={(e) => setSelectedPartnersB2BOrB2C(e.value)}
                                        options={groupedPartners}
                                        optionLabel="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        emptyMessage="Nenhum resultado encontrado."
                                        emptyFilterMessage="Nenhum resultado encontrado."
                                        placeholder="Selecione o parceiro"
                                        showFilterClear
                                        filter
                                    />

                                    <Error name="partner" errors={propsForm.errors} />
                                </div>

                                <div>
                                    Planilha para importação de produtos em massa.
                                    <FileUpload
                                        ref={fileUpload}
                                        style={{ marginTop: "5px" }}
                                        name="invoice"
                                        accept=".xls,.xlsx"
                                        customUpload
                                        uploadHandler={(event: any) => {
                                            propsForm.setFieldValue("file", event.files[0])
                                            propsForm.setFieldValue('imageUrl', event.files[0])
                                        }}
                                        onRemove={(event) => {
                                            propsForm.setFieldValue("file", undefined)
                                            propsForm.setFieldValue('imageUrl', undefined)
                                        }}
                                        auto
                                        chooseLabel="Anexar arquivo"
                                    />
                                    <Error name="imageUrl" errors={propsForm.errors} />
                                </div>

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        style={{ marginTop: "2em" }}
                                        type="submit"
                                        disabled={!propsForm.values.file || setSelectedPartnersB2BOrB2C == null}
                                    >
                                        Importar produtos
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </FormTemplateElement>
        </>
    )

}
export default RegisterProdutorsForm
