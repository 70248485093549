import { SpanElement } from "./style";

interface text {
  text: string;
}

const SpanSubtitle: React.FC<text> = ({ text }) => {
  return <SpanElement>{text}</SpanElement>;
};

export default SpanSubtitle;
