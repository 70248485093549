import styled from "styled-components";

export const ButtomAddElement = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#d3d3d3" : "#27ae60")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  
  height: 45px;
  border: none;
  text-align: center;
  width: 210px;
  color: white;
  border-radius: 17px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 17px;
`;
