import { useCallback, useEffect, useState } from "react"
import * as Yup from "yup"
import { useAppContext } from "../../../../context/app"
import ItemForm from "../../molecules/item-form"
import { LineFormElement } from "../../molecules/item-form/style"

const LENGTH = {
    ZIPCODE: 8,
    FIFTY: 50,
    TWENTY_FIVE: 25,
    TWENTY: 20,
}

interface FormAddressProps {
    propsForm: any
    disabled: boolean
    appContext?: any
}

const FormAddress: React.FC<FormAddressProps> = ({ propsForm, disabled, appContext }) => {
    const [disabledZipCode, setDisabledZipCode] = useState(false);
    const [disabledOutherFields, setDisabledOutherFields] = useState(false);
    const { apiProvider, toast } = useAppContext();
    const getAutoCompleteAddress = async (zipCode: any) => {
        try {
            appContext.setIsShowLoading(true);
            setDisabledOutherFields(true);
            const address: any = await apiProvider.get(`/general/address/cep/${zipCode}`)
            propsForm.values.address.city = address.data.city
            propsForm.values.address.state = address.data.state
            propsForm.values.address.district = address.data.district
            propsForm.values.address.street = address.data.street
            if (address?.data?.street == "" || address?.data?.city == "" || address?.data?.state == "" || address?.data?.district == ""){
                setDisabledOutherFields(false);
            }
            buildValidationAddress(propsForm.values.address);
        } catch (e: any) {
            toast.show({
                severity: "error",
                summary: "Atenção",
                detail: e.response.data.message || "Endereço inválido, tente novamente!",
                life: 5000,
            });
            setDisabledOutherFields(false);
        } finally {
            setDisabledZipCode(false);
            appContext.setIsShowLoading(false);
        }
    }

    useEffect(() => {
        const len = propsForm.values.address?.zipCode?.replace('-', '').replace('.', '').replace(',', '').length
        if (len !== undefined && len !== 0) {

            if (len != LENGTH.ZIPCODE) {
                let zipCode = propsForm.values.address.zipCode;
                propsForm.values.address.zipCode = zipCode;
                propsForm.values.address.city = ''
                propsForm.values.address.state = ''
                propsForm.values.address.district = ''
                propsForm.values.address.street = ''
            }

            if (len === LENGTH.ZIPCODE) {
                setDisabledZipCode(true);
                getAutoCompleteAddress(propsForm.values.address?.zipCode.replace('-', '').replace('.', '').replace(',', '')).then()
            }

        } else {
            propsForm.values.address = '';
        }

    }, [propsForm.values.address?.zipCode, disabled])

    const getDisabled = useCallback((propsForm: any, disabled: boolean, nameField: any) => {
        if (nameField !== 'number' && nameField != 'complement') {
            return propsForm.values.address?.zipCode?.replace('-', '') == undefined || propsForm.values.address?.zipCode?.replace('-', '').length < LENGTH.ZIPCODE || disabledOutherFields;
        } else {
            return propsForm.values.address?.zipCode?.replace('-', '') == undefined || propsForm.values.address?.zipCode?.replace('-', '').length < LENGTH.ZIPCODE || disabledZipCode;
        }
    }, [propsForm.values.address, disabledOutherFields, disabledZipCode])

    return (
        <>
            <LineFormElement style={{ justifyContent: 'flex-start' }}>
                <ItemForm
                    disabled={disabledZipCode}
                    propsForm={propsForm}
                    name="address.zipCode"
                    label="Cep"
                    widthPercent="35"
                />
            </LineFormElement>

            <LineFormElement style={{ justifyContent: 'flex-start' }}>
                <ItemForm
                    disabled={getDisabled(propsForm, disabledOutherFields, 'city')}
                    propsForm={propsForm}
                    name="address.city"
                    label="Cidade"
                    widthPercent="32"
                />

                <ItemForm
                    disabled={getDisabled(propsForm, disabledOutherFields, 'state')}
                    propsForm={propsForm}
                    name="address.state"
                    label="Estado"
                    widthPercent="32"
                />

                <ItemForm
                    disabled={getDisabled(propsForm, disabled, 'number')}
                    propsForm={propsForm}
                    name="address.number"
                    label="Número"
                    widthPercent="15"
                />
            </LineFormElement>

            <LineFormElement style={{ justifyContent: 'flex-start' }}>
                <ItemForm
                    disabled={getDisabled(propsForm, disabledOutherFields, 'street')}
                    propsForm={propsForm}
                    name="address.street"
                    label="Rua"
                    widthPercent="32"
                />
                <ItemForm
                    disabled={getDisabled(propsForm, disabledOutherFields, 'district')}
                    propsForm={propsForm}
                    name="address.district"
                    label="Bairro"
                    widthPercent="32"
                />
                <ItemForm
                    disabled={getDisabled(propsForm, disabled, 'complement')}
                    propsForm={propsForm}
                    name="address.complement"
                    label="Complemento"
                    widthPercent="32"
                />
            </LineFormElement>
        </>
    )
}

export default FormAddress

export function buildValidationAddress(validation: any) {
    validation.address = Yup.object().shape({
        city: Yup.string().max(LENGTH.FIFTY).required(),
        district: Yup.string().max(LENGTH.FIFTY).required(),
        state: Yup.string().max(LENGTH.TWENTY_FIVE).required(),
        street: Yup.string().max(LENGTH.FIFTY).required(),
        zipCode: Yup.string().required(),
        number: Yup.string().max(LENGTH.TWENTY).required(),
    })

    return Yup.object().shape(validation)
}
