import { Form, Formik } from "formik"
import { FileUpload } from "primereact/fileupload"
import { useCallback, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../../context/app"
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form"
import Error from "../../../../../../components/atoms/error-message"
import PageHeader from "../../../../../../components/atoms/page-header"
import SelectDropDownVm from "../../../../../../components/atoms/select-drop-down"
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle"
import FormTemplateElement from "../../../../../../templates/form-template"
import { SpanHelp, ButtomDownalod } from "./style"
import { useNavigate } from "react-router";
import BackButtom from "../../../../../../components/atoms/back-buttom";
import { Contractor } from "../../../../../../../dto/contractor";
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { Dropdown } from "primereact/dropdown";
import { FaFileDownload } from "react-icons/fa"

const RegisterUserForm: React.FC = () => {
    const navigate = useNavigate()
    const [contractorList, setContractorList] = useState([])
    const fileUpload = useRef(null as any);
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()
    const { state } = location as any
    const [selectedContractorsB2BOrB2C, setSelectedContractorsB2BOrB2C] = useState<Contractor>();
    const [groupedContractors, setGroupedContractors] = useState([] as any[]);

    useEffect(() => {
        getContractors()
        getAllContractors()
    }, [])

    // Validações do yup
    const validations = Yup.object().shape({
        contractor: Yup.object().when((actionEnum, schema): any => {
            if (selectedContractorsB2BOrB2C === null) {
                return schema.required();
            }
        }),
        imageUrl: Yup.string().required()
    })

   // Salvar Cadastros
    async function save(values: any, obj: any) {
        const contractorId:any = selectedContractorsB2BOrB2C?.id;
        appContext.setIsShowLoading(true)
        const formData = new FormData()
        formData.append("contractorId", contractorId)
        formData.append("file", values.file)

        try {
            await apiProvider.post("/admin/bulk-users-registration", formData)

            appContext.toast.show({ severity: 'success', summary: 'Usuário', detail: 'Salvo com sucesso!', life: 3000 })
            obj.resetForm({})
            fileUpload.current.clear();
        } catch (error: any) {
            let mensagem = 'Erro ao importar novos usuários, tente novamente!'
            if (error.response.status === 400 && error.response.data.message) {
                mensagem = error.response.data.message
            }
            appContext.toast.show({ severity: 'error', summary: 'Usuário', detail: mensagem, life: 5000 })
        } finally{
            appContext.setIsShowLoading(false)
        }
    }

    const getContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/contractor')
        setContractorList(response.data)
    }, [])

    // Importação com B2B e B2C com delimitação por módulo
    const getAllContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/contractor')

        if (response?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            response.data.forEach((item: Contractor) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({ label: item.name, value: item });
                    if (state?.item?.contractorCnpj == item.cnpj) {
                        setSelectedContractorsB2BOrB2C(item);
                    }
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({ label: item.name, value: item });
                    if (state?.item?.contractorCnpj == item.cnpj) {
                        setSelectedContractorsB2BOrB2C(item);
                    }
                }
            });

            let objectB2cAndB2B = [];

            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2C', items: aB2C })
            }

            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2B', items: aB2B })
            }

            setGroupedContractors(objectB2cAndB2B)
            setContractorList(response.data)
        }
    }, [])

    async function downalodTemplate() {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.download(`/admin/bulk-users-registration/download/template/users`)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Importação em massa de usuários.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    return (
        <>
            <PageHeader text="Importação de usuários através de planílha." />

            <FormTemplateElement>

                <div style={{ width: "100%", display: "flex" }}>
                    <BackButtom
                        onclick={() => {
                            navigate("/imports-view")
                        }}
                    />
                    <ButtomDownalod onClick={downalodTemplate} disabled={selectedContractorsB2BOrB2C == null}><FaFileDownload style={{ marginRight: "0.5em" }} /><span> Download exemplo</span></ButtomDownalod>
                </div>

                <Formik
                    initialValues={{} as any}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <>
                            <SpanSubtitle text="Como fazer a importação de usuário?" />
                            <SpanHelp>
                                Baixe a planilha de exemplo clicando em "Download exemplo", preencha as linhas com as informações
                                dos novos
                                usuários, envie a planilha de volta para nosso sistema, selecione o contratante e clique no botão
                                "Importar
                                usuários".
                            </SpanHelp>

                            <Form>
                                <div>
                                    Contratante
                                    <Dropdown
                                        name='contractor'
                                        style={{ width: '100%', height: '50px', borderRadius: '10px' }}
                                        value={selectedContractorsB2BOrB2C}
                                        onChange={(e) => setSelectedContractorsB2BOrB2C(e.value)}
                                        options={groupedContractors}
                                        optionLabel="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        emptyMessage="Nenhum resultado encontrado."
                                        emptyFilterMessage="Nenhum resultado encontrado."
                                        placeholder="Selecione o contratante"
                                        showFilterClear
                                        filter
                                    />
                                    <Error name="contractor" errors={propsForm.errors} />
                                </div>

                                <div>
                                    Planilha para importação de usuários em massa. (Apenas usuários para acesso via app)
                                    <FileUpload
                                        ref={fileUpload}
                                        style={{ marginTop: "5px" }}
                                        name="invoice"
                                        accept=".xls,.xlsx"
                                        customUpload
                                        uploadHandler={(event: any) => {
                                            propsForm.setFieldValue("file", event.files[0])
                                            propsForm.setFieldValue('imageUrl', event.files[0])
                                        }}
                                        onRemove={(event) => {
                                            propsForm.setFieldValue("file", undefined)
                                            propsForm.setFieldValue('imageUrl', undefined)
                                        }}
                                        auto
                                        chooseLabel="Anexar arquivo"
                                    />
                                    <Error name="imageUrl" errors={propsForm.errors} />
                                </div>

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        style={{ marginTop: "2em" }}
                                        type="submit"
                                        disabled={!propsForm.values.file || selectedContractorsB2BOrB2C == null}
                                    >
                                        Importar arquivo OFX
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </FormTemplateElement>
        </>
    )
}

export default RegisterUserForm
