
import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import plusSign from "../../../../../../../assets/buttons/plus-sign.svg";
import trashIcon from "../../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../../context/app";
import { TruncateData } from "../../../../../../../utils/truncateData";
import { confirm } from "../../../../../../../utils/validation";
import ButtonExport from "../../../../../../components/atoms/buttom-export";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import { maskCNPJ } from "../../../../../../templates/masks-template";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import {
    ActionButtonsElement,
    ButtomsElement,
    ContractorButtomElement,
    HeaderElementLeft,
    HeaderElementRight,
    TableElement
} from "./style";

const ContractorView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ contractorList: [], isLoading: true });
    const [filterText, setFilterText] = useState("");
    const appContext = useAppContext();
    const { apiProvider } = appContext;
    const { contractorList } = state;

    useEffect(() => {
        getContractorData();
    }, []);

    const cnpjMask = (rowData: any) => {
        return (
            <>{maskCNPJ(rowData.cnpj)}</>
        );
    }

    const phoneAndCellPhone = (rowData: any) => {
        return (<>{(rowData.phone != null && rowData.phone != '') ? rowData.phone : rowData.cellPhone}</>);
    }

    // DELETAR CONTRACTOR
    async function deleteContractor(rowData: any) {
        try {
            await apiProvider.delete(`/admin/contractor/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Contratante",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getContractorData();
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Contratante",
                detail: error.response.data.message,
                life: 3000,
            });
        }
    }

    const getContractorData = useCallback(
        async function () {
            const response: any = await apiProvider.get("/admin/contractor");
            setState({ contractorList: response.data, isLoading: false });
        }, []);

    const exportReport = async () => {
        try {
            appContext.setIsShowLoading(true);
            const response: any = await apiProvider.download('/admin/contractor/export?filterText=' + filterText);
            const type = response.headers['content-type'];
            const blob = new Blob([response.data], { type: type });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Relatórios de contratantes.xlsx';
            link.click();
        } catch (error) {
            console.error(error);
        } finally {
            appContext.setIsShowLoading(false);
        }
    }

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Contratante"> </PageHeader>
            <HeaderViewElement>
                <HeaderElementLeft>
                    <ContractorButtomElement onClick={() => { navigate("/contractor-form"); }}>
                        <img alt="" style={{ marginRight: "10px" }} src={plusSign} /> Novo contratante
                    </ContractorButtomElement>
                    <ButtonExport onClick={exportReport} />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>
            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginator
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.contractorList}
                    responsiveLayout="scroll"
                    itemsList={contractorList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="name" body={(e) => TruncateData(e.name, 50)} header="Nome" sortable style={{ width: '40%' }} />
                    <Column alignHeader="center" align="center" field="cnpj" header="cnpj" body={cnpjMask} sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="phone" header="Telefone" body={phoneAndCellPhone} sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "10%" }} />
                </DataTableElement>
            </TableElement>
        </>
    );

    // RENDERIZAÇÃO DA COLUNA AÇÕES DA TABELA
    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <ButtomsElement>
                    <ActionButtonsElement
                        onClick={() =>
                            confirm(rowData, "Deseja excluir este contratante?", () =>
                                deleteContractor(rowData)
                            )
                        }
                    >
                        <img alt="" style={{ width: "17px" }} src={trashIcon} />{" "}
                    </ActionButtonsElement>

                    <ActionButtonsElement
                        onClick={() => {
                            navigate("/contractor-form", {
                                state: { item: rowData, disabled: false },
                            });
                        }}
                    >
                        <img alt="" style={{ width: "17px" }} src={penIcon} />
                    </ActionButtonsElement>
                </ButtomsElement>
            </div>
        );
    }
};

export default ContractorView;
