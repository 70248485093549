import styled from "styled-components";

export const HeaderViewElement = styled.div`
  width: 100%;
  height: 65px;
  border-radius: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;
  display: flex;
  @media(max-width: 1320px){
    width: 97%;
  }
  @media(max-width: 825px){
    width: 90%;
  }
`;
