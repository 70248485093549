import { createContext, FC, useCallback, useContext, useEffect, useMemo } from "react";
import moment from "moment";
import { ModuleEnum } from "../../dto/moduleEnum/module-enum";
import ApiProvider from "../../provider/api";
import SessionProvider from "../../provider/session";

const AppContext = createContext<IAppContext>({} as IAppContext);
export default AppContext;
export const useAppContext = () => useContext(AppContext);
const apiProvider = new ApiProvider();
export const sessionProvider = new SessionProvider(apiProvider);
export const AppContextProvider: FC<IAppContextProvider> = ({ children, setApplicationStatus, }) => {

    // const defaultAppContext: IAppContext = { sessionProvider, apiProvider, setApplicationStatus };
    const defaultAppContext: IAppContext = useMemo(() => {
        return { sessionProvider, apiProvider, setApplicationStatus }
    }, []);

    useEffect(() => {
        checkLogin();
        setInterval(checkLogin, (1000 * 60) * 2)
    }, [])

    const checkLogin = useCallback(() => {
        try {
            if (sessionProvider.getSession()) {

                // Verifica o mantenha-me logado, caso true, dispensa a verificação de tempo de logout
                const keep_logged = localStorage.getItem('@vmparcerias:keep_logged');
                if (keep_logged) {
                    return;
                }

                const loginTime = moment(localStorage.getItem('@vmparcerias:login_time'));
                if (loginTime) {
                    const lastTime = moment().subtract(2, 'hours');
                    const currentTime = moment()

                    if (loginTime.isBefore(currentTime) && loginTime.isAfter(lastTime)) {
                        return;
                    }
                }

                logOut();
            }
        } catch (error) {
            logOut();
        }
    }, [sessionProvider])

    const logOut = useCallback(() => {
        const moduleEnum = sessionProvider.getSession()?.modules[0]?.moduleEnum
        sessionProvider.deleteSession().then();
        if (moduleEnum === ModuleEnum.PARTNER) {
            document.location.pathname = '/parceiro'
        } else if (moduleEnum === ModuleEnum.CONTRACTOR) {
            document.location.pathname = '/contratante'
        } else {
            document.location.pathname = '/'
        }
    }, [sessionProvider])

    return (
        <AppContext.Provider value={defaultAppContext}>
            {children}
        </AppContext.Provider>
    );
};

export interface IAppContext {
    sessionProvider: SessionProvider;
    apiProvider: ApiProvider;
    setApplicationStatus: any;
    toast?: any;
    setIsShowLoading?: any;
}

interface IAppContextProvider {
    setApplicationStatus: any;
    children: React.ReactNode;
}
