import {Toast} from 'primereact/toast';
import {useCallback, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useAppContext} from '../../../context/app';
import {ModuleEnum} from '../../../dto/moduleEnum/module-enum';
import {Session} from '../../../dto/session';
import Header from '../../components/organisms/header';
import UserProfile from '../../components/organisms/user-profile';
import AssociateControlView from '../../pages/modules/admin/forms/associate-control/view';
import AssociateControlForm from '../../pages/modules/admin/forms/associate-control/form';
import BannerForm from '../../pages/modules/admin/forms/banner/form';
import BannerView from '../../pages/modules/admin/forms/banner/view';
import CategoryForm from '../../pages/modules/admin/forms/category/form';
import CategoryView from '../../pages/modules/admin/forms/category/view';
import CommissionForm from '../../pages/modules/admin/forms/comission/form';
import ComissionView from '../../pages/modules/admin/forms/comission/view';
import ContractorForm from '../../pages/modules/admin/forms/contractor/form';
import ContractorView from '../../pages/modules/admin/forms/contractor/view';
import MessagesForm from '../../pages/modules/admin/forms/messages/form';
import MessagesView from '../../pages/modules/admin/forms/messages/view';
import NewsForm from '../../pages/modules/admin/forms/news/form';
import NewsView from '../../pages/modules/admin/forms/news/view';
import PartnerForm from '../../pages/modules/admin/forms/partner/form';
import PartnerView from '../../pages/modules/admin/forms/partner/view';
import PopupForm from '../../pages/modules/admin/forms/popup/form';
import PopupView from '../../pages/modules/admin/forms/popup/view';
import RegisterTransactionForm from '../../pages/modules/admin/forms/register/transactions';
import RegisterUserForm from '../../pages/modules/admin/forms/register/users';
import SuggestPartnerView from '../../pages/modules/admin/forms/suggested-partner-admin/view';
import SuggestPartnerFormAdmin from '../../pages/modules/admin/forms/suggested-partner-admin/view/form';
import UserAdmForm from '../../pages/modules/admin/forms/user/form';
import UserAdmView from '../../pages/modules/admin/forms/user/view';
import ReportAdminGroupedByContractorPartnerProductor
    from '../../pages/modules/admin/reports/report-admin-grouped-by-contractor-partner-productor';
import ReportAdminGroupedByPartner from '../../pages/modules/admin/reports/report-admin-grouped-by-partner';
import ReportAdminGroupedByPartnerB2b from '../../pages/modules/admin/reports-b2b/report-admin-grouped-by-partner-b2b';
import ReportAdminGroupedByBanner from '../../pages/modules/admin/reports-b2b/report-admin-grouped-by-banner';
import ReportAdminGroupedByPopup from '../../pages/modules/admin/reports-b2b/report-admin-grouped-by-popup';
import ReportAdminGroupedByNews from '../../pages/modules/admin/reports-b2b/report-admin-grouped-by-news';
import ReportAdminGroupedByInformative from '../../pages/modules/admin/reports-b2b/report-admin-grouped-by-informative';
import ReportAdminView from '../../pages/modules/admin/reports/view';
import AssociateForm from '../../pages/modules/contractor/associate/form';
import AssociateView from '../../pages/modules/contractor/associate/view';
import DashboardContractorB2C from '../../pages/modules/contractor/dashboard-B2C';
import DashboardContractorB2B from '../../pages/modules/contractor/dashboard-B2B';
import InformativeForm from '../../pages/modules/contractor/informative/form';
import InformativeView from '../../pages/modules/contractor/informative/view';
import PartnerFromContractor from '../../pages/modules/contractor/partner/form';
import ContractorPartners from '../../pages/modules/contractor/partner/view';
import ReportContractorGroupedByCategory
    from '../../pages/modules/contractor/report/report-contractor-grouped-by-category';
import ReportPartnerGroupedByCategory
    from '../../pages/modules/contractor/report/report-contractor-grouped-by-category';
import ReportContractorGroupedByPartnerB2C
    from '../../pages/modules/contractor/report/report-contractor-grouped-by-partner-B2C';
import ReportPartnerGroupedByPartner
    from '../../pages/modules/contractor/report/report-contractor-grouped-by-partner-B2C';
import ReportContractorGroupedByPartnerB2B
    from '../../pages/modules/contractor/report/report-contractor-grouped-by-partner-B2B';
import ReportContractorViewB2C from '../../pages/modules/contractor/report/view-B2C';
import ReportContractorViewB2B from '../../pages/modules/contractor/report/view-B2B';
import SuggestPartner from '../../pages/modules/contractor/suggest-partner/form';
import TransactionContractorView from '../../pages/modules/contractor/transaction/view';
import UserContractorForm from '../../pages/modules/contractor/user/form';
import UserContractorView from '../../pages/modules/contractor/user/view';
import BenefitForm from '../../pages/modules/partner/benefit/form';
import BenefitView from '../../pages/modules/partner/benefit/view';
import DashboardPartner from '../../pages/modules/partner/dashboard';
import DashboardPartnerB2B from '../../pages/modules/partner/dashboard-B2B';
import ProductForm from '../../pages/modules/partner/product/form';
import ProductView from '../../pages/modules/partner/product/view';
import GroupedByContractors from '../../pages/modules/partner/report/grouped-by-contractors';
import GroupedByContractorsB2B from '../../pages/modules/partner/report/report-partner-grouped-by-contractor-B2B';
import GroupedByProducts from '../../pages/modules/partner/report/grouped-by-products';
import ReportPartnerNewUsers from '../../pages/modules/partner/report/report-partner-new-users';
import ReportPartnerView from '../../pages/modules/partner/report/view';
import ReportPartnerViewB2B from '../../pages/modules/partner/report/view-B2B';
import StoreForm from '../../pages/modules/partner/store/form';
import StoreView from '../../pages/modules/partner/store/view';
import TransactionForm from '../../pages/modules/partner/transaction/form';
import TransactionView from '../../pages/modules/partner/transaction/view';
import UserPartnerForm from '../../pages/modules/partner/user/form';
import UserPartnerView from '../../pages/modules/partner/user/view';
import TalkToUs from '../talk-to-us-template';
import WelcomeLoginTemplate from '../welcome-login-template';
import {BodyTemplateElement, ContentPageElement, HomeTemplateElement, ScrollPanelElement} from './style';
import ReportAdminPartnerNewUsers from '../../pages/modules/admin/reports/report-admin-partner-new-users';
import ReportPartnerGroupedByBenefit from '../../pages/modules/partner/report/report-partner-grouped-by-benefit';
import ReportAdminGroupedByBenefit from '../../pages/modules/admin/reports/report-admin-grouped-by-benefit';
import RegistrationsView from '../../pages/modules/admin/registrations-view';
import ImportsView from '../../pages/modules/admin/imports-view';
import RegisterProdutorsForm from '../../pages/modules/admin/forms/register/productors';
import RegisterPaymentForm from '../../pages/modules/admin/forms/register/payment';
import UpdatePassword from '../../components/organisms/update-password';
import WantToDisclose from "../want-to-disclose";
import ContractsForm from "../../pages/modules/admin/forms/contracts/form/index";
import ContractPaymentView from "../../pages/modules/admin/forms/contract_payment/view/index";
import ContractsView from "../../pages/modules/admin/forms/contracts/view/index";
import TributesForm from "../../pages/modules/admin/forms/tributes/form/index";
import TributesView from "../../pages/modules/admin/forms/tributes/view/index";

interface HomeTemplateProps {
    menu: any;
}

const RoutesTemplate: React.FC<HomeTemplateProps> = ({menu}) => {
  const appContext = useAppContext();
  const session: Session = appContext.sessionProvider.getSession();
  const [isFirstAccess, setIsFirstAccess] = useState(session.user.firstAccess);

  const renderComponent = useCallback((Component: React.FC) => {
    return (
        <HomeTemplateElement>
          <Toast ref={(el) => (appContext.toast = el)}/>
          <Header/>
          <BodyTemplateElement>
            {menu}
            <ContentPageElement>
              <ScrollPanelElement>
                <Component/>
              </ScrollPanelElement>
            </ContentPageElement>
          </BodyTemplateElement>
        </HomeTemplateElement>
    )
  }, [])

  function setFirstAccess() {
    if (appContext.sessionProvider.setSessionFirstAccess()) {
      setIsFirstAccess(false);
    }
  }

  function setRenderViewDashByModule():any {
      let renderViewDashByModule = null;
      if(session.modules[0].moduleEnum === ModuleEnum.ADMIN){
          renderViewDashByModule = UserAdmView
      } else if(session.modules[0].moduleEnum === ModuleEnum.PARTNER && session.modules[0]?.partner?.moduleName === ModuleEnum.B2C){
          renderViewDashByModule = DashboardPartner
      } else if(session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR && session.modules[0]?.contractor?.moduleName === ModuleEnum.B2C){
          renderViewDashByModule = DashboardContractorB2C
      } else if(session.modules[0].moduleEnum === ModuleEnum.PARTNER && session.modules[0]?.partner?.moduleName === ModuleEnum.B2B){
          renderViewDashByModule = DashboardPartnerB2B
      } else if(session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR && session.modules[0]?.contractor?.moduleName === ModuleEnum.B2B){
          renderViewDashByModule = DashboardContractorB2B
      }
      return renderViewDashByModule
  }

  if (isFirstAccess) {
    return <WelcomeLoginTemplate setIsFirstAccess={setFirstAccess}/>;
  } else {
    return (
        <Routes>
            {localStorage.getItem('@vmparcerias:app_token') && (
                <>
                    <Route path='/' element={renderComponent(setRenderViewDashByModule())}/>
                    <Route path='/registrations-view' element={renderComponent(RegistrationsView)}/>
                    <Route path='/imports-view' element={renderComponent(ImportsView)}/>
                    <Route path='/register-payment-form' element={renderComponent(RegisterPaymentForm)}/>

                {/* TODO montar apenas rotas que fazem sentido para o módulo atual */}
                    <Route path='/benefit-view' element={renderComponent(BenefitView)}/>
                    <Route path='/benefit-form' element={renderComponent(BenefitForm)}/>
                    <Route path='/contractor-form' element={renderComponent(ContractorForm)}/>
                    <Route path='/contractor-view' element={renderComponent(ContractorView)}/>
                    <Route path='/partner-view' element={renderComponent(PartnerView)}/>
                    <Route path='/partner-form' element={renderComponent(PartnerForm)}/>
                    <Route path='/store-view' element={renderComponent(StoreView)}/>
                    <Route path='/store-form' element={renderComponent(StoreForm)}/>
                    <Route path='/category-view' element={renderComponent(CategoryView)}/>
                    <Route path='/category-form' element={renderComponent(CategoryForm)}/>
                    <Route path='/product-view' element={renderComponent(ProductView)}/>
                    <Route path='/product-form' element={renderComponent(ProductForm)}/>
                    <Route path='/banner-form' element={renderComponent(BannerForm)}/>
                    <Route path='/banner-view' element={renderComponent(BannerView)}/>
                    <Route path='/popup-form' element={renderComponent(PopupForm)}/>
                    <Route path='/popup-view' element={renderComponent(PopupView)}/>
                    <Route path='/userContractor-form' element={renderComponent(UserContractorForm)}/>
                    <Route path='/userContractor-view' element={renderComponent(UserContractorView)}/>
                    <Route path='/userPartner-form' element={renderComponent(UserPartnerForm)}/>
                    <Route path='/userPartner-view' element={renderComponent(UserPartnerView)}/>
                    <Route path='/userAdm-view' element={renderComponent(UserAdmView)}/>
                    <Route path='/userAdm-form' element={renderComponent(UserAdmForm)}/>
                    <Route path='/associate-view' element={renderComponent(AssociateView)}/>
                    <Route path='/associate-form' element={renderComponent(AssociateForm)}/>
                    <Route path='/transaction-form' element={renderComponent(TransactionForm)}/>
                    <Route path='/transaction-view' element={renderComponent(TransactionView)}/>
                    <Route path='/contractor-transaction-view' element={renderComponent(TransactionContractorView)}/>
                    <Route path='/contractor-partner-view' element={renderComponent(ContractorPartners)}/>
                    <Route path='/dashboard-contractor' element={renderComponent(DashboardContractorB2C)}/>
                    <Route path='/dashboard-contractor-B2B' element={renderComponent(DashboardContractorB2B)}/>
                    <Route path='/dashboard-partner' element={renderComponent(DashboardPartner)}/>
                    <Route path='/dashboard-partner-B2B' element={renderComponent(DashboardPartnerB2B)}/>
                    <Route path='/informative-view' element={renderComponent(InformativeView)}/>
                    <Route path='/informative-form' element={renderComponent(InformativeForm)}/>
                    <Route path='/register-user-form' element={renderComponent(RegisterUserForm)}/>
                    <Route path='/register-transaction-form' element={renderComponent(RegisterTransactionForm)}/>
                    <Route path='/register-productor-form' element={renderComponent(RegisterProdutorsForm)}/>
                    <Route path='/talk-to-us' element={renderComponent(TalkToUs)}/>
                    <Route path='/messages' element={renderComponent(MessagesView)}/>
                    <Route path='/messages-form' element={renderComponent(MessagesForm)}/>
                    <Route path='/user-profile' element={renderComponent(UserProfile)}/>
                    <Route path='/update-password' element={renderComponent(UpdatePassword)}/>
                    <Route path='/commission-view' element={renderComponent(ComissionView)}/>
                    <Route path='/commission-form' element={renderComponent(CommissionForm)}/>
                    <Route path='/partner-contractor-form' element={renderComponent(PartnerFromContractor)}/>
                    <Route path='/news-view' element={renderComponent(NewsView)}/>
                    <Route path='/news-form' element={renderComponent(NewsForm)}/>
                    <Route path='/suggest-partner-form' element={renderComponent(SuggestPartner)}/>
                    <Route path='/suggest-partner-view' element={renderComponent(SuggestPartnerView)}/>
                    <Route path='/suggest-partner-form-admin' element={renderComponent(SuggestPartnerFormAdmin)}/>
                    <Route path='/associate-control-view' element={renderComponent(AssociateControlView)}/>
                    <Route path='/associate-control-form' element={renderComponent(AssociateControlForm)}/>
                    <Route path='/contracts-form' element={renderComponent(ContractsForm)}/>
                    <Route path='/contract-payment' element={renderComponent(ContractPaymentView)}/>
                    <Route path='/contracts-view' element={renderComponent(ContractsView)}/>
                    <Route path='/tributes-form' element={renderComponent(TributesForm)}/>
                    <Route path='/tributes-view' element={renderComponent(TributesView)}/>
                    <Route path='/want-to-disclose' element={renderComponent(WantToDisclose)}/>

                {/* Relatórios parceiro */}
                    <Route path='/report-partner-view' element={renderComponent(ReportPartnerView)}/>
                    <Route path='/report-partner-view-B2B' element={renderComponent(ReportPartnerViewB2B)}/>
                    <Route path='/grouped-by-contractors' element={renderComponent(GroupedByContractors)}/>
                    <Route path='/grouped-by-contractors-B2B' element={renderComponent(GroupedByContractorsB2B)}/>
                    <Route path='/grouped-by-products' element={renderComponent(GroupedByProducts)}/>
                    <Route path='/report-partner-grouped-by-benefit' element={renderComponent(ReportPartnerGroupedByBenefit)}/>
                    <Route path='/report-partner-new-user' element={renderComponent(ReportPartnerNewUsers)}/>

                {/* Relatórios contratante */}
                    <Route path='/report-contractor-view' element={renderComponent(ReportContractorViewB2C)}/>
                    <Route path='/report-contractor-view-B2B' element={renderComponent(ReportContractorViewB2B)}/>
                    <Route path='/grouped-by-category' element={renderComponent(ReportContractorGroupedByCategory)}/>
                    <Route path='/grouped-by-partner' element={renderComponent(ReportContractorGroupedByPartnerB2C)}/>
                    <Route path='/grouped-by-partner-B2B' element={renderComponent(ReportContractorGroupedByPartnerB2B)}/>
                    <Route path='/grouped-by-category' element={renderComponent(ReportPartnerGroupedByCategory)}/>
                    <Route path='/grouped-by-partner' element={renderComponent(ReportPartnerGroupedByPartner)}/>

                {/* Relatórios Admin B2C*/}
                    <Route path='/report-admin-view' element={renderComponent(ReportAdminView)}/>
                    <Route path='/admin-grouped-by-partner' element={renderComponent(ReportAdminGroupedByPartner)}/>
                    <Route path='/grouped-by-contractor-partner-productor' element={renderComponent(ReportAdminGroupedByContractorPartnerProductor)}/>
                    <Route path='/report-admin-new-user' element={renderComponent(ReportAdminPartnerNewUsers)}/>
                    <Route path='/report-admin-grouped-by-benefit' element={renderComponent(ReportAdminGroupedByBenefit)}/>

                  {/* Relatórios Admin B2B*/}
                    <Route path='/report-admin-grouped-by-partner' element={renderComponent(ReportAdminGroupedByPartnerB2b)}/>
                    <Route path='/report-admin-grouped-by-banner' element={renderComponent(ReportAdminGroupedByBanner)}/>
                    <Route path='/report-admin-grouped-by-popup' element={renderComponent(ReportAdminGroupedByPopup)}/>
                    <Route path='/report-admin-grouped-by-news' element={renderComponent(ReportAdminGroupedByNews)}/>
                    <Route path='/report-admin-grouped-by-informative' element={renderComponent(ReportAdminGroupedByInformative)}/>

              </>
          )}
        </Routes>
    )
  }
};
export default RoutesTemplate;
