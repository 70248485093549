import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import eyesIcon from "../../../../../../assets/buttons/eyes.svg"
import penIcon from "../../../../../../assets/buttons/pen.svg"
import plusSign from "../../../../../../assets/buttons/plus-sign.svg"
import trashIcon from "../../../../../../assets/buttons/trash.svg"
import { useAppContext } from "../../../../../../context/app"
import { confirm } from "../../../../../../utils/validation"
import DataTableElement from "../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../components/molecules/search-text-label"
import { HeaderElementLeft, HeaderElementRight } from "../../../../../components/organisms/header/style"
import HeaderViewElement from "../../../../../templates/view-header-template"
import { ButtomsElement, ContractorButtomElement, TableElement } from "../../../admin/forms/contractor/view/style"
import { ActionButtonsElement } from "./style"
import { ISessionContext, useSessionContext } from "../../../../../../context/session";
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum";

const ProductView: React.FC = () => {
    const { apiProvider } = useAppContext();
    const [state, setState] = useState({ productList: [], isLoading: true });
    const [admin, setAdmin] = useState(false);
    const { productList } = state;
    const [filterText, setFilterText] = useState("");
    const appContext = useAppContext();
    const navigate = useNavigate();
    const context: ISessionContext = useSessionContext();

    const getProductsData = useCallback(async function () {
        let urlAdmin: string = '';
        if (context.session.modules[0].moduleEnum === ModuleEnum.ADMIN) {
            setAdmin(true);
            urlAdmin = '/admin';
        }

        const response: any = await apiProvider.get("/web/product" + urlAdmin);
        setState({ productList: response.data, isLoading: false });

    }, [apiProvider]);

    useEffect(() => {
        getProductsData();
    }, [getProductsData]);

    return (
        <>
            <PageHeader text="Produtos"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ContractorButtomElement
                        onClick={() => { navigate("/product-form") }}>
                        <img alt="" style={{ marginRight: "10px" }} src={plusSign} /> Novo
                        produto
                    </ContractorButtomElement>
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel onChange={(e: any) => { setFilterText(e.target.value) }} />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.productList}
                    responsiveLayout="scroll"
                    itemsList={productList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    {admin && <Column alignHeader="center" align="center" field="partnerName" header="Parceiro" sortable style={{ width: '30%' }} />}
                    <Column alignHeader="center" align="center" field="name" header="Nome" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="externalCode" header="Código externo" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" sortable style={{ width: '10%' }} />
                </DataTableElement>
            </TableElement>
        </>
    );

    async function deleteProduct(rowData: any) {
        try {
            await apiProvider.delete(`/web/product/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Produto",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getProductsData();
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Produto",
                detail: "Erro ao excluir produto!",
                life: 3000,
            });
        }
    }

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <ActionButtonsElement
                    onClick={() =>
                        confirm(rowData, "Deseja excluir este produto?", () =>
                            deleteProduct(rowData)
                        )
                    }
                >
                    <img alt="" style={{ width: "17px" }} src={trashIcon} />
                </ActionButtonsElement>
                <ActionButtonsElement
                    onClick={() => {
                        navigate("/product-form", {
                            state: { item: rowData, disabled: false },
                        })
                    }}
                >
                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtonsElement>
            </div>
        )
    }
}

export default ProductView
