import { Form, Formik } from 'formik'
import { InputTextarea } from 'primereact/inputtextarea'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useAppContext } from '../../../../../../../../context/app'
import SpanSubtitle from '../../../../../../../components/atoms/span-subtitle'
import ItemForm from '../../../../../../../components/molecules/item-form'
import { LineFormElement } from '../../../../../../../components/molecules/item-form/style'
import FormTemplateElement from '../../../../../../../templates/form-template'
import { InputTextareaDivElement } from '../../../../../contractor/informative/form/style'
import BackButtom from '../../../../../../../components/atoms/back-buttom'
import { BackButtomElement } from '../../../../../../../components/atoms/back-buttom/style'

const SuggestPartnerFormAdmin: React.FC = () => {
    const navigate = useNavigate()
    const [disabled, setDisabled] = useState(false)
    const [suggestion, setSuggestion] = useState({} as any)
    const { apiProvider } = useAppContext()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any

    const isLoadApi = () => {
        return !!suggestion.id
      }

    const save = async(values: any) => {
    }

    const getOneSuggestion = async() => {
        const response: any = await apiProvider.get(`/admin/suggestion/detail/${state.item.id}`)
        setSuggestion(response.data)
    }

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOneSuggestion()
        }
    }, [])

    return (
        <FormTemplateElement>
            <BackButtomElement>
                <BackButtom
                    onclick={() => {
                navigate('/suggest-partner-view')
                }}
                />
            </BackButtomElement>
            <SpanSubtitle text='Sugestão de nova rede' />
            {(isLoadApi() || isNew) && (
                <Formik
                    initialValues={suggestion}
                    onSubmit={save}
                >
                    {(propsForm) => (
                    <Form>
                        <LineFormElement style={{ justifyContent: 'flex-start' }}>
                            <ItemForm
                                propsForm={propsForm}
                                label='Nome da empresa'
                                widthPercent='50'
                                disabled={disabled}
                                name='companyName'
                            />
                            <ItemForm
                                propsForm={propsForm}
                                label='Email da empresa sugerida'
                                widthPercent='50'
                                disabled={disabled}
                                name='suggestedEmail'
                            />
                        </LineFormElement>
                        <LineFormElement style={{ justifyContent: 'flex-start' }}>
                            <ItemForm
                                propsForm={propsForm}
                                label='Telefone da empresa'
                                widthPercent='50'
                                disabled={disabled}
                                name='phone'
                            />
                        </LineFormElement>

                        <LineFormElement style={{justifyContent: 'flex-start'}}>
                            <ItemForm
                                propsForm={propsForm}
                                label='Contratante que indicou'
                                name='contractoName'
                                widthPercent='50'
                                disabled={disabled}
                            />
                            <ItemForm
                                propsForm={propsForm}
                                label='Email do contratante'
                                widthPercent='50'
                                disabled={disabled}
                                name='userContractorEmail'
                            />
                        </LineFormElement>
                        <LineFormElement>
                            <InputTextareaDivElement style={{ width: '100%'}}>
                                Mensagem
                                <InputTextarea
                                    disabled={disabled}
                                    style={{ marginTop: '5px' }}
                                    value={propsForm.values.message}
                                    name='message'
                                    autoResize
                                    onChange={propsForm.handleChange}
                                />
                            </InputTextareaDivElement>
                        </LineFormElement>
                    </Form>
                 )}
            </Formik>
          )}
        </FormTemplateElement>
    )
}
export default SuggestPartnerFormAdmin