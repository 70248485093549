import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import penIcon from "../../../../../../../assets/buttons/pen.svg"
import trashIcon from "../../../../../../../assets/buttons/trash.svg"
import { useAppContext } from "../../../../../../../context/app"
import { confirm } from "../../../../../../../utils/validation"
import ButtomAddVm from "../../../../../../components/atoms/buttom-add-vm"
import ButtonExport from "../../../../../../components/atoms/buttom-export"
import DataTableElement from "../../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../../components/molecules/search-text-label"
import { HeaderElementRight } from "../../../../../../components/organisms/header/style"
import HeaderViewElement from "../../../../../../templates/view-header-template"
import TableViewElement from "../../../../../../templates/view-table-template"
import { ActionButtons, StatusButtomElement } from "../../../../partner/benefit/view/style"
import { HeaderElementLeft } from "../../contractor/view/style"

const CategoryView: React.FC = () => {
    const context = useAppContext();
    const navigate = useNavigate()
    const [state, setState] = useState({ categoryList: [], isLoading: true })
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()
    const { categoryList } = state
    const [filterText, setFilterText] = useState("")
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        getCategoryData()
    }, [])

    const getCategoryData = useCallback(
        async function () {
            const response: any = await apiProvider.get("/web/category")
            setState({ categoryList: response.data, isLoading: false })
        }, [])

    const exportReport = async () => {
        appContext.setIsShowLoading(true)
        try {
            let categoryName = verifyFilterApply(filterText)
            if (filterText != "" && categoryName.length < 1) {
                throw new Error('Este filtro não contém registros!');
            }

            const response: any = await apiProvider.download('/web/category/export?filterText=' + filterText)
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Relatórios de categorias.xlsx'
            link.click()

        } catch (error: any) {
            context.toast.show({
                severity: 'error',
                summary: 'Atenção',
                detail: error.message,
                life: 3000,
            });
        }
        appContext.setIsShowLoading(false)
    }

    // NOVA CATEGORIA
    function newCategory() {
        navigate("/category-form")
    }

    // RENDER BOTÃO PREFERÍVEL
    function PreferredBody(rowData: any) {
        if (!rowData.isPreferred) {
            return <StatusButtomElement buttonColor={false}>Não</StatusButtomElement>
        }
        return <StatusButtomElement buttonColor>Sim</StatusButtomElement>
    }

    // DELETAR CATEGORIA
    async function deleteCategory(rowData: any) {
        try {
            await apiProvider.delete(`/web/category/${rowData.id}`)
            getCategoryData()
            appContext.toast.show({
                severity: "success",
                summary: "Categoria",
                detail: "Excluido com sucesso!",
                life: 3000,
            })
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Categoria",
                detail: "Erro ao excluir a categoria, tente novamente!",
                life: 3000,
            })
        }
    }

    function verifyFilterApply(filterText: any) {
        const categoryName: any[] = [];
        categoryList.map((value: any) => {
            if (value.name.toLowerCase().includes(filterText.toLowerCase())) {
                categoryName.push(value.name);
            }
        });

        return categoryName
    }

    function checkRegisterFilter(filterText: any) {
        let categoryName = verifyFilterApply(filterText)
        if (categoryName.length < 1) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Categorias" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newCategory} text="Nova categoria" />
                    <ButtonExport onClick={exportReport} disable={disabled} />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                            checkRegisterFilter(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    filters={{
                        global: {
                            value: filterText,
                            matchMode: FilterMatchMode.CONTAINS
                        },
                    }}
                    paginator
                    paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                    rows={10}
                    value={state.categoryList}
                    responsiveLayout="scroll"
                    emptyMessage="Nenhum resultado encontrado"
                    itemsList={categoryList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="name" header="Nome" sortable style={{ width: '50%' }} />
                    <Column alignHeader="center" align="center" header="Ícone" body={ico} sortable style={{ width: '50%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: '10%' }} />
                </DataTableElement>
            </TableViewElement>
        </>
    )

    // RENDER IMAGEM
    function ico(rowData: any) {
        return (
            <FontAwesomeIcon icon={rowData.nameIcon} size="2x" />
        )
    }

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <ActionButtons
                    onClick={() =>
                        confirm(rowData, "Deseja excluir esta categoria?", () =>
                            deleteCategory(rowData)
                        )
                    }
                >
                    <img alt="" style={{ width: "17px" }} src={trashIcon} />
                </ActionButtons>
                <ActionButtons
                    onClick={() => {
                        navigate("/category-form", {
                            state: { item: rowData, disabled: false },
                        })
                    }}
                >
                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtons>
            </div>
        )
    }
}

export default CategoryView
