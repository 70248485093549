import React, { useContext } from "react";

import { Modules } from "../../dto/modules";
import { Session } from "../../dto/session";
import { User } from "../../dto/user";

export const initialState: ISessionContext = {
  session: {
    user: {} as User,
    modules: [] as Modules[],
  },
  toggleMenu: false,
  isMenuOpen: false,
};

const SessionContext = React.createContext<ISessionContext>(initialState);

export default SessionContext;

export const useSessionContext = () => useContext(SessionContext);

export interface ISessionContext {
  session: Session;
  toggleMenu: any;
  isMenuOpen: boolean;
}
