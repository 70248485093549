import styled from "styled-components";

interface isView {
  disabled: boolean;
}

export const ButtomAssociateElement = styled.button`
  height: 2em;
  border: none;
  text-align: center;
  width: 6em;
  color: white;
  border-radius: 13px;
  background-color: #0172cb;
  font-size: 17px;
  cursor: pointer;
`;

export const LabelElement = styled.div<isView>`
  color: ${({ disabled }) => (disabled ? "#C0C0C0" : "black")};
  align-items: center;
  font-size: 20px;
  margin-left: 1em;
`;
