import { Form, Formik, FormikProps } from "formik";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton, RadioButtonChangeParams } from "primereact/radiobutton";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { useAppContext } from "../../../../../../../context/app";
import { AdmUser } from "../../../../../../../dto/admUser";
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { Session } from '../../../../../../../dto/session';
import BackButtom from "../../../../../../components/atoms/back-buttom";
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form";
import SelectDropDownVm from "../../../../../../components/atoms/select-drop-down";
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle";
import ItemForm from "../../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../../components/molecules/item-form/style";
import { Error } from "../../../../../../components/organisms/form-address/style";
import FormTemplateElement from "../../../../../../templates/form-template";
import { ButtomSaveElement, DivInput } from "../../../../partner/benefit/form/style";
import { DropBoxDivContractorElement, RadioButtonDivElement, RadioButtonElement, RadioButtonTwoElement } from "./style";

const initialState: AdmUser = { userModule: { moduleEnum: ModuleEnum.EMPTY } };

const UserAdmForm: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isNew = !location.state;
    const { state } = location as any;
    const context = useAppContext();
    const [change, setChange] = useState(false);
    const [masterManager, setMasterManager] = useState(false);
    const session: Session = context.sessionProvider.getSession();

    const [user, setUser] = useState<AdmUser>({});
    const [disabled, setDisabled] = useState(false);

    const [contractorList, setContractorList] = useState([]);
    const [partnerList, setPartnerList] = useState([]);

    const { apiProvider } = useAppContext();
    const appContext = useAppContext();

    const getOneAdmUser = useCallback(async function () {
        const response: any = await apiProvider.get(`/admin/manager/${state.item.id}`);
        setUser(response.data);
        setMasterManager(state.item.masterManager);
    }, []);

    const getAllContractors = useCallback(async function () {
        const response: any = await apiProvider.get("/admin/contractor/");
        setContractorList(response.data);
    }, []);

    const getAllPartners = useCallback(async function () {
        const response: any = await apiProvider.get("/admin/partner");
        setPartnerList(response.data);
    }, []);

    const save = async (values: any) => {
        appContext.setIsShowLoading(true);

        const data = {
            id: values.id ? values.id : null,
            email: values.email,
            name: values.name,
            contractorId: values.contractor ? values.contractor.id : null,
            partnerId: values.partner ? values.partner.id : null,
            module: values.userModule.moduleEnum,
            masterManager: masterManager
        };

        try {

            if (isNew) {
                await apiProvider.post("/admin/manager", data);
            } else {
                await apiProvider.put("/admin/manager", data);
            }

            appContext.toast.show({
                severity: "success",
                summary: "Usuário",
                detail: "Salvo com sucesso!",
                life: 3000,
            });

            navigate("/userAdm-view");
        } catch (error: any) {
            const message = error.response.data.message;
            if (message === "This E-mail is already registered") {
                appContext.toast.show({
                    severity: "error",
                    summary: "E-mail",
                    detail: "Este e-mail já existe, tente outro!",
                    life: 3000,
                });
            } else {
                appContext.toast.show({
                    severity: "error",
                    summary: "Usuário",
                    detail: `Erro ao salvar usuário: ${message}`,
                    life: 3000,
                });
            }
        }

        appContext.setIsShowLoading(false)
    }

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled);
            getOneAdmUser();
        } else {
            setUser(initialState);
        }
    }, []);

    useEffect(() => {
        if (user?.userModule?.moduleEnum === ModuleEnum.CONTRACTOR) {
            getAllContractors();
        }

        if (user?.userModule?.moduleEnum === ModuleEnum.PARTNER) {
            getAllPartners();
        }
    }, [user]);

    const validations = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        partner: Yup.object()
            .nullable()
            .when(["userModule.moduleEnum"], (userModule, schema): any => {
                if (userModule === ModuleEnum.PARTNER) {
                    return schema.required();
                }
            }),
        contractor: Yup.object()
            .nullable()
            .when(["userModule.moduleEnum"], (userModule, schema): any => {
                if (userModule === ModuleEnum.CONTRACTOR) {
                    return schema.required();
                }
            }),
    });

    function _setMasterManager(value: boolean) {
        setMasterManager(value);
        setChange(true);
    }

    function showContent(rowData: any) {
        if (rowData.principal) {
            return false;
        } else {
            if (session.masterManager) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <FormTemplateElement>
            <BackButtom
                onclick={() => {
                    navigate("/userAdm-view");
                }}
            />

            <SpanSubtitle text="Dados - Gestor" />

            {(isLoadApi() || isNew) && (
                <Formik initialValues={user} validationSchema={validations} onSubmit={save}>
                    {(propsForm) => (
                        <Form style={{ width: '100%' }}>
                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                <ItemForm propsForm={propsForm} label="Nome" widthPercent="50" disabled={disabled} name="name" />
                                <ItemForm propsForm={propsForm} widthPercent="50" label="Email" disabled={(isNew) ? false : true} name="email" />
                                {showContent(propsForm) && (
                                    <DivInput style={{ width: '10%' }}>
                                        Gestor?
                                        <InputSwitch
                                            style={{ marginTop: "15px", marginLeft: "15px" }}
                                            checked={masterManager}
                                            onChange={(e: any) => _setMasterManager(e.value)}
                                            disabled={disabled}
                                        />
                                    </DivInput>
                                )}
                            </LineFormElement>

                            <div
                                style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                    display: "flex",
                                }}
                            >
                                <RadioButtonDivElement>
                                    <RadioButtonElement>
                                        <RadioButton style={{ marginRight: "10px" }} inputId="ADMIN" disabled={disabled} name="userModule.moduleEnum" value={ModuleEnum.ADMIN} checked={propsForm.values?.userModule && propsForm.values?.userModule?.moduleEnum === ModuleEnum.ADMIN} onChange={(e) => onChangeRadioButton(e, propsForm)} />
                                        Admin VM
                                    </RadioButtonElement>
                                </RadioButtonDivElement>
                            </div>

                            <RadioButtonTwoElement>
                                <RadioButtonElement>
                                    <RadioButton disabled={disabled} style={{ marginRight: "10px" }} inputId="PARTNER" name="userModule.moduleEnum" value={ModuleEnum.PARTNER} checked={propsForm.values?.userModule && propsForm.values?.userModule?.moduleEnum === ModuleEnum.PARTNER} onChange={(e) => onChangeRadioButton(e, propsForm)} />
                                    Parceiro
                                </RadioButtonElement>

                                <DropBoxDivContractorElement>
                                    {propsForm.values?.userModule?.moduleEnum === ModuleEnum.PARTNER && (
                                        <SelectDropDownVm
                                            style={{
                                                marginTop: "0",
                                                height: "45px",
                                                width: "230px",
                                            }}
                                            disabled={disabled}
                                            name="partner"
                                            value={propsForm.values.partner}
                                            options={partnerList}
                                            onChange={propsForm.handleChange}
                                            optionLabel="name"
                                        />
                                    )}
                                    <Error>{propsForm.errors.partner ? propsForm.errors.partner : <>&nbsp;</>}</Error>
                                </DropBoxDivContractorElement>
                            </RadioButtonTwoElement>

                            <RadioButtonTwoElement>
                                <RadioButtonElement>
                                    <RadioButton disabled={disabled} style={{ marginRight: "10px" }} inputId="CONTRACTOR" name="userModule.moduleEnum" value={ModuleEnum.CONTRACTOR} checked={propsForm.values?.userModule && propsForm.values?.userModule?.moduleEnum === ModuleEnum.CONTRACTOR} onChange={(e) => onChangeRadioButton(e, propsForm)} />
                                    Contratante
                                </RadioButtonElement>

                                <DropBoxDivContractorElement>
                                    {propsForm.values?.userModule?.moduleEnum === ModuleEnum.CONTRACTOR && (
                                        <SelectDropDownVm
                                            style={{
                                                marginTop: "0",
                                                height: "45px",
                                                width: "230px",
                                            }}
                                            disabled={disabled}
                                            name="contractor"
                                            value={propsForm.values.contractor}
                                            options={contractorList}
                                            onChange={propsForm.handleChange}
                                            optionLabel="name"
                                        />
                                    )}
                                    <Error>{propsForm.errors.contractor ? propsForm.errors.contractor : <>&nbsp;</>}</Error>
                                </DropBoxDivContractorElement>
                            </RadioButtonTwoElement>

                            <div style={{ textAlign: 'end' }}>
                                {!disabled && (
                                    <ButtomSaveElement>
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={(!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty) && !change}>
                                            Salvar dados
                                        </ButtomRequestElement>
                                    </ButtomSaveElement>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </FormTemplateElement>
    );

    function onChangeRadioButton(e: RadioButtonChangeParams, propsForm: FormikProps<AdmUser>) {
        setUser((value) => ({
            ...value,
            userModule: { ...value.userModule, moduleEnum: e.value },
        }));
        if (propsForm.values?.userModule?.moduleEnum === ModuleEnum.PARTNER) {
            propsForm.values.contractor = undefined;
        } else if (propsForm.values?.userModule?.moduleEnum === ModuleEnum.CONTRACTOR) {
            propsForm.values.partner = undefined;
        } else if (propsForm.values?.userModule?.moduleEnum === ModuleEnum.ADMIN) {
            propsForm.values.contractor = undefined;
            propsForm.values.partner = undefined;
        }
        propsForm.handleChange(e);
    }

    function isLoadApi(): boolean {
        return !!user.id;
    }

};

export default UserAdmForm;
