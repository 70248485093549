export enum ModuleEnum {
  B2C = "B2C",
  B2B = "B2B",
  CONTRACTOR = "CONTRACTOR",
  PARTNER = "PARTNER",
  ADMIN = "ADMIN",
  APP = "APP",
  EMPTY = "",
  CPF = "CPF",
  CNPJ = "CNPJ",
}
