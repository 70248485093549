import styled from "styled-components";

export const BackButtomElement = styled.button`
  height: 45px;
  border: none;
  text-align: center;
  width: 150px;
  color: white;
  border-radius: 17px;
  background-color: #0172cb;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
`;
