import { Form, Formik } from "formik"
import { FileUpload } from "primereact/fileupload"
import { useCallback, useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../../context/app"
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form"
import Error from "../../../../../../components/atoms/error-message"
import PageHeader from "../../../../../../components/atoms/page-header"
import SelectDropDownVm from "../../../../../../components/atoms/select-drop-down"
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle"
import FormTemplateElement from "../../../../../../templates/form-template"
import { SpanHelp } from "./style"
import { useNavigate } from "react-router";
import BackButtom from "../../../../../../components/atoms/back-buttom";
import { Contractor } from "../../../../../../../dto/contractor";
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { Dropdown } from "primereact/dropdown";

const RegisterPaymentForm: React.FC = () => {
    const navigate = useNavigate()
    const [contractorList, setContractorList] = useState([])
    const fileUpload = useRef(null as any);
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()
    const { state } = location as any
    const [selectedContractorsB2BOrB2C, setSelectedContractorsB2BOrB2C] = useState<Contractor>();
    const [groupedContractors, setGroupedContractors] = useState([] as any[]);

    useEffect(() => {
        getContractors()
        getAllContractors()
    }, [])

    // Validações do yup
    const validations = Yup.object().shape({
        contractor: Yup.object().required(),
        imageUrl: Yup.string().required(),
    })

    // Salvar Cadastros
    const save = async (values: any, obj: any) => {
        const formData = new FormData()
        formData.append("partnerId", values.partner.id)
        formData.append("file", values.file)
        try {
            await apiProvider.post("/admin/bulk-productor-registration", formData)
            appContext.toast.show({ severity: 'success', summary: 'Parceiro', detail: 'Salvo com sucesso!', life: 3000 })

            obj.resetForm({})
            fileUpload.current.clear();
        } catch (error: any) {
            let title = 'Importação de produtos.'
            let mensagem = 'Erro ao importar novas produtos, entre em contato com o administrador!'
            if (error.response.status === 400 && error.response.data.message) {
                mensagem = error.response.data.message
            }

            appContext.toast.show({ severity: "error", summary: title, detail: mensagem, life: 5000 })
            console.error(error)
        }
    }

    const getContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/contractor')
        setContractorList(response.data)
    }, [])

    // Importação com B2B e B2C com delimitação por módulo
    const getAllContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/partner')

        if (response?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            response.data.forEach((item: Contractor) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({ label: item.name, value: item });
                    if (state?.item?.contractorCnpj == item.cnpj) {
                        setSelectedContractorsB2BOrB2C(item);
                    }
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({ label: item.name, value: item });
                    if (state?.item?.contractorCnpj == item.cnpj) {
                        setSelectedContractorsB2BOrB2C(item);
                    }
                }
            });

            let objectB2cAndB2B = [];

            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2C', items: aB2C })
            }

            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2B', items: aB2B })
            }

            setGroupedContractors(objectB2cAndB2B)
            setContractorList(response.data)
        }
    }, [])

    return (
        <>
            <PageHeader text="Importação de arquivos OFX para processamento de pagamentos." />

            <FormTemplateElement>

                <div style={{ width: "100%", display: "flex" }}>
                    <BackButtom
                        onclick={() => {
                            navigate("/imports-view")
                        }}
                    />
                </div>

                <Formik
                    initialValues={{} as any}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <>
                            <SpanSubtitle text="Como fazer a importação do arquivo OFX?" />
                            <SpanHelp>
                                Selecione o contratante, envie o arquivo OFX para nosso sistema, e clique no botão "Importar
                                pagamentos".
                            </SpanHelp>

                            <Form>
                                <div>
                                    Contratante
                                    <Dropdown
                                        name='contractor'
                                        style={{ width: '100%', height: '50px', borderRadius: '10px' }}
                                        value={selectedContractorsB2BOrB2C}
                                        onChange={(e) => setSelectedContractorsB2BOrB2C(e.value)}
                                        options={groupedContractors}
                                        optionLabel="label"
                                        optionGroupLabel="label"
                                        optionGroupChildren="items"
                                        emptyMessage="Nenhum resultado encontrado."
                                        emptyFilterMessage="Nenhum resultado encontrado."
                                        placeholder="Selecione o contratante"
                                        showFilterClear
                                        filter
                                    />
                                    <Error name="contractor" errors={propsForm.errors} />
                                </div>

                                <div>
                                    Arquivo OFX
                                    <FileUpload
                                        ref={fileUpload}
                                        style={{ marginTop: "5px" }}
                                        name="invoice"
                                        customUpload
                                        uploadHandler={(event: any) => {
                                            propsForm.setFieldValue("file", event.files[0])
                                            propsForm.setFieldValue('imageUrl', event.files[0])
                                        }}
                                        onRemove={(event) => {
                                            propsForm.setFieldValue("file", undefined)
                                            propsForm.setFieldValue('imageUrl', undefined)
                                        }}
                                        auto
                                        chooseLabel="Anexar arquivo"
                                    />
                                    <Error name="imageUrl" errors={propsForm.errors} />
                                </div>

                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        style={{ marginTop: "2em" }}
                                        type="submit"
                                        disabled={!propsForm.values.partner || !propsForm.values.file}>
                                        Importar arquivo OFX
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        </>
                    )}
                </Formik>
            </FormTemplateElement>
        </>
    )
}

export default RegisterPaymentForm
