import styled from "styled-components";

export const TableViewElement = styled.div`
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;
  /* display: flex; */
  justify-content: center;
  margin-top: 20px;
`;
