import {FilterMatchMode} from "primereact/api";
import {Column} from "primereact/column";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import plusSign from "../../../../../../../assets/buttons/plus-sign.svg";
import trashIcon from "../../../../../../../assets/buttons/trash.svg";
import {useAppContext} from "../../../../../../../context/app";
import {confirm} from "../../../../../../../utils/validation";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import {InputSwitch} from "primereact/inputswitch";
import {
    ActionButtonsElement,
    ButtomsElement,
    ContractorButtomElement,
    HeaderElementLeft,
    HeaderElementRight,
    TableElement
} from "./style";
import {ModuleEnumStatus} from "../../../../../../../dto/moduleEnum/module-enum-status";
import {TruncateData} from "../../../../../../../utils/truncateData";

const taxView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({ taxTypeState: [] as any, isLoading: true })
    const [filterText, setFilterText] = useState("")
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const { taxTypeState: taxTypeList } = state;

    useEffect(() => {
        getTaxData();
    }, []);

    // DELETE CONTRACT
    async function deleteContracts(rowData: any) {
        try {
            await apiProvider.delete(`/admin/taxtype/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Contrato",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getTaxData();
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Contrato",
                detail: error.response.data.message || "Erro ao excluir o contrato!",
                life: 3000,
            });
        }
    }

    const getTaxData = useCallback(
        async function () {
            const response: any = await apiProvider.get("/admin/taxtype")
            setState({ taxTypeState: response.data, isLoading: false });
        }, []
    )
    const handleToggle = async (e: any) => {
        try {
            const data = {
                id: e.id ? e.id : null,
                isActive: (e.isActive == null) ? ModuleEnumStatus.INACTIVE : (e.isActive) ? ModuleEnumStatus.INACTIVE :ModuleEnumStatus.ACTIVE
            }

            const response: any = await apiProvider.put(`/admin/taxtype/${data.id}`, data)
            if (response == null) {
                throw new Error("Não foi possível realizar essa alteração, tente novamente!");
            }

            appContext.toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Alteração realizada com sucesso!',
                life: 3000,
            })

            setState({
                taxTypeState: taxTypeList.map((item:any) => {
                    if (item.id === e.id) {
                        return { ...item, isActive: !e.isActive }
                    }
                    return item
                }), isLoading: false
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        }
    };

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Tributos"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>

                    <ContractorButtomElement onClick={() => { navigate("/tributes-form"); }}>
                        <img alt="" style={{ marginRight: "10px" }} src={plusSign} /> Novo tributo
                    </ContractorButtomElement>

                </HeaderElementLeft>

                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    paginator
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.taxTypeState}
                    responsiveLayout="scroll"
                    itemsList={taxTypeList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="name" body={(e) => TruncateData(e.name, 20)} header="Tributo" sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" field="description" body={(e) => TruncateData(e.description, 20)} header="Descrição" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="percentage" body={(e) => `${(e.percentage * 100).toFixed(2)}%`} header="Valor percentual" sortable style={{ width: '20%' }} />
                    <Column alignHeader='center' align='center' body={(e) => <InputSwitch checked={e.isActive} onChange={() => handleToggle(e)} />} field='isActive' header='Ativo' sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "10%" }} />
                </DataTableElement>
            </TableElement>
        </>
    );

    // RENDERIZAÇÃO DA COLUNA AÇÕES DA TABELA
    function actionButtons(rowData: any) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <ButtomsElement>
                    <ActionButtonsElement
                        onClick={() =>
                            confirm(rowData, "Deseja excluir este contrato?", () =>
                                deleteContracts(rowData)
                            )
                        }
                    >
                        <img alt="" style={{ width: "17px" }} src={trashIcon} />{" "}
                    </ActionButtonsElement>

                    <ActionButtonsElement
                        onClick={() => {
                            navigate("/tributes-form", {
                                state: { item: rowData, disabled: false },
                            });
                        }}
                    >
                        <img alt="" style={{ width: "17px" }} src={penIcon} />
                    </ActionButtonsElement>
                </ButtomsElement>
            </div>
        );
    }
};

export default taxView;
