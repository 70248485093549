import {Column} from 'primereact/column';
import {useState} from "react";
import {useAppContext} from '../../../../../../context/app';
import DataTableElement from '../../../../../components/atoms/data-table-view';
import PageHeader from '../../../../../components/atoms/page-header';
import HeaderReportFilter from '../../../../../components/organisms/header-report-filter';
import {ModuleEnumReport} from "../../../../../../dto/moduleEnum/module-enum-report";
import {ModuleEnum} from "../../../../../../dto/moduleEnum/module-enum";
import {Row} from "primereact/row";
import {ColumnGroup} from "primereact/columngroup";

const GroupedByContractors: React.FC = () => {
    const [results, setResults] = useState([]);
    const [total, setTotal] = useState([] as any);
    const [isLoading, setIsLoading] = useState(false);
    const appContext = useAppContext()
    const { apiProvider } = appContext

    const findReport = async (param: any) => {
        setIsLoading(true)
        const response: any = await apiProvider.post('/web/partner/report/grouped-by-contractors', param)
        if (response) {
            let dataGrid: any = [];

            // Total visualizations
            response.data.map((item: any) => {
                if (item.id == null) {
                    setTotal(item)
                } else {
                    dataGrid.push(item)
                }
            })

            setResults(dataGrid)
        }
        setIsLoading(false)
    }

    const exportReport = async (param: any) => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.downloadPost('/web/partner/report/grouped-by-contractors/export', param)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type})
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Relatórios agrupado por contratantes.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="TOTAL:" footerStyle={{textAlign: 'center'}}/>
                <Column footer={`${(total.salesAmount == null)? 0: total.salesAmount}`} footerStyle={{ textAlign: 'center' }}/>
                <Column footer={`R$ ${(total.totalSalesValue == null)? 0: total.totalSalesValue}`} footerStyle={{ textAlign: 'center' }}/>
            </Row>
        </ColumnGroup>
    );

    return <>
        <PageHeader text="Transações" subtext="Agrupadas por Contratante"/>

        <HeaderReportFilter labelText="Contratante" exportReport={exportReport} findReport={findReport} typeReport={ModuleEnumReport.CONTRACTOR_B2C} typeModuleName={ModuleEnum.B2C} textPlaceholder="Selecione o contratante"/>

        <div style={{ paddingTop: "2em" }}>
            <DataTableElement
                loading={isLoading}
                paginator
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                rows={10}
                value={results}
                responsiveLayout="scroll"
                emptyMessage="Nenhum resultado encontrado"
                itemsList={results}
                footerColumnGroup={footerGroup}
            >
                <Column header="Contratante" alignHeader="center" align="center" field="name" sortable style={{ width: '34%' }}/>
                <Column header="Vendas" alignHeader="center" align="center" field="salesAmount" sortable style={{ width: '33%' }}/>
                <Column header="Valor" alignHeader="center" align="center" field="totalSalesValue" sortable style={{ width: '33%' }}/>
            </DataTableElement>
        </div>
    </>
}

export default GroupedByContractors