import styled from "styled-components";

export const FormTemplateElement = styled.div`
  // width: 100%;
  padding: 25px;
  border-radius: 25px;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 10px;
`;
