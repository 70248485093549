import { Form, Formik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { useAppContext } from "../../../../../../../context/app";
import BackButtom from "../../../../../../components/atoms/back-buttom";
import ButtomRequestElement from "../../../../../../components/atoms/buttom-request-form";
import Error from "../../../../../../components/atoms/error-message";
import SelectDropDownVm from "../../../../../../components/atoms/select-drop-down";
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle";
import ItemForm from "../../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../../components/molecules/item-form/style";
import FormTemplateElement from "../../../../../../templates/form-template";
import { CategoryDivElement, InputTextareaDivElement } from "../../../../contractor/informative/form/style";
import { ButtomSaveElement } from "../../../../partner/benefit/form/style";

const MessagesForm: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location as any;
    const [message, setMessage] = useState({} as any);
    const [disabled, setDisabled] = useState(false);
    const appContext = useAppContext();
    const { apiProvider } = appContext;

    useEffect(() => {
        getOneMessage();
        setDisabled(state.disabled);
    }, []);

    const status = [
        {
            value: "OPEN",
            label: "Aberto",
        },
        {
            value: "PENDING",
            label: "Pendente",
        },
        {
            value: "FINISHED",
            label: "Finalizado",
        },
    ]

    const getOneMessage = useCallback(async () => {
        const response: any = await apiProvider.get(`/admin/contact-us/${state.item.id}`)
        setMessage(response.data)
    }, [])

    const validations = Yup.object().shape({
        status: Yup.string().required(),
        observation: Yup.string().max(2000).nullable(true),
    });

    async function save(values: any) {
        const data = {
            id: values.id ? values.id : null,
            message: values.message,
            observation: values.observation,
            status: values.status,
            subject: {
                id: values.id,
                text: values.text
            }
        }

        appContext.setIsShowLoading(true)
        try {
            await apiProvider.put("/admin/contact-us", data);
            appContext.toast.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Informações salvas com sucesso!",
                life: 3000,
            });
            navigate("/messages");
        } catch (error: any) {
            console.error(error.response.data)
            appContext.toast.show({
                severity: "error",
                summary: "Eroo",
                detail: "Erro ao salvar informações, tente novamente!",
                life: 3000,
            });
        }
        appContext.setIsShowLoading(false)
    }

    function isLoadApi(): boolean {
        return !!message.id;
    }

    return (
        <FormTemplateElement>
            <BackButtom
                onclick={() => {
                    navigate("/messages");
                }}
            />

            <SpanSubtitle text="Mensagem" />

            {(isLoadApi()) && (
                <Formik
                    initialValues={message}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <Form>
                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                <ItemForm
                                    propsForm={propsForm}
                                    label="Módulo:"
                                    disabled={true}
                                    name="moduleName"
                                />
                                <CategoryDivElement>
                                    Status
                                    <SelectDropDownVm
                                        style={{ width: "60%", height: "20%" }}
                                        disabled={disabled}
                                        name="status"
                                        onChange={propsForm.handleChange}
                                        value={propsForm.values.status}
                                        options={status}
                                        optionLabel="label"
                                    />
                                    <Error name="status" errors={propsForm.errors} />
                                </CategoryDivElement>
                            </LineFormElement>

                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                <ItemForm
                                    appContext={appContext}
                                    copyText={true}
                                    propsForm={propsForm}
                                    label="Enviado por:"
                                    disabled={true}
                                    name="sender.name"
                                />
                                <ItemForm
                                    appContext={appContext}
                                    copyText={true}
                                    propsForm={propsForm}
                                    label="E-mail:"
                                    disabled={true}
                                    name="email"
                                />
                                <ItemForm
                                    appContext={appContext}
                                    copyText={true}
                                    propsForm={propsForm}
                                    label="Vinculada á:"
                                    disabled={true}
                                    name="company"
                                />
                            </LineFormElement>

                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                <InputTextareaDivElement style={{ width: "100%" }}>
                                    Mensagem
                                    <InputTextarea
                                        style={{ marginTop: "5px" }}
                                        disabled={true}
                                        value={propsForm.values.message}
                                        name="observation"
                                        autoResize
                                        onChange={propsForm.handleChange}
                                    />
                                </InputTextareaDivElement>
                            </LineFormElement>

                            <LineFormElement>
                                <InputTextareaDivElement style={{ width: "100%" }}>
                                    Observação
                                    <InputTextarea
                                        style={{ marginTop: "5px" }}
                                        disabled={disabled}
                                        value={propsForm.values.observation}
                                        name="observation"
                                        autoResize
                                        onChange={propsForm.handleChange}
                                    />
                                    <Error name="observation" errors={propsForm.errors} />
                                </InputTextareaDivElement>
                            </LineFormElement>

                            <div style={{ textAlign: 'end' }}>
                                {!disabled && (
                                    <ButtomSaveElement>
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={!propsForm.isValid || propsForm.isSubmitting}
                                        >
                                            Salvar dados
                                        </ButtomRequestElement>
                                    </ButtomSaveElement>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </FormTemplateElement>
    );
}

export default MessagesForm
