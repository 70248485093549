import styled from "styled-components";

export const ActionButtonsElement = styled.button`
  background-color: #f7f9fc;
  width: 50px;
  height: 50px;
  border-radius: 13px;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  align-items: center;
  border: none;
`;
