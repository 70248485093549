import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import penIcon from "../../../../../../assets/buttons/pen.svg";
import plusSign from "../../../../../../assets/buttons/plus-sign.svg";
import trashIcon from "../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../context/app";
import { ModuleEnumStatus } from "../../../../../../dto/moduleEnum/module-enum-status";
import { dateToStrg } from "../../../../../../utils/formatUtil";
import { confirm } from "../../../../../../utils/validation";
import DataTableElement from "../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../components/molecules/search-text-label";
import {
    HeaderElementLeft,
    HeaderElementRight
} from "../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../templates/view-header-template";
import TableViewElement from "../../../../../templates/view-table-template";
import { ActionButtons, BenefitButtomElement } from "./style";
import { TruncateData } from "../../../../../../utils/truncateData";

const BenefitView: React.FC = () => {
    const navigate = useNavigate();

    const { apiProvider } = useAppContext();

    const [filterText, setFilterText] = useState("");
    const [state, setState] = useState({ benefitList: [] as any, isLoading: true });
    const { benefitList } = state;
    const appContext = useAppContext();

    const getAllBenefits = useCallback(async () => {
        const response: any = await apiProvider.get("/web/benefit");
        setState({ benefitList: response.data, isLoading: false });
    }, [])

    const data = (rowData: any) => {
        return (
            <span>
                {dateToStrg(rowData.startingDate)}
            </span>
        )
    }

    useEffect(() => {
        getAllBenefits();
    }, []);

    const handleToggle = async (e: any) => {
        try {
            const data = {
                id: e.id ? e.id : null,
                isActive: (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
            }

            const response: any = await apiProvider.put('/web/benefit', data)
            if (response == null) {
                throw new Error("Não foi possível realizar essa alteração, tente novamente!");
            }

            appContext.toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Alteração realizada com sucesso!',
                life: 3000,
            })

            setState({
                benefitList: benefitList.map((item: any) => {
                    if (item.id === e.id) {
                        return {
                            ...item,
                            isActive: (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
                        }
                    }
                    return item
                }), isLoading: false
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        }
    };

    return (
        <>
            <PageHeader text="Benefícios" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <BenefitButtomElement onClick={() => {
                        navigate("/benefit-form");
                    }}>
                        <img style={{ marginRight: "10px" }} alt="" src={plusSign} /> Novo benefício
                    </BenefitButtomElement>
                </HeaderElementLeft>

                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    loading={state.isLoading}
                    paginator
                    rows={10}
                    value={state.benefitList}
                    responsiveLayout="scroll"
                    itemsList={benefitList}
                    emptyMessage="Nenhum resultado encontrado"
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column
                        alignHeader="center"
                        align="center"
                        field="title"
                        body={(e) => TruncateData(e.title, 30)}
                        header="Benefício"
                        sortable
                        style={{ width: '40%' }}
                    />
                    <Column
                        alignHeader="center"
                        align="center"
                        body={benefitValue}
                        header="Desconto"
                        sortable
                        style={{ width: '10%' }}
                    />
                    <Column
                        alignHeader='center'
                        align='center'
                        body={(e) =>
                            <InputSwitch
                                checked={(e.isActive == ModuleEnumStatus.ACTIVE) ? true : false}
                                onChange={() => handleToggle(e)}
                            />
                        }
                        field='isActive'
                        header='Ativo'
                        sortable
                        style={{ width: '20%' }} />
                    <Column
                        alignHeader="center"
                        align="center"
                        field="startingDate"
                        header="Data de inicio"
                        body={data}
                        sortable
                        style={{ width: '20%' }}
                    />
                    <Column
                        alignHeader="center"
                        align="center"
                        field="actions"
                        body={actionButtons}
                        header="Ações"
                        style={{ width: '10%' }}
                    />
                </DataTableElement>
            </TableViewElement>
        </>
    );

    function benefitValue(rowData: any) {
        return <span>{rowData.benefitValue}%</span>;
    }

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <ActionButtons
                    onClick={() =>
                        confirm(rowData, "Deseja excluir este benefício?", () =>
                            deleteBenefit(rowData)
                        )
                    }
                >
                    {" "}
                    <img style={{ width: "17px" }} alt="" src={trashIcon} />{" "}
                </ActionButtons>
                <ActionButtons
                    onClick={() => {
                        navigate("/benefit-form", {
                            state: { item: rowData, disabled: false },
                        });
                    }}
                >
                    {" "}
                    <img style={{ width: "17px" }} alt="" src={penIcon} />
                </ActionButtons>
            </div>
        );
    }

    async function deleteBenefit(rowData: any) {
        try {
            await apiProvider.delete(`/web/benefit/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Benefício",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getAllBenefits();
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Benefício",
                detail: error.response.data || "Erro ao excluir benefício!",
                life: 3000,
            });
        }
    }
};

export default BenefitView;
