import { Rating } from 'primereact/rating';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import activeAssociates from '../../../../assets/buttons/activeAssociates.png';
import arrowDown from '../../../../assets/buttons/arrow-down.png';
import logOUt from '../../../../assets/buttons/log-out.png';
import menuButton from '../../../../assets/buttons/menu.svg';
import userConfig from '../../../../assets/buttons/userConfig.png';
import guyWithStar from '../../../../assets/guyWithStar.png';
import guyWitHeart from '../../../../assets/handWithHeart.png';
import handsWithTwoDots from '../../../../assets/handsWithTwoDots.png';
import whiteGuyUser from '../../../../assets/whiteGuyUser.svg';
import { useAppContext } from '../../../../context/app';
import { ISessionContext, initialState, useSessionContext } from '../../../../context/session';
import { ModuleEnum } from '../../../../dto/moduleEnum/module-enum';
import { parseDoubleToStr } from '../../../../utils/formatUtil';
import {
    ButtonMenuElement,
    HeaderDiv,
    HeaderElement,
    HeaderElementLeft,
    HeaderElementRight,
    HideProgressBarElement,
    ImgLogoElementLeft,
    ItensInsideDivItensElement,
    MenuButtonsDivElement,
    MenuItensElement,
    MenuProfileElement,
    ProgressBarElement,
    SessionUserNameDivElement,
    SideBarElement,
    SideBarMainDivElement,
    UserImageElement
} from './style';

interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
    const navigate = useNavigate()
    let context: ISessionContext = useSessionContext()
    const appContext = useAppContext();
    const sessionProvider = appContext.sessionProvider;
    const session: any = sessionProvider.getSession()
    const [visible, setVisible] = useState(false)
    const { apiProvider } = appContext
    const [activeContractoesUsersAmount, setActiveUsersAmount] = useState(0)
    const [featuredBenefit, setFeaturedBenefit] = useState('-')
    const [usersAmountActiveInApp, setUsersAmountActiveInApp] = useState(0)
    const [userRating, setUserRating] = useState(0)
    const [userEngajement, setUserEngajement] = useState(0)
    const [isShowLoading, setIsShowLoading] = useState(false)

    appContext.setIsShowLoading = setIsShowLoading;

    const getAllActiveContractorsUsers = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/contractor/actived-associates')
        setActiveUsersAmount(response.data.activedAssociates || 0)
    }, [])

    const getFeaturedBenefit = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/partner/featured-benefit')
        setFeaturedBenefit(response.data ? response.data.benefitValue + '% ' + response.data.benefitTitle : '0')
    }, [])

    const getUsersAmountActiveApp = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/partner/amount-users')
        setUsersAmountActiveInApp(response.data.amountUsers || 0)
    }, [])

    const getUserRating = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/partner/rating')
        setUserRating(response.data.rating || 0)
    }, [])

    const getUserEngajement = useCallback(async () => {
        const response: any = await apiProvider.get('/web/menu/partner/engagement')
        setUserEngajement(+response.data.amount || 0)
    }, [])

    useEffect(() => {
        if (context.session.modules[0].moduleEnum === 'CONTRACTOR') {
            getAllActiveContractorsUsers()
        }
        if (context.session.modules[0].moduleEnum === 'PARTNER') {
            getFeaturedBenefit()
            getUsersAmountActiveApp()
            getUserEngajement()
            getUserRating()
        }
    }, [visible])

    return (
        <HeaderDiv>
            <HeaderElement>
                <HeaderElementLeft>
                    <ButtonMenuElement onClick={() => context.toggleMenu()}>
                        <img alt='' src={menuButton} />
                    </ButtonMenuElement>
                    <ImgLogoElementLeft
                        alt=''
                        src={context.session?.modules[0]?.imageUrl}
                    />
                    {context.session?.modules[0].moduleEnum === 'PARTNER' && context.session?.modules[0].partner?.name}
                    {context.session?.modules[0].moduleEnum === 'CONTRACTOR' && context.session?.modules[0].contractor?.name}
                    {context.session?.modules[0].moduleEnum === 'ADMIN' && 'ADM'}
                </HeaderElementLeft>

                <HeaderElementRight>
                    <UserImageElement>
                        <img alt='' style={{ width: '1.2em', height: '1.2em' }} src={whiteGuyUser}></img>
                    </UserImageElement>

                    <MenuProfileElement>
                        {context.session.user.name}

                        {spanModule()}
                    </MenuProfileElement>
                </HeaderElementRight>

                <SideBarElement onClick={() => setVisible(true)}>
                    <img src={arrowDown} alt='' />
                </SideBarElement>

                <Sidebar
                    position='right'
                    visible={visible}
                    onHide={() => setVisible(false)}>

                    <SideBarMainDivElement>
                        <UserImageElement style={{ width: '30%', height: '5.2em' }}>
                            <img alt='' style={{ width: '1.5em' }} src={whiteGuyUser}></img>
                        </UserImageElement>


                        <SessionUserNameDivElement>
                            <span style={{ fontSize: '1em' }}>{context.session.user.name}</span>
                        </SessionUserNameDivElement>

                        <span style={{ color: '#9898B2', fontSize: '1em' }}>
                            {context.session.user.email}
                        </span>

                        <div style={{ display: 'flex' }}>
                            <MenuButtonsDivElement style={{ marginRight: '0.6em' }} onClick={() => logOut()}>
                                <img
                                    style={{ height: '23px', marginTop: '13px', marginLeft: '16px' }}
                                    alt=''
                                    src={logOUt}
                                />
                            </MenuButtonsDivElement>

                            <MenuButtonsDivElement onClick={() => navigateToUserProfile()}>
                                <img style={{ height: '23px', marginTop: '13px', marginLeft: '16px' }}
                                    alt=''
                                    src={userConfig}>
                                </img>
                            </MenuButtonsDivElement>
                        </div>

                        {useSessionContext().session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR &&
                            <MenuItensElement>
                                <img style={{ height: '1.6em', marginTop: '13px', marginLeft: '16px' }}
                                    src={activeAssociates} />
                                <ItensInsideDivItensElement>
                                    <span style={{ color: '#42425D', fontSize: '0.9em', fontWeight: '500 medium' }}>Usuários ativos</span>
                                    <span style={{
                                        color: '#42425D',
                                        font: 'Poppins',
                                        fontWeight: '600',
                                        fontSize: '1em'
                                    }}>{activeContractoesUsersAmount}</span>
                                </ItensInsideDivItensElement>
                            </MenuItensElement>
                        }

                        {useSessionContext().session.modules[0].moduleEnum === ModuleEnum.PARTNER &&
                            <>
                                {
                                    session && session.modules[0].partner.moduleName == 'B2C' ?
                                        <MenuItensElement>
                                            <img style={{ height: '1.6em', marginTop: '13px', marginLeft: '16px' }}
                                                src={handsWithTwoDots} />
                                            <ItensInsideDivItensElement>
                                                <span style={{ color: '#42425D', fontSize: '0.9em', fontWeight: '500' }}>Benefício em destaque</span>
                                                <span style={{
                                                    color: '#42425D',
                                                    font: 'Poppins',
                                                    fontWeight: '600',
                                                    fontSize: '1em'
                                                }}>{featuredBenefit}</span>
                                            </ItensInsideDivItensElement>
                                        </MenuItensElement>
                                        :
                                        ""
                                }

                                {
                                    session && session.modules[0].partner.moduleName == 'B2C' ?
                                        <MenuItensElement>
                                            <img style={{ height: '1.6em', marginTop: '13px', marginLeft: '21px' }}
                                                src={guyWithStar} />
                                            <ItensInsideDivItensElement>
                                                <span style={{ color: '#42425D', fontSize: '0.9em', fontWeight: '500 medium' }}>Classificação atual</span>
                                                <span style={{
                                                    color: '#42425D',
                                                    font: 'Poppins',
                                                    fontWeight: '600',
                                                    fontSize: '1em'
                                                }}>
                                                    <Rating style={{ color: 'red' }} value={userRating} readOnly stars={5} cancel={false} />
                                                </span>
                                            </ItensInsideDivItensElement>
                                        </MenuItensElement>
                                        :
                                        ''
                                }

                                {
                                    session && session.modules[0].partner.moduleName == 'B2C' ?
                                        <MenuItensElement>
                                            <img style={{ height: '1.6em', marginTop: '13px', marginLeft: '16px' }}
                                                src={guyWitHeart} />
                                            <ItensInsideDivItensElement>
                                                <span style={{
                                                    color: '#42425D',
                                                    fontSize: '0.9em',
                                                    fontWeight: '500 medium'
                                                }}>Engajamento</span>
                                                <span style={{
                                                    color: '#42425D',
                                                    font: 'Poppins',
                                                    fontWeight: '600',
                                                    fontSize: '1em'
                                                }}>{parseDoubleToStr(userEngajement)}%</span>
                                            </ItensInsideDivItensElement>
                                        </MenuItensElement>
                                        :
                                        ""}

                                {
                                    session && session.modules[0].partner.moduleName == 'B2C' ?
                                        <MenuItensElement>
                                            <img style={{ height: '1.6em', marginTop: '13px', marginLeft: '21px' }}
                                                src={activeAssociates} />

                                            <ItensInsideDivItensElement>
                                                <span style={{ color: '#42425D', fontSize: '0.9em', fontWeight: '500 medium' }}>Usuários ativos no APP</span>
                                                <span style={{
                                                    color: '#42425D',
                                                    font: 'Poppins',
                                                    fontWeight: '600',
                                                    fontSize: '1em'
                                                }}>{usersAmountActiveInApp}</span>
                                            </ItensInsideDivItensElement>
                                        </MenuItensElement>
                                        :
                                        ""
                                }
                            </>
                        }

                    </SideBarMainDivElement>
                </Sidebar>
            </HeaderElement>

            <ProgressBarElement isShow={isShowLoading} mode="indeterminate" style={{ height: '4px' }} />
            <HideProgressBarElement isShow={isShowLoading} style={{ height: '4px' }} />
        </HeaderDiv>
    )

    function spanModule() {
        return (
            <span style={{ color: '#9898B2', fontSize: visible ? '18px' : '15px' }}>
                {context.session.modules[0].moduleEnum === 'PARTNER' && 'Parceiro'}
                {context.session.modules[0].moduleEnum === 'CONTRACTOR' && 'Contratante'}
                {context.session.modules[0].moduleEnum === 'ADMIN' && 'Administrador'}
            </span>
        )
    }

    function logOut() {
        if (session) {
            context = initialState

            const moduleEnum = session.modules[0].moduleEnum

            if (moduleEnum === 'PARTNER') {
                navigate('/parceiro')
            } else if (moduleEnum === 'CONTRACTOR') {
                navigate('/contratante')
            } else {
                navigate('/')
            }

            sessionProvider.deleteSession().then();
            document.location.reload()
        }
    }

    function navigateToUserProfile() {
        navigate('/user-profile')
        setVisible(false)
    }

}

export default Header
