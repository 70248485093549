import { Form, Formik } from "formik"
import { InputMask } from "primereact/inputmask"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../context/app"
import { Associate } from "../../../../../../dto/associte"
import { BenefitsDto } from "../../../../../../dto/benefits"
import { Transaction } from "../../../../../../dto/transaction"
import BackButtom from "../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../components/atoms/buttom-request-form"
import InputTextVm from "../../../../../components/atoms/input-text"
import SelectDropDownVm from "../../../../../components/atoms/select-drop-down"
import SpanSubtitle from "../../../../../components/atoms/span-subtitle"
import { Error } from "../../../../../components/organisms/form-address/style"
import FormTemplateElement from "../../../../../templates/form-template"
import { DivIn } from "../../../admin/forms/comission/form/style"
import { ButtomSaveElement, FormElementItems } from "../../benefit/form/style"
import { InputNumber } from 'primereact/inputnumber';
import { LabelElement } from "./style"
import {parseDoubleToStr} from "../../../../../../utils/formatUtil";

const TransactionForm: React.FC = () => {
	const appContext = useAppContext()
	const { apiProvider } = appContext
	const navigate = useNavigate()
	const location = useLocation()
	const { state } = location as any
	const isNew = !state
	const [benefitList, setBenefitList] = useState([] as any[])
	const [benefit, setBenefit] = useState({} as BenefitsDto)
	const [transaction, setTransaction] = useState({} as Transaction)
	const [associate, setAssociate] = useState({} as Associate)
	const [amountPaid, setAmountPaid] = useState(0)

	const validations = Yup.object().shape({
		benefit: Yup.object().required(),
		associateCpf: Yup.string().required(),
		product: Yup.object().required(),
		store: Yup.object().required(),
		totalAmount: Yup.number().positive('Valor total é obrigatório').required('Valor total é obrigatório'),
	})

	const calcAmountPaid = (benefitValue: number, totalAmount: number) => {
		if (!totalAmount) {
			setAmountPaid(0)
		} else if (!benefitValue && totalAmount) {
			setAmountPaid(totalAmount)
		} else {
			const desconto = benefitValue / 100
			const total = totalAmount - (totalAmount * desconto)
			setAmountPaid(parseFloat(total.toFixed(2)))
		}
	}

	const getAssociate = useCallback(async (value: any) => {
		if (!value) {
			return
		}

		try {
			const response: any = await apiProvider.get(`/web/filters/associate/cpf/${value}`)
			setAssociate(response.data)
		} catch (error: any) {
			setAssociate({ id: -1 } as Associate)
		}
	}, [])

	const getBenefits = useCallback(async () => {

		if (associate.id && associate.id > -1) {
			const response: any = await apiProvider.get(`/web/filters/transaction/benefit/associate/${associate.id}`)
			const benefits = response.data.map((item: any) => {
				return {
					id: item.id,
					name: `${item.benefitValue}% - ${item.title}`,
				}
			})
			setBenefitList(benefits)
		}
	}, [associate])

	const getOneTransaction = useCallback(async () => {
		const response: any = await apiProvider.get(`/web/transaction/${state.item.id}`)

		//Formata o label do benefício para parecer no title do combobox.
		const ben: any = {
			id: response.data.benefit.id,
			name: `${response.data.benefit.benefitValue}% - ${response.data.benefit.title}`,
		}

		const transaction: any = {
			associate: response.data.associate,
			benefit: ben,
			discountedAmount: response.data.discountedAmount,
			id: response.data.id,
			product: response.data.product,
			store: response.data.store,
			totalAmount: response.data.totalAmount,
			associateCpf: response.data.associate.cpfCnpj
		}

		setTransaction(transaction)
		setBenefitList([ben])
		setAssociate(response.data.associate)

	}, [])

	useEffect(() => {
		getBenefits()
	}, [associate])

	useEffect(() => {
		if (!isNew) {
			// setDisabled(state.disabled)
			getOneTransaction()
		} else {
			setAmountPaid(0)
		}
	}, [])

	return (
		<FormTemplateElement>
			<BackButtom
				onclick={() => {
					navigate("/transaction-view")
				}}
			/>

			<SpanSubtitle text="Dados - Transação" />

			{(isLoadApi() || isNew) && (
				<Formik initialValues={transaction} validationSchema={validations} onSubmit={save}>
					{(propsForm) => {
						const getOneBenefit = useCallback(async () => {
							if (propsForm.values.benefit) {
								const response: any = await apiProvider.get(`/web/filters/transaction/benefit/${propsForm.values.benefit.id}`)
								setBenefit(response.data)
							}
						}, [propsForm.values.benefit])

						//Atualizar o benefício quando o valor do combo de benefícios é alterado.
						useEffect(() => {
							getOneBenefit()
						}, [getOneBenefit, propsForm.values.benefit])

						//Recalcular o valor ha pagar, pegar o valor total e descontar o benefício.
						useEffect(() => {
							calcAmountPaid(benefit.benefitValue, propsForm.values.totalAmount)
						}, [benefit, propsForm.values.benefit, propsForm.values.totalAmount])

						return (

							<Form>
								<div>
									Usuário (CPF) <br />
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<InputMask
											name="associateCpf"
											onBlur={() => getAssociate(propsForm.values.associateCpf)}
											disabled={!isNew}
											mask="99*.999.999-99"
											value={propsForm.values.associateCpf}
											onChange={propsForm.handleChange}
										/>
										<LabelElement disabled={!isNew}>
											{associate.id !== -1 ? <>{associate.name}</> :
												(associate.id ? <strong style={{ color: "red" }}>CPF não consta como usuário cadastrado.</strong> : '')}
										</LabelElement>
									</div>

									<Error>{propsForm.errors.associateCpf ? propsForm.errors.associateCpf : <>&nbsp;</>}</Error>
								</div>

								<div style={{ justifyContent: "flex-start" }}>
									{associate.id && associate.id > -1 &&
										<FormElementItems>
											Benefícios
											{isNew ?
												<>
													<SelectDropDownVm disabled={!isNew} name="benefit" emptyMessage="Sem resultados" onChange={propsForm.handleChange} value={propsForm.values.benefit} options={benefitList} optionLabel="name" />
													<Error>{propsForm.errors.benefit ? propsForm.errors.benefit : <>&nbsp;</>}</Error>
												</>
												:
												<InputTextVm disabled={true} value={propsForm.values.benefit.name} style={{ marginBottom: "1em" }} />
											}

										</FormElementItems>
									}

									{propsForm.values.benefit &&
										<>
											<FormElementItems>
												Produtos
												{isNew ?
													<>
														<SelectDropDownVm disabled={!isNew} emptyMessage="Sem resultados"
															name="product" onChange={propsForm.handleChange} value={propsForm.values.product}
															options={benefit.products} optionLabel="name" />
														<Error>{propsForm.errors.product ? propsForm.errors.product : <>&nbsp;</>}</Error>
													</>
													:
													<InputTextVm disabled={true} value={propsForm.values.product.name} style={{ marginBottom: "1em" }} />
												}
											</FormElementItems>

											<FormElementItems>
												Lojas

												{isNew ?
													<>
														<SelectDropDownVm disabled={!isNew} name="store" onChange={propsForm.handleChange}
															emptyMessage="Sem resultados"
															value={propsForm.values.store}
															options={benefit.stores}
															optionLabel="description" />
														<Error>
															{propsForm.errors.store ? (propsForm.errors.store) : (<>&nbsp;</>)}
														</Error>
													</>
													:
													<InputTextVm disabled={true} value={propsForm.values.store.description} style={{ marginBottom: "1em" }} />
												}
											</FormElementItems>
										</>
									}
								</div>

								<div style={{ display: "flex", justifyContent: "flex-start" }}>
									<DivIn>
										Valor total
										<InputNumber
											name="totalAmount"
											value={propsForm.values.totalAmount}
											onValueChange={propsForm.handleChange}
											mode="currency"
											currency="BRL"
											locale="pt-BR"
											minFractionDigits={2}
											placeholder="R$ 0,00"
										/>
										<Error> {propsForm.errors.totalAmount ? (propsForm.errors.totalAmount) : (<>&nbsp;</>)} </Error>
									</DivIn>

									<div style={{ marginTop: "2px" }}>
										Valor com desconto
										<LabelElement disabled={!isNew} style={{ marginTop: "10px" }}>
											<strong>{"R$ " + parseDoubleToStr(amountPaid)}</strong>

										</LabelElement>
									</div>
								</div>

								<div style={{ textAlign: 'end' }}>
									{isNew && (
										<ButtomSaveElement>
											<ButtomRequestElement type="submit" disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}>
												Salvar dados
											</ButtomRequestElement>
										</ButtomSaveElement>
									)}
								</div>
							</Form>
						)
					}
					}
				</Formik>
			)}
		</FormTemplateElement>
	)

	async function save(values: any) {
		const data = {
			associateId: associate.id,
			benefitId: values.benefit.id,
			productId: values.product.id,
			storeId: values.store.id,
			totalAmount: values.totalAmount,
		}

		appContext.setIsShowLoading(true)
		try {
			if (isNew) {
				await apiProvider.post("/web/transaction", data)
			} else {
				await apiProvider.put("/admin/transaction", data)
			}

			appContext.toast.show({
				severity: "success",
				summary: "Transação",
				detail: "Salvo com sucesso!",
				life: 3000,
			})
			navigate("/transaction-view")
		} catch (error: any) {
			appContext.toast.show({
				severity: "error",
				summary: "Transação",
				detail: "CPF não cadastrado no sistema!",
				life: 3000,
			})
		}
		appContext.setIsShowLoading(false)
	}

	function isLoadApi(): boolean {
		return !!transaction.id
	}
}
export default TransactionForm