import styled from "styled-components";

interface isButtomDisabled {
  disabled: boolean;
}

export const ButtomRequestElement = styled.button<isButtomDisabled>`
  background-color: ${({ disabled }) => (disabled ? "#d3d3d3 " : "#B7E4FF")};
  color: ${({ disabled }) => (disabled ? "white" : "#195C8C")};
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  border-radius: 11px;
  width: 285px;
  height: 50px;
  border: none;
`;
