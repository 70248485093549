import {Form, Formik} from 'formik'
import {Column} from 'primereact/column'
import {InputMask} from 'primereact/inputmask'
import {useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router'
import * as Yup from 'yup'
import {useAppContext} from '../../../../../../../context/app'
import BackButtom from '../../../../../../components/atoms/back-buttom'
import ButtomRequestElement from '../../../../../../components/atoms/buttom-request-form'
import DataTableElement from '../../../../../../components/atoms/data-table-view'
import PageHeader from '../../../../../../components/atoms/page-header'
import SpanSubtitle from '../../../../../../components/atoms/span-subtitle'
import ItemForm from '../../../../../../components/molecules/item-form'
import {LineFormElement} from '../../../../../../components/molecules/item-form/style'
import {Error} from '../../../../../../components/organisms/form-address/style'
import FormTemplateElement from '../../../../../../templates/form-template'
import {DivInput} from '../../../../partner/benefit/form/style'
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {ModuleEnum} from "../../../../../../../dto/moduleEnum/module-enum";
import {Contractor} from "../../../../../../../dto/contractor";
import {cnpj, cpf} from "cpf-cnpj-validator";

const AssociateControlForm: React.FC = () => {
  const appContext = useAppContext()
  const {apiProvider} = appContext
  const location = useLocation()
  const isNew = !location.state
  const {state} = location as any
  const navigate = useNavigate()
  const [associate, setAssociate] = useState({} as any)
  const [disabled, setDisabled] = useState(false)
  const [contractorList, setContractorList] = useState([] as any[])
  const [groupedContractors, setGroupedContractors] = useState([] as any[]);
  const [selectedContractorsB2BOrB2C, setSelectedContractorsB2BOrB2C] = useState<Contractor>();
  const [fieldCPFOrCNPJ, setCPFOrCNPJ] = useState<String>();

  const getAllContractors = useCallback(async () => {
    const response: any = await apiProvider.get('/admin/contractor')

    if (response?.data?.length > 0) {
      let aB2C: any = [];
      let aB2B: any = [];

      response.data.forEach((item: Contractor) => {
        if (item.moduleName == ModuleEnum.B2C) {
          aB2C.push({label: item.name, value: item});
          if (state?.item?.contractorCnpj == item.cnpj) {
            setSelectedContractorsB2BOrB2C(item);
          }
        } else if (item.moduleName == ModuleEnum.B2B) {
          aB2B.push({label: item.name, value: item});
          if (state?.item?.contractorCnpj == item.cnpj) {
            setSelectedContractorsB2BOrB2C(item);
          }
        }
      });

      let objectB2cAndB2B = [];

      if (aB2C?.length > 0) {
        objectB2cAndB2B.push({label: 'B2C', items: aB2C})
      }

      if (aB2B?.length > 0) {
        objectB2cAndB2B.push({label: 'B2B', items: aB2B})
      }

      setGroupedContractors(objectB2cAndB2B)
      setContractorList(response.data)
    }
  }, [])

  const getOneAssociate = useCallback(async () => {
    const response: any = await apiProvider.get(`/admin/user/${state.item.userId}`)

    contractorList.forEach(e => {
      if (e.id === response.data.contractorId) {
        response.data.contractor = e
      }
    })

    setAssociate(response.data)

  }, [contractorList])

  useEffect(() => {
    if (selectedContractorsB2BOrB2C != null) {
      if (selectedContractorsB2BOrB2C.moduleName == ModuleEnum.B2C) {
        setCPFOrCNPJ(ModuleEnum.CPF);
      } else {
        setCPFOrCNPJ(ModuleEnum.CNPJ);
      }
      setDisabled(false);
    }
  }, [selectedContractorsB2BOrB2C])

  useEffect(() => {
    getAllContractors()
    if (selectedContractorsB2BOrB2C == null && isNew) {
      setDisabled(true)
    }
  }, [])

  useEffect(() => {
    if (contractorList.length > 0 && !isNew) {
      getOneAssociate()
      setDisabled(state.disabled)
    }
  }, [contractorList])

  const save = async (values: any) => {
    const data = {
      id: values.id? values.id : null,
      contractorId: selectedContractorsB2BOrB2C?.id,
      cpfCnpj: values.cpfCnpj,
      email: values.email,
      name: values.name,
      isActive: values.isActive? values.isActive : true
    }

    appContext.setIsShowLoading(true)
    try {
      let msg = "";
      if (!isNew) {
        await apiProvider.put('/admin/user', data);
        msg = "Alteração realizada com sucesso!";
      } else {
        await apiProvider.post('/admin/user', data);
        msg = "Cadastrado com sucesso!";
      }

      navigate('/associate-control-view');

      appContext.toast.show({
        severity: 'success',
        summary: 'Usuário',
        detail: msg,
        life: 3000,
      })
    } catch (error: any) {
      appContext.toast.show({
        severity: 'error',
        summary: 'Usuário',
        detail: error.response.data.message,
        life: 3000,
      })
    }
    appContext.setIsShowLoading(false)
  }

  const validationsB2C = Yup.object().shape({
    name: Yup.string().required().max(250),
    email: Yup.string().required().email().max(100),
    cpfCnpj: Yup.string().required("CPF é obrigatório!").test((value: any) => cpf.isValid(value))
  })

  const validationsB2B = Yup.object().shape({
    name: Yup.string().required().max(250),
    email: Yup.string().required().email().max(100),
      cpfCnpj: Yup.string().required("CNPJ é obrigatório!").test((value: any) => cnpj.isValid(value))
  })

  const isLoadApi = (): boolean => {
    return !!associate.id
  }

  return (
      <>
        <PageHeader text='Usuário do app'/>

        <FormTemplateElement>

          <BackButtom onclick={() => {
            navigate('/associate-control-view')
          }}/>

          <SpanSubtitle text='Dados'/>

          {(isLoadApi() || isNew) && (
              <Formik
                  initialValues={associate}
                  validationSchema={(selectedContractorsB2BOrB2C != null && selectedContractorsB2BOrB2C.moduleName == ModuleEnum.B2C) ? validationsB2C : validationsB2B}
                  onSubmit={save}
              >
                {(propsForm) => (
                    <Form>
                      <LineFormElement>
                        <DivInput style={{width: '100%'}}>
                          CONTRATANTE
                          <Dropdown
                              name='contractor'
                              style={{width: '100%', height: '50px', borderRadius: '10px'}}
                              disabled={!isNew}
                              value={selectedContractorsB2BOrB2C}
                              onChange={(e) => setSelectedContractorsB2BOrB2C(e.value)}
                              options={groupedContractors}
                              optionLabel="label"
                              optionGroupLabel="label"
                              optionGroupChildren="items"
                              emptyMessage="Nenhum resultado encontrado."
                              emptyFilterMessage="Nenhum resultado encontrado."
                              placeholder="Selecione o contratante"
                              showFilterClear
                              filter
                          />
                        </DivInput>
                      </LineFormElement>
                      <LineFormElement style={{justifyContent: 'space-between'}}>
                        <ItemForm
                            disabled={disabled}
                            propsForm={propsForm}
                            name='name'
                            label='Nome'
                            widthPercent='100'
                        />
                        <ItemForm
                            disabled={disabled}
                            propsForm={propsForm}
                            name='email'
                            label='Email'
                            widthPercent='100'
                        />
                      </LineFormElement>
                      <LineFormElement style={{justifyContent: 'flex-start'}}>
                        {fieldCPFOrCNPJ &&
                            <>
                                <DivInput style={{width: '100%'}}>
                                  {fieldCPFOrCNPJ}
                                    <InputMask
                                        name='cpfCnpj'
                                        disabled={disabled}
                                        style={{
                                          width: '100%',
                                          borderRadius: '10px',
                                          marginTop: '6px',
                                        }}
                                        mask={(fieldCPFOrCNPJ == "CPF") ? '999.999.999-99' : '99.999.999/9999-99'}
                                        value={propsForm.values.cpfCnpj}
                                        onChange={propsForm.handleChange}
                                    />
                                    <Error>
                                      {propsForm.errors.cpf ? propsForm.errors.cpf : (<>&nbsp;</>)}
                                    </Error>
                                </DivInput>
                            </>
                        }
                        <DivInput style={{width: '100%'}}>
                          Ativo
                          <InputSwitch
                              name='isActive'
                              checked={(isNew && propsForm.values.isActive == null) ? true : propsForm.values.isActive}
                              onChange={propsForm.handleChange}
                              disabled={disabled}/>
                        </DivInput>
                      </LineFormElement>
                      <div style={{textAlign: 'end'}}>
                        <ButtomRequestElement
                            type='submit'
                            disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}
                        >
                          Salvar dados
                        </ButtomRequestElement>
                      </div>
                    </Form>
                )}
              </Formik>
          )}

          {disabled && !isNew &&
              <>
                  <h3>Histórico de pagamento</h3>
                  <DataTableElement
                      paginator
                      paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                      rows={10}
                      responsiveLayout='scroll'
                      emptyMessage='Nenhum resultado encontrado'
                      itemsList={[]}>
                      <Column
                          alignHeader='center'
                          align='center'
                          header='Data'
                      />
                      <Column
                          alignHeader='center'
                          align='center'
                          header='Valor'
                      />
                  </DataTableElement>
              </>
          }

        </FormTemplateElement>
      </>
  )
}
export default AssociateControlForm
