import { Form, Formik } from 'formik';
import { Checkbox } from 'primereact/checkbox';
import { ColorPicker } from 'primereact/colorpicker';
import { Dropdown } from "primereact/dropdown";
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useAppContext } from '../../../../../../../context/app';
import { Contractor } from '../../../../../../../dto/contractor';
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { validateCnpj } from '../../../../../../../utils/validation';
import BackButtom from '../../../../../../components/atoms/back-buttom';
import ButtomRequestElement from '../../../../../../components/atoms/buttom-request-form';
import DivInput from '../../../../../../components/atoms/div-input';
import Error from '../../../../../../components/atoms/error-message';
import InputTextVm from '../../../../../../components/atoms/input-text';
import PageHeader from '../../../../../../components/atoms/page-header';
import SpanSubtitle from '../../../../../../components/atoms/span-subtitle';
import ImageUpdate from '../../../../../../components/molecules/image-update';
import { LineFormElement } from '../../../../../../components/molecules/item-form/style';
import FormAddress, { buildValidationAddress } from '../../../../../../components/organisms/form-address';
import FormTemplateElement from '../../../../../../templates/form-template';
import { ColorElement, DivColors } from './style';
import { ModuleCompanyTypeEnum } from '../../../../../../../dto/moduleEnum/company-type-enum';

const ContractorForm: React.FC = () => {
    const [contractor, setContractor] = useState({ isAllCategorys: true, } as Contractor);
    const [categoryList, setCategoryList] = useState([] as any[]);
    const [disabled, setDisabled] = useState(true);
    const moduleList = [{ name: ModuleEnum.B2C }, { name: ModuleEnum.B2B }];
    const [moduleSelected, setModuleSelected] = useState(null) as any;
    const [companyType, setCompanyType] = useState(null) as any;
    const [displayType, setDisplayType] = useState('none');
    const navigate = useNavigate();
    const location = useLocation();
    const appContext = useAppContext();
    const { state } = location as any;
    const { apiProvider } = appContext;
    const isNew = !location.state;
    const optionsCompanyType = [
        {
            type: 'MEI',
            typeValue: ModuleCompanyTypeEnum.MEI
        },
        {
            type: 'LIMITADA',
            typeValue: ModuleCompanyTypeEnum.LIMITED
        },
        {
            type: 'INDIVIDUAL',
            typeValue: ModuleCompanyTypeEnum.INDIVIDUAL
        },
        {
            type: 'ASSOCIAÇÃO',
            typeValue: ModuleCompanyTypeEnum.ASSOCIATION
        },
    ];

    const getAllCategories = useCallback(async () => {
        const response: any = await apiProvider.get('/web/category/');
        setCategoryList(response.data);
    }, []);

    const getOneContractor = useCallback(
        async function () {
            const response: any = await apiProvider.get(`/admin/contractor/${state.item.id}`);
            if (response.data.categorys) {
                let catSelect = [];
                //Busca lista de categorias
                for (let item of response.data.categorys) {
                    for (let originalCat of categoryList) {
                        if (item === originalCat.id) {
                            catSelect.push(originalCat);
                            break;
                        }
                    }
                }
                response.data.categorys = catSelect;
                setDisplayType((response.data.moduleName == ModuleEnum.B2B) ? 'none' : 'flex')
            }

            optionsCompanyType.forEach(option => {
                if (response.data.companyType == option.typeValue) {
                    setCompanyType(option);
                }
            });

            response.data.validateImg = ''
            response.data.file = response.data.imageUrl
            setContractor(response.data);
            setModuleSelected({ name: response?.data?.moduleName })
        },
        [categoryList]
    );

    useEffect(() => {
        getAllCategories();
    }, []);

    useEffect(() => {
        if (categoryList.length > 0 && !isNew) {
            setDisabled(state.disabled);
            getOneContractor();
        }
    }, [categoryList]);

    // Validações
    const validationsB2C = buildValidationAddress({
        name: Yup.string().required().max(250),
        email: Yup.string().required("Favor digitar o e-mail!").email(),
        corporateName: Yup.string().required("Campo obrigatório").nullable(),
        primaryColor: Yup.string().required('Campo obrigatório').max(10),
        secondaryColor: Yup.string().required('Campo obrigatório').max(10),
        cnpj: Yup.string()
            .test('isCnpjValid', 'cnpj inválido', (cnpj) => {
                try {
                    return validateCnpj(cnpj);
                } catch (error) {
                    console.error(error)
                }
                return false;
            })
            .required(),
        cellPhone: Yup.string().required().max(16),
        categorys: Yup.array().when(['isAllCategorys'], (isAllCategorys, schema): any => {
            if (!isAllCategorys) {
                return schema.required().min(1);
            }
        }),
    });

    const validationsB2B = buildValidationAddress({
        name: Yup.string().required().max(250),
        email: Yup.string().required("Favor digitar o e-mail!").email(),
        corporateName: Yup.string().required("Campo obrigatório").nullable(),
        primaryColor: Yup.string().required().max(10),
        secondaryColor: Yup.string().required().max(10),
        cnpj: Yup.string()
            .test('isCnpjValid', 'cnpj inválido', (cnpj) => {
                try {
                    return validateCnpj(cnpj);
                } catch (error) {
                    console.error(error)
                }
                return false;
            })
            .required(),
        cellPhone: Yup.string().required().max(16),
        categorys: Yup.array().when(['isAllCategorys'], (isAllCategorys, schema): any => {
            if (!isAllCategorys) {
                return schema.required().min(1);
            }
        }),
    });

    function handleChangeSetModule(e: any) {
        if (e?.value?.name) {
            setDisabled(false);
            setDisplayType((e.value.name == ModuleEnum.B2B) ? 'none' : 'flex')
            setModuleSelected(e.value);
        }
    }

    return (
        <>
            <PageHeader text='Novo contrante' />

            <FormTemplateElement>
                <BackButtom
                    onclick={() => {
                        navigate('/contractor-view');
                    }}
                />

                <SpanSubtitle text='Dados - Contratante' />

                {(isLoadApi() || isNew) && (
                    <Formik
                        validationSchema={(moduleSelected == null || moduleSelected.name == ModuleEnum.B2B) ? validationsB2B : validationsB2C}
                        initialValues={contractor}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <DivInput style={{ width: '100%' }}>
                                    MÓDULO
                                    <Dropdown
                                        style={{ marginTop: '10px' }}
                                        value={moduleSelected}
                                        onChange={(e) => handleChangeSetModule(e)}
                                        options={moduleList}
                                        optionLabel="name"
                                        placeholder="Selecione o módulo desejado."
                                        disabled={(isNew) ? false : true}
                                    />
                                </DivInput>
                                <LineFormElement style={{ marginBottom: '0px' }}>
                                    <DivInput style={{ width: '32%' }}>
                                        Nome
                                        <InputTextVm
                                            style={{
                                                borderRadius: '10px',
                                                width: '97%',
                                                marginTop: '10px',
                                            }}
                                            name='name'
                                            value={propsForm.values.name}
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                        />
                                        <Error name='name' errors={propsForm.errors} />
                                    </DivInput>
                                    <DivInput style={{ width: '32%' }}>
                                        Cnpj
                                        <InputMask
                                            name='cnpj'
                                            disabled={disabled}
                                            style={{
                                                height: '54px',
                                                borderRadius: '10px',
                                                marginTop: '9px',
                                            }}
                                            mask='99.999.999/9999-99'
                                            value={propsForm.values.cnpj}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name='cnpj' errors={propsForm.errors} />
                                    </DivInput>
                                    <div style={{ width: '15%', height: '100px' }}>
                                        Fixo
                                        <InputMask
                                            id='phone'
                                            disabled={disabled}
                                            style={{
                                                height: '50px',
                                                width: '100%',
                                                borderRadius: '10px',
                                                marginTop: '7px',
                                            }}
                                            mask={'(99) 9999-9999'}
                                            value={propsForm.values.phone}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name='phone' errors={propsForm.errors} />
                                    </div>
                                    <div style={{ width: '15%', height: '100px' }}>
                                        Celular
                                        <InputMask
                                            id='cellPhone'
                                            disabled={disabled}
                                            style={{
                                                height: '50px',
                                                width: '100%',
                                                borderRadius: '10px',
                                                marginTop: '7px',
                                            }}
                                            mask={'(99) 99999-9999'}
                                            value={propsForm.values.cellPhone}
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name='cellPhone' errors={propsForm.errors} />
                                    </div>
                                </LineFormElement>
                                <LineFormElement style={{ marginBottom: '0px' }}>
                                    <DivInput style={{ width: '50%' }}>
                                        Razão Social
                                        <InputTextVm
                                            style={{ borderRadius: '10px', width: '97%', marginTop: '10px', }}
                                            name="corporateName"
                                            value={propsForm.values.corporateName}
                                            onChange={propsForm.handleChange}
                                            disabled={disabled}
                                        />
                                        <Error name='corporateName' errors={propsForm.errors} />
                                    </DivInput>
                                    <DivInput style={{ width: '15%', marginTop: '2px', marginRight: '20px' }}>
                                        Tipo da empresa
                                        <Dropdown
                                            name="companyType"
                                            disabled={disabled}
                                            style={{ marginTop: '10px' }}
                                            value={companyType}
                                            onChange={(e) => setCompanyType(e.value)}
                                            options={optionsCompanyType}
                                            optionLabel="type"
                                            placeholder="Selecione o tipo da empresa"
                                        />
                                    </DivInput>
                                    <DivInput style={{ width: '50%' }}>
                                        Email
                                        <InputTextVm
                                            style={{ height: '54px', borderRadius: '10px', marginTop: '9px' }}
                                            name='email'
                                            value={propsForm.values.email}
                                            onChange={propsForm.handleChange}
                                            disabled={(isNew && !disabled) ? false : true}
                                        />
                                        <Error name='email' errors={propsForm.errors} />
                                    </DivInput>
                                </LineFormElement>
                                <LineFormElement style={{ marginBottom: '0px' }}>
                                    <DivInput style={{ width: '50%' }}>
                                        Inscrição municipal
                                        <InputNumber
                                            style={{ borderRadius: '10px', width: '97%', marginTop: '10px' }}
                                            name="countyRegistry"
                                            value={propsForm.values.countyRegistry}
                                            onValueChange={propsForm.handleChange}
                                            useGrouping={false}
                                            disabled={disabled}
                                        />
                                        <Error name='countyRegistry' errors={propsForm.errors} />
                                    </DivInput>
                                    <DivInput style={{ width: '50%' }}>
                                        Inscrição estadual
                                        <InputNumber
                                            style={{ height: '54px', borderRadius: '10px', marginTop: '9px' }}
                                            name="stateRegistry"
                                            value={propsForm.values.stateRegistry}
                                            onValueChange={propsForm.handleChange}
                                            disabled={disabled}
                                            useGrouping={false}
                                        />
                                        <Error name='stateRegistry' errors={propsForm.errors} />
                                    </DivInput>
                                </LineFormElement>
                                <Error name='corporateName' errors={propsForm.errors} />
                                <LineFormElement style={{ width: '100%', marginBottom: '15px' }}>
                                    <DivColors style={{ width: '100%' }}>
                                        Cor primária
                                        <ColorElement style={{ justifyContent: "space-between" }}>
                                            <ColorPicker
                                                style={{ marginRight: '10px' }}
                                                name='primaryColor'
                                                value={propsForm.values.primaryColor}
                                                onChange={propsForm.handleChange}
                                                disabled={disabled}
                                            />
                                            <InputTextVm
                                                name='primaryColor'
                                                style={{
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    marginTop: '0px',
                                                }}
                                                value={propsForm.values.primaryColor}
                                                onChange={propsForm.handleChange}
                                                disabled={disabled}
                                            />
                                        </ColorElement>
                                        <Error name='primaryColor' errors={propsForm.errors} />
                                    </DivColors>
                                    <DivColors style={{ width: '100%' }}>
                                        Cor secundária
                                        <ColorElement>
                                            <ColorPicker
                                                name='secondaryColor'
                                                style={{ marginRight: '10px' }}
                                                value={propsForm.values.secondaryColor}
                                                onChange={propsForm.handleChange}
                                                disabled={disabled}
                                            />
                                            <InputTextVm
                                                name='secondaryColor'
                                                style={{
                                                    borderRadius: '10px',
                                                    width: '100%',
                                                    marginTop: '0px',
                                                }}
                                                value={propsForm.values.secondaryColor}
                                                onChange={propsForm.handleChange}
                                                disabled={disabled}
                                            />
                                        </ColorElement>
                                        <Error name='secondaryColor' errors={propsForm.errors} />
                                    </DivColors>
                                </LineFormElement>
                                <LineFormElement style={{ marginBottom: '15px' }}>
                                    <ImageUpdate
                                        disabled={disabled}
                                        name='file'
                                        nameUrl='imageUrl'
                                        label='Upload da logomarca da contratante.'
                                        propsForm={propsForm}
                                        widthImage={365}
                                        heightImage={177}
                                    />
                                    <div style={{ display: displayType }}>
                                        <ImageUpdate
                                            name='businessCard'
                                            disabled={disabled}
                                            nameUrl='businessCardUrl'
                                            label='Cartão virtual'
                                            propsForm={propsForm}
                                            widthImage={365}
                                            heightImage={177}
                                        />
                                    </div>
                                </LineFormElement>
                                <div>
                                    <Checkbox
                                        name='isAllCategorys'
                                        style={{ marginRight: '10px' }}
                                        disabled={disabled}
                                        checked={propsForm.values.isAllCategorys}
                                        onChange={propsForm.handleChange}
                                    />
                                    Mostrar quais categorias aplicáveis
                                </div>
                                {!propsForm.values.isAllCategorys && (
                                    <>
                                        <div style={{ width: '100%' }}>
                                            <MultiSelect
                                                style={{ width: '100%', marginTop: '1em' }}
                                                name='categorys'
                                                disabled={disabled}
                                                value={propsForm.values.categorys}
                                                placeholder='Selecione uma ou mais categorias'
                                                options={categoryList}
                                                optionLabel='name' onChange={propsForm.handleChange}
                                            />
                                            <Error name='categorys' errors={propsForm.errors} />
                                        </div>
                                    </>
                                )}

                                <SpanSubtitle text='Endereço' />

                                <FormAddress
                                    disabled={disabled}
                                    propsForm={propsForm}
                                    appContext={appContext}
                                />
                                <div style={{ textAlign: 'end' }}>
                                    {!disabled && (
                                        <ButtomRequestElement
                                            type='submit'
                                            disabled={isNew ?
                                                !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                                :
                                                propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                            }
                                        >
                                            Salvar dados
                                        </ButtomRequestElement>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    );

    function isLoadApi(): boolean {
        return !!contractor.id;
    }

    async function save(values: any) {
        let primarySplit, primary, secundarySplit, secundary = null;

        if (companyType == null) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Dados incompletos',
                detail: 'Favor selecionar o tipo da empresa!',
                life: 3000,
            });

            return;
        }

        if (values.primaryColor != null && values.secondaryColor != null) {
            primarySplit = values.primaryColor?.split('#');
            primary = '#' + primarySplit.join('');
            secundarySplit = values.secondaryColor?.split('#');
            secundary = '#' + secundarySplit.join('');

            let address = {
                zipCode: values.address.zipCode,
                street: values.address.street,
                number: values.address.number,
                district: values.address.district,
                city: values.address.city,
                state: values.address.state,
                complement: values.address.complement,
                description: values.address.description
            }

            const data = {
                moduleName: moduleSelected.name,
                address,
                id: values.id ? values.id : '',
                name: values.name.toUpperCase(),
                corporateName: values.corporateName.toUpperCase(),
                cnpj: values.cnpj,
                phone: values.phone,
                cellPhone: values.cellPhone,
                primaryColor: primary,
                secondaryColor: secundary,
                isAllCategorys: values.isAllCategorys,
                categorys: !values.isAllCategorys ? values.categorys.map((ids: any) => ids.id) : [],
                email: values.email,
                countyRegistry: values.countyRegistry,
                stateRegistry: values.stateRegistry,
                companyType: companyType?.typeValue
            };

            let formData = new FormData();

            formData.append('contractor', JSON.stringify(data));
            formData.append('file', values.file);
            formData.append('businessCard', values.businessCard);

            appContext.setIsShowLoading(true);
            try {
                if (isNew) {
                    await apiProvider.post('/admin/contractor', formData)
                        .then((response) => {
                            if (response.status == 403) {
                                appContext.toast.show({
                                    severity: 'error',
                                    summary: 'Acesso negado',
                                    detail: 'Causa provável: imagem de baixa resolução!',
                                    life: 3000,
                                });
                            } else {
                                appContext.toast.show({
                                    severity: 'success',
                                    summary: 'Contratante',
                                    detail: 'Salvo com sucesso!',
                                    life: 3000,
                                });

                                navigate('/contractor-view');
                            }
                        })
                        .catch((error) => {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Contratante',
                                detail: error.response.data.message || 'Erro ao salvar contratante, tente novamente!',
                                life: 3000,
                            });
                        });
                } else {
                    await apiProvider.put('/admin/contractor', formData)
                        .then((response) => {
                            if (response.status == 403) {
                                appContext.toast.show({
                                    severity: 'error',
                                    summary: 'Acesso negado',
                                    detail: 'Causa provável: imagem de baixa resolução!',
                                    life: 3000,
                                });
                            } else {
                                appContext.toast.show({
                                    severity: 'success',
                                    summary: 'Contratante',
                                    detail: 'Salvo com sucesso!',
                                    life: 3000,
                                });

                                navigate('/contractor-view');
                            }
                        })
                        .catch((error) => {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Contratante',
                                detail: error.response.data.message || 'Erro ao salvar contratante, tente novamente!',
                                life: 3000,
                            });
                        });
                }
            } catch (error: any) {
                appContext.toast.show({
                    severity: 'error',
                    summary: 'Contratante',
                    detail: error.response.data.message || 'Erro ao salvar contratante, tente novamente!',
                    life: 3000,
                });
            }

            appContext.setIsShowLoading(false);

        } else {
            appContext.toast.show({
                severity: 'error',
                summary: 'Contratante',
                detail: 'Favor inserir as cores correspondentes!',
                life: 3000,
            });
        }
    }
};

export default ContractorForm;
