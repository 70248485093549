import styled from "styled-components";

interface buttonColor {
  buttonColor: string;
}

export const HeaderElementLeft = styled.div`
  width: 40%;
  align-items: center;
  padding-left: 15px;
  display: flex;
`;

export const ButtomsElement = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContractorButtomElement = styled.button`
  height: 45px;
  border: none;
  text-align: center;
  width: 210px;
  color: white;
  border-radius: 17px;
  background-color: #27ae60;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
  font-size: 17px;
  cursor: pointer;
`;

export const HeaderElementRight = styled.div`
  width: 60%;
  padding-right: 15px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
`;

export const TableElement = styled.div`
  border-radius: 15px;
  width: 100%;
  padding: 20px;
  box-shadow: 2px 3px 4px 1px rgba(0, 0, 0, 0.1);
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const ActionButtonsElement = styled.button`
  background-color: #f7f9fc;
  width: 50px;
  height: 50px;
  border-radius: 13px;
  justify-content: center;
  display: flex;
  margin-right: 10px;
  cursor: pointer;
  align-items: center;
  border: none;
`;

export const StatusButtomElement = styled.button<buttonColor>`
  width: 95px;
  padding: 10px;
  border-radius: 25px;
  background-color: ${({ buttonColor }) => buttonColor};
  color: white;
  border: none;
`;
