import { Form, Formik } from "formik";
import { InputMask } from "primereact/inputmask";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { useAppContext } from "../../../../../../context/app";
import { ISessionContext, useSessionContext } from "../../../../../../context/session";
import { Associate } from "../../../../../../dto/associte";
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum";
import BackButtom from "../../../../../components/atoms/back-buttom";
import ButtomRequestElement from "../../../../../components/atoms/buttom-request-form";
import PageHeader from "../../../../../components/atoms/page-header";
import SpanSubtitle from "../../../../../components/atoms/span-subtitle";
import ItemForm from "../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../components/molecules/item-form/style";
import FormTemplateElement from "../../../../../templates/form-template";
import { DivInput } from "../../../partner/benefit/form/style";

const AssociateForm: React.FC = () => {
    const [category, setAssociate] = useState({} as Associate);
    const [disabled, setDisabled] = useState(false);
    const [moduleName, setModuleName] = useState() as any;
    const appContext = useAppContext();
    const { apiProvider } = appContext;
    const navigate = useNavigate();
    const location = useLocation();
    const isNew = !location.state;
    const { state } = location as any;
    const context: ISessionContext = useSessionContext();

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled);
            getOneAssociate();
        }

        if (context.session.modules[0].moduleEnum != ModuleEnum.ADMIN) {
            if (context.session.modules[0].moduleEnum == ModuleEnum.CONTRACTOR) {
                setModuleName(context.session.modules[0].contractor?.moduleName);
            } else if (context.session.modules[0].moduleEnum == ModuleEnum.PARTNER) {
                setModuleName(context.session.modules[0].partner?.moduleName);
            }
        }

    }, []);

    const getOneAssociate = useCallback(async () => {
        const response: any = await apiProvider.get(`/web/user/associate/${state.item.id}`);
        setAssociate(response.data);
    }, []);

    // Validações do yup
    const validations = Yup.object().shape({
        name: Yup.string().required().max(250),
        email: Yup.string().email().required().max(250),
        cpfCnpj: Yup.string().required(),
    });

    function isLoadApi(): boolean {
        return !!category.id;
    }

    return (
        <>
            <PageHeader text="Novo usuário" />

            <FormTemplateElement>
                <BackButtom onclick={() => { navigate("/associate-view"); }} />

                <SpanSubtitle text="Dados - Usuário" />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={category}
                        validationSchema={validations}
                        onSubmit={save}>
                        {(propsForm) => (
                            <Form>
                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    <ItemForm disabled={disabled} propsForm={propsForm} name="name" label="Nome" widthPercent="50" />
                                    <ItemForm disabled={disabled} propsForm={propsForm} name="email" label="E-mail" widthPercent="50" />
                                </LineFormElement>

                                <LineFormElement style={{ justifyContent: "flex-start" }}>
                                    {(moduleName === ModuleEnum.B2C) && (
                                        <DivInput style={{ width: "48%" }}>
                                            CPF
                                            <InputMask
                                                name="cpfCnpj"
                                                mask="999.999.999-99"
                                                value={propsForm.values.cpfCnpj}
                                                onChange={propsForm.handleChange}
                                                disabled={disabled}
                                            />
                                        </DivInput>
                                    )}

                                    {(moduleName === ModuleEnum.B2B) && (
                                        <DivInput style={{ width: "48%" }}>
                                            CNPJ
                                            <InputMask
                                                name="cpfCnpj"
                                                mask="99.999.999/9999-99"
                                                value={propsForm.values.cpfCnpj}
                                                onChange={propsForm.handleChange}
                                                disabled={disabled}
                                            />
                                        </DivInput>
                                    )}
                                </LineFormElement>

                                <div style={{ textAlign: 'end' }}>
                                    {!disabled && (
                                        <ButtomRequestElement type="submit" disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}>
                                            Salvar dados
                                        </ButtomRequestElement>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </FormTemplateElement>
        </>
    );

    // Funções do sistema

    // Salvar Associate
    async function save(values: any) {
        appContext.setIsShowLoading(true)
        try {
            if (!isNew) {
                await apiProvider.put("/web/user/associate", values);
            } else {
                await apiProvider.post("/web/user/associate", values);
            }

            navigate("/associate-view");
            appContext.toast.show({
                severity: "success",
                summary: "Usuário",
                detail: "Salvo com sucesso!",
                life: 3000,
            });
        } catch (error: any) {
            const message = error.response.data.message;

            if (message === "This CPF is already registered.") {
                appContext.toast.show({
                    severity: "error",
                    summary: "Cpf",
                    detail: (moduleName === ModuleEnum.B2C) ? "CPF" : "CNPJ" + " já utilizado, tente outro!",
                    life: 3000,
                });
            } else {
                appContext.toast.show({
                    severity: "error",
                    summary: "Usuário",
                    detail: `Erro ao salvar usuário: ${message}`,
                    life: 3000,
                });
            }
        }

        appContext.setIsShowLoading(false)
    }
};

export default AssociateForm;
