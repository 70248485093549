import {useEffect, useState} from "react"
import {BrowserRouter} from "react-router-dom"
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import "./App.css"
import "./utils/yup-translation"
import {AppContextProvider, sessionProvider} from "./context/app"
import Home from "./ui/pages/home"
import PublicRoutes from "./public-routes"

Amplify.configure(awsconfig)

export enum ApplicationStatus {
  INITIALIZING,
  NO_USER,
  LOGGED_IN_USER,
  WELCOME,
}

function App() {
  const [applicationStatus, setApplicationStatus] = useState(
      ApplicationStatus.INITIALIZING
  )

  useEffect(() => {
    if (applicationStatus === ApplicationStatus.INITIALIZING) {
      init().then();
    }
  })

  async function init() {
    const session = await sessionProvider.initSession()
    if (session) {
      setApplicationStatus(ApplicationStatus.LOGGED_IN_USER)
    } else {
      setApplicationStatus(ApplicationStatus.NO_USER)
    }
  }

  return (
      <>
        {applicationStatus !== ApplicationStatus.INITIALIZING && (
            <AppContextProvider setApplicationStatus={setApplicationStatus}>
              <BrowserRouter>
                {
                  applicationStatus === ApplicationStatus.LOGGED_IN_USER ?
                      <Home/> : <PublicRoutes/>
                }
              </BrowserRouter>
            </AppContextProvider>
        )}
      </>
  )
}

export default App
