import { Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import { ButtomSaveElement } from "../../../../src/ui/pages/modules/partner/benefit/form/style";
import { useAppContext } from "../../../context/app";
import { ISessionContext, useSessionContext } from "../../../context/session";
import { Banner } from "../../../dto/banner";
import { ModuleEnum } from "../../../dto/moduleEnum/module-enum";
import { ModuleEnumStatus } from "../../../dto/moduleEnum/module-enum-status";
import BackButtom from "../../components/atoms/back-buttom";
import ButtomRequestElement from "../../components/atoms/buttom-request-form";
import DivInput from "../../components/atoms/div-input";
import SpanSubtitle from "../../components/atoms/span-subtitle";
import ImageUpdate from "../../components/molecules/image-update";
import ItemForm from "../../components/molecules/item-form";
import { LineFormElement } from "../../components/molecules/item-form/style";
import ActionFowarding, { buildValidationActionFowarding } from "../../components/organisms/action-fowarding";
import FormTemplateElement from "../form-template";

const BannerForm: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any
    const [disabled, setDisabled] = useState(false)
    const [banner, setBanner] = useState({ actionForwarding: { actionEnum: 0 }, } as Banner)
    const appContext = useAppContext()
    const { apiProvider } = useAppContext();
    let context: ISessionContext = useSessionContext();
    const [moduleSelected, setModuleSelected] = useState(null) as any;
    const bannerOrPopup = [{ name: "Banner" }, { name: "Popup" }];

    const validations = buildValidationActionFowarding({
        title: Yup.string().required("Campo obrigatório"),
    })

    const getOneBanner = useCallback(async function () {
        const response: any = await apiProvider.get(`/admin/banner/${state.item.id}`)
        response.data.validateImg = ''
        setBanner(response.data)
        setModuleSelected({ name: response?.data?.moduleName })
    }, [])

    useEffect(() => {
        if (!isNew) {
            setDisabled(state.disabled)
            getOneBanner()
        }
    }, [])

    function handleChangeSetModule(e: any) {
        if (e?.value?.name) {
            setDisabled(false);
            setModuleSelected(e.value);
        }
    }

    function isLoadApi(): boolean {
        return !!banner.id
    }

    return (
        <FormTemplateElement>
            <BackButtom
                onclick={() => {
                    navigate("/dashboard-partner")
                }}
            />

            <SpanSubtitle text="Divulgue agora e venda muito mais!" />

            {(isLoadApi() || isNew) && (
                <Formik
                    initialValues={banner}
                    validationSchema={validations}
                    onSubmit={save}
                >
                    {(propsForm) => (
                        <Form>
                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                <ItemForm
                                    label="Título"
                                    propsForm={propsForm}
                                    disabled={disabled}
                                    name="title"
                                />

                                <DivInput style={{ width: '20em', height: '50px' }}>
                                    Local de divulgação
                                    <Dropdown
                                        style={{ marginTop: '10px' }}
                                        value={moduleSelected}
                                        onChange={(e) => handleChangeSetModule(e)}
                                        options={bannerOrPopup}
                                        optionLabel="name"
                                        placeholder="Selecione"
                                        disabled={(isNew) ? false : true}
                                    />
                                </DivInput>
                            </LineFormElement>

                            <LineFormElement style={{ marginTop: 30 }}>
                                <ImageUpdate
                                    disabled={disabled}
                                    name="file"
                                    nameUrl="imageUrl"
                                    label="Imagem para upload"
                                    propsForm={propsForm}
                                    widthImage={365}
                                    heightImage={177}
                                />
                            </LineFormElement>

                            <SpanSubtitle text="Ação ao usuário clincar no banner." />

                            <LineFormElement>
                                <ActionFowarding disabled={disabled} propsForm={propsForm} />
                            </LineFormElement>

                            <div style={{ textAlign: 'end' }}>
                                {!disabled && (
                                    <ButtomSaveElement>
                                        <ButtomRequestElement
                                            type="submit"
                                            disabled={isNew ?
                                                !propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                                :
                                                propsForm.isSubmitting || !propsForm.dirty || propsForm.values.validateImg != ''
                                            }>
                                            Solicitar divulgação
                                        </ButtomRequestElement>
                                    </ButtomSaveElement>
                                )}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </FormTemplateElement>
    )

    async function save(values: any) {
        try {
            let actionEnum: string = "";

            if (values.actionForwarding.actionEnum === 0) {
                actionEnum = "EXTERNAL_LINK"
            } else if (values.actionForwarding.actionEnum === 1) {
                actionEnum = "BENEFIT"
            } else if (values.actionForwarding.actionEnum === 2) {
                actionEnum = "CATEGORY"
            } else if (values.actionForwarding.actionEnum === 3) {
                actionEnum = "PARTNER"
            }

            const formatExternalLink = (link: string) => {
                if (link) {
                    if (link.startsWith("https://")) {
                        return link;
                    } else {
                        return `https://${link}`;
                    }
                }
                return "";
            }

            let getModuleName: any = null;
            if (context.session.modules[0].moduleEnum === ModuleEnum.PARTNER) {
                getModuleName = context.session?.modules[0]?.partner?.moduleName
            } else if (context.session.modules[0].moduleEnum === ModuleEnum.CONTRACTOR) {
                getModuleName = context.session?.modules[0]?.contractor?.moduleName
            }

            const data = {
                actionForwarding: {
                    actionEnum,
                    benefitId: values.actionForwarding.benefit ? values.actionForwarding.benefit.id : "",
                    categoryId: values.actionForwarding.category ? values.actionForwarding.category.id : "",
                    externalLink: formatExternalLink(values.actionForwarding.externalLink),
                    id: values.actionForwarding.id ? values.actionForwarding.id : "",
                    partnerId: values.actionForwarding.partner ? values.actionForwarding.partner.id : "",
                },
                id: values.id ? values.id : "",
                priorityOrder: null,
                isActive: ModuleEnumStatus.INACTIVE,
                title: values.title,
                moduleName: getModuleName,
                user: context.session?.user
            }

            const formData = new FormData()
            formData.append(moduleSelected.name.toLowerCase(), JSON.stringify(data))
            formData.append("file", values.file)

            appContext.setIsShowLoading(true)

            if (moduleSelected.name == "Banner") {
                await apiProvider.post("/admin/banner", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Solicitação',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            setTimeout(() => {
                                appContext.toast.show({
                                    severity: "success",
                                    summary: "Solicitação",
                                    detail: "Você receberá o retorno em seu email cadastrado na plataforma.",
                                    life: 3000,
                                });
                            }, 2000);

                            navigate("/dashboard-partner")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Solicitação',
                            detail: error.response.data || "Erro ao enviar sua solicitação, tente novamente!",
                            life: 3000,
                        });
                    });
            } else if (moduleSelected.name == "Popup") {
                await apiProvider.post("/admin/popup", formData)
                    .then((response) => {
                        if (response.status == 403) {
                            appContext.toast.show({
                                severity: 'error',
                                summary: 'Acesso negado',
                                detail: 'Causa provável: imagem de baixa resolução!',
                                life: 3000,
                            });
                        } else {
                            appContext.toast.show({
                                severity: 'success',
                                summary: 'Solicitação',
                                detail: 'Salvo com sucesso!',
                                life: 3000,
                            });

                            setTimeout(() => {
                                appContext.toast.show({
                                    severity: "success",
                                    summary: "Solicitação",
                                    detail: "Você receberá o retorno em seu email cadastrado na plataforma.",
                                    life: 3000,
                                });
                            }, 2000);

                            navigate("/dashboard-partner")
                        }
                    })
                    .catch((error) => {
                        appContext.toast.show({
                            severity: 'error',
                            summary: 'Solicitação',
                            detail: error.response.data || "Erro ao enviar sua solicitação, tente novamente!",
                            life: 3000,
                        });
                    });
            }
        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Solicitação",
                detail: error.response.data || "Erro ao enviar sua solicitação, tente novamente!",
                life: 5000,
            })
        }

        appContext.setIsShowLoading(false)
    }
}

export default BannerForm
