import { ButtonExportElement } from "./style";

interface ButtonEvent {
    onClick: any;
    disable?:boolean
}

const ButtonExport: React.FC<ButtonEvent> = ({onClick, disable}) => {
    return <ButtonExportElement className="p-button-raised p-button-text" label="Exportar" icon="pi pi-download" onClick={onClick} disabled={disable}/>
}

export default ButtonExport;
