import styles from './styles.module.css'
import computer from '../../../assets/computer.svg'

const Mobile = () => {
    return (
        <div className={styles.containerExterno}>
            <div className={styles.containerInterno}>
                <div className={styles.text}>
                    <h3>ATENÇÃO</h3>
                    <p>Este sistema deve ser utilizado apenas em tablets, notebooks e desktops!</p>
                </div>
                <div>
                    <img src={computer} alt="computer" className={styles.img}/>
                </div>
            </div>
        </div>
    )
}
export default Mobile