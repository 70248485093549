import { Form, Formik } from "formik";
import { InputTextarea } from "primereact/inputtextarea";
import { useCallback, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import * as Yup from "yup";
import emailIcon from "../../../assets/emailIcon.png";
import rightGuy from "../../../assets/rightGuy.png";
import { useAppContext } from "../../../context/app";
import ButtomRequestElement from "../../components/atoms/buttom-request-form";
import Error from "../../components/atoms/error-message";
import FormElementItems from "../../components/atoms/form-element-items";
import PageHeader from "../../components/atoms/page-header";
import SelectDropDownVm from "../../components/atoms/select-drop-down";
import { LineFormElement } from "../../components/molecules/item-form/style";
import { SelectElement } from "../../pages/modules/partner/product/form/style";
import FormTemplateElement from "../form-template";
import { LeftDivElement, MainDivElement, RightSideDivElement } from "./style";

const TalkToUs: React.FC = () => {

    let [subjectList, setSubjectList] = useState([])
    const { apiProvider } = useAppContext();
    const appContext = useAppContext();

    const validations = Yup.object({
        text: Yup.object().required(),
        message: Yup.string().required().max(2000),
    });

    const getSubjects = useCallback(async () => {
        const response: any = await apiProvider.get("/general/contact-us/subject");
        setSubjectList(response.data)
    }, [])

    useEffect(() => {
        getSubjects()
    }, [])

    const submit = async (values: any) => {
        const data = {
            message: values.message,
            subjectId: values.text ? values.text.id : null
        }

        appContext.setIsShowLoading(true)

        try {
            await apiProvider.post("/general/contact-us/send", data)
            appContext.toast.show({
                severity: "success",
                summary: "Sucesso",
                detail: "Seu pedido foi enviado e logo será analizado!",
                life: 4000,
            });

            setTimeout(() => {
                appContext.toast.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Você receberá o retorno em seu email cadastrado na plataforma.",
                    life: 5000,
                });
            }, 2000);


            values.text = ""
            values.message = ""

        } catch (error: any) {
            appContext.toast.show({
                severity: "error",
                summary: "Erro",
                detail: "Erro ao entrar em contato conosco, tente novamente! ",
                life: 4000,
            });
        }
        appContext.setIsShowLoading(false)
    };

    return (
        <>
            <PageHeader text="Entre em contato" />

            <FormTemplateElement style={{ padding: "0px" }}>
                <Formik
                    validationSchema={validations}
                    initialValues={{ text: "", message: "" }}
                    onSubmit={submit}
                >
                    {(propsForm) => (
                        <Form>

                            <MainDivElement>

                                {/* left div */}
                                <LeftDivElement>

                                    <LineFormElement>
                                        <SelectElement>
                                            <span style={{ color: "#42425d", fontWeight: "bold" }}>
                                                Assunto
                                            </span>
                                            <SelectDropDownVm
                                                style={{ width: "100%" }}
                                                optionLabel="text"
                                                onChange={propsForm.handleChange}
                                                value={propsForm.values.text}
                                                options={subjectList}
                                                name="text"
                                            />
                                            <Error name="text" errors={propsForm.errors} />
                                        </SelectElement>
                                    </LineFormElement>

                                    <FormElementItems>
                                        <span style={{ color: "#42425d", fontWeight: "bold", width: "100%", marginTop: "-10px" }}>
                                            Digite sua mensagem aqui:
                                        </span>

                                        <InputTextarea
                                            style={{ marginTop: "5px", width: "130%" }}
                                            value={propsForm.values.message}
                                            name="message"
                                            autoResize
                                            onChange={propsForm.handleChange}
                                        />
                                        <Error name="message" errors={propsForm.errors} style={{ textAlign: "left" }} />
                                    </FormElementItems>

                                    <ButtomRequestElement
                                        style={{ marginTop: "0px", width: "100%" }}
                                        type="submit"
                                        disabled={!propsForm.isValid || propsForm.isSubmitting} >
                                        Enviar
                                    </ButtomRequestElement>

                                    <LineFormElement style={{ justifyContent: "flex-start" }}>
                                        <FaWhatsapp style={{ height: "30px", width: "30px", marginTop: "25px" }} />
                                        <span style={{ marginTop: "30px" }}><a href="https://api.whatsapp.com/send?phone=5511976673372&text=Oi,%20estou%20com%20uma%20d%C3%BAvida,%20poderia%20me%20ajudar?" target="_blank" rel="noopener noreferrer">(11) 97667-3372</a></span>
                                    </LineFormElement>

                                    <LineFormElement style={{ justifyContent: "flex-start" }}>
                                        <img alt="" src={emailIcon} style={{ height: "25px", width: "25px", marginRight: "5px" }} />
                                        <span style={{}}><a href={`mailto:${'suporte@vmparcerias.com'}`}>Enviar e-mail</a></span>
                                    </LineFormElement>

                                </LeftDivElement>

                                {/* right div */}
                                <div style={{ width: "70%" }}>
                                    <RightSideDivElement>
                                        <img alt="" style={{ width: "400px", height: "400px" }} src={rightGuy}></img>
                                    </RightSideDivElement>
                                </div>


                            </MainDivElement>

                        </Form>
                    )}
                </Formik>



            </FormTemplateElement>
        </>
    );
};
export default TalkToUs;
