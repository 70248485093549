import trashIcon from "../../../../assets/buttons/copy.svg";
import { ActionButtons } from "../../../pages/modules/partner/benefit/view/style";
import {
    maskCEP,
    maskCNPJ,
    maskCPF,
    maskPhone,
} from "../../../templates/masks-template";
import { ErrorTextElement, InputTextVmElement, ItemFormElement, TipText } from "./style";

interface ItemFormProps {
    appContext?: any;
    copyText?: boolean;
    propsForm: any;
    name: string;
    label: string;
    widthPercent?: string;
    disabled?: boolean;
    type?: "text" | "number";
    tip?: string;
}

const ItemForm: React.FC<ItemFormProps> = ({
    appContext,
    copyText = false,
    propsForm,
    name,
    label,
    widthPercent,
    disabled = false,
    type = "text",
    tip
}) => {

    function validationForMask(): any {
        if (name === "cnpj") {
            return maskCNPJ(getAtrObj(propsForm.values, name));
        }
        if (name === "phone") {
            return maskPhone(getAtrObj(propsForm.values, name));
        }
        if (name === "address.zipCode") {
            return maskCEP(getAtrObj(propsForm.values, name));
        }
        if (name === "cpf") {
            return maskCPF(getAtrObj(propsForm.values, name));
        }

        return getAtrObj(propsForm.values, name);
    }

    const copyToClipboard = (e: any, text: any) => {
        e.preventDefault();

        if (text !== undefined) {
            if (!navigator.clipboard) {
                return;
            }

            return navigator.clipboard.writeText(text).then(() => {
                appContext.toast.show({
                    severity: "success",
                    summary: "Sucesso",
                    detail: "Texto copiado com sucesso!",
                    life: 3000,
                });
            }).catch(() => {
                appContext.toast.show({
                    severity: "error",
                    summary: "Atenção",
                    detail: "Não foi possivel copiar o texto, tente novamente!",
                    life: 3000,
                });
            });
        } else {
            appContext.toast.show({
                severity: "error",
                summary: "Atenção",
                detail: "Não foi possivel copiar o texto, tente novamente!",
                life: 3000,
            });
        }
    };

    return (
        <ItemFormElement style={{ width: `${widthPercent}%`, marginRight: "10px" }} >
            <span style={{ width: '100%' }}>{label}</span>
            {copyText && <ActionButtons style={{ marginLeft: '7rem', marginTop: '-1.7rem' }}><img src={trashIcon} onClick={(e) => copyToClipboard(e, getAtrObj(propsForm.values, name))} /></ActionButtons>}
            <InputTextVmElement
                disabled={disabled}
                onChange={propsForm.handleChange}
                onBlur={propsForm.handleBlur}
                value={validationForMask()}
                name={name}
                type={type}
                {...{ ...(type === "number" && { min: 1 }) }}
            />

            {
                tip && (<TipText>{tip}</TipText>)
            }

            <ErrorTextElement>
                {getErrorObject(propsForm.errors, name, propsForm.touched)}
            </ErrorTextElement>

        </ItemFormElement>
    );

    function getErrorObject(errors: any, name: string, touched: any) {
        const errorText = getAtrObj(errors, name);
        const touchedItem = getAtrObj(touched, name);

        if (touchedItem && errorText) {
            return errorText;
        }

        return <>&nbsp;</>;
    }

    function getAtrObj(obj: any, name: string) {
        const vet = name.split(".");

        return getAtrObjAuxRec(obj, vet, 0);
    }

    function getAtrObjAuxRec(obj: any, vet: string[], index: number): any {
        if (!obj) {
            return "";
        }

        if (vet.length > index) {
            const value = obj[vet[index]];
            return getAtrObjAuxRec(value, vet, ++index);
        }
        return obj;
    }
};

export default ItemForm;
