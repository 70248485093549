import { useNavigate } from "react-router";
import PageHeader from "../../../../../components/atoms/page-header";
import { ItemMenuReportElement } from "./style"

const ReportPartnerView: React.FC = () => {
    const navigate = useNavigate();
    return <>
        <PageHeader text="Relatórios"/> 

        <ItemMenuReportElement onClick={() => {navigate("/grouped-by-contractors-B2B") }}>
            Visualizações agrupadas por contratante
        </ItemMenuReportElement>
    </>
}

export default ReportPartnerView