import { Form, Formik } from 'formik';
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as Yup from 'yup';
import trashIcon from '../../../../../../../assets/buttons/trash.svg';
import { useAppContext } from "../../../../../../../context/app";
import { Contractor } from "../../../../../../../dto/contractor";
import { ModuleEnum } from "../../../../../../../dto/moduleEnum/module-enum";
import { ModuleEnumStatus } from '../../../../../../../dto/moduleEnum/module-enum-status';
import { ModuleRuleEnum } from "../../../../../../../dto/moduleEnum/module-rule-enum";
import { sanitizeError } from '../../../../../../../utils/sanitizeError';
import BackButtom from "../../../../../../components/atoms/back-buttom";
import { ButtonExportElement } from "../../../../../../components/atoms/buttom-export/style";
import PageHeader from "../../../../../../components/atoms/page-header";
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle";
import ItemForm from "../../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../../components/molecules/item-form/style";
import { HeaderElementLeft } from "../../../../../../components/organisms/header/style";
import FormTemplateElement from "../../../../../../templates/form-template";
import { DivInput } from "../../../../partner/benefit/form/style";
import { ActionButtons } from "../../../../partner/benefit/view/style";
import { ButtomRequestElement } from "../../../imports-view/style";

const ContractsForm: React.FC = () => {
    const appContext = useAppContext()
    const { apiProvider } = appContext
    const location = useLocation()
    const isNew = !location.state
    const { state } = location as any
    const navigate = useNavigate()
    const [associate, setAssociate] = useState({} as any)
    const [disabled, setDisabled] = useState(true)
    const [groupedContractors, setGroupedContractors] = useState([] as any[]);
    const [groupedPartner, setGroupedPartner] = useState([] as any[]);
    const [selectedDataB2BOrB2C, setSelectedDataB2BOrB2C] = useState<any>();
    const [selectedDataPartner, setSelectedDataPartner] = useState<Contractor>();
    const [rulesUpdated, setRulesUpdated] = useState([{ partner: "", type: "", value: 0, profitSharingRate: 0, profitSharingAmount: 0 }]);
    const [moduleEnumSelected, setModuleEnumSelected] = useState(0);
    const [taxData, setTaxData] = useState([] as any[])
    const [bill, setBill] = useState({ value: 0 })
    const [payToPartner, setPayToPartner] = useState({ value: 0 })
    const [profit, setProfit] = useState({ value: 0 })
    const [taxValue, setTaxValue] = useState({ value: 0 })
    const [partnerData, setPartnerData] = useState([] as any[])
    const [disabledInputs, setDisabledInputs] = useState(false)
    const [disabledInputsEdit, setDisabledInputsEdit] = useState(true)
    const [comparePartnersRules, setComparePartnersRules] = useState([] as [])
    const [comparePartnersAssociate, setComparePartnersAssociate] = useState([] as [])
    const [dialogVisible, setDialogVisible] = useState(false)
    const [errorMessages, setErrorMessages] = useState([] as any);
    const [paymentTypeOfThePayer, setPaymentTypeOfThePayer] = useState<number | null | undefined>(1);
    const [paymentAmountOfThePayer, setPaymentAmountOfThePayer] = useState<number | null | undefined>(0);
    const visibility = 'visible'
    const invisibile = 'hidden'

    const [rules, setRules] = useState([{
        id: 1,
        partner: "",
        type: "",
        value: null,
        disabledTypeView: true,
    }]);

    const optionsList = [
        {
            type: ModuleRuleEnum.PERCENT,
            typeView: '%'
        },
        {
            type: ModuleRuleEnum.FIXED_VALUE,
            typeView: 'Valor Fixo'
        }
    ];

    useEffect(() => {
        if (!isNew) {
            contractRulesEdit();
        }
    }, [partnerData]);

    // All use Effects are here
    useEffect(() => { // Busca os dados:
        getTaxType() // Dados do campo "Tributos(%)" cadastrados.
        if (state) {
            getOneContractor();
            changeTypeForPayers(state.item.typeOfPayer);
        } else {
            // do "Contratante" ou "parceiro" a depender do checkbox que estiver marcado;
            changeTypeForPayers(0)
        }
    }, []);

    useEffect(() => {
        handleChangeTotalToPay()
    }, [taxValue, bill, rulesUpdated, rules, associate, rulesUpdated])

    // Espera setar um valor na variável "associate" para chamar a "changeTypeForPayers". Isso é necessário para que ao editar um contratante o campo "Contratante" ou "Parceiro" venham com o valor preenchido
    useEffect(() => {
        changeTypeForPayers(associate.typeOfPayer)
        if (!isNew) {
            contractRulesEdit()
            validations
        }
    }, [associate])

    useEffect(() => { // Atualiza o campos: Desabilitado para falso e busca os dados dos parceiros
        setDisabled(false);
        PartnerPart()
    }, [selectedDataB2BOrB2C]) //Sempre que for selecionado o contratante ou parceiro

    useEffect(() => { // Atualiza o campos: Desabilitado para verdadeiro se for um Contratante e for um novo registro
        if (selectedDataB2BOrB2C == 0 && isNew) {
            setDisabled(true)
        }
    }, [changeTypeForPayers]) // Quando o checkbox do incio da página for alterado

    useEffect(() => { // atualize o vetor das partes do contrato
        const newArray: any = rules.map((item: any) => {
            const { id, partner, disabledTypeView, ...resto } = item;
            const partnerId = partner && typeof partner === 'object' ? partner.id : null;
            const typeOfPayment = item.type;
            let profitSharingRate = 0;
            let profitSharingAmount = 0;
            if (typeOfPayment == 0) {
                profitSharingRate = item.value / 100; // Converting to percetual for intragrate with ASAAS
            } else {
                profitSharingAmount = item.value * 100; // Multiplicate per 100 for intragrate with ASAAS
            }
            if (!isNew) {
                return {
                    ...resto,
                    id,
                    partnerId,
                    typeOfPayment,
                    profitSharingRate,
                    profitSharingAmount,
                };
            } else {
                return {
                    ...resto,
                    partnerId,
                    typeOfPayment,
                    profitSharingRate,
                    profitSharingAmount,
                };
            }

        });

        setRulesUpdated(newArray);
        handleChangeTotalToPay()
    }, [rules]); // Sempre que for selecionado as regras forem alteradas

    async function getOneContractor() {
        const response: any = await apiProvider.get(`/admin/contract/${state.item.id}`);

        response.data.isActive = (response.data.isActive == ModuleEnumStatus.ACTIVE);
        response.data.billValue = response.data.billValue / 100
        response.data.profit = response.data.profit / 100
        response.data.taxTypeValue = response.data.taxTypeValue / 100
        response.data.totalAmountPayable = response.data.totalAmountPayable / 100

        setBill({ value: response.data.billValue })
        setPaymentTypeOfThePayer(response.data.paymentTypeOfThePayer);
        setPaymentAmountOfThePayer((response.data.paymentAmountOfThePayer / 100));
        setAssociate(response.data);
    }

    async function getTaxType() { // Função que seta a lista do campo "Tributos (%)"
        await apiProvider.get(`/admin/taxtype`).then((res: any) => {
            let taxTypeList: any = [];
            if (res.status == 200) {
                res.data.map((item: any) => {
                    taxTypeList.push({ taxData: `${(item?.percentage * 100).toFixed(2)}% - ${item?.name}`, value: item?.id, percentage: item?.percentage })
                })
                setTaxData(taxTypeList)
            }
        })
    }

    // Altera os dados do campo "Contratante" (ou parceiro) dependendo do checkbox selecionado.
    async function changeTypeForPayers(type: number) {
        setModuleEnumSelected(type);

        let url = ''
        if (type == 0 || type == 2) { // Type of Contractor
            url = '/admin/contractor'
        } else if (type == 1) { // Type of Partner
            url = '/admin/partner'
        }
        const responseData: any = await apiProvider.get(url)

        if (responseData?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            responseData.data.forEach((item: any) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({ label: item.name, value: item });

                    if (state?.item?.contractorPartnerCnpj == item.cnpj) {
                        setSelectedDataB2BOrB2C(item);
                    } else if (!isNew && associate?.contractorPartnerId == item.id) {
                        setSelectedDataB2BOrB2C(item);
                    }
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({ label: item.name, value: item });
                    if (state?.item?.contractorPartnerCnpj == item.cnpj) {
                        setSelectedDataB2BOrB2C(item);
                    } else if (!isNew && associate?.contractorPartnerId == item.id) {
                        setSelectedDataB2BOrB2C(item);
                    }
                }
            });

            let objectB2cAndB2B = [];
            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2C', items: aB2C })
            }
            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2B', items: aB2B })
            }

            setGroupedContractors(objectB2cAndB2B)
        }
    }

    async function PartnerPart() { // Solicita ao backend os todos os parceiros do B2B e do B2C
        const responseData: any = await apiProvider.get("/admin/partner")

        setPartnerData(responseData.data)
        if (responseData?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            responseData.data.forEach((item: any) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({ label: item.name, value: item });

                    if (state?.item?.contractorPartnerCnpj == item.cnpj) {
                        setSelectedDataPartner(item);
                    }
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({ label: item.name, value: item });
                    if (state?.item?.contractorPartnerCnpj == item.cnpj) {
                        setSelectedDataPartner(item);
                    }
                }
            });

            let objectB2cAndB2B = [];

            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2C', items: aB2C })
            }

            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({ label: 'B2B', items: aB2B })
            }

            setGroupedPartner(objectB2cAndB2B)
        }
    }

    const validations = Yup.object().shape({ // Validações para liberar o "Salvar dados"
        name: Yup.string().required(),
    })

    const isLoadApi = (): boolean => {
        return !!associate.id
    }

    const addRule = () => { // Adiciona uma "Parte do Contrato"
        const newRule = {
            id: rules.length + 1,
            partner: "",
            type: '',
            value: null,
            disabledTypeView: true,
        };

        setRules([...rules, newRule]);
    }

    const contractRulesEdit = async () => {
        if (!isNew) {
            await setRules([]);
        }
        associate?.contractRule?.forEach((item: any) => {
            let id = item.id,
                partnerId = item.partnerId,
                partner: object = [],
                type = item.typeOfPayment !== null ? item.typeOfPayment : 0,
                value: any = 0,
                disabledTypeView = false;

            partnerData?.forEach((itemPartnerData: any) => {
                if (partnerId == itemPartnerData.id) {
                    partner = itemPartnerData
                }
            })

            if (type === 0) {
                value = (item.profitSharingRate * 100);
            } else {
                value = (item.profitSharingAmount / 100);
            }
            const ruleUpdate = {
                id,
                partner,
                type,
                value,
                disabledTypeView,
                key: item.id.toString(),
            };

            // @ts-ignore
            setRules((prevRules) => [...prevRules, ruleUpdate]);
        });
    };

    const removeRule = (id: any) => { // Remove a respectiva "Parte do Contrato"
        const updatedRules = rules.filter(rule => rule.id !== id);
        setRules(updatedRules); // Atualiza o state "Rules"
        handleChangeTotalToPay() // Atualiza o valor do campo "Valor a pagar"
    }

    const handleRuleUpdate = async (nameField: any, value: any, itemId: any) => {
        let sumValuePercent = 0
        const updatedRules = rules.map((item) => {
            if (item.id === itemId) {
                if (nameField != 'type') {
                    return { ...item, [nameField]: value };
                } else {
                    return { ...item, [nameField]: value.type, disabledTypeView: false };
                }
            }

            if (parseInt(item.type) == ModuleRuleEnum.PERCENT) {
                sumValuePercent += (item.value == null) ? 0 : item.value
            }

            return item;
        });

        setRules(updatedRules);
    };

    const save = async (values: any) => {
        try {
            appContext.setIsShowLoading(true)
            let percentageData: any = { id: 0, value: 0 }


            if (moduleEnumSelected == 0) {
                rules.map((item) => {
                    if (parseInt(item.type) < 0 || item.value == null) {
                        throw new Error("Preencha todos os campos das regras do contrato");
                    }
                });
            }

            taxData.forEach((item) => {
                if (item.value == values.taxTypePercentual) {
                    percentageData = { id: item.value, value: item.percentage };
                }
            });

            let data = {
                name: values.name,
                description: values.description,
                isActive: (values.isActive || values.isActive == undefined) ? ModuleEnumStatus.ACTIVE : ModuleEnumStatus.INACTIVE,
                typeOfPayer: moduleEnumSelected,
                personBillingId: selectedDataB2BOrB2C?.id,
                billValue: (parseFloat((bill.value).toFixed(2)) * 100), // Multiplicate per 100 for intragrate with ASAAS
                taxTypeId: percentageData.id,
                taxTypeValue: (parseFloat((taxValue.value).toFixed(2)) * 100),
                totalAmountPayable: (parseFloat((payToPartner.value).toFixed(2)) * 100), // Multiplicate per 100 for intragrate with ASAAS,
                profit: (parseFloat((profit.value).toFixed(2)) * 100),
                paymentTypeOfThePayer: paymentTypeOfThePayer ? paymentTypeOfThePayer : 0,
                paymentAmountOfThePayer: paymentAmountOfThePayer ? (parseFloat((paymentAmountOfThePayer).toFixed(2)) * 100) : 0, // Multiplicate per 100 for intragrate with ASAAS
                contractRule: rulesUpdated,
            }

            let msg = "";
            if (!isNew) {
                await apiProvider.put(`/admin/contract/${state.item.id}`, data)
                msg = "Alteração realizada com sucesso!"
            } else {
                await apiProvider.post('/admin/contract', data)
                msg = "Cadastrado com sucesso!"
            }

            navigate('/contracts-view')

            appContext.toast.show({
                severity: 'success',
                summary: 'Contrato',
                detail: msg,
                life: 3000,
            })
        } catch (error: any) {
            try {
                setErrorMessages(await sanitizeError(error.response.data));
                setDialogVisible(true);
            } catch (error: any) {
                appContext.toast.show({
                    severity: 'error',
                    summary: 'Contrato',
                    detail: "Por favor, verifique os dados de contratante e parceiro(s) e tente novamente",
                    life: 3000,
                });
            }
        } finally {
            appContext.setIsShowLoading(false);
        }
    }

    const setValues = (e: any) => { // Atualiza o "Valor" do Boleto
        setBill(e)
    }

    const handleChangeTotalToPay = async () => { // Seta os valores de "Total a Pagar"
        let paySum: number = 0
        let profit: number = 0

        rulesUpdated.map((item) => {
            if (!bill.value) {
                bill.value = associate.billValue
            }

            if (item.profitSharingRate) {
                paySum += (bill.value * item.profitSharingRate)
            } else {
                paySum += (item.profitSharingAmount / 100)
            }
        })

        if (bill.value && (taxValue.value || paySum)) {
            profit = bill.value - ((!isNew ? (taxValue.value || associate.taxTypeValue) : taxValue.value) + paySum);
            await setProfit({ value: profit });

        }

        await setPayToPartner({ value: paySum });

    }

    async function calculateTaxValue(e?: any) { // "Tributos (R$)" -> Calcula o valor monetário do imposto sobre o boleto
        let setValueTax: number = 0
        let taxValue: number = 0
        taxData.map((item) => {
            if (item.value === e?.values?.taxTypePercentual) {
                taxValue = item.percentage
            }
        });
        if (bill != null && e.values?.taxTypePercentual) {
            setValueTax = bill?.value * taxValue
            setTaxValue({ value: setValueTax })
        } else {
            setTaxValue({ value: 0 })
        }
        handleChangeTotalToPay
    }

    useEffect(() => {
        if (isNew) {
            disabledInputsVerification();
        } else {
            const newArrayRules: any = rules?.map((item: any) => {
                const { partner } = item;
                const partnerId = partner && typeof partner === 'object' ? partner.id : null;
                return {
                    partnerId,
                };
            });
            setComparePartnersRules(newArrayRules);

            const newArrayAssociate: any = associate?.contractRule?.map((item: any) => {
                const partnerId = item.partnerId;
                return {
                    partnerId,
                };
            });
            setComparePartnersAssociate(newArrayAssociate);

            if (
                comparePartnersRules &&
                comparePartnersAssociate &&
                JSON.stringify(comparePartnersRules) !== JSON.stringify(comparePartnersAssociate) ||
                (associate.billValue != bill.value) ||
                (taxValue.value != 0) ||
                (profit.value != associate.profit)
            ) {
                setDisabledInputsEdit(false)
            } else {
                setDisabledInputsEdit(true)
            }
        }
    }, [moduleEnumSelected, bill, taxValue, profit, rulesUpdated, rules]);

    const disabledInputsVerification = () => {
        if (moduleEnumSelected != 1) {
            if (
                (moduleEnumSelected == undefined) ||
                (bill.value == undefined) || (taxValue.value == 0) ||
                (profit.value === 0) ||
                (rules[0].value == null || rules[0].value == 0)) {
                setDisabledInputs(true);
            } else {
                rules.map((item) => {
                    if (parseInt(item.type) < 0 || item.value == null) {
                        setDisabledInputs(true);
                    } else {
                        setDisabledInputs(false);
                    }
                });
            }
        } else {
            if (
                (moduleEnumSelected == undefined) ||
                (bill.value == undefined) || (taxValue.value == 0) ||
                (profit.value === 0)) {
                setDisabledInputs(true);
            } else {
                setDisabledInputs(false);
            }
        }
    }

    // Função para ocultar o diálogo
    const hideDialog = () => {
        setDialogVisible(false);
    };

    return (
        <>
            <PageHeader text={isNew ? 'Novo Contrato' : ''} />

            <FormTemplateElement>

                <BackButtom onclick={() => {
                    navigate('/contracts-view')
                }} />

                <SpanSubtitle text='Dados' />

                {(isLoadApi() || isNew) && (
                    <Formik
                        initialValues={associate}
                        validationSchema={validations}
                        onSubmit={save}
                    >
                        {(propsForm) => (
                            <Form>
                                <Accordion activeIndex={0}>
                                    <AccordionTab header={'Informações do contrato'}>
                                        <SpanSubtitle text='Quem vai pagar o boleto?' />
                                        <HeaderElementLeft style={{ paddingLeft: '0px' }}>
                                            <div
                                                style={{
                                                    textAlign: "center",
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "left",
                                                    marginTop: "5px",
                                                    marginBottom: "20px"
                                                }}
                                            >
                                                <Checkbox
                                                    style={{ marginRight: "10px" }}
                                                    checked={moduleEnumSelected == 0}
                                                    onChange={() => {
                                                        changeTypeForPayers(0);
                                                    }}
                                                    disabled={!isNew}
                                                />

                                                Contratante
                                                <Checkbox
                                                    style={{ marginRight: "10px", marginLeft: "15px" }}
                                                    checked={moduleEnumSelected == 1}
                                                    onChange={() => {
                                                        changeTypeForPayers(1);
                                                    }}
                                                    disabled={!isNew}
                                                />
                                                Parceiro
                                                {/* <Checkbox
                                                    style={{ marginRight: "10px", marginLeft: "15px" }}
                                                    checked={moduleEnumSelected == 2}
                                                    onChange={() => {
                                                        changeTypeForPayers(2);
                                                    }}
                                                    disabled={!isNew}
                                                />
                                                Usuário do app */}
                                            </div>
                                        </HeaderElementLeft>
                                        <div style={{ width: "100%", borderBottom: "1px solid #D3D3D3", marginBottom: "20px" }}>
                                        </div>
                                        <LineFormElement>
                                            <DivInput style={{ width: '100%' }}>
                                                {(moduleEnumSelected != 1) ? (<strong>Contratante</strong>) : (<strong>Parceiro</strong>)}
                                                <Dropdown
                                                    name='contractor'
                                                    style={{ width: '100%', height: '50px', borderRadius: '10px' }}
                                                    disabled={!isNew || (isNew && moduleEnumSelected == undefined)}
                                                    value={selectedDataB2BOrB2C}
                                                    onChange={(e) => setSelectedDataB2BOrB2C(e.value)}
                                                    options={groupedContractors}
                                                    optionLabel="label"
                                                    optionGroupLabel="label"
                                                    optionGroupChildren="items"
                                                    emptyMessage="Nenhum resultado encontrado."
                                                    emptyFilterMessage="Nenhum resultado encontrado."
                                                    placeholder={(moduleEnumSelected != 1) ? ("Selecione o contratante") : ("Selecione o parceiro")}
                                                    showFilterClear
                                                    filter
                                                />
                                            </DivInput>
                                        </LineFormElement>

                                        <LineFormElement style={{ width: '100%', marginTop: "30px" }}>
                                            <DivInput style={{ width: '50%' }}>
                                                <strong>Tipo de recebimento</strong>
                                                <Dropdown
                                                    style={{ marginTop: '10px', height: '52px', borderRadius: '9px' }}
                                                    value={{
                                                        typeView: (paymentTypeOfThePayer !== undefined && paymentTypeOfThePayer !== null && paymentTypeOfThePayer == ModuleRuleEnum.PERCENT) ? optionsList[0].typeView : optionsList[1].typeView,
                                                        type: paymentTypeOfThePayer
                                                    }}
                                                    options={optionsList}
                                                    optionLabel="typeView"
                                                    placeholder="Selecione"
                                                    onChange={(e) => setPaymentTypeOfThePayer(e.target.value.type)}
                                                />
                                            </DivInput>
                                            <DivInput style={{ width: '50%' }}>
                                                <strong>Valor à receber</strong>
                                                {
                                                    (paymentTypeOfThePayer !== undefined && paymentTypeOfThePayer !== null && paymentTypeOfThePayer == ModuleRuleEnum.PERCENT) ?
                                                        <InputNumber
                                                            inputId="currency-brl"
                                                            value={paymentAmountOfThePayer}
                                                            onValueChange={(e) => setPaymentAmountOfThePayer(e.target.value)}
                                                            minFractionDigits={2}
                                                            suffix='%'
                                                            style={{ marginTop: '10px' }}
                                                            min={0}
                                                            max={100}
                                                            disabled={propsForm.values.disabledTypeView}
                                                        /> :
                                                        <InputNumber
                                                            inputId="currency-us"
                                                            value={paymentAmountOfThePayer}
                                                            onValueChange={(e) => setPaymentAmountOfThePayer(e.target.value)}
                                                            mode='currency'
                                                            currency='BRL'
                                                            locale='pt-BR'
                                                            minFractionDigits={2}
                                                            style={{ marginTop: '10px' }}
                                                            disabled={propsForm.values.disabledTypeView}
                                                        />
                                                }
                                            </DivInput>
                                        </LineFormElement>

                                        <LineFormElement style={{ justifyContent: 'space-between', display: 'block', marginTop: "30px" }}>
                                            <strong>Nome do contrato</strong>
                                            <ItemForm
                                                propsForm={propsForm}
                                                name='name'
                                                label=''
                                                widthPercent='100'
                                            />
                                        </LineFormElement>
                                        <strong>Descrição</strong>
                                        <LineFormElement style={{ justifyContent: 'space-between', height: '100px' }}>
                                            <LineFormElement style={{ justifyContent: "flex-start" }}>
                                                <InputTextarea
                                                    style={{ width: "98.5%" }}
                                                    value={propsForm.values.description}
                                                    name="description"
                                                    autoResize
                                                    onChange={propsForm.handleChange}
                                                />
                                            </LineFormElement>

                                            <DivInput style={{ width: '10%' }}>
                                                <strong>Ativo</strong>
                                                <InputSwitch
                                                    name='isActive'
                                                    checked={(isNew && propsForm.values.isActive == null) ? true : propsForm.values.isActive}
                                                    onChange={propsForm.handleChange}
                                                />
                                            </DivInput>
                                        </LineFormElement>
                                    </AccordionTab>
                                    <AccordionTab header={'Configurações do contrato'} disabled={disabled}>
                                        <SpanSubtitle text='Dados do boleto' />
                                        <LineFormElement style={{
                                            marginBottom: '3rem',
                                            width: '100%',
                                            justifyContent: 'space-between'
                                        }}>
                                            <DivInput style={{ width: '48%' }}>
                                                <strong>Valor total do boleto</strong>
                                                <InputNumber
                                                    name='billValue'
                                                    inputId="currency-us"
                                                    onChange={(e) => setValues(e)}
                                                    value={bill.value ? bill.value : !isNew ? (propsForm.values.billValue || associate.billValue) : propsForm.values.billValue}
                                                    mode='currency'
                                                    currency='BRL'
                                                    locale='pt-BR'
                                                    minFractionDigits={2}
                                                    style={{ marginTop: '10px' }}
                                                    placeholder='R$ 0,00'
                                                    onFocus={(e) => { e.target.setAttribute('autocomplete', 'off'); }} // Desabilita a sugestão de valores ao receber foco
                                                />
                                            </DivInput>
                                            <DivInput style={{ width: '100%' }}>
                                                <strong>Tributos (%)</strong>
                                                <Dropdown
                                                    style={{
                                                        marginTop: '10px',
                                                        height: '52px',
                                                        borderRadius: '9px'
                                                    }}
                                                    name='taxTypePercentual'
                                                    disabled={bill.value == undefined}
                                                    value={!isNew ? (propsForm?.values?.taxTypePercentual || associate.taxTypeId) : propsForm?.values?.taxTypePercentual}
                                                    options={taxData}
                                                    optionLabel="taxData"
                                                    placeholder="Selecione"
                                                    onChange={propsForm.handleChange}
                                                    onBlur={(e) => calculateTaxValue(propsForm)}
                                                />
                                            </DivInput>
                                        </LineFormElement>

                                        {(moduleEnumSelected == 1) ?
                                            <LineFormElement style={{
                                                marginBottom: '3rem',
                                                width: '100%',
                                                justifyContent: 'space-between'
                                            }}>
                                                <DivInput style={{ width: '50%' }}>
                                                    <strong>Tributos(R$)</strong>
                                                    <InputNumber
                                                        inputId="currency-us"
                                                        name='amountToPay'
                                                        value={!isNew ? (taxValue.value || associate.taxTypeValue) : taxValue.value}
                                                        onValueChange={propsForm.handleChange}
                                                        onChange={() => handleChangeTotalToPay()}
                                                        mode='currency'
                                                        currency='BRL'
                                                        locale='pt-BR'
                                                        minFractionDigits={2}
                                                        style={{ marginTop: '10px' }}
                                                        disabled={true}
                                                    />
                                                </DivInput>
                                                <DivInput style={{ width: '50%' }}>
                                                    <strong>Lucro líquido</strong>
                                                    <InputNumber
                                                        inputId="currency-us"
                                                        value={!isNew ? (profit.value || associate.profit) : profit.value}
                                                        mode='currency'
                                                        currency='BRL'
                                                        locale='pt-BR'
                                                        minFractionDigits={2}
                                                        style={{ marginTop: '10px' }}
                                                        disabled={true}
                                                    />
                                                </DivInput>
                                            </LineFormElement>
                                            : (
                                                <LineFormElement style={{
                                                    marginBottom: '3rem',
                                                    width: '100%',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <DivInput style={{ width: '33%' }}>
                                                        <strong>Tributos(R$)</strong>
                                                        <InputNumber
                                                            inputId="currency-us"
                                                            name='amountToPay'
                                                            value={!isNew ? (taxValue.value || associate.taxTypeValue) : taxValue.value}
                                                            onValueChange={propsForm.handleChange}
                                                            onChange={() => handleChangeTotalToPay()}
                                                            mode='currency'
                                                            currency='BRL'
                                                            locale='pt-BR'
                                                            minFractionDigits={2}
                                                            style={{ marginTop: '10px' }}
                                                            disabled={true}
                                                        />
                                                    </DivInput>
                                                    <DivInput style={{ width: '33%' }}>
                                                        <strong>Total a pagar às partes</strong>
                                                        <InputNumber
                                                            inputId="currency-us"
                                                            name='amountToPay'
                                                            value={!isNew ? (payToPartner.value || associate.totalAmountPayable) : payToPartner.value}
                                                            onValueChange={propsForm.handleChange}
                                                            mode='currency'
                                                            currency='BRL'
                                                            locale='pt-BR'
                                                            minFractionDigits={2}
                                                            style={{ marginTop: '10px' }}
                                                            disabled={true}

                                                        />
                                                    </DivInput>
                                                    <DivInput style={{ width: '33%' }}>
                                                        <strong>Lucro líquido</strong>
                                                        <InputNumber
                                                            inputId="currency-us"
                                                            value={!isNew ? (profit.value || associate.profit) : profit.value}
                                                            mode='currency'
                                                            currency='BRL'
                                                            locale='pt-BR'
                                                            minFractionDigits={2}
                                                            style={{ marginTop: '10px' }}
                                                            disabled={true}
                                                        />
                                                    </DivInput>
                                                </LineFormElement>
                                            )
                                        }

                                        <div style={{ width: "100%", borderBottom: "1px solid #D3D3D3", marginBottom: "20px", display: `${(moduleEnumSelected != 1 && bill.value != undefined) ? "block" : "none"}` }} />

                                        <div style={{ display: `${(moduleEnumSelected != 1 && bill.value != undefined) ? "block" : "none"}` }}><SpanSubtitle text='Partes do contrato' /></div>
                                        {(moduleEnumSelected != 1 && bill.value != undefined) && rules.map((item, idx) => {
                                            return (
                                                <>
                                                    <LineFormElement
                                                        style={{
                                                            marginBottom: '15px',
                                                            width: '100%',
                                                            justifyContent: 'space-between'
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <DivInput style={{ width: '36%' }}>
                                                            <strong>Parceiro</strong>
                                                            <Dropdown
                                                                name='partner'
                                                                style={{
                                                                    height: '50px',
                                                                    borderRadius: '10px',
                                                                    marginTop: '10px'
                                                                }}
                                                                value={item.partner}
                                                                onChange={(e) => handleRuleUpdate('partner', e.target.value, item.id)}
                                                                options={groupedPartner}
                                                                optionLabel="label"
                                                                optionGroupLabel="label"
                                                                optionGroupChildren="items"
                                                                emptyMessage="Nenhum resultado encontrado."
                                                                emptyFilterMessage="Nenhum resultado encontrado."
                                                                placeholder="Selecione o parceiro"
                                                                showFilterClear
                                                                filter
                                                            />
                                                        </DivInput>
                                                        <DivInput style={{ width: '36%' }}>
                                                            <strong>Tipo de pagamento</strong>
                                                            <Dropdown
                                                                style={{
                                                                    marginTop: '10px',
                                                                    height: '52px',
                                                                    borderRadius: '9px'
                                                                }}
                                                                value={{
                                                                    typeView: (parseInt(item.type) == ModuleRuleEnum.PERCENT) ? optionsList[0].typeView : optionsList[1].typeView,
                                                                    type: item.type
                                                                }}
                                                                options={optionsList}
                                                                optionLabel="typeView"
                                                                placeholder="Selecione"
                                                                onChange={(e) => handleRuleUpdate('type', e.target.value, item.id)}
                                                            />
                                                        </DivInput>
                                                        <DivInput style={{ width: '30%' }}>
                                                            <strong>Valor a pagar</strong>
                                                            {(parseInt(item.type) == ModuleRuleEnum.PERCENT) ?
                                                                <InputNumber
                                                                    inputId="currency-brl"
                                                                    value={item?.value}
                                                                    onValueChange={(e) => handleRuleUpdate('value', e.target.value, item.id)}
                                                                    onBlur={() => handleChangeTotalToPay()}
                                                                    minFractionDigits={2}
                                                                    suffix='%'
                                                                    style={{ marginTop: '10px' }}
                                                                    min={0}
                                                                    max={100}
                                                                    disabled={item.disabledTypeView}
                                                                /> :
                                                                <InputNumber
                                                                    inputId="currency-us"
                                                                    value={item?.value}
                                                                    onValueChange={(e) => handleRuleUpdate('value', e.target.value, item.id)}
                                                                    onBlur={() => handleChangeTotalToPay()}
                                                                    mode='currency'
                                                                    currency='BRL'
                                                                    locale='pt-BR'
                                                                    minFractionDigits={2}
                                                                    style={{ marginTop: '10px' }}
                                                                    disabled={item.disabledTypeView}
                                                                />
                                                            }
                                                        </DivInput>
                                                        <ActionButtons style={{ marginTop: '35px', width: '5%', visibility: `${(idx != 0) ? visibility : invisibile}` }}
                                                            type='button'>
                                                            <img src={trashIcon}
                                                                alt="remover regra"
                                                                onClick={() => removeRule(item.id)}
                                                            />
                                                        </ActionButtons>
                                                    </LineFormElement>
                                                </>
                                            )
                                        })} {/*End of map*/}

                                        <div style={{ textAlign: 'end', paddingTop: '30px', display: `${(moduleEnumSelected != 1 && bill.value != undefined) ? "block" : "none"}` }}>
                                            <div style={{ textAlign: 'end' }}>
                                                <ButtonExportElement
                                                    className="p-button-raised p-button-text w-[200px]"
                                                    label="Adicionar"
                                                    icon="pi pi-plus"
                                                    type='button'
                                                    onClick={addRule}
                                                />
                                            </div>
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                                <div style={{ textAlign: 'end' }}>
                                    <ButtomRequestElement
                                        type='submit'
                                        disabled={isNew ? (!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty || disabledInputs) : ((!propsForm.dirty) && (disabledInputsEdit))}
                                    >
                                        Salvar dados
                                    </ButtomRequestElement>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}

                <Dialog
                    visible={dialogVisible}
                    header={"Atenção"}
                    onHide={hideDialog}
                    breakpoints={{ '960px': '75vw', '640px': '100vw' }}
                    style={{ width: '75vw', height: '80vw' }}
                >
                    <>
                        <div>
                            {errorMessages &&
                                errorMessages.map((item: any) => {
                                    return (<li>{item}</li>)
                                })
                            }
                        </div>
                        <div style={{ position: 'absolute', bottom: '1.5rem', right: '1.5rem' }}>
                            <ButtomRequestElement
                                onClick={hideDialog}
                                disabled={false}
                            >
                                Fechar
                            </ButtomRequestElement>
                        </div>
                    </>

                </Dialog>

            </FormTemplateElement>
        </>
    )
}

export default ContractsForm
