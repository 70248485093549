import { useNavigate } from "react-router";
import PageHeader from "../../../../../components/atoms/page-header";
import { ItemMenuReportElement } from "./style";

const ReportContractorView: React.FC = () => {
    const navigate = useNavigate();
    return <>
        <PageHeader text="Relatórios"/> 
        <ItemMenuReportElement onClick={() => {navigate("/grouped-by-partner-B2B") }}>
            Vizualizações agrupadas por parceiros
        </ItemMenuReportElement>
    </>
}

export default ReportContractorView