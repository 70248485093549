import { CSSProperties } from "styled-components";
import plusSign from "../../../../assets/buttons/plus-sign.svg";
import { ButtomAddElement } from "./style";

interface ButtonEvent {
  onclick: any;
  text: string;
  style?: CSSProperties
  disable?:boolean
}

const ButtomAddVm: React.FC<ButtonEvent> = ({ onclick, text, style, disable }) => {
  return (
    <ButtomAddElement onClick={onclick} style={style} disabled={disable}>
      <img style={{ marginRight: "10px" }} alt="" src={plusSign} /> {text}
    </ButtomAddElement>
  );
};

export default ButtomAddVm;
