import {Auth} from 'aws-amplify';
import {Session} from '../dto/session';
import ApiService from './api';
import {ModuleEnum} from "../dto/moduleEnum/module-enum";

const key_session = '@vmparcerias:user_data';

export default class SessionProvider {
  constructor(private apiService: ApiService) {
  }

  getSession(): Session {
    const userLogged: any = localStorage.getItem(key_session);
    return JSON.parse(userLogged);
  }

  setSessionFirstAccess(): boolean {
    const userLogged: any = localStorage.getItem(key_session);

    let json =  JSON.parse(userLogged);
    json.user.firstAccess = false;

    localStorage.setItem(key_session, JSON.stringify(json));

    return true;
  }

  async initSessionTemp(email: string, password: string) {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    let token = '';

    if (
        environment !== 'local'
        // && environment !== "dev"
    ) {
      const user = await Auth.signIn(email, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        throw new Error('NEW_PASSWORD_REQUIRED');
      }

      if (user.attributes.email_verified) {
        token = user.signInUserSession.accessToken.jwtToken;
      } else {
        throw new Error('EMAIL_NOT_VERIFIED');
      }
    } else {
      token = email;
    }

    if (!token) {
      const tokenStorage = localStorage.getItem('@vmparcerias:app_token');
      if (!tokenStorage) {
        throw new Error('ACCESS_DENIED');
      } else {
        token = tokenStorage;
      }
    }

    this.apiService.setToken(token);
    return await this.initSession();
  }

  async initSession() {
    try {
      const session = this.getSession();
      if (session != null && session.user != null) {
        const tokenStorage = localStorage.getItem('@vmparcerias:app_token');
        if (!tokenStorage) {
          throw new Error('ACCESS_DENIED');
        }

        if (session.modules.length === 0 || session.modules[0].moduleEnum === ModuleEnum.APP) {
          throw new Error('ACCESS_DENIED');
        }

        return session;
      }

      const response: any = await this.apiService.get('web/session');

      if (response.data.modules.length === 0 || response.data.modules[0].moduleEnum === ModuleEnum.APP) {
        throw new Error('ACCESS_DENIED');
      }

      localStorage.setItem(key_session, JSON.stringify(response.data));
      return response.data;
    } catch (e: any) {
      await this.deleteSession();
      return null;
    }

    return null;
  }

  async deleteSession() {
    const keep_logged = localStorage.getItem("@vmparcerias:keep_logged")
    localStorage.clear()
    localStorage.setItem('@vmparcerias:keep_logged', `${keep_logged}`);
    await Auth.signOut({global: true});
  }
}
