import figura1 from "../../../../../assets/contractor/banner1.png"
import figura2 from "../../../../../assets/contractor/banner2.png"
import figura3 from "../../../../../assets/contractor/banner3.png"
import { Banner } from "../../../../components/molecules/carousel-vm"
import LoginGeneric from "../../../../templates/login"

const Login: React.FC = () => {
  const banners: Banner[] = [
    {
      img: figura1,
      title: "Acompanhe seus usuários",
      text1: "Fique por dentro de todos seus ganhos",
      text2: (
        <>
          {" "}
          de forma &nbsp; <strong>diária, semanal e mensal.</strong>{" "}
        </>
      ),
    },
    {
      img: figura2,
      title: "Administre suas comissões",
      text1: "Aqui é possível acompanhar seus usuários ",
      text2: "gerados através do uso do APP.",
    },
    {
      img: figura3,
      title: "Esteja sempre por dentro",
      text1: "Use nossas ferramentas para acompanhar",
      text2: <strong>suas melhorias e saia na frente.</strong>,
    },
  ]

  return <LoginGeneric banners={banners} />
}

export default Login
