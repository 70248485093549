import styled from "styled-components";

import InputTextVm from "../../atoms/input-text";

export const ItemFormElement = styled.div`
  display: flex;
  width: 32%;
  flex-direction: column;
  height: 100px;
  white-space: nowrap;
`;

export const ErrorTextElement = styled.span`
  color: red;
  text-align: right;
  margin-top: 5px;
  font-size: 12px;
`;

export const InputTextVmElement = styled(InputTextVm)`
  width: 97%;
  border-radius: 10px;
`;

export const LineFormElement = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const TipText = styled.span`
  color: #766868;
  text-align: left;
  font-size: 12px;
`