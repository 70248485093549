
import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import penIcon from "../../../../../../assets/buttons/pen.svg"
import trashIcon from "../../../../../../assets/buttons/trash.svg"
import { useAppContext } from "../../../../../../context/app"
import { ISessionContext, useSessionContext } from "../../../../../../context/session"
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum"
import { confirm } from "../../../../../../utils/validation"
import ButtomAddVm from "../../../../../components/atoms/buttom-add-vm"
import ButtonExport from "../../../../../components/atoms/buttom-export"
import DataTableElement from "../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../components/molecules/search-text-label"
import {
    HeaderElementLeft,
    HeaderElementRight
} from "../../../../../components/organisms/header/style"
import { maskCNPJ, maskCPF } from "../../../../../templates/masks-template"
import HeaderViewElement from "../../../../../templates/view-header-template"
import TableViewElement from "../../../../../templates/view-table-template"
import { ActionButtons } from "../../../partner/benefit/view/style"

const AssociateView: React.FC = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({ associateList: [], isLoading: true })
    const { associateList } = state
    const appContext = useAppContext()
    const [moduleName, setModuleName] = useState() as any;
    const { apiProvider } = appContext
    const [filterText, setFilterText] = useState("")
    const context: ISessionContext = useSessionContext();

    useEffect(() => {
        getAssociateData()
        getModuleName()
    }, [])

    const getAssociateData = useCallback(async function () {
        const response: any = await apiProvider.get("/web/user/associate")
        setState({ associateList: response.data, isLoading: false })
    }, [])

    const cpfMask = (rowData: any) => {
        if (moduleName === ModuleEnum.B2C) {
            return (<>{maskCPF(rowData.cpfCnpj)}</>)
        } else {
            return (<>{maskCNPJ(rowData.cpfCnpj)}</>)
        }
    }

    function getModuleName() {
        if (context.session.modules[0].moduleEnum != ModuleEnum.ADMIN) {
            if (context.session.modules[0].moduleEnum == ModuleEnum.CONTRACTOR) {
                setModuleName(context.session.modules[0].contractor?.moduleName);
            } else if (context.session.modules[0].moduleEnum == ModuleEnum.PARTNER) {
                setModuleName(context.session.modules[0].partner?.moduleName);
            }
        }
    }

    const exportReport = async () => {
        appContext.setIsShowLoading(true)
        try {
            const response: any = await apiProvider.download('/web/user/associate/export?filterText=' + filterText)

            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = 'Exportação de usuários do app.xlsx'
            link.click()
        } catch (error) {
            console.error(error)
        }
        appContext.setIsShowLoading(false)
    }

    // NOVO ASSOCIATE
    function newAssociate() {
        navigate("/associate-form")
    }

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Usuário" />

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newAssociate} text="Novo usuário" />
                    <ButtonExport onClick={exportReport} />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    value={state.associateList}
                    responsiveLayout="scroll"
                    emptyMessage="Nenhum resultado encontrado"
                    itemsList={associateList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                >
                    <Column alignHeader="center" align="center" field="name" header="Nome" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="cpfCnpj" header={(moduleName === ModuleEnum.B2C) ? "CPF" : "CNPJ"} body={cpfMask} sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="email" header="E-mail" sortable style={{ width: '30%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: "10%" }} />
                </DataTableElement>
            </TableViewElement>
        </>
    )

    // DELETAR ASSOCIATE
    async function deleteAssociate(rowData: any) {
        try {
            await apiProvider.delete(`/web/user/associate/${rowData.id}`)
            appContext.toast.show({
                severity: "success",
                summary: "Usuário",
                detail: "Excluido com sucesso!",
                life: 3000,
            })
            getAssociateData()
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Usuário",
                detail: "Erro ao excluir usuário!",
                life: 3000,
            })
        }
    }

    // RENDERIZAÇÃO DAS COLUNAS DA TABELA
    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <ActionButtons
                    onClick={() =>
                        confirm(rowData, "Deseja excluir este usuário?", () =>
                            deleteAssociate(rowData)
                        )
                    }
                >
                    <img alt="" style={{ width: "17px" }} src={trashIcon} />
                </ActionButtons>
                <ActionButtons
                    onClick={() => {
                        navigate("/associate-form", {
                            state: { item: rowData, disabled: false },
                        })
                    }}
                >
                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtons>
            </div>
        )
    }
}

export default AssociateView
