import { Form, Formik } from 'formik';
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import * as Yup from 'yup';
import { useAppContext } from "../../../../../../../context/app";
import { Contractor } from "../../../../../../../dto/contractor";
import { ModuleEnumStatus } from '../../../../../../../dto/moduleEnum/module-enum-status';
import BackButtom from "../../../../../../components/atoms/back-buttom";
import PageHeader from "../../../../../../components/atoms/page-header";
import SpanSubtitle from "../../../../../../components/atoms/span-subtitle";
import ItemForm from "../../../../../../components/molecules/item-form";
import { LineFormElement } from "../../../../../../components/molecules/item-form/style";
import FormTemplateElement from "../../../../../../templates/form-template";
import { DivInput } from "../../../../partner/benefit/form/style";
import { ButtomRequestElement } from "../../../imports-view/style";

const TaxForm: React.FC = () => {
	const appContext = useAppContext()
	const { apiProvider } = appContext
	const location = useLocation()
	const isNew = !location.state
	const { state } = location as any
	const navigate = useNavigate()
	const [associate, setAssociate] = useState({} as any)
	const [disabled, setDisabled] = useState(true)
	const [selectedDataB2BOrB2C, setSelectedDataB2BOrB2C] = useState<Contractor>();

	useEffect(() => {
		if (state) {
			if (state.item.percentage) {
				state.item.percentage = (state.item.percentage * 100);
			}
			setAssociate(state?.item)
		}
	}, [])

	useEffect(() => {
		setDisabled(false);
	}, [selectedDataB2BOrB2C])

	const validations = Yup.object().shape({
		name: Yup.string().required(),
		percentage: Yup.string().required(),
		description: Yup.string().required()
	})

	const isLoadApi = (): boolean => {
		return !!associate.id
	}

	const save = async (values: any) => {
		try {
			appContext.setIsShowLoading(true)

			const data = {
				name: values.name,
				percentage: values.percentage / 100,
				isActive: (values.isActive || values.isActive == null) ? ModuleEnumStatus.ACTIVE : ModuleEnumStatus.INACTIVE,
				description: values.description
			}

			let msg = "";
			if (!isNew) {
				await apiProvider.put(`/admin/taxtype/${values.id}`, data)
				msg = "Alteração realizada com sucesso!"
			} else {
				await apiProvider.post('/admin/taxtype/', data)
				msg = "Cadastrado com sucesso!"
			}

			navigate('/tributes-view')

			appContext.toast.show({
				severity: 'success',
				summary: 'Contrato',
				detail: msg,
				life: 3000,
			})
		} catch (error: any) {
			appContext.toast.show({
				severity: 'error',
				summary: 'Contrato',
				detail: error.toString(),
				life: 3000,
			})
		} finally {
			appContext.setIsShowLoading(false)
		}
	}

	return (
		<>
			{(isNew) ? <PageHeader text='Novo Tributo' /> : <PageHeader text='Editar Tributo' />}

			<FormTemplateElement>

				<BackButtom onclick={() => {
					navigate('/tributes-view')
				}} />

				<SpanSubtitle text='Dados' />

				{(isLoadApi() || isNew) && (
					<Formik
						initialValues={associate}
						validationSchema={validations}
						onSubmit={save}
					>
						{(propsForm) => (
							<Form>
								<LineFormElement style={{
									justifyContent: 'space-between',
									display: 'block',
									marginTop: "30px"
								}}>
									<LineFormElement style={{ justifyContent: "flex-start" }}>
										<DivInput style={{ width: '100%' }}>
											<strong>Nome do tributo</strong>
											<ItemForm
												disabled={disabled}
												propsForm={propsForm}
												name='name'
												label=''
												widthPercent='100'
											/>
										</DivInput>
										<DivInput style={{ width: '40%' }}>
											<strong>Valor percentual dos Tributos</strong>
											<InputNumber
												inputId="currency-brl"
												value={isNew ? propsForm.values.percentage : propsForm.values.percentage}
												onValueChange={propsForm.handleChange}
												minFractionDigits={2}
												suffix='%'
												style={{ height: '55px', marginTop: '5px' }}
												min={0}
												max={100}
												name='percentage'
												disabled={!isNew}
											/>
										</DivInput>
										<DivInput style={{ width: '10%' }}>
											<strong>Ativo</strong>
											<InputSwitch
												name='isActive'
												checked={(isNew && propsForm.values.isActive == null) ? true : propsForm.values.isActive}
												onChange={propsForm.handleChange}
												disabled={disabled}
												style={{ marginTop: '5px' }}
											/>
										</DivInput>
									</LineFormElement>
								</LineFormElement>
								<strong>Descrição</strong>
								<LineFormElement style={{ justifyContent: 'space-between', height: '100px' }}>
									<LineFormElement style={{ justifyContent: "flex-start" }}>
										<InputTextarea
											style={{ marginTop: "5px", width: "98.5%" }}
											disabled={disabled}
											value={propsForm.values.description}
											name="description"
											autoResize
											onChange={propsForm.handleChange}
										/>
									</LineFormElement>
								</LineFormElement>
								<LineFormElement style={{
									marginBottom: '3rem',
									width: '70%',
									justifyContent: 'space-between'
								}}>
								</LineFormElement>
								<div style={{ textAlign: 'end' }}>
									<ButtomRequestElement
										type='submit'
										disabled={!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty}
									>
										Salvar dados
									</ButtomRequestElement>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</FormTemplateElement>
		</>
	)
}

export default TaxForm;
