import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useLocation, useNavigate } from "react-router"
import chats from "../../../assets/chat-bubble-front-color.svg"
import headPhone from "../../../assets/headPhone.png"
import { useSessionContext } from "../../../context/session"
import { ModuleEnum } from "../../../dto/moduleEnum/module-enum"
import { ButtonElement, ButtonElementClosedMenu, ButtonElementDiv, FooterMainDivElement, MenuElement, MenuItemsElement, MenuSpanElement, TextDivElement, UpperImageDivElement } from "./style"

interface MenuProps {
  itens: any[]
}

const Menu: React.FC<MenuProps> = ({ itens }) => {
  const location = useLocation()
  const navigate = useNavigate()

  function renderItem(item: any) {

    const isSelected: boolean = item.route.includes(location.pathname)
    
    return (
      <MenuItemsElement to={item.route[0]} key={item.key} selected={isSelected}>
        <FontAwesomeIcon icon={item.icon} fixedWidth />
        <MenuSpanElement> {item.title} </MenuSpanElement>
      </MenuItemsElement>
    )
  }

  const talkToUs = () => {
    navigate("/talk-to-us")
  }

  return (
    <MenuElement>

      <div style={{ height: "60%"}}>
       {itens.map(renderItem)}
      </div>

        { !useSessionContext().isMenuOpen && useSessionContext().session.modules[0].moduleEnum !== ModuleEnum.ADMIN && (
          <FooterMainDivElement>
            
            <UpperImageDivElement>
              <img alt="" style={{width: "100px", marginTop: '1em'}} src={chats}></img>
            </UpperImageDivElement>

            <TextDivElement>
              <span style={{color: "white", fontSize: "14px", display: "flex", textAlign: "center" }}>
               Precisando de ajuda ou suporte em algo?
              </span>
            </TextDivElement>

            <ButtonElement>
              <ButtonElementDiv onClick={() => talkToUs()}>
                <span style={{color: "white", fontWeight: "bold", justifyContent: "center", alignItems: "center", display: "flex", marginTop: "10px"}}>Entre em contato</span>
              </ButtonElementDiv>
            </ButtonElement>
            
          </FooterMainDivElement>
        )}

        { useSessionContext().isMenuOpen && useSessionContext().session.modules[0].moduleEnum !== ModuleEnum.ADMIN && (
          <ButtonElementClosedMenu onClick={() => talkToUs()}>
            <div style={{display: 'flex', height: '2.5em', justifyContent: 'center', alignItems: 'center'}} >
              <img alt="" src={headPhone}></img>
            </div>
          </ButtonElementClosedMenu>
        )}

    </MenuElement>
  )
}
export default Menu
