import { FilterMatchMode } from "primereact/api"
import { Column } from "primereact/column"
import { InputSwitch } from "primereact/inputswitch"
import { Tag } from "primereact/tag"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import penIcon from "../../../../../../../assets/buttons/pen.svg"
import trashIcon from "../../../../../../../assets/buttons/trash.svg"
import { useAppContext } from "../../../../../../../context/app"
import { ModuleEnumStatus } from "../../../../../../../dto/moduleEnum/module-enum-status"
import { TruncateData } from "../../../../../../../utils/truncateData"
import { confirm } from "../../../../../../../utils/validation"
import ButtomAddVm from "../../../../../../components/atoms/buttom-add-vm"
import DataTableElement from "../../../../../../components/atoms/data-table-view"
import PageHeader from "../../../../../../components/atoms/page-header"
import SearchTextLabel from "../../../../../../components/molecules/search-text-label"
import HeaderViewElement from "../../../../../../templates/view-header-template"
import {
    ActionButtonsElement,
    ButtomsElement,
    HeaderElementLeft,
    HeaderElementRight,
    TableElement
} from "./style"

const PopupView: React.FC = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({ popupList: [] as any, isLoading: true })
    const { popupList } = state
    const { apiProvider } = useAppContext()
    const appContext = useAppContext()
    const [filterText, setFilterText] = useState("")

    // LISTAR TODOS OS POPUPS
    const getAllPopups = useCallback(async () => {
        const response: any = await apiProvider.get("/admin/popup")
        setState({ popupList: response.data, isLoading: false })
    }, [])

    useEffect(() => {
        getAllPopups()
    }, [])

    // Ações do status
    const handleToggle = async (e: any) => {
        try {
            const data = {
                id: e.id ? e.id : null,
                isActive: (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
            }

            const formData = new FormData()
            formData.append("popup", JSON.stringify(data))

            const response: any = await apiProvider.put(`/admin/popup`, formData)
            if (response == null) {
                throw new Error("Não foi possível realizar essa alteração, tente novamente!");
            }

            appContext.toast.show({
                severity: 'success',
                summary: 'Usuário',
                detail: 'Alteração realizada com sucesso!',
                life: 3000,
            })

            setState({
                popupList: popupList.map((item: any) => {
                    if (item.id === e.id) {
                        return {
                            ...item,
                            isActive: (e.isActive == ModuleEnumStatus.ACTIVE) ? ModuleEnumStatus.INACTIVE : ModuleEnumStatus.ACTIVE
                        }
                    }
                    return item
                }), isLoading: false
            });
        } catch (error: any) {
            appContext.toast.show({
                severity: 'error',
                summary: 'Usuário',
                detail: error.response.data.message,
                life: 3000,
            })
        }
    };

    // RENDERIZAÇÃO
    return (
        <>
            <PageHeader text="Popup"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newPopup} text="Novo popup" />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value)
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{ global: { value: filterText, matchMode: FilterMatchMode.CONTAINS } }}
                    paginator
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.popupList}
                    responsiveLayout="scroll"
                    itemsList={popupList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="title" body={(e) => TruncateData(e.title, 50)} header="Título" sortable style={{ width: '45%' }} />
                    <Column alignHeader="center" align="center" field="priorityOrder" header="Prioridade" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="Intervalo" body={(e) => (e.startDay ?? '') + '/' + (e.endDay ?? '')} header="Intervalo" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="discloseRequest" body={actionStatus} header="Origem" sortable style={{ width: '10%' }} />
                    <Column alignHeader='center' align='center' body={(e) => <InputSwitch checked={(e.isActive == ModuleEnumStatus.ACTIVE) ? true : false} onChange={() => handleToggle(e)} />} field='isActive' header='Ativo' sortable style={{ width: '15%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" style={{ width: '10%' }} />
                </DataTableElement>
            </TableElement>
        </>
    )

    function actionStatus(rowData: any) {
        if (rowData.discloseRequest) {
            return (<Tag className="mr-2" icon="pi pi-exclamation-triangle" severity="warning" value="Solicitação" />);
        } else {
            return (<Tag className="mr-2" icon="pi pi-user" severity="success" value="ADMIN" />);
        }
    }

    function newPopup() {
        navigate("/popup-form")
    }

    async function deletePopup(rowData: any) {
        try {
            await apiProvider.delete(`/admin/popup/${rowData.id}`)
            appContext.toast.show({
                severity: "success",
                summary: "Popup",
                detail: "Excluido com sucesso!",
                life: 3000,
            })
            getAllPopups()
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Popup",
                detail: "Erro ao excluir popup!",
                life: 3000,
            })
        }
    }

    function actionButtons(rowData: any) {
        return (
            <ButtomsElement>
                <ActionButtonsElement
                    onClick={() =>
                        confirm(rowData, "Deseja excluir este popup?", () =>
                            deletePopup(rowData)
                        )
                    }
                >
                    <img alt="" style={{ width: "17px" }} src={trashIcon} />{" "}
                </ActionButtonsElement>
                <ActionButtonsElement
                    onClick={() => {
                        navigate("/popup-form", {
                            state: { item: rowData, disabled: false },
                        })
                    }}
                >
                    <img alt="" style={{ width: "17px" }} src={penIcon} />
                </ActionButtonsElement>
            </ButtomsElement>
        )
    }
}

export default PopupView
