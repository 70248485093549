import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";

export const HomeTemplateElement = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
`;

export const BodyTemplateElement = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 75px);
  padding: 5px;
  padding-top: 20px;
  padding-bottom: 2px;
`;

export const ContentPageElement = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px;
  padding-top: 0px;
`;

export const ScrollPanelElement = styled(ScrollPanel)`
  width: 100%;
  height: 100%;

  .p-scrollpanel-bar {
    border: 0 none;
    background: #cdd0d4 !important;
  }
`;
