import { useNavigate } from "react-router";
import PageHeader from "../../../../components/atoms/page-header";
import { ItemMenuReportElement } from "./style";
import {Accordion, AccordionTab} from "primereact/accordion";
import React from "react";

const ImportsView: React.FC = () => {
    const navigate = useNavigate();
    return <>
        <PageHeader text="Importações em massa"/>

        <Accordion >
            <AccordionTab header="Módulo B2C e B2B">
                <ItemMenuReportElement onClick={() => {navigate('/register-user-form') }}>
                    Usuários do app
                </ItemMenuReportElement>

                <ItemMenuReportElement onClick={() => {navigate('/register-productor-form') }}>
                    Produtos
                </ItemMenuReportElement>

                {/* <ItemMenuReportElement onClick={() => {navigate('/register-payment-form') }}>
                    Processamento de pagamentos de usuários (arquivo OFX)
                </ItemMenuReportElement> */}
            </AccordionTab>
            <AccordionTab header="Exclusivo para o módulo B2C">
                <ItemMenuReportElement onClick={() => {navigate('/register-transaction-form') }}>
                    Transações
                </ItemMenuReportElement>
            </AccordionTab>

        </Accordion>
        
    </>
}

export default ImportsView