import {FilterMatchMode} from "primereact/api";
import {Column} from "primereact/column";
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import penIcon from "../../../../../../../assets/buttons/pen.svg";
import {useAppContext} from "../../../../../../../context/app";
import {Contractor} from "../../../../../../../dto/contractor";
import {parseDoubleToStr} from "../../../../../../../utils/formatUtil";
import ButtomAddVm from "../../../../../../components/atoms/buttom-add-vm";
import DataTableElement from "../../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../../components/molecules/search-text-label";
import {HeaderElementLeft, HeaderElementRight} from "../../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../../templates/view-header-template";
import TableViewElement from "../../../../../../templates/view-table-template";
import {ActionButtons} from "../../../../partner/benefit/view/style";
import {Dropdown} from "primereact/dropdown";
import {ModuleEnum} from "../../../../../../../dto/moduleEnum/module-enum";

const ComissionView: React.FC = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({commissionList: [], isLoading: true});
    const {commissionList: commissionList} = state;
    const {apiProvider} = useAppContext();
    const [filterText, setFilterText] = useState("");
    const [groupedContractors, setGroupedContractors] = useState([] as any[]);
    const [selectedContractorsB2BOrB2C, setSelectedContractorsB2BOrB2C] = useState<Contractor>();

    useEffect(() => {
        getAllContractors()
    }, []);

    useEffect(() => {
        getCommissions();
    }, [selectedContractorsB2BOrB2C]);

    /**
     * FUNÇÕES DO SISTEMA
     */

    const getCommissions = useCallback(async function () {
        if (selectedContractorsB2BOrB2C?.id) {
            setState({commissionList: [], isLoading: true})
            const response: any = await apiProvider.get("/admin/commission/byIdContractor/" + selectedContractorsB2BOrB2C?.id)
            setState({commissionList: response.data, isLoading: false})
        } else {
            setState({commissionList: [], isLoading: false})
        }

    }, [selectedContractorsB2BOrB2C])

    // Importação com B2B e B2C com delimitação por módulo
    const getAllContractors = useCallback(async () => {
        const response: any = await apiProvider.get('/admin/contractor')

        if (response?.data?.length > 0) {
            let aB2C: any = [];
            let aB2B: any = [];

            response.data.forEach((item: Contractor) => {
                if (item.moduleName == ModuleEnum.B2C) {
                    aB2C.push({label: item.name, value: item});
                } else if (item.moduleName == ModuleEnum.B2B) {
                    aB2B.push({label: item.name, value: item});
                }
            });

            let objectB2cAndB2B = [];

            if (aB2C?.length > 0) {
                objectB2cAndB2B.push({label: 'B2C', items: aB2C})
            }

            if (aB2B?.length > 0) {
                objectB2cAndB2B.push({label: 'B2B', items: aB2B})
            }

            setGroupedContractors(objectB2cAndB2B)
        }
    }, [])

    function newCommission() {
        navigate("/commission-form", {
            state: {contractor: selectedContractorsB2BOrB2C, disabled: false},
        });
    }

    function actionButtons(rowData: any) {
        return (
            <div style={{display: "flex", justifyContent: "space-around"}}>
                <ActionButtons
                    onClick={() => {
                        navigate("/commission-form", {
                            state: {contractor: selectedContractorsB2BOrB2C, item: rowData, disabled: false},
                        });
                    }}>
                    <img alt="" style={{width: "17px"}} src={penIcon}/>
                </ActionButtons>
            </div>
        );
    }

    const MaskPercentual = (rowData: any) => {
        return (
            <>{rowData.percentCommission + "%"}</>
        )
    }

    const MaskMoney = (rowData: any) => {
        return (
            <>{"R$ " + parseDoubleToStr(rowData.totalReceived)}</>

        )
    }

    return (
        <>
            <PageHeader text="Comissões"/>

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm
                        onclick={newCommission}
                        text="Nova comissão"
                        disable={(selectedContractorsB2BOrB2C == null) ? true : false}
                    />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableViewElement>
                <div style={{width: "100%", marginBottom: "1em"}}>
                    Contratante
                    <Dropdown
                        name='contractor'
                        style={{width: '100%', height: '50px', borderRadius: '10px'}}
                        value={selectedContractorsB2BOrB2C}
                        onChange={(e) => setSelectedContractorsB2BOrB2C(e.value)}
                        options={groupedContractors}
                        optionLabel="label"
                        optionGroupLabel="label"
                        optionGroupChildren="items"
                        emptyMessage="Nenhum resultado encontrado."
                        emptyFilterMessage="Nenhum resultado encontrado."
                        placeholder="Selecione o contratante"
                        showFilterClear
                        filter
                    />
                </div>

                <DataTableElement
                    loading={state.isLoading}
                    filters={{
                        global: {value: filterText, matchMode: FilterMatchMode.CONTAINS},
                    }}
                    globalFilterFields={['moduleName', 'dateToString', 'activeUsersAmout', 'totalReceived', 'percentCommission']}
                    paginator
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    rows={10}
                    value={state.commissionList}
                    responsiveLayout="scroll"
                    emptyMessage={() => {
                        if (selectedContractorsB2BOrB2C?.id) {
                            return <>Nenhum resultado encontrado</>
                        } else {
                            return <>Selecione um parceiro para buscar os registros de comissão</>
                        }
                    }}
                    itemsList={commissionList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="moduleName" header="Módulo" sortable style={{ width: '10%' }}/>
                    <Column alignHeader="center" align="center" field="dateToString" header="Data/Entrada" sortable style={{ width: '20%' }}/>
                    <Column alignHeader="center" align="center" field="activeUsersAmout" header="Qnt de usuários ativos" sortable style={{ width: '20%' }}/>
                    <Column alignHeader="center" align="center" field="totalReceived" header="Total recebido" body={MaskMoney} sortable style={{ width: '20%' }}/>
                    <Column alignHeader="center" align="center" field="percentCommission" header="Percentual da comissão" body={MaskPercentual} sortable style={{ width: '20%' }} />
                    <Column alignHeader="center" align="center" body={actionButtons} header="Ações" style={{ width: '10%' }}/>
                </DataTableElement>
            </TableViewElement>
        </>
    );
}
export default ComissionView;
