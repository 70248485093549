import moment from "moment";
import { Checkbox } from 'primereact/checkbox';
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import * as Yup from "yup";
import { ApplicationStatus } from '../../../App';
import { sessionProvider, useAppContext } from '../../../context/app';
import CarouselVm, { Banner } from '../../components/molecules/carousel-vm';
import Mobile from "../mobile";
import {
    ButtomElement,
    CheckboxElement,
    FormElement,
    LeftSideElement,
    LoginElement,
    LoginTituloElement,
    ResetPassword,
    ResetPasswordButtonElement,
    RightPanelElement,
    RightSideElement
} from './style';
import { ModuleEnum } from "../../../dto/moduleEnum/module-enum";

export interface LoginProps {
    banners?: Banner[];
}

const Login: React.FC<LoginProps> = ({ banners }) => {
    const context = useAppContext();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [checked, setChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);

    async function handleLogin() {
        if (process.env.REACT_APP_ENVIRONMENT == 'local') {
            try {
                const userSchema = Yup.object()
                    .shape({
                        email: Yup.string().required("Favor digitar o e-mail!").email()
                    });
                await userSchema.validate({ email: email, password: password });
                sigIn();
            } catch (err: any) {
                context.toast.show({
                    severity: 'error',
                    summary: 'Atenção',
                    detail: err.errors || 'Erro ao realizar o login, tente novamente!',
                    life: 3000,
                });
            }
        } else {
            if (!email || !password) {
                context.toast.show({
                    severity: 'error',
                    summary: 'Atenção',
                    detail: 'Preencha todos os campos!',
                    life: 3000,
                });
                return
            }
            try {
                const userSchema = Yup.object()
                    .shape({
                        password: Yup.string().required("Favor digitar a senha!"),
                        email: Yup.string().required("Favor digitar o e-mail!").email()
                    });
                await userSchema.validate({ email: email, password: password });
                sigIn();
            } catch (err: any) {
                context.toast.show({
                    severity: 'error',
                    summary: 'Atenção',
                    detail: err.errors || 'Erro ao realizar o login, tente novamente!',
                    life: 3000,
                });
            }
        }
    }

    async function sigIn() {
        try {
            setDisabled(true);
            const session = await context.sessionProvider.initSessionTemp(email.trim(), password);

            if (session) {
                localStorage.setItem("@vmparcerias:login_time", `${moment()}`)
                localStorage.setItem("@vmparcerias:keep_logged", `${checked}`)

                context.setApplicationStatus(ApplicationStatus.LOGGED_IN_USER);

                navigate('/')
            } else {
                context.toast.show({
                    severity: 'error',
                    summary: 'Usuário',
                    detail: 'Usuário inválido, tente novamente!',
                    life: 3000,
                });
            }

        } catch (err: any) {
            const message = err.message;
            if (message === 'ACCESS_DENIED') {
                context.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: 'Acesso negado!',
                    life: 3000,
                });
                return;
            }

            if (message.includes('Incorrect username or password')) {
                context.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: 'Usuário ou senha incorretos.',
                    life: 3000,
                });
                return;
            }

            if (message.includes('Temporary password has expired and must be reset by an administrator')) {
                context.toast.show({
                    severity: 'error',
                    summary: 'Login',
                    detail: 'A senha temporária expirou e deve ser redefinida por um administrador.',
                    life: 3000,
                });
                return;
            }

            if (message === 'NEW_PASSWORD_REQUIRED') {
                navigate('/new-password', { state: { backPage: '/', email, oldPass: password } });
                return;
            }

            if (message === 'EMAIL_NOT_VERIFIED') {
                navigate('/verify-account', { state: { backPage: '/', email } });
                return;
            }

            context.toast.show({
                severity: 'error',
                summary: 'Login',
                detail: `Não foi possível fazer login: ${message}`,
                life: 3000,
            });
        } finally {
            setDisabled(false);
        }
    }

    function handleResetPassword() {
        navigate('/reset-password', { state: { backPage: '/' } });
    }

    useEffect(() => {
        function handleKeyDown(e: any) {
            if (e.key === 'Enter') {
                if (email != '') {
                    e.preventDefault();
                    handleLogin();
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [email, password]);

    return (
        <>
            <Mobile />
            <LoginElement>
                <LeftSideElement>
                    <CarouselVm banners={banners} />
                </LeftSideElement>

                <RightSideElement>
                    <RightPanelElement>
                        <LoginTituloElement>
                            Seja bem vindo! <br /> Faça o seu login
                        </LoginTituloElement>

                        <FormElement>
                            E-mail:
                            <InputText
                                style={{ marginBottom: '15px', borderRadius: '10px' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Digite seu e-mail" id="email"
                                name="email"
                            />
                            <form>
                                Senha:
                                <Password
                                    style={{ display: 'flex', borderRadius: '10px' }}
                                    size={38}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    feedback={false}
                                    placeholder="Digite a sua senha"
                                    toggleMask
                                />
                            </form>
                            <CheckboxElement>
                                <Checkbox
                                    style={{ marginRight: '15px' }}
                                    onChange={(e) => setChecked(e.checked)}
                                    checked={checked}
                                />
                                Manter-me conectado
                            </CheckboxElement>
                            <ButtomElement
                                onClick={handleLogin}
                                disabled={disabled}
                            >
                                Entrar
                            </ButtomElement>
                            <ResetPassword>
                                <ResetPasswordButtonElement
                                    onClick={handleResetPassword}
                                >
                                    Esqueci minha senha
                                </ResetPasswordButtonElement>
                            </ResetPassword>
                        </FormElement>
                    </RightPanelElement>
                </RightSideElement>
            </LoginElement>
        </>
    );
};

export default Login;
