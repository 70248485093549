import { Form, Formik } from "formik"
import { Checkbox } from "primereact/checkbox"
import { InputSwitch } from "primereact/inputswitch"
import { InputTextarea } from "primereact/inputtextarea"
import { MultiSelect } from "primereact/multiselect"
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import * as Yup from "yup"
import { useAppContext } from "../../../../../../context/app"
import { BenefitsDto } from "../../../../../../dto/benefits"
import { ModuleEnumStatus } from "../../../../../../dto/moduleEnum/module-enum-status"
import BackButtom from "../../../../../components/atoms/back-buttom"
import ButtomRequestElement from "../../../../../components/atoms/buttom-request-form"
import InputTextVm from "../../../../../components/atoms/input-text"
import PageHeader from "../../../../../components/atoms/page-header"
import SelectDropDownVm from "../../../../../components/atoms/select-drop-down"
import SpanSubtitle from "../../../../../components/atoms/span-subtitle"
import ItemForm from "../../../../../components/molecules/item-form"
import { LineFormElement } from "../../../../../components/molecules/item-form/style"
import { Error } from "../../../../../components/organisms/form-address/style"
import FormTemplateElement from "../../../../../templates/form-template"
import { Session } from "../../../../../../dto/session";
import { ModuleEnum } from "../../../../../../dto/moduleEnum/module-enum"
import {
  ButtomSaveElement,
  CheckboxElement,
  DivInput,
  DivSelect,
  FormElementItems
} from "./style"

const BenefitForm: React.FC = () => {
  const navigate = useNavigate()
  const appContext = useAppContext()
  const { apiProvider } = appContext
  const location = useLocation()
  const { state } = location as any
  const isNew = !location.state
  const [benefit, setBenefit] = useState({} as BenefitsDto)
  const [categoryList, setCategoryList] = useState([])
  const [storeList, setStoreList] = useState([])
  const [productList, setProductList] = useState([])
  const [contractorList, setContractorList] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [isActive, setIsActive] = useState(true);
  const [alterIsActive, setAlterIsActive] = useState(false);
  const session: Session = useAppContext().sessionProvider.getSession();

  const getAllCategories = useCallback(async () => {
    const response: any = await apiProvider.get("/web/category/")
    setCategoryList(response.data)
  }, [])

  const getAllStories = useCallback(async () => {
    const response: any = await apiProvider.get("/web/store")
    setStoreList(response.data)
  }, [])

  const getAllProducts = useCallback(async () => {
    const response: any = await apiProvider.get("/web/product")
    setProductList(response.data)
  }, [])

  const getAllContractors = useCallback(async () => {
    let moduleName = '';
    if (session.modules[0].moduleEnum === ModuleEnum.PARTNER) {
      moduleName = '?moduleName=' + session.modules[0]?.partner?.moduleName;
    }

    const response: any = await apiProvider.get("/admin/contractor" + moduleName)
    setContractorList(response.data)
  }, [])

  const getOneBenefit = useCallback(async () => {
    const response: any = await apiProvider.get(`/web/benefit/${state.item.id}`)
    if (response.data.isActive == ModuleEnumStatus.INACTIVE) {
      setIsActive(false)
    }
    setBenefit(response.data)
  }, [])

  useEffect(() => {
    getAllData()
  }, [])

  async function getAllData() {
    await getAllCategories()
    await getAllStories()
    await getAllProducts()
    await getAllContractors()

    if (!isNew) {
      await getOneBenefit()
      setDisabled(state.disabled)
    }
  }

  const validations = Yup.object().shape({
    benefitValue: Yup.number().min(0).required(),
    category: Yup.object().required(),
    externalCode: Yup.string().required().max(10),
    rule: Yup.string().required().max(2000),
    title: Yup.string().required().max(250),
    stores: Yup.array().when(["isAllStores"],
      (isAllStores, schema): any => {
        if (!isAllStores) {
          return schema.required().min(1)
        }
      }),
    products: Yup.array().when(["isAllProducts"],
      (isAllProducts, schema): any => {
        if (!isAllProducts) {
          return schema.required().min(1)
        }
      }
    ),
    contractors: Yup.array().when(["isAllContractors"],
      (isAllContractors, schema): any => {
        if (!isAllContractors) {
          return schema.required().min(1)
        }
      }
    ),
  })

  function handleChangeSetActive(e: any) {
    setIsActive(e)
    setAlterIsActive(true)
  }

  return (
    <>
      <PageHeader text="Novo benefício" />

      <FormTemplateElement>
        <BackButtom
          onclick={() => {
            navigate("/benefit-view")
          }}
        />
        <SpanSubtitle text="Dados - Benefício" />

        {(isLoadApi() || isNew) && (
          <Formik
            validationSchema={validations}
            initialValues={benefit}
            onSubmit={save}
          >
            {(propsForm) => (
              <Form>
                <LineFormElement style={{ justifyContent: "flex-start" }}>
                  <DivInput style={{ width: "10em" }}>
                    Valor (%)
                    <InputTextVm
                      style={{ borderRadius: "10px", width: "100%" }}
                      name="benefitValue"
                      onChange={propsForm.handleChange}
                      value={propsForm.values.benefitValue}
                      disabled={disabled}
                      type="number"
                    />
                    <Error>
                      {propsForm.errors.benefitValue ? (
                        propsForm.errors.benefitValue
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </DivInput>

                  <DivInput style={{ width: "100%" }}>
                    Título
                    <InputTextVm
                      style={{ borderRadius: "10px", width: "100%" }}
                      name="title"
                      onChange={propsForm.handleChange}
                      value={propsForm.values.title}
                      disabled={disabled}
                    />
                    <Error>
                      {propsForm.errors.title ? (
                        propsForm.errors.title
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </DivInput>
                </LineFormElement>

                <LineFormElement style={{ justifyContent: "flex-start" }}>
                  <FormElementItems style={{ width: "40%" }}>
                    Categoria
                    <SelectDropDownVm
                      disabled={disabled}
                      name="category"
                      onChange={propsForm.handleChange}
                      value={propsForm.values.category}
                      options={categoryList}
                      optionLabel="name"
                    />
                    <Error>
                      {propsForm.errors.category ? (
                        propsForm.errors.category
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </FormElementItems>

                  <FormElementItems style={{ marginLeft: "2em" }}>
                    Ativo
                    <InputSwitch
                      checked={isActive}
                      name='isActive'
                      onChange={(e: any) => {
                        handleChangeSetActive(e.target.value)
                      }}
                      disabled={disabled}
                    />
                    <Error>
                      {propsForm.errors.status ? (
                        propsForm.errors.status
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </FormElementItems>

                  <FormElementItems style={{ marginLeft: '2em', marginTop: '-5px' }}>
                    <ItemForm
                      disabled={disabled}
                      propsForm={propsForm}
                      name="externalCode"
                      label="Código externo"
                      widthPercent="50"
                    />
                  </FormElementItems>
                </LineFormElement>

                <FormElementItems>
                  <div >
                    Regras
                    <InputTextarea
                      style={{ marginTop: "5px", width: "100%" }}
                      disabled={disabled}
                      value={propsForm.values.rule}
                      name="rule"
                      autoResize
                      onChange={propsForm.handleChange}
                    />
                  </div>
                  <Error>
                    {propsForm.errors.rule ? (propsForm.errors.rule) : (<>&nbsp;</>)}
                  </Error>
                </FormElementItems>

                <CheckboxElement>
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    disabled={disabled}
                    checked={propsForm.values.isAllStores}
                    onChange={propsForm.handleChange}
                    name="isAllStores"
                  />
                  Mostrar itens de todas as lojas.
                </CheckboxElement>

                {!propsForm.values.isAllStores && (
                  <DivSelect>
                    <MultiSelect
                      style={{ width: "100%" }}
                      disabled={disabled}
                      value={propsForm.values.stores}
                      placeholder="Selecione uma ou mais loja"
                      name="stores"
                      options={storeList}
                      display="chip"
                      optionLabel="description"
                      onChange={propsForm.handleChange}
                    />
                    <Error>
                      {propsForm.errors.stores ? (
                        propsForm.errors.stores
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </DivSelect>
                )}

                <CheckboxElement>
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    disabled={disabled}
                    checked={propsForm.values.isAllProducts}
                    onChange={propsForm.handleChange}
                    name="isAllProducts"
                  />
                  Todos os produtos
                </CheckboxElement>

                {!propsForm.values.isAllProducts && (
                  <DivSelect>
                    <MultiSelect
                      style={{ width: "100%" }}
                      disabled={disabled}
                      value={propsForm.values.products}
                      placeholder="Selecione um ou mais produtos"
                      name="products"
                      options={productList}
                      display="chip"
                      optionLabel="name"
                      maxSelectedLabels={5}
                      onChange={propsForm.handleChange}
                    />
                    <Error>
                      {propsForm.errors.products ? (
                        propsForm.errors.products
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </DivSelect>
                )}

                <CheckboxElement>
                  <Checkbox
                    style={{ marginRight: "10px" }}
                    disabled={disabled}
                    checked={propsForm.values.isAllContractors}
                    onChange={propsForm.handleChange}
                    name="isAllContractors"
                  />{" "}
                  Todos os contratantes
                </CheckboxElement>

                {!propsForm.values.isAllContractors && (
                  <DivSelect>
                    <MultiSelect
                      style={{ width: "100%" }}
                      disabled={disabled}
                      value={propsForm.values.contractors}
                      placeholder="Selecione um ou mais contratantes"
                      name="contractors"
                      options={contractorList}
                      display="chip"
                      optionLabel="name"
                      onChange={propsForm.handleChange}
                    />
                    <Error>
                      {propsForm.errors.contractors ? (
                        propsForm.errors.contractors
                      ) : (
                        <>&nbsp;</>
                      )}
                    </Error>
                  </DivSelect>
                )}
                  <div style={{ textAlign: 'end' }}>
                    {!disabled && (
                      <ButtomSaveElement>
                        <ButtomRequestElement
                          type="submit"
                          disabled={!alterIsActive && (!propsForm.isValid || propsForm.isSubmitting || !propsForm.dirty)}
                        >
                          Salvar dados
                        </ButtomRequestElement>
                      </ButtomSaveElement>
                    )}
                  </div>
              </Form>
            )}
          </Formik>
        )}
      </FormTemplateElement>
    </>
  )

  async function save(values: any) {
    let contractorsId
    let storesId
    let productsId

    if (!values.isAllContractors) {
      contractorsId = values.contractors.map((ids: any) => ids.id)
    } else {
      contractorsId = []
    }

    if (!values.isAllStores) {
      storesId = values.stores.map((ids: any) => ids.id)
    } else {
      storesId = []
    }

    if (!values.isAllProducts) {
      productsId = values.products.map((ids: any) => ids.id)
    } else {
      productsId = []
    }

    const data = {
      id: values.id ? values.id : "",
      benefitValue: values.benefitValue,
      title: values.title,
      categoryId: values.category.id,
      externalCode: values.externalCode,
      isActive: (isActive) ? ModuleEnumStatus.ACTIVE : ModuleEnumStatus.INACTIVE,
      isPrimary: true,
      rule: values.rule,

      isAllContractors: !!values.isAllContractors,
      contractorsId,

      isAllStores: !!values.isAllStores,
      storesId,

      isAllProducts: !!values.isAllProducts,
      productsId,
    }

    appContext.setIsShowLoading(true)
    try {
      if (!isNew) {
        await apiProvider.put('/web/benefit', data)
      } else {
        await apiProvider.post('/web/benefit', data)
      }
      navigate("/benefit-view")

      appContext.toast.show({
        severity: "success",
        summary: "Benefício",
        detail: "Salvo com sucesso!",
        life: 3000,
      })
    } catch (error: any) {
      let title = 'Benefício'
      let mensagem = 'Erro ao salvar benefício, tente novamente!'

      if (error.response.status === 400 && error.response.data.message) {
        mensagem = error.response.data.message
      }

      appContext.toast.show({
        severity: "error",
        summary: title,
        detail: mensagem,
        life: 5000,
      })

      console.error(error)
    }
    appContext.setIsShowLoading(false)
  }

  function isLoadApi(): boolean {
    return !!benefit.id
  }
}
export default BenefitForm
