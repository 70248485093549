import { ScrollPanel } from "primereact/scrollpanel";
import styled from "styled-components";

export const DashboardSize = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 120%;
`;

export const UpperDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 9%;
`;

export const BannerElement = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  background-color: #6285ae;
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const DashboardDivs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 59%;
`;

export const DivsLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 65%;
`;

export const Chart1 = styled.div`
  width: 32.9%;
  height: 85%;
  padding: 15px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
`;

export const Chart2 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: white;
  padding: 15px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
`;

export const DivRight = styled.div`
  padding: 18px;
  width: 34%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.15);
  background-color: white;
`;

export const DivsBanner = styled.div`
  margin: auto;
  width: 35%;
  font-size: 17px;
  color: #d9ebf3;
  text-align: center;
`;

export const BannerTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

export const BannerButton = styled.button`
  border: none;
  margin-top: 12px;
  border-radius: 10px;
  background-color: #00205c;
  color: white;
  width: 180px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

export const HeaderItemElement = styled.div`
  color: #42425d;
`;

export const ScrollPanelElement = styled(ScrollPanel)`
  width: 100%;
  height: 85%;

  .p-scrollpanel-bar {
    border: 0 none;
    background: #cdd0d4 !important;
  }
`;

export const AssociatesDivElement = styled.div`
  display: flex;
  width: 68%;
  align-items: flex-start;
  flex-direction: column;
`;

export const BannerImage = styled.div`
  display: flex;
  width: 25%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const ImgDivElement = styled.div`
  display: flex;
  margin-right: 0.7em;
  margin-bottom: 1em;
  align-items: center;
  width: 20%;
  height: 75%;
`;

export const Square = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 82%;
  border-radius: 16px;
  background-color: #5edb93;
`;

export const TextChart = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: #42425d;
  font-weight: 500;
`;

export const DivText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Partners = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

export const CircleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Circle = styled.div`
  display: flex;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  box-shadow: 2px 3px 8px 1px rgba(0, 0, 0, 0.12);
  background-color: white;
  margin-right: 10px;
`;

export const DivText2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 72%;
`;

export const TextGraph = styled.span`
  font-size: 17px;
  font-weight: 500;
  color: #42425d;
`;

export const ChartBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChartBarElement = styled.div`
  display: flex;
  width: 100%;
`;

export const StraightDiv = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  width: 80%;
  height: 50px;
`;

export const StraightElement = styled.div`
  border-radius: 10px;
  background-color: #6285ae;
  width: 100%;
  height: 22px;
  margin-right: 10px;
`;

export const TextChartDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #42425d;
`;

export const ValueElement = styled.div`
  width: 60px;
  color: #42425d;
  font-size: 16px;
  font-weight: 400;
`;

export const TwoDivs = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 34%;
`;
