import { PageHeaderElement } from "./style";

interface PageHeaderText {
  text: string;
  subtext ?: string;
  isHidden ?: boolean;
}

const PageHeader: React.FC<PageHeaderText> = ({ text, subtext, isHidden}) => {
  return (
      <>
          <div style={{ display: isHidden ? 'none' : 'block' }}>
            <PageHeaderElement>{ text }</PageHeaderElement>
            <p style={{fontStyle: 'italic', marginBottom:'20px', marginTop:'0', }}>{subtext}</p>

          </div>
      </>
  )
};

export default PageHeader;
