import { FilterMatchMode } from "primereact/api";
import { Column } from "primereact/column";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import penIcon from "../../../../../../assets/buttons/pen.svg";
import trashIcon from "../../../../../../assets/buttons/trash.svg";
import { useAppContext } from "../../../../../../context/app";
import { Session } from '../../../../../../dto/session';
import { confirm } from "../../../../../../utils/validation";
import ButtomAddVm from "../../../../../components/atoms/buttom-add-vm";
import DataTableElement from "../../../../../components/atoms/data-table-view";
import PageHeader from "../../../../../components/atoms/page-header";
import SearchTextLabel from "../../../../../components/molecules/search-text-label";
import { HeaderElementLeft, HeaderElementRight } from "../../../../../components/organisms/header/style";
import HeaderViewElement from "../../../../../templates/view-header-template";
import { ActionButtonsElement, TableElement } from "../../../admin/forms/contractor/view/style";

const UserPartnerView: React.FC = () => {
    const navigate = useNavigate();
    const appContext = useAppContext();
    const [state, setState] = useState({ userList: [], isLoading: true });
    const { userList } = state;
    const [filterText, setFilterText] = useState("");
    const { apiProvider } = useAppContext();
    const session: Session = appContext.sessionProvider.getSession();

    const getAllUsers = useCallback(
        async function () {
            const response: any = await apiProvider.get("/web/user/partner");
            setState({ userList: response.data, isLoading: false });
        },
        [apiProvider]
    );

    useEffect(() => {
        getAllUsers();
    }, [getAllUsers]);

    function showContent(rowData: any) {
        if (rowData.principal) {
            return false;
        } else {
            if (session.masterManager) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <>
            <PageHeader text="Gestores"> </PageHeader>

            <HeaderViewElement>
                <HeaderElementLeft>
                    <ButtomAddVm onclick={newUser} text="Novo gestor" />
                </HeaderElementLeft>
                <HeaderElementRight>
                    <SearchTextLabel
                        onChange={(e: any) => {
                            setFilterText(e.target.value);
                        }}
                    />
                </HeaderElementRight>
            </HeaderViewElement>

            <TableElement>
                <DataTableElement
                    loading={state.isLoading}
                    paginator
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    filters={{
                        global: { value: filterText, matchMode: FilterMatchMode.CONTAINS },
                    }}
                    rows={10}
                    emptyMessage="Nenhum resultado encontrado"
                    value={state.userList}
                    responsiveLayout="scroll"
                    itemsList={userList}
                    sortField="id"
                    sortOrder={-1}
                    rowsPerPageOptions={[5, 10, 25]}
                    resizableColumns
                >
                    <Column alignHeader="center" align="center" field="name" header="Nome" sortable style={{ width: '35%' }} />
                    <Column alignHeader="center" align="center" field="email" header="Email" sortable style={{ width: '35%' }} />
                    <Column alignHeader="center" align="center" field="principal" body={(e) => actionStatusPricipal(e)} header="Principal?" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="masterManager" body={(e) => actionStatusManager(e)} header="Gestor?" sortable style={{ width: '10%' }} />
                    <Column alignHeader="center" align="center" field="actions" body={actionButtons} header="Ações" sortable style={{ width: '10%' }} />
                </DataTableElement>
            </TableElement>
        </>
    );

    function actionButtons(rowData: any) {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {showContent(rowData) && (
                    <ActionButtonsElement onClick={() => confirm(rowData, "Deseja excluir este usuário?", () => deleteUser(rowData))}>
                        <img alt="" style={{ width: "17px" }} src={trashIcon} />
                    </ActionButtonsElement>
                )}
                <ActionButtonsElement
                    onClick={() => {
                        navigate("/userPartner-form", {
                            state: { item: rowData, disabled: false },
                        });
                    }}
                >
                    <img style={{ width: "17px" }} alt="" src={penIcon} />
                </ActionButtonsElement>
            </div>
        );
    }

    async function deleteUser(rowData: any) {
        try {
            await apiProvider.delete(`/web/user/partner/${rowData.id}`);
            appContext.toast.show({
                severity: "success",
                summary: "Usuário",
                detail: "Excluido com sucesso!",
                life: 3000,
            });
            getAllUsers();
        } catch (error) {
            appContext.toast.show({
                severity: "error",
                summary: "Usuário",
                detail: "Erro ao excluir usuário!",
                life: 3000,
            });
        }
    }

    function newUser() {
        navigate("/userPartner-form");
    }

    function actionStatusManager(rowData: any) {
        if (rowData.masterManager) {
            return (<i className="pi pi-check" style={{ color: 'green' }} />);
        } else {
            return (<i className="pi pi-times" style={{ color: 'red' }}></i>);
        }
    }

    function actionStatusPricipal(rowData: any) {
        if (rowData.principal) {
            return (<i className="pi pi-check" style={{ color: 'green' }} />);
        } else {
            return (<i className="pi pi-times" style={{ color: 'red' }}></i>);
        }
    }

}

export default UserPartnerView;
