import { ButtonExportElement } from "./style";

interface ButtonEvent {
    onClick: any;
}

const ButtonImport: React.FC<ButtonEvent> = ({onClick}) => { 
    return <ButtonExportElement className="p-button-raised p-button-text" label="Importar" icon="pi pi-upload" onClick={onClick}/> 
}

export default ButtonImport;
